import { Pipe, PipeTransform } from '@angular/core';

interface ShouldShow {
  shouldShow?: () => boolean;
}

@Pipe({
  name: 'filterShouldShow'
})
export class FilterShouldShowPipe implements PipeTransform {
  transform<T extends ShouldShow>(items: T[]): T[] {
    return items?.filter((item) => item.shouldShow?.() ?? true) ?? [];
  }
}
