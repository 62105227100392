import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@ct/core';
import { take } from 'rxjs/operators';

import { CookiesNoticeComponent } from './cookies-notice.component';

const CLOSE_COOKIES_NOTICE_KEY = 'CT.CookiesNotice.Close';
const CLOSE_COOKIES_NOTICE_VALUE = 'true';

@Injectable({ providedIn: 'root' })
export class CookiesNoticeService {
  constructor(private overlay: Overlay, private localStorageService: LocalStorageService) {}

  showNotice(): void {
      if (this.localStorageService.getItem(CLOSE_COOKIES_NOTICE_KEY)) {
        return;
      }
      const positionStrategy = this.overlay.position()
        .global()
        .bottom('10px')
        .centerHorizontally();
      const overlayRef = this.overlay.create({
        positionStrategy,
        width: '70vw',
        minWidth: '320px'
      });
      const cookiesNoticePortal = new ComponentPortal(CookiesNoticeComponent);
      const cookieNoticeComponentRef = overlayRef.attach(cookiesNoticePortal);
      cookieNoticeComponentRef.instance.closeNotice
        .pipe(
          take(1)
        )
        .subscribe(() => {
          overlayRef.dispose();
          this.localStorageService.setItem(CLOSE_COOKIES_NOTICE_KEY, CLOSE_COOKIES_NOTICE_VALUE);
        })
  }
}
