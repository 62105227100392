<div class="elements-root">
  <div class="row">
    <ct-trip-card
      [trip]="trip"
      [canEdit]="isAuthor"
      (edit)="editTrip()"
      (share)="shareTrip()"
      [canOpen]="false"
      class="trip-header col-12"
    >
      <div ctTripCardFooter>
        <p *ngIf="!isAuthor" class="author mb-2 mb-md-0">{{ trip?.author?.firstName }} {{ trip?.author?.lastName }}</p>
        <ct-tab-header
          style="margin-bottom: -1rem"
          class="d-none d-md-flex"
          [tabs]="tabs"
          (tabChange)="onFilterChanged($event)"
        >
          <ct-icon
            ctTabPrefix
            *ngIf="isAuthor"
            title="Add To This Trip"
            class="add-trip__icon"
            icon="add-circle"
            (click)="addDialog()"
          ></ct-icon>
        </ct-tab-header>
      </div>
    </ct-trip-card>
  </div>
  <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)">
    <ct-icon ctTabPrefix class="add-trip__icon" icon="add-circle" (click)="addDialog()"></ct-icon>
  </ct-tabs-mobile>
  <router-outlet></router-outlet>
</div>
