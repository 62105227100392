import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  DestroyableFeature,
  EntityComment,
  EntityThread,
  Features,
  getCroppedThumbPublicUrl,
  trackById,
  UserProfile
} from '@ct/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NewCommentService } from '../new-comment.service';

@Component({
  selector: 'ct-entity-thread',
  templateUrl: './entity-thread.component.html',
  styleUrls: ['./entity-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class EntityThreadComponent {
  public readonly destroyed$: Observable<void>;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  @ViewChild('vc', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('containerWrapper') containerWrapper: ElementRef;

  @Input() thread: EntityThread;
  @Input() user: UserProfile | null;

  @Output() publishedComment = new EventEmitter();
  @Output() editedThread = new EventEmitter();
  @Output() deletedThread = new EventEmitter();
  @Output() editedComment = new EventEmitter();
  @Output() deletedComment = new EventEmitter();

  get isInteractable() {
    return this.thread?.authorId === this.user?.userId && !this.thread.comments?.length;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  public trackByFn = trackById;

  constructor(private newCommentService: NewCommentService) {}

  onReply() {
    const instance = this.newCommentService.create(this.container);
    instance.user = this.user;
    instance.submitButtonKey = 'MY_ACCOUNT.MY_POSTS_FEATURE.REPLY';
    instance.submitted.pipe(takeUntil(this.destroyed$)).subscribe((value: string) => {
      this.publishedComment.emit({ threadId: this.thread.id, value });
      this.newCommentService.clear();
    });
    instance.cancelled.pipe(takeUntil(this.destroyed$)).subscribe(() => this.newCommentService.clear());
  }

  onEditComment(comment: EntityComment) {
    const instance = this.newCommentService.create(this.container);
    instance.user = this.user;
    instance.submitButtonKey = 'COMMON.UPDATE';
    instance.form.controls.body.patchValue(comment.body);
    this.containerWrapper.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    instance.submitted.pipe(takeUntil(this.destroyed$)).subscribe((value: string) => {
      this.editedComment.emit({ threadId: this.thread.id, commentId: comment.id, value });
      this.newCommentService.clear();
    });
    instance.cancelled.pipe(takeUntil(this.destroyed$)).subscribe(() => this.newCommentService.clear());
  }

  onDeleteComment(comment: EntityComment) {
    this.deletedComment.emit({ threadId: this.thread.id, commentId: comment.id });
  }
}
