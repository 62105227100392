import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Span } from '@ct/opentelemetry';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { AuthTokens } from '../interfaces';
import { AuthService } from '../services';

const AUTH_TOKEN_KEY = 'X-SCOTCRO-CMP-AUTH-TOKEN';
const EXCEPTED_REQUESTS = [
  {
    url: 'authentications', // refresh token
    method: 'PUT'
  },
  {
    url: 'authentications', // login
    method: 'POST'
  },
  {
    url: 'forgotten-passwords', // forgot password
    method: 'POST'
  },
  {
    url: 'forgotten-passwords', // forgot password verifying
    method: 'PUT'
  }
];

const PROTECTED_ROUTES = ['my-account', 'create-merchant'];

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  private refreshingInProgress = false;
  private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRequestExcepted(req)) {
      return next.handle(req);
    }

    const accessToken = this.authService.getAccessToken();

    return next.handle(this.setAccessToken(req, accessToken)).pipe(
      catchError((err) => {
        // in case of 401 http error
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // get refresh tokens
          const refreshToken = this.authService.getRefreshToken();

          // if there are tokens then send refresh token request
          if (refreshToken && accessToken) {
            return this.refreshToken(req, next);
          }

          // otherwise logout and redirect to login page (if needed)
          return this.logoutAndRedirect(err);
        }

        // TODO: think about this condition as it's breaking app
        // in case of 403 http error (refresh token failed)
        // if (err instanceof HttpErrorResponse && err.status === 403) {
        //   // logout and redirect to login page
        //   return this.logoutAndRedirect(err);
        // }
        // if error has status neither 401 nor 403 then just return this error
        return throwError(err);
      })
    );
  }

  private setAccessToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!token) {
      return request;
    }
    return request.clone({
      setHeaders: {
        [AUTH_TOKEN_KEY]: token
      }
    });
  }

  @Span()
  private logoutAndRedirect(err: Error): Observable<HttpEvent<any>> {
    return this.authService.logout().pipe(
      switchMap(() => {
        const { pathname } = globalThis.location;
        //logout and redirect only if trying to access protected routes
        if (PROTECTED_ROUTES.some((route) => pathname.includes(route))) {
          this.authService.redirectToLogin();
        } else {
          location?.reload();
        }
        return throwError(err);
      })
    );
  }

  @Span()
  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap(({ newAuthToken }: AuthTokens) => {
          this.accessTokenSubject.next(newAuthToken as string);
          // repeat failed request with new token
          return next.handle(this.setAccessToken(request, newAuthToken as string));
        }),
        catchError((err) => {
          return this.logoutAndRedirect(err);
        }),
        finalize(() => {
          this.refreshingInProgress = false;
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(Boolean),
        take(1) as any,
        switchMap((token: string) => {
          // repeat failed request with new token
          return next.handle(this.setAccessToken(request, token));
        })
      );
    }
  }

  private isRequestExcepted(req: HttpRequest<any>): boolean {
    return EXCEPTED_REQUESTS.some(
      (exceptedRequest) => req.url.includes(exceptedRequest.url) && exceptedRequest.method === req.method
    );
  }
}
