import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@ct/components/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerComponent, SpinnerOverlayComponent, SpinnerSimpleComponent } from './components';

@NgModule({
  declarations: [SpinnerOverlayComponent, SpinnerComponent, SpinnerSimpleComponent],
  imports: [CommonModule, TranslateModule, IconModule],
  exports: [SpinnerOverlayComponent, SpinnerComponent, SpinnerSimpleComponent]
})
export class SpinnerModule {}
