import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countDownTime'
})
export class CountDownTimePipe implements PipeTransform {
  transform(value: number | null = 0): string {
    const minutes: number = Math.floor((value || 0) / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor((value || 0) - minutes * 60)).slice(-2);
  }
}
