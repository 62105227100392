import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GalleryItem, ThumbnailsPosition } from '@ct/components/gallery';

@Component({
  selector: 'ct-featured-photos',
  templateUrl: './featured-photos.component.html',
  styleUrls: ['./featured-photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedPhotosComponent {
  @Input() photos: GalleryItem[] = [];
  @Input() thumbPosition: ThumbnailsPosition = ThumbnailsPosition.Right;
}
