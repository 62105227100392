import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BaseFormComponent,
  CustomValidators,
  DestroyableFeature,
  Features,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  ValidatorFeature,
  ValueAccessorFeature
} from '@ct/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-passwords',
  templateUrl: './passwords.component.html',
  styleUrls: ['./passwords.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class PasswordsComponent<T> extends BaseFormComponent<T> {
  public readonly destroyed$: Observable<void>;

  @Input() lightLabel: boolean;

  public form = new UntypedFormGroup({
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(PASSWORD_MAX_LENGTH),
      Validators.minLength(PASSWORD_MIN_LENGTH)
    ]),
    confirmPassword: new UntypedFormControl('')
  });

  public hiddenPassword = true;
  public hiddenConfirmPassword = true;

  public get passwordControl() {
    return this.form.controls.password as UntypedFormControl;
  }

  public get confirmPasswordControl() {
    return this.form.controls.confirmPassword as UntypedFormControl;
  }

  get errorState() {
    return !this.form.controls.password.value && this.form.controls.password.value;
  }

  constructor() {
    super();

    this.confirmPasswordControl.setValidators([
      Validators.required,
      CustomValidators.passwordMatch(this.passwordControl)
    ]);
  }

  onPasswordIcon() {
    this.hiddenPassword = !this.hiddenPassword;
  }

  onConfirmPasswordIcon() {
    this.hiddenConfirmPassword = !this.hiddenConfirmPassword;
  }
}
