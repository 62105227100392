import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService, IconType } from '@ct/components';
import { DestroyableFeature, Features, FormStateDispatcher, RelationUserProfile } from '@ct/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { WritingJournalAddDialogComponent } from './components';

enum WritingType {
  Journal = 'journal',
  Story = 'story'
}

interface AddOption {
  icon: IconType;
  titleKey: string;
  type: WritingType;
}

@Component({
  selector: 'ct-add-to-writings-dialog',
  templateUrl: './add-to-writings-dialog.component.html',
  styleUrls: ['./add-to-writings-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class AddToWritingsDialogComponent {
  public readonly destroyed$: Observable<void>;
  public readonly addOptions: AddOption[] = [
    {
      icon: 'book',
      titleKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.STORY',
      type: WritingType.Story
    },
    {
      icon: 'article',
      titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.JOURNAL_ENTRY',
      type: WritingType.Journal
    }
  ];

  get friends$(): Observable<RelationUserProfile[]> {
    return this.data?.friends$;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private dialogRef: MatDialogRef<AddToWritingsDialogComponent>,
    private dialogService: DialogService,
    private router: Router
  ) {}

  onOptionSelect(option: AddOption) {
    switch (option.type) {
      case WritingType.Story:
        return this.onAddStory();
      case WritingType.Journal:
        return this.onAddJournal();
    }
  }

  onAddStory() {
    this.dialogRef.close();
    this.router.navigate(['/my-account/writings/create']);
  }

  onAddJournal() {
    this.dialogService
      .open(WritingJournalAddDialogComponent, {
        data: { friends$: this.friends$ }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((reload: boolean) => {
        this.dialogRef.close({ reload });
      });
  }
}
