import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@ct/auth';
import { BlogPost, Tag, UserProfile } from '@ct/core';
import { entitySlugUrl } from '@ct/shared';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'ct-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogSidebarComponent implements OnInit {
  public currentUser$: Observable<UserProfile | null> = this.authService
    .getUserProfile()
    .pipe(catchError(() => of(null)));
  public searchControl: UntypedFormControl = new UntypedFormControl();

  get posts$(): Observable<BlogPost[]> {
    return this.route.data.pipe(
      map(({ sidebar }) => sidebar?.posts),
      shareReplay(1)
    );
  }

  get tags$(): Observable<Tag[]> {
    return this.route.data.pipe(
      map(({ sidebar }) => sidebar?.tags),
      shareReplay(1)
    );
  }

  get users$(): Observable<UserProfile[]> {
    return this.route.data.pipe(
      map(({ sidebar }) => sidebar?.users),
      shareReplay(1)
    );
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => !!user));
  }

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ search }) => {
      this.searchControl.setValue(search);
    });
  }

  getLink(id?: string | undefined, slug?: string | undefined): string {
    const url = entitySlugUrl('', { id, slug });
    return `/stories/${url}`;
  }

  onSearch() {
    const query = this.searchControl.value;
    if (query) {
      this.router.navigateByUrl(`stories/search?search=${query}`);
    }
    // TODO: what do we do if someone wants to reset search.
  }
}
