import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthorModule } from '@ct/components/author';
import { ButtonModule } from '@ct/components/button';
import { CardWrapperModule } from '@ct/components/card-wrapper';
import { DialogModule } from '@ct/components/dialog';
import { EntityCommentsModule } from '@ct/components/entity-comments/entity-comments.module';
import { GalleryModule } from '@ct/components/gallery';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { LikeModule } from '@ct/components/like';
import { LinkModule } from '@ct/components/link';
import { SocialSharingModule } from '@ct/components/social-sharing';
import { TagModule } from '@ct/components/tag';
import { TextEditorModule } from '@ct/components/text-editor';
import { UserAvatarModule } from '@ct/components/user-avatar';
import { PipesModule } from '@ct/core';
import { CardItemModule } from '@ct/shared/modules/card-item/card-item.module';
import { TranslateModule } from '@ngx-translate/core';

import { MediaSharedModule } from '../media-shared';
import { ReportInappropriateDialogModule } from '../report-inappropriate-dialog';
import { YoutubeModule } from '../youtube';
import {
  ActiveCampaignSignUpComponent,
  BlogPostCardComponent,
  BlogPostComponent,
  BlogPostListItemComponent,
  BlogPostSeriesComponent,
  BlogPostSignupBannerComponent,
  BlogPostStatusComponent,
  WritingCardComponent
} from './components';

@NgModule({
  declarations: [
    BlogPostCardComponent,
    BlogPostStatusComponent,
    BlogPostListItemComponent,
    BlogPostComponent,
    WritingCardComponent,
    ActiveCampaignSignUpComponent,
    BlogPostSignupBannerComponent,
    BlogPostSeriesComponent
  ],
  imports: [
    CommonModule,
    ImageModule,
    LinkModule,
    TextEditorModule,
    TranslateModule,
    AuthorModule,
    PipesModule,
    UserAvatarModule,
    ButtonModule,
    DialogModule,
    TagModule,
    MediaSharedModule,
    GalleryModule,
    ReactiveFormsModule,
    SocialSharingModule,
    LikeModule,
    YoutubeModule,
    CardWrapperModule,
    IconModule,
    CardItemModule,
    MatToolbarModule,
    ReportInappropriateDialogModule,
    RouterLink,
    RouterLinkActive,
    MatListModule,
    EntityCommentsModule
  ],
  exports: [
    BlogPostCardComponent,
    BlogPostStatusComponent,
    BlogPostListItemComponent,
    BlogPostComponent,
    WritingCardComponent,
    BlogPostSignupBannerComponent,
    BlogPostSeriesComponent
  ]
})
export class BlogSharedModule {}
