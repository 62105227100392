import { ɵDirectiveDef } from '@angular/core';

import { CanonicalConfig, SeoConfig } from '../../interfaces';
import { Writable } from '../../models';
import { AppInjector, CanonicalService } from '../../services';

export function CanonicalFeature() {
  return <T extends { seoConfig?: SeoConfig; canonicalConfig?: CanonicalConfig }>(
    directiveDef: Writable<ɵDirectiveDef<T>>
  ) => {
    const { factory, type } = directiveDef;

    directiveDef.factory = () => {
      const instance = factory?.(type) as T;
      const injector = AppInjector.getInjector();

      if (!injector) {
        throw new Error(`AppInjector not found! Please, provide it or remove CanonicalFeature() decorator`);
      }

      if (!instance.seoConfig && !instance.canonicalConfig) {
        throw new Error(
          `'seoConfig.canonicalConfig' or 'canonicalConfig' is not defined in the ${type?.name}! Please, provide it or remove CanonicalFeature() decorator`
        );
      }
      const { canonicalUrl } = (instance.seoConfig?.canonicalConfig || instance.canonicalConfig) as CanonicalConfig;
      const canonicalService = injector.get(CanonicalService);
      canonicalService.setURL(canonicalUrl);
      return instance;
    };
  };
}
