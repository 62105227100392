import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  AuthorModule,
  ButtonModule,
  DialogModule,
  IconModule,
  ImageModule,
  ImageUploadModule,
  LightboxModule,
  LinkModule,
  ProgressSpinnerModule,
  TagModule,
  UserAvatarModule,
  VideoModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { MyAccountHeaderComponent, MyAccountMarketplaceItemComponent } from './components';

const COMPONENTS = [MyAccountHeaderComponent, MyAccountMarketplaceItemComponent];

const MODULES = [
  CommonModule,
  TranslateModule,
  PipesModule,
  IconModule,
  ButtonModule,
  AuthorModule,
  TagModule,
  ImageModule,
  VideoModule,
  MatDialogModule,
  DialogModule,
  ProgressSpinnerModule,
  LinkModule,
  UserAvatarModule,
  ImageUploadModule,
  LightboxModule
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  exports: [...COMPONENTS]
})
export class MyAccountSharedModule {}
