import { UploadedImage } from '@ct/core';

export class ImageUpload {
  constructor(
    public file: File,
    public isNew: boolean,
    public done: boolean = false,
    public error: boolean = false,
    public progress: number = 0,
    public selected: boolean = false,
    public data?: UploadedImage
  ) {}

  setProgress(loaded: number, total: number) {
    this.done = false;
    this.progress = Math.round((loaded * 100) / total);
  }

  setSuccess(data: UploadedImage) {
    this.data = data;
    this.done = true;
  }

  setError() {
    this.done = true;
    this.error = true;
  }
}
