<ct-card-section class="mb-3" [showLine]="false">
  <ct-widget titleKey="HOME.FEATURED.BLOG_POSTS" icon="article" [showMore]="true" [showMoreLink]="['/stories']">
    <div class="content">
      <div class="item" [class.featured]="first" *ngFor="let post of posts; let first = first">
        <ct-blog-post-card
          [post]="post"
          [showLink]="true"
          [showStatus]="false"
          [imgHeight]="first ? '750px' : '220px'"
          [imgWidth]="'100%'"
          [link]="getLink(post)"
          [imgCols]="first ? 12 : 5"
          [contentCols]="first ? 12 : 7"
          [contentMargin]="first"
          [objectFit]="first ? 'scale-down' : 'cover'"
        ></ct-blog-post-card>
      </div>
    </div>
  </ct-widget>
</ct-card-section>
