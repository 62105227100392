import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, RequestRange, Series, SeriesWithStories, SeriesWithStoriesCount } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const endpoint = environment.seriesApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class SeriesApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll({ range, authorId }: { range?: RequestRange; authorId?: string }): Observable<SeriesWithStoriesCount[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }

    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }

    return this.get('', params, { headers, withCredentials: true });
  }

  getById(id: string): Observable<SeriesWithStories> {
    return this.get(id).pipe(
      map((series) => ({
        ...series,
        stories: series.stories?.sort((a: { publishedOn: Date }, b: { publishedOn: Date }) => {
          return new Date(a.publishedOn).getTime() - new Date(b.publishedOn).getTime();
        })
      }))
    );
  }

  remove(id: string): Observable<undefined> {
    return this.delete(`posts/${id}`);
  }

  create(series: Series): Observable<Series> {
    return this.post<Series>('', series);
  }

  update(id: string, series: Partial<Series>): Observable<Series> {
    return this.patch(id, series);
  }
}
