<ct-image-banner img="site-search" [title]="'SITE_SEARCH.IMG_TITLE' | translate"></ct-image-banner>
<div class="container">
  <div class="search">
    <div class="row flex-md-row flex-column-reverse">
      <div class="col-12 col-md-3 pt-2 pt-md-0">
        <div class="my-account-sidebar">
          <div class="container">
            <ct-sidebar-section
              *ngIf="tags$ | async as tags"
              class="mb-4"
              [titleKey]="'SITE_SEARCH.TAGS_HEADER'"
              background="white"
            >
              <ng-container *ngIf="tags?.length">
                <ct-link
                  *ngFor="let tag of tags"
                  [link]="['/stories/by-tag']"
                  [queryParams]="{ tagId: tag.id, authorId: null }"
                  inline="true"
                >
                  <ct-tag class="mr-1 mb-1" [name]="tag?.name"></ct-tag>
                </ct-link>
              </ng-container>
              <ng-container *ngIf="!tags?.length">
                <p class="empty flex-center">{{ 'SITE_SEARCH.EMPTY' | translate }}</p>
              </ng-container>
            </ct-sidebar-section>
            <ct-sidebar-section
              *ngIf="users$ | async as users"
              class="mb-4"
              [titleKey]="'SITE_SEARCH.USERS_HEADER'"
              background="white"
            >
              <ng-container *ngIf="users?.length">
                <ct-link
                  *ngFor="let user of users"
                  [link]="['/stories/by-author']"
                  [queryParams]="{ authorId: user.userId, tagId: null }"
                >
                  <div class="user">
                    <ct-user-avatar
                      class="user__avatar"
                      [src]="getCroppedThumbPublicUrl(user?.photo?.publicUrl)"
                      width="32px"
                      height="32px"
                    ></ct-user-avatar>
                    <span class="user__name">{{ user.username }}</span>
                  </div>
                </ct-link>
              </ng-container>
              <ng-container *ngIf="!users?.length">
                <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
              </ng-container>
            </ct-sidebar-section>
            <ct-sidebar-section
              *ngIf="trips$ | async as trips"
              class="mb-4"
              [titleKey]="'SITE_SEARCH.TRIPS_HEADER'"
              background="white"
            >
              <ng-container *ngIf="trips?.length">
                <ct-trip
                  class="col-12 col-md-4 mb-3 trip"
                  *ngFor="let trip of trips"
                  [trip]="trip"
                  [link]="getTripLink(trip)"
                ></ct-trip>
              </ng-container>
              <ng-container *ngIf="!trips?.length">
                <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
              </ng-container>
            </ct-sidebar-section>
            <ct-sidebar-section
              *ngIf="campsites$ | async as campsites"
              class="mb-4"
              [titleKey]="'SITE_SEARCH.WAYPOINTS_HEADER'"
              background="white"
            >
              <ng-container *ngIf="campsites?.length">
                <ct-campsite-entry
                  *ngFor="let entry of campsites"
                  [entry]="entry"
                  [link]="getCampsiteLink(entry)"
                ></ct-campsite-entry>
              </ng-container>
              <ng-container *ngIf="!campsites?.length">
                <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
              </ng-container>
            </ct-sidebar-section>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <mat-tab-group>
          <mat-tab [label]="'Stories'" *ngIf="posts$ | async as posts">
            <div *ngFor="let post of posts">
              <ct-blog-post-card
                [post]="post"
                [showLink]="true"
                [showStatus]="false"
                imgHeight="220px"
                imgWidth="100%"
                [link]="getStoriesLink(post)"
              ></ct-blog-post-card>
              <div class="line"></div>
            </div>
          </mat-tab>
          <mat-tab [label]="'Marketplace'" *ngIf="marketplaceItems$ | async as items">
            <div *ngFor="let item of items">
              <ct-marketplace-item
                [item]="item"
                [showLink]="true"
                imgHeight="220px"
                imgWidth="100%"
                [link]="getMarketplaceLink(item)"
              ></ct-marketplace-item>
              <div class="line"></div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
