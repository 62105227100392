import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CheckboxModule } from '../checkbox/checkbox.module';
import { IconModule } from '../icon/icon.module';
import { DataListComponent } from './data-list.component';
import { DataListRowComponent } from './data-list-row/data-list-row.component';

@NgModule({
  imports: [CommonModule, IconModule, CheckboxModule, CdkTreeModule, CdkScrollableModule],
  declarations: [DataListComponent, DataListRowComponent],
  exports: [DataListComponent, DataListRowComponent]
})
export class DataListModule {}

export { DataListColumn, DataListItem } from './data-list.interfaces';
