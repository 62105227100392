<ct-card-item
  [item]="post"
  [showLink]="showLink"
  [creationDate]="post?.publishedOn"
  [description]="post?.body"
  [showAuthor]="showAuthor"
  [showTags]="showTags"
  [imgHeight]="imgHeight"
  [imgCols]="imgCols"
  [imgWidth]="imgWidth"
  [objectFit]="objectFit"
  [link]="link"
  [linkFullWidth]="linkFullWidth"
  [contentMargin]="contentMargin"
  [contentCols]="contentCols"
>
  <ct-blog-post-status *ngIf="showStatus" [status]="status" class="mb-1"></ct-blog-post-status>
</ct-card-item>
