import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeletionDialogComponent, DialogButton, DialogService } from '@ct/components';
import { FormStateDispatcher, Tag, UploadedImage } from '@ct/core';
import { MyAccountPhotoApiService, TagApiService } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap, take } from 'rxjs/operators';

import { MyAccountPhotosOperation } from '../../enums';

@Component({
  selector: 'ct-my-account-photos-edit-dialog',
  templateUrl: './my-account-photos-edit-dialog.component.html',
  styleUrls: ['./my-account-photos-edit-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountPhotosEditDialogComponent implements OnInit {
  public readonly form = new UntypedFormGroup({
    description: new UntypedFormControl('', [Validators.required]),
    tags: new UntypedFormControl([]),
    isPrivate: new UntypedFormControl(false)
  });

  get photo(): UploadedImage {
    return this.data?.photo;
  }

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => this.onSubmit()
    }
  ];

  public readonly optionsFn = (match: string) => this.tagApiService.getAll(match);

  public readonly createFn = (name: string) => this.tagApiService.create(name);

  public readonly checkFn = (name: string) => this.tagApiService.getByName(name);

  public readonly labelFn = ({ name }: Tag) => name as string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { photo: UploadedImage },
    private formState: FormStateDispatcher,
    private dialogRef: MatDialogRef<MyAccountPhotosEditDialogComponent>,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private tagApiService: TagApiService
  ) {}

  ngOnInit(): void {
    if (this.photo) {
      this.form.patchValue(this.photo);
    }
  }

  onDelete() {
    const photoId = this.photo?.id;
    const message = this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE.SINGLE_MESSAGE', {
      blogPost: this.photo.blogPostIds?.length || 0,
      journalEntry: this.photo.journalEntryIds?.length || 0,
      trip: this.photo.tripIds?.length || 0
    });
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          message
        },
        width: '50vw'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.myAccountPhotoApiService.remove(photoId))
      )
      .subscribe(() => this.dialogRef.close({ operation: MyAccountPhotosOperation.Delete, photo: this.photo }));
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  @HostListener('window:keyup.Enter')
  onSubmit() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }
    const photoId = this.photo?.id;
    this.myAccountPhotoApiService.update(photoId, { ...this.form.value }).subscribe((photo) =>
      this.dialogRef.close({
        operation: MyAccountPhotosOperation.Update,
        photo
      })
    );
  }
}
