import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { IDestroyable } from './destroyable.interface';

const destroySubject = Symbol('destroyed');

@Directive({
  selector: '[ctDestroyable]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class Destroyable implements IDestroyable, OnDestroy {
  private readonly [destroySubject] = new Subject<void>();
  readonly destroyed$ = this[destroySubject].asObservable();

  ngOnDestroy() {
    this[destroySubject].next();
    this[destroySubject].complete();
  }
}
