import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'ct-my-video-player',
  templateUrl: './my-video-player.component.html',
  styleUrls: ['./my-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyVideoPlayerComponent implements OnChanges, AfterViewInit {
  @ViewChild('videoPlayer', { static: true }) private videoPlayer: ElementRef;
  @Input() hlsUrl: string;
  @Input() dashUrl: string;
  @Input() autoplay: boolean;

  private player: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.hlsUrl || changes.dashUrl) && this.player) {
      this.updateVideoSource();
    }
  }

  ngAfterViewInit() {
    this.initializeVideoPlayer();
  }

  private initializeVideoPlayer() {
    const videoElement = this.videoPlayer.nativeElement;

    this.player = videojs(videoElement, {
      fluid: true
    });

    this.updateVideoSource();

    // Add quality selection support
    this.player.qualityLevels();

    // Automatically start playback
    if (this.autoplay) {
      this.player.play();
    }

    // Set up quality selection menu
    this.player.on('loadedmetadata', () => {
      const levels = this.player.qualityLevels();

      // Populate the quality selection menu
      levels.on('addqualitylevel', () => {
        const qualityButton = this.player.controlBar.addChild('QualitySelector');
        qualityButton.update();
      });
    });

    this.cdr.markForCheck();
  }

  private updateVideoSource() {
    const videoElem = document.createElement('video');

    // Check for HLS support
    const hlsSupport = videoElem.canPlayType('application/vnd.apple.mpegurl') !== '';

    // Check for DASH support
    const mseSupport = typeof window.MediaSource !== 'undefined';
    const codecSupport = window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E"');

    const dashSupport = mseSupport && codecSupport;

    if (this.hlsUrl && hlsSupport) {
      this.player.src({
        src: this.hlsUrl,
        type: 'application/x-mpegURL'
      });
    } else if (this.dashUrl && dashSupport) {
      this.player.src({
        src: this.dashUrl,
        type: 'application/dash+xml'
      });
    }

    this.cdr.markForCheck();
  }
}
