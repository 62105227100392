import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService, ImageUploadService, SpinnerService } from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  LocalStorageService,
  VideoChannelEntity
} from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

import { MyAccountPhotoApiService } from '../../../../services';

const MAX_BODY_LENGTH = 1000;

@Component({
  selector: 'ct-channel-edit-dialog',
  templateUrl: './channel-edit-dialog.component.html',
  styleUrls: ['./channel-edit-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ChannelEditDialogComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public isLoading = false;

  public form = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl('', [Validators.required]),
    handle: new UntypedFormControl(''),
    avatarImage: new UntypedFormControl(''),
    bannerImage: new UntypedFormControl(''),
    description: new UntypedFormControl('', [Validators.required, Validators.maxLength(MAX_BODY_LENGTH)])
  });

  public get buttons(): DialogButton[] {
    const cancelButton: DialogButton = {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.dialogRef.close()
    };

    const saveButton: DialogButton = {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onPublish();
      },
      color: 'accent'
    };
    return [cancelButton, saveButton];
  }

  get channel(): VideoChannelEntity {
    return this.data.channel;
  }

  get authorId(): VideoChannelEntity {
    return this.data.authorId ?? this.channel?.authorId;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private dialogRef: MatDialogRef<ChannelEditDialogComponent>,
    protected localStorageService: LocalStorageService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    private formState: FormStateDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private imageUploadService: ImageUploadService,
    private channelVideoUploadApiService: ChannelVideoUploadApiService,
    private myAccountPhotoApiService: MyAccountPhotoApiService
  ) {}

  ngOnInit() {
    if (this.channel) {
      this.form.patchValue(this.channel);
    }
  }

  onPublish() {
    this.createOrUpdate();
  }

  createOrUpdate() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { id, ...entityPayload } = this.form.value;
    return (
      !id
        ? this.channelVideoUploadApiService.create({ ...entityPayload, authorId: this.authorId })
        : this.channelVideoUploadApiService.update(id, entityPayload)
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onSelectFeaturedPhotoPlaceholder(photoControlName: string) {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.ADD_FEATURED_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(([photo]) => !!photo)
      )
      .subscribe(([photo]) => {
        this.form.controls[photoControlName].patchValue(photo);
        this.changeDetectorRef.detectChanges();
      });
  }

  onReplaceFeaturedPhoto(photoControlName: string) {
    this.onSelectFeaturedPhotoPlaceholder(photoControlName);
  }

  onRemoveFeaturedPhoto(photoControlName: string) {
    this.form.controls[photoControlName].patchValue(undefined);
  }
}
