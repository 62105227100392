import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ct/auth';
import { environment } from '@ct/environment';
import { MenuItem, translateMenuItems } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';

import { basicMenuItems } from '../../constants';

@Component({
  selector: 'ct-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  protected menuItems: MenuItem[] = translateMenuItems(basicMenuItems, (key: string) => this.translateService.get(key));

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  get queryParams() {
    if (this.document?.location) {
      const { pathname, search } = this.document.location;
      const returnUrl = search ? pathname + search : pathname;
      return { returnUrl };
    }
    return {};
  }

  protected collapsed = true;
  @Input() protected showSearch = true;

  protected readonly isProd = environment.production;
  protected readonly externalUrl = 'https://camping.tools';

  constructor(
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: any
  ) {}

  onLogout() {
    this.authService.logout().subscribe(() => {
      this.closeMenu();
      this.authService.redirectToLogin();
    });
  }

  closeMenu() {
    if (!this.collapsed) {
      this.collapsed = true;
    }
    this.changeDetectorRef.detectChanges();
  }
}
