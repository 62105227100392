import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityType } from '@ct/shared/enums/share-entity-type.enum';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HeaderType, SortOrder } from '../enums';
import { EntityThread, RequestRange } from '../interfaces';
import { BaseHttpService } from './base-http.service';

const endpoint = environment.threadApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class ThreadApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll(
    entityId: string,
    range?: RequestRange,
    sortOrder: SortOrder = SortOrder.Desc,
    authorId?: string,
    entityType?: EntityType
  ): Observable<EntityThread[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (entityType !== undefined && entityType !== null) {
      params = params.append('entityType', entityType);
    }
    params = params.append('entityId', entityId);
    return this.get(``, params, { headers, withCredentials: true });
  }

  create(entityId: string, body: string, entityType: EntityType = EntityType.BlogPost) {
    return this.post(``, { body, entityId, entityType });
  }

  update(threadId: string, body: string) {
    return this.patch(`${threadId}`, { body });
  }

  remove(threadId: string) {
    return this.delete(`${threadId}`);
  }
}
