import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageModule } from '../image';
import { LinkModule } from './../link';
import { CardComponent } from './card.component';
import { CardSectionComponent } from './card-section';

@NgModule({
  declarations: [CardComponent, CardSectionComponent],
  imports: [CommonModule, LinkModule, ImageModule],
  exports: [CardComponent, CardSectionComponent]
})
export class CardModule {}
