import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HeaderType, SortOrder } from '../enums';
import { RequestRange, TripList } from '../interfaces';
import { BaseHttpService } from './base-http.service';

const endpoint = environment.listsApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class ListsApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll(range?: RequestRange, sortOrder: SortOrder = SortOrder.Desc, authorId?: string): Observable<TripList[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    return this.get(``, params, { headers, withCredentials: true });
  }

  getAllForTrip(tripId: string): Observable<TripList[]> {
    return this.get<TripList>(`trip/${tripId}`);
  }

  getById(id: string): Observable<TripList> {
    return this.get<TripList>(`${id}`);
  }

  create(entity: TripList): Observable<TripList> {
    return this.post(``, entity);
  }

  update(id: string, body: Partial<TripList>): Observable<TripList> {
    return this.patch(`${id}`, body);
  }

  remove(id: string) {
    return this.delete(`${id}`);
  }
}
