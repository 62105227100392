import { GroupTimeline } from '@ct/core/interfaces/group-timeline.interface';
import { EntityType } from '@ct/shared/enums/share-entity-type.enum';
import { Trip, TripCampsite, TripJournalEntry } from '@ct/shared/modules/trip-shared/interfaces/trip.interface';

import { BlogPost } from './blog-post.interface';
import { Group } from './group.interface';
import { UploadedImage } from './uploaded-image.interface';
import { UserProfile } from './user-profile.interface';

export enum NotificationStatus {
  New = 'new',
  Dispatched = 'dispatched',
  Seen = 'seen'
}

export type NotificationType =
  | 'shared'
  | 'liked'
  | 'item_changed'
  | 'list_item_check'
  | 'list_item_uncheck'
  | 'entity_tag'
  | 'comment'
  | 'thread_reply'
  | 'friend_request'
  | 'group_invite'
  | 'group_request'
  | 'waypoint_checkin'
  | 'share_location'
  | 'group_ownership_change'
  | 'trip_add_memory';

export class NotificationObject {
  readonly id?: string;
  readonly notificationType?: NotificationType;
  readonly entityId?: string;
  readonly entityType?: EntityType;
  readonly authorId?: string;
  readonly createdOn?: Date;
}

export class Notification {
  readonly id?: string;
  readonly notificationObjectId?: string;
  readonly userId?: string;
  status?: NotificationStatus;
  readonly createdOn?: Date;
  readonly modifiedOn?: Date;
}

export class NotificationWithObject extends Notification {
  readonly notificationObject: NotificationObject;
}

export class NotificationWithEntity extends NotificationWithObject {
  readonly entity: (
    | BlogPost
    | Trip
    | TripJournalEntry
    | UploadedImage
    | TripCampsite
    | GroupTimeline
    // | BlogPostThread
    // | BlogPostComment
    | Group
  ) & { author: UserProfile; likesCount?: number; threadBody?: string; commentBody?: string }; // | LikeSummary[]
}
export class NotificationWithAuthor extends NotificationWithEntity {
  readonly author: UserProfile;
  readonly relatedEntityType?: EntityType;

  readonly notificationSettings?: any;
}
