export interface TwitterCard {
  title: string;
  titleSuffix?: string;
  card?: TwitterCardType;
  site?: string;
  description?: string;
  image?: string;
  imageAlt?: string;
}

export interface FacebookCard {
  title: string;
  titleSuffix?: string;
  type: FacebookCardType;
  description?: string;
  url?: string;
  image?: string;
}

export enum FacebookCardType {
  Article = 'article',
  Website = 'website'
}

export enum TwitterCardType {
  SummaryLargeImage = 'summary_large_image',
  Summary = 'summary'
}

export interface SocialMedia {
  title: string;
  description: string;
  image: string;
  facebookCardType: FacebookCardType;
  twitterCardType: TwitterCardType;
}
