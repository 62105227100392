<ct-user-avatar
  class="user__avatar"
  [src]="getCroppedThumbPublicUrl(user?.photo?.publicUrl)"
  width="32px"
  height="32px"
></ct-user-avatar>

<ng-container *ngIf="clickable; else simpleNamesTpl">
  <div class="user__link" (click)="userClick.emit()">
    <ng-container *ngTemplateOutlet="namesTpl"></ng-container>
  </div>
</ng-container>
<div class="ml-auto">
  <ng-content></ng-content>
</div>

<ng-template #simpleNamesTpl>
  <div>
    <ng-container *ngTemplateOutlet="namesTpl"></ng-container>
  </div>
</ng-template>

<ng-template #namesTpl>
  <p class="user__name">{{ user.firstName }} {{ user.lastName }}</p>
  <p class="user__name user__name--light">{{ user.username }}</p>
</ng-template>
