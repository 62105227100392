import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ct-new-item-placeholder',
  templateUrl: './new-item-placeholder.component.html',
  styleUrls: ['./new-item-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewItemPlaceholderComponent {
  @Input() textKey: string;
  @Input() replaceKey: string;
  @Input() img: string;
  @Input() height: string | undefined = '150px';
  @Input() width: string | undefined = '100%';

  @Output() placeholderClicked = new EventEmitter<void>();
  @Output() replaced = new EventEmitter<void>();
  @Output() removed = new EventEmitter<void>();
}
