import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { TagModule } from '../tag';
import { ImageComponent, ImageWithTagsComponent, ProgressiveImageComponent } from './components';

@NgModule({
  declarations: [ImageComponent, ImageWithTagsComponent, ProgressiveImageComponent],
  imports: [CommonModule, TagModule, IconModule],
  exports: [ImageComponent, ImageWithTagsComponent, ProgressiveImageComponent]
})
export class ImageModule {}
