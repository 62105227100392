import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-image-upload-progress',
  templateUrl: './image-upload-progress.component.html',
  styleUrls: ['./image-upload-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadProgressComponent {
  @Input() public done: boolean | undefined;
  @Input() public error: boolean | undefined;
  @Input() public imageUrl: string | undefined;
  @Input() public imageName: string | undefined;
  @Input() public value: number | undefined = 0;
  @Input() public selected: boolean | undefined = false;
}
