import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MyAccountMediaComponent, MyAccountPhotosComponent, MyAccountVideosComponent } from './components';
import { MyAccountPhotosDataResolver, MyAccountVideosDataResolver } from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: MyAccountMediaComponent,
    children: [
      {
        path: 'photos',
        component: MyAccountPhotosComponent,
        resolve: {
          photos: MyAccountPhotosDataResolver
        },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'videos',
        component: MyAccountVideosComponent,
        resolve: {
          videos: MyAccountVideosDataResolver
        },
        runGuardsAndResolvers: 'always'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountMediaRoutingModule {}
