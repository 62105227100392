import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthQuery } from '@ct/auth';
import { UserProfile } from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-my-account-marketplace-wrapper',
  templateUrl: './my-account-marketplace-wrapper.component.html',
  styleUrls: ['./my-account-marketplace-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountMarketplaceWrapperComponent {
  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(private authQuery: AuthQuery, private notificationQuery: NotificationQuery) {}
}
