<div class="container">
  <div class="row justify-content-center">
    <iframe
      class="col-12 col-md-6 col-xl-4 mb-4 welcome-video"
      [src]="'HOME.WELCOME_VIDEO' | translate | safe: 'resourceUrl'"
      frameborder="0"
      allowfullscreen
    >
    </iframe>
  </div>
  <div class="title">{{ 'HOME.WHY_REGISTER.TITLE' | translate }}</div>
  <div class="items-container row">
    <div class="item col-12 col-lg-4">
      <img class="item__image" src="assets/register/why-register-1.png" loading="lazy" />
      <h4 class="item__title">{{ 'HOME.WHY_REGISTER.ITEM.0.TITLE' | translate }}</h4>
      <p class="item__description">{{ 'HOME.WHY_REGISTER.ITEM.0.DESC' | translate }}</p>
    </div>
    <div class="item col-12 col-lg-4">
      <img class="item__image" src="assets/register/why-register-2.png" loading="lazy" />
      <h4 class="item__title">{{ 'HOME.WHY_REGISTER.ITEM.1.TITLE' | translate }}</h4>
      <p class="item__description">{{ 'HOME.WHY_REGISTER.ITEM.1.DESC' | translate }}</p>
    </div>
    <div class="item col-12 col-lg-4">
      <img class="item__image" src="assets/register/why-register-3.png" loading="lazy" />
      <h4 class="item__title">{{ 'HOME.WHY_REGISTER.ITEM.2.TITLE' | translate }}</h4>
      <p class="item__description">{{ 'HOME.WHY_REGISTER.ITEM.2.DESC' | translate }}</p>
    </div>
  </div>
  <div class="bottom-text">
    {{ 'HOME.WHY_REGISTER.BOTTOM_TEXT' | translate }},
    <ct-link class="bottom-text__link" [link]="['/register']" inline="true">{{
      'HOME.WHY_REGISTER.CLICK_HERE' | translate
    }}</ct-link>
  </div>
</div>
