import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '@ct/core';

import { MarketplaceItem } from '../../../../../marketplace/interfaces';

@Component({
  selector: 'ct-my-account-marketplace-item',
  templateUrl: './my-account-marketplace-item.component.html',
  styleUrls: ['./my-account-marketplace-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountMarketplaceItemComponent {
  @Input() item: MarketplaceItem | undefined;

  @Output() public clicked = new EventEmitter<MouseEvent>();

  get tag() {
    return { name: String(this.item?.price) } as Tag;
  }

  get hasPhotos(): boolean {
    return Boolean(this.item?.photos?.length);
  }

  get thumbnail(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].xsPreview : undefined;
  }

  get featured(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].path : undefined;
  }

  get publicUrl(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].publicUrl : undefined;
  }
}
