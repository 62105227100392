<div class="not-found-block">
  <div class="not-found" [ngStyle]="{ 'font-size': '2.5rem' }">
    <h2 class="not-found__title">Video Adaptive Streaming POC</h2>
  </div>
  <button (click)="toggleEditPOC()">Toggle Edit Before Upload POC (not uploading to API)</button>
  <ct-file-upload-placeholder
    *ngIf="!showEditPOC"
    [multiple]="false"
    [disabled]="false"
    accept="video/mp4"
    (selectFiles)="onSelectFiles($event)"
  ></ct-file-upload-placeholder>
  <ct-edit-video *ngIf="showEditPOC"></ct-edit-video>
  <ul>
    <ng-container *ngFor="let video of files">
      <li (click)="onPlayVideo(video)">
        {{ video.fileName }}
      </li>
      <ct-my-video-player
        *ngIf="selectedVideoName === video.fileName"
        [hlsUrl]="m3u8 && decodeUrl(m3u8)"
        [dashUrl]="mpd && decodeUrl(mpd)"
      ></ct-my-video-player>
    </ng-container>
  </ul>
</div>
