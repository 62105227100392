<ng-container *ngIf="isLoggedIn$ | async; else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div class="container">
    <section class="user mt-4" *ngIf="user$ | async as user">
      <div class="d-flex flex-nowrap align-items-center">
        <ct-user-avatar class="user__photo" [src]="user?.photo?.path"></ct-user-avatar>
        <span class="user__name text-truncate">{{ user?.username }}</span>
        <span class="user__join"> {{ 'MY_ACCOUNT.JOINED' | translate : { date: user?.createdOn | date } }}</span>
      </div>
      <div class="user__description">
        {{ user.description }}
      </div>
    </section>
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="(userFriend$ | async) === undefined && (loggedInUser$ | async) !== null">
          <ct-button class="mr-2" variant="flat" color="accent" (click)="onAddFriend()">{{
            'USER_PROFILE.ADD_FRIEND' | translate
          }}</ct-button>
        </ng-container>
        <ng-container *ngIf="(userFriend$ | async)?.friendStatus === friendStatus.Pending">
          <ct-button class="mr-2" variant="flat" color="accent" [disabled]="true">{{
            'USER_PROFILE.ADD_FRIEND' | translate
          }}</ct-button>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <ct-filter-pane
        class="my-4 col-12"
        [filters]="(loggedInUser$ | async) !== null ? filters : publicFilters"
        [activeFilter]="activeFilter"
        (filterChanged)="onFilterChanged($event)"
      ></ct-filter-pane>
      <ng-container *ngIf="activeFilter === profileFilter.BlogPosts">
        <ct-widget
          titleKey="USER_PROFILE.LATEST_STORIES"
          icon="article"
          class="col-12"
          [showMore]="true"
          [showMoreLink]="['/stories/by-author']"
          [queryParams]="{ authorId: user?.userId }"
        >
          <div *ngFor="let post of posts$ | async">
            <ct-blog-post-card
              class="mb-4"
              [post]="post"
              [showLink]="true"
              [showAuthor]="false"
              imgHeight="200px"
              imgWidth="100%"
              [link]="getBlogLink(post)"
            ></ct-blog-post-card>
          </div>
        </ct-widget>
        <ng-container *ngIf="!(posts$ | async)?.length">
          <p class="flex-center col-12">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="activeFilter === profileFilter.Trips">
        <div class="col-12 d-flex flex-column">
          <div class="d-flex">
            <h2>{{ 'USER_PROFILE.LATEST_SHARED_TRIPS' | translate }}</h2>
          </div>
          <div class="line"></div>
        </div>
        <div *ngFor="let trip of trips$ | async" class="col-12">
          <ct-trip
            class="mb-3"
            [trip]="trip"
            [link]="getTripLink(trip)"
            [extraLink]="true"
            [showAuthor]="showAuthor"
          ></ct-trip>
          <div class="line"></div>
        </div>
        <ng-container *ngIf="!(trips$ | async)?.length">
          <p class="flex-center col-12">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="activeFilter === profileFilter.Marketplace">
        <ct-widget
          titleKey="USER_PROFILE.LATEST_MARKETPLACE_ITEMS"
          icon="article"
          class="col-12"
          [showMore]="true"
          [showMoreLink]="['/marketplace/by-merchant']"
          [queryParams]="{ merchantId: user?.userId }"
        >
          <div *ngFor="let item of marketplaceItems$ | async">
            <ct-marketplace-item
              class="mb-4"
              [item]="item"
              [showLink]="true"
              [showAuthor]="false"
              imgHeight="180px"
              imgWidth="100%"
              [link]="getMarketplaceItemLink(item)"
            ></ct-marketplace-item>
          </div>
        </ct-widget>
        <ng-container *ngIf="!(marketplaceItems$ | async)?.length">
          <p class="flex-center col-12">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
