import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerModule } from '@ct/components';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MyVideoPlayerModule } from '../my-video-player';
import { VideoPlayerDialogComponent } from './video-player-dialog.component';

@NgModule({
  declarations: [VideoPlayerDialogComponent],
  imports: [CommonModule, DialogModule, TranslateModule, IconModule, SpinnerModule, MyVideoPlayerModule],
  exports: [VideoPlayerDialogComponent]
})
export class VideoPlayerDialogModule {}
