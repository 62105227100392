import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { TemplateRef } from '@angular/core';

import { TreeFlatDataSource, TreeFlattener } from './flat-data-source';

export class DataListItem {
  public id?: string;
  public children?: DataListItem[];
  public collapsed?: boolean;
  public disabled?: boolean;

  constructor(children?: DataListItem[]) {
    this.children = children === undefined ? [] : children;
  }
}

export interface DataListColumn {
  field: string;
  width?: string;
  cellTpl?: TemplateRef<any>;
  iconField?: string;
  iconsMap?: { [key: string]: string };
  align: 'left' | 'right';
}

export interface DataListApi {
  treeControl: FlatTreeControl<DataListItem>;
  dataSource: TreeFlatDataSource<DataListItem, DataListItem>;
  treeFlattener: TreeFlattener<DataListItem, DataListItem>;
  listSelection: SelectionModel<DataListItem | null>;
  findById: (id: string) => DataListItem;
}
