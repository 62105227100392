import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { AuthQuery, AuthService } from '@ct/auth';
import { DialogService, ImageItem } from '@ct/components';
import {
  CanonicalConfig,
  CanonicalFeature,
  FacebookCardType,
  Features,
  MetaTagService,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import {
  BLOG_POSTS_HOME_LIMIT,
  BlogPostApiService,
  getBiggerPublicUrl,
  getCroppedThumbPublicUrl,
  ImageUploadApiService,
  SortOrder
} from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PHOTOS_HOME_LIMIT } from '../../constants';
import { WelcomeVideoComponent } from '../';

@Component({
  selector: 'ct-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature()])
export class HomeComponent implements OnDestroy {
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.TITLE',
      suffixKey: 'MAIN.DESCRIPTION_SHORT'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.TITLE',
      titleSuffixKey: 'MAIN.DESCRIPTION_SHORT',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/home.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    }
  };
  public canonicalConfig: CanonicalConfig = {
    canonicalUrl: '/home'
  };

  public unregisteredBannerImages = [
    'assets/banners/unregistered-banner-1.png',
    'assets/banners/unregistered-banner-2.png',
    'assets/banners/unregistered-banner-3.png'
  ];

  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public blogPosts$ = this.blogPostApiService.getAll({ range: { limit: BLOG_POSTS_HOME_LIMIT } });
  public photos$ = this.imageUploadApiService
    .getAll({ range: { limit: PHOTOS_HOME_LIMIT, offset: 0 }, sortOrder: SortOrder.Desc, isPrivate: false })
    .pipe(
      map((images) =>
        images.map(
          (photo) =>
            new ImageItem({
              src: getBiggerPublicUrl(photo.publicUrl) ?? photo.path,
              thumb: getCroppedThumbPublicUrl(photo.publicUrl),
              data: photo
            })
        )
      )
    );

  private destroyed$ = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private authService: AuthService,
    private blogPostApiService: BlogPostApiService,
    private imageUploadApiService: ImageUploadApiService,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private metaTagService: MetaTagService,
    private translateService: TranslateService
  ) {
    this.translateService
      .get('MAIN.DESCRIPTION')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((description) => this.metaTagService.setMetaDescription(description));
  }

  onLoadedMetadata(videoElem: HTMLVideoElement) {
    // Chrome doesn't want to play video on initial load if we don't set `muted` property explicitly
    videoElem.muted = true;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyVideo();
  }

  showIntroVideo() {
    this.dialogService.open(WelcomeVideoComponent, {
      width: '80vw',
      height: '74vh',
      disableClose: false
    });
  }

  private destroyVideo() {
    if (isPlatformBrowser(this.platformId)) {
      const videoElement = this.videoElement?.nativeElement;
      if (videoElement) {
        videoElement.pause();
        videoElement.removeAttribute('src'); // empty source
        videoElement.load();
      }
    }
  }
}
