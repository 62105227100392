import { Injectable } from '@angular/core';
import { NotificationWithAuthor } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface NotificationsState extends EntityState<NotificationWithAuthor>, NotificationWithAuthor {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notifications', idKey: 'id' })
export class NotificationStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }
}
