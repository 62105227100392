<div class="row wrap">
  <ng-container>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let element of elements; trackBy: trackByFn" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ element.title }}</strong>
            <ct-icon
              *ngIf="isAuthor && !isEditing(element)"
              icon="edit"
              (click)="onStartEdit($event, element)"
            ></ct-icon
          ></mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="!element.items?.length && !isEditing(element)">
          <p>List is empty!</p>
        </ng-container>
        <ng-container *ngIf="isAuthor && isEditing(element)">
          <ng-container *ngIf="arrayControl" [formGroup]="form">
            <ng-container *ngFor="let control of arrayControl.controls; let i = index">
              <div class="d-flex justify-content-between align-items-start">
                <div [formGroupName]="i" class="flex-grow-1">
                  <div class="d-flex align-items-start">
                    <ct-checkbox formControlName="checked" class="flex-shrink-0 mr-2"></ct-checkbox>
                    <ct-input formControlName="name" class="flex-grow-1"> </ct-input>
                    <ct-input
                      formControlName="amount"
                      class="flex-shrink-0 ml-2 item-amount-input"
                      type="number"
                    ></ct-input>
                  </div>
                </div>
                <div class="flex-shrink-0 d-flex ml-4">
                  <ct-icon
                    icon="delete"
                    color="warn"
                    class="text-center cursor-pointer pt-2"
                    (click)="onDeleteItem(i)"
                  ></ct-icon>
                </div>
              </div>
            </ng-container>

            <ct-button [fullWidth]="true" variant="stroked" class="mt-4 mb-4" (click)="addNewItem()">{{
              'Add New Item' | translate
            }}</ct-button>

            <div class="d-flex">
              <ct-button
                variant="stroked"
                class="mt-4 mb-4 mr-2"
                (click)="saveEdits()"
                [disabled]="arrayControl.invalid"
                >{{ 'Save Changes' | translate }}</ct-button
              >

              <ct-button variant="flat" class="mt-4 mb-4" color="accent" (click)="cancelEdit()">{{
                'Cancel' | translate
              }}</ct-button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isAuthor || !isEditing(element)">
          <ng-container *ngIf="element.items?.length">
            <ng-container *ngFor="let item of element.items">
              <ng-container *ngTemplateOutlet="readonlyItem; context: { item: this.item }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <ct-button *ngIf="isAuthor" [fullWidth]="true" variant="flat" class="mt-4 mb-4" (click)="createList()">{{
    'Create New List' | translate
  }}</ct-button>
</div>

<ng-template #readonlyItem let-item="item">
  <p [class.item-checked]="item.checked">
    {{ item.name }} <span class="item-amount">x{{ item.amount }}</span>
  </p>
</ng-template>
