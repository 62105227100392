import { ImageSize, LoadingStrategy, SlidingDirection, ThumbnailsMode, ThumbnailsPosition } from '../enums';
import { GalleryConfig } from '../interfaces';

export const defaultConfig: GalleryConfig = {
  nav: true,
  thumb: true,
  gestures: true,
  autoPlay: false,
  thumbWidth: 120,
  thumbHeight: 90,
  panSensitivity: 25,
  disableThumb: false,
  playerInterval: 3000,
  imageSize: ImageSize.Cover,
  thumbMode: ThumbnailsMode.Strict,
  thumbPosition: ThumbnailsPosition.Bottom,
  loadingStrategy: LoadingStrategy.Default,
  slidingDirection: SlidingDirection.Horizontal
};
