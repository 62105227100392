import { MapsAPILoader } from '@agm/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { BehaviorSubject, Observable } from 'rxjs';

export const GOOGLE_PLACES_DEFAULT_OPTIONS: Options = {
  types: ['(regions)'],
  strictBounds: false,
  componentRestrictions: {
    country: ['us', 'pr', 'vi', 'ca', 'mp']
  },
  origin: { lat: 35, lng: -84 }
} as unknown as Options;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss']
})
@Features([DestroyableFeature()])
export class GooglePlacesComponent extends BaseControlComponent<string> {
  public readonly destroyed$: Observable<void>;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @Input() public label: string;
  @Input() public readonly placeholder: string = '';

  @Output() public locationChanged = new EventEmitter<Address>();
  @Output() public setCurrentLocation = new EventEmitter<any>();

  @Input() set options(options: Options) {
    this.#options = options;
  }
  get options(): Options {
    return this.#options;
  }

  public readonly control = new UntypedFormControl(null);

  get isApiLoaded$(): Observable<boolean> {
    return this.isApiLoadedSubject.asObservable();
  }

  #options: Options = GOOGLE_PLACES_DEFAULT_OPTIONS;

  private isApiLoadedSubject = new BehaviorSubject(false);

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    @Optional() readonly formState: FormStateDispatcher | null,
    private mapsAPILoader: MapsAPILoader
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
    this.mapsAPILoader.load().then(() => this.isApiLoadedSubject.next(true));
  }

  public handleAddressChange(address: Address) {
    this.locationChanged.emit(address);
  }

  public currentLocation() {
    this.setCurrentLocation.emit();
  }
}
