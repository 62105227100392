import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { ImageCarouselItemComponent } from './components/image-carousel-item/image-carousel-item.component';
import { ImageCarouselComponent } from './image-carousel.component';

@NgModule({
  declarations: [ImageCarouselComponent, ImageCarouselItemComponent],
  imports: [CommonModule, IconModule],
  exports: [ImageCarouselComponent, ImageCarouselItemComponent]
})
export class ImageCarouselModule {}
