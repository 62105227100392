import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { LightboxModule } from '@ct/components/lightbox';
import { LinkModule } from '@ct/components/link';
import { VideoModule } from '@ct/components/video';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';
import { SortablejsModule } from 'ngx-sortablejs';

import {
  NewItemPlaceholderComponent,
  PhotoGalleryComponent,
  PhotoGridComponent,
  VideoGridComponent
} from './components';
import { PhotoComponent } from './components/photo/photo.component';

@NgModule({
  declarations: [
    PhotoGridComponent,
    VideoGridComponent,
    NewItemPlaceholderComponent,
    PhotoComponent,
    PhotoGalleryComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ImageModule,
    VideoModule,
    LightboxModule,
    LinkModule,
    IconModule,
    SortablejsModule.forRoot({ animation: 150 }),
    PipesModule
  ],
  exports: [PhotoGridComponent, VideoGridComponent, NewItemPlaceholderComponent, PhotoComponent, PhotoGalleryComponent]
})
export class MediaSharedModule {}
