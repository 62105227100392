import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CardWrapperItem } from '@ct/components/card-wrapper';
import { BlogPost, Tag, UserProfile } from '@ct/core/interfaces';

import { getCroppedThumbPublicUrl, getYoutubeId } from '../../../../helpers';

export interface CardWrapperItemWithAuthor extends CardWrapperItem {
  author?: UserProfile;
  tags?: Tag[];
}

@Component({
  selector: 'ct-writing-card',
  templateUrl: './writing-card.component.html',
  styleUrls: ['./writing-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WritingCardComponent implements OnChanges {
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  @Input() readonly story: BlogPost | undefined;
  @Input() readonly link: string[] | string;
  @Input() readonly canEdit: boolean = false;
  @Input() readonly canShare: boolean = false;
  @Input() readonly canOpen: boolean = true;
  @Input() readonly showLastEdit: boolean = true;

  @Output() readonly edit: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly share: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly openStory: EventEmitter<void> = new EventEmitter<void>();

  cardItem: CardWrapperItemWithAuthor;

  get placeholderPath(): string {
    let index = 0;
    if (this.story?.id) {
      index = Number(this.story.id) % 4;
    }
    return `assets/placeholders/camping_bg_${index}.jpeg`;
  }

  get thumbnail(): string | undefined {
    return this.story?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.story?.featuredYoutubeVideo)}/default.jpg`
      : this.story?.featuredPhoto?.xsPreview;
  }

  get publicUrl(): string | undefined {
    return this.story?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.story?.featuredYoutubeVideo)}/maxresdefault.jpg`
      : this.story?.featuredPhoto?.publicUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.story && this.story) {
      const photoIds = [this.story.featuredPhotoId, ...(this.story.photoIds ?? [])];
      this.cardItem = { ...this.story, photoIds };
    }
  }

  onEditClick() {
    if (this.story) {
      this.edit.emit();
    }
  }

  onShareClick() {
    if (this.story) {
      this.share.emit();
    }
  }

  onOpenClick() {
    if (this.story) {
      this.openStory.emit();
    }
  }
}
