import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

let apiLoaded = false;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<youtube-player videoId="{{videoLink}}" [height]="height" [width]="width"></youtube-player>',
  selector: 'ct-youtube-player'
})
export class YoutubePlayerComponent implements OnInit {
  @Input() videoLink: string;
  @Input() width: number | undefined = undefined;
  @Input() height: number | undefined = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && !apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }
}
