import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BlogPostApiService, uniqueBy } from '@ct/shared';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BlogSidebar } from '../interfaces';

const TAGS_LIMIT = 20;
const USER_LIMIT = 5;
const BLOG_LIMIT = 5;

@Injectable({ providedIn: 'root' })
export class BlogSidebarDataResolver implements Resolve<BlogSidebar> {
  constructor(private blogPostApiService: BlogPostApiService) {}

  resolve() {
    return this.getSidebarData();
  }

  private getSidebarData(): Observable<BlogSidebar> {
    return forkJoin([
      this.blogPostApiService.getAll({ range: { limit: BLOG_LIMIT } }),
      this.blogPostApiService.getLatestTags().pipe(catchError(() => of(null)))
    ]).pipe(
      map(([posts, tags]) => {
        return {
          tags: tags?.slice(0, TAGS_LIMIT) ?? [],
          posts: posts?.slice(0, BLOG_LIMIT),
          users: uniqueBy(posts?.map((post) => post.author) ?? [], 'userId').slice(0, USER_LIMIT)
        };
      })
    );
  }
}
