import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ChannelVideoUploadApiService, VideoUploadEntity } from '@ct/core';

import { MY_ACCOUNT_VIDEOS_FEATURE_LIMIT } from '../../../constants';

@Injectable({ providedIn: 'root' })
export class MyAccountVideosDataResolver implements Resolve<VideoUploadEntity[]> {
  constructor(private videoUploadApiService: ChannelVideoUploadApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.videoUploadApiService.getAllVideos({ range: { limit: MY_ACCOUNT_VIDEOS_FEATURE_LIMIT, offset } });
  }
}
