import { Pipe, PipeTransform } from '@angular/core';

import { YoutubeVideo } from '../interfaces';

@Pipe({
  name: 'youtubeObject'
})
export class YoutubeObjectPipe implements PipeTransform {
  transform(value: string | undefined | YoutubeVideo): YoutubeVideo {
    if (!value) {
      return {} as YoutubeVideo;
    }
    return { youtubeId: value } as YoutubeVideo;
  }
}

@Pipe({
  name: 'youtubeObjectArray'
})
export class YoutubeObjectArrayPipe implements PipeTransform {
  transform(value: string[] | YoutubeVideo[]): YoutubeVideo[] {
    if (Array.isArray(value)) {
      return value.map((v) => ({ youtubeId: v } as YoutubeVideo));
    }
    return [];
  }
}
