import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { EnrichedExploreTimeline, GroupTimelineType, UserProfile } from '@ct/core';
import { EntityType, getCroppedThumbPublicUrl, LikeApiService, trackById, TripJournalEntry } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-explore-entity',
  templateUrl: './my-account-explore-entity.component.html',
  styleUrls: ['./my-account-explore-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountExploreEntityComponent implements OnDestroy {
  public timeline: EnrichedExploreTimeline;
  public readonly timelineType = GroupTimelineType;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public loading = false;

  protected readonly trackById = trackById;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private likeApiService: LikeApiService,
    private route: ActivatedRoute
  ) {
    this.route.data
      .pipe(
        map(({ entity }) => entity),
        takeUntil(this.destroyed$)
      )
      .subscribe((timeline) => {
        this.timeline = timeline;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getEntityType(entity: EnrichedExploreTimeline): EntityType {
    if (entity.groupId && entity.id === entity.metadataId) {
      return EntityType.GroupTimeline;
    }

    const type = entity.type;

    switch (type) {
      case GroupTimelineType.Journal:
        return EntityType.JournalEntry;
      default:
        return type as unknown as EntityType;
    }
  }

  toJournalEntry(entity: TripJournalEntry | undefined): TripJournalEntry {
    return entity as TripJournalEntry;
  }

  getTripLink(timeline: EnrichedExploreTimeline): string[] {
    return ['/my-account/trips', timeline.entityId as string, timeline.trip?.slug as string, 'timeline'];
  }

  getStoryLink(timeline: EnrichedExploreTimeline): string {
    const slug = timeline.story?.slug as string;
    const slugArray = slug.split('-');
    const id = slugArray[slugArray.length - 1];
    return [
      '/stories',
      id === String(timeline.entityId) ? (timeline.story?.slug as string) : `${slug}-${timeline.entityId}`
    ].join('/');
  }

  onLike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .like({
        entityId: entity.metadataId as string,
        entityType: EntityType.GroupTimeline
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  onDislike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .dislike({
        entityId: entity.metadataId as string,
        userId: this.authQuery.profile?.userId as string
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }
}
