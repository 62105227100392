import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Trip } from '../interfaces';
import { TripApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class TripDataResolver implements Resolve<Trip | undefined> {
  constructor(private tripApiService: TripApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const id = activatedRouteSnapshot.params?.id;
    return this.tripApiService.getById(id);
  }
}
