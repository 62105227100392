import { PasswordMatchValidator } from './password-match.validator';
import { PhoneNumberValidator } from './phone-number.validator';
import { PhoneNumberExtendedValidator } from './phone-number-extended.validator';
import { UsernameValidator } from './username.validator';
import { VerificationCodeValidator } from './verification-code.validator';

const CUSTOM_VALIDATORS = {
  phoneNumber: PhoneNumberValidator,
  phoneNumberExtended: PhoneNumberExtendedValidator,
  passwordMatch: PasswordMatchValidator,
  verificationCode: VerificationCodeValidator,
  username: UsernameValidator
};

export { CUSTOM_VALIDATORS as CustomValidators };
