import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonBase } from '@ct/components/button';
import { DialogService } from '@ct/components/dialog/services';
import { NotificationWithAuthor, UserProfile } from '@ct/core';
import { Trip } from '@ct/shared';
import { CreateTripDialogComponent } from '@ct/shared/modules/trip-shared/modules/create-trip-dialog';

@Component({
  selector: 'ct-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutHeaderComponent {
  readonly logoutButtonClass = 'logout-button-content';

  @Input() visibleFullList: boolean;

  @Input() isMobile: boolean;

  @Input() user: UserProfile | null;

  @Input() notifications: NotificationWithAuthor[] | null;

  @Input() headerMenuCollapsed: boolean;

  @Input() sidebarShown: boolean | null;

  @Input() userButtons: ButtonBase[];

  @Input() protected showSearch = true;

  @Input() trip: Trip;

  @Output() addToTrip: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleHeaderMenu: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(private dialogService: DialogService) {}

  onToggleSidebar() {
    this.toggleSidebar.emit();
  }

  onLogout() {
    this.logout.emit();
  }

  onToggleHeaderMenu(event: MouseEvent) {
    this.toggleHeaderMenu.emit(event);
  }

  openNewTripDialog() {
    this.dialogService.open(CreateTripDialogComponent);
  }

  addToTripDialog() {
    this.addToTrip.emit();
  }
}
