import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import {
  AuthorModule,
  ButtonModule,
  CardModule,
  CheckboxModule,
  DateModule,
  DialogModule,
  EmptyCallToActionModule,
  FilterPaneModule,
  IconModule,
  ImageModule,
  InputModule,
  LinkModule,
  NavigationModule,
  SelectModule,
  StaticPagesModule,
  TabHeaderModule,
  TabsMobileModule,
  TagModule,
  TextEditorModule,
  UserAvatarModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { LayoutModule } from '@ct/layout';
import {
  AddToTripDialogModule,
  CreateTripDialogModule,
  MediaSharedModule,
  TripSharedModule,
  YoutubeModule
} from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SortablejsModule } from 'ngx-sortablejs';

import { MyAccountSharedModule } from '../my-account-shared';
import {
  AddListDialogComponent,
  MyAccountTripEditComponent,
  MyAccountTripItineraryComponent,
  MyAccountTripJournalsComponent,
  MyAccountTripListsComponent,
  MyAccountTripPhotoEditComponent,
  MyAccountTripsComponent,
  MyAccountTripTabsComponent,
  MyAccountTripTimelineComponent,
  MyAccountTripWrapperComponent,
  ShareTripDialogComponent
} from './components';
import { MyAccountTripsRoutingModule } from './my-account-trips-routing.module';

@NgModule({
  declarations: [
    MyAccountTripsComponent,
    MyAccountTripWrapperComponent,
    MyAccountTripEditComponent,
    MyAccountTripTimelineComponent,
    ShareTripDialogComponent,
    MyAccountTripTimelineComponent,
    MyAccountTripTabsComponent,
    MyAccountTripJournalsComponent,
    MyAccountTripItineraryComponent,
    MyAccountTripListsComponent,
    MyAccountTripPhotoEditComponent,
    AddListDialogComponent
  ],
  imports: [
    CommonModule,
    MyAccountTripsRoutingModule,
    TranslateModule,
    CardModule,
    DialogModule,
    MyAccountSharedModule,
    TripSharedModule,
    NavigationModule,
    LinkModule,
    PipesModule,
    ButtonModule,
    AuthorModule,
    TagModule,
    TextEditorModule,
    DateModule,
    ReactiveFormsModule,
    InputModule,
    StaticPagesModule,
    MediaSharedModule,
    NavigationModule,
    SortablejsModule.forRoot({ animation: 150 }),
    MatIconModule,
    UserAvatarModule,
    FilterPaneModule,
    YoutubeModule,
    EmptyCallToActionModule,
    TabHeaderModule,
    TabsMobileModule,
    IconModule,
    IconModule,
    LayoutModule,
    InfiniteScrollModule,
    CreateTripDialogModule,
    AddToTripDialogModule,
    SelectModule,
    ImageModule,
    MatExpansionModule,
    CheckboxModule
  ]
})
export class MyAccountTripsModule {}
