import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@ct/components/spinner/services';
import { BaseHttpService, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

import { EntityType, SortOrder } from '../../../enums';
import { ShareApiService } from '../../../services/share-api.service';
import { ItineraryCreateRequest, ItineraryResponse } from '../interfaces';

const endpoint = environment.itineraryApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class ItineraryApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    protected shareApiService: ShareApiService<ItineraryResponse>,
    protected spinnerService: SpinnerService
  ) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    sortOrder = SortOrder.Desc,
    authorId,
    fullEntries = true,
    sortBy
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    authorId?: string;
    fullEntries?: boolean;
    sortBy?: keyof ItineraryResponse;
  }): Observable<ItineraryResponse[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (fullEntries !== undefined && fullEntries !== null) {
      params = params.append('fullEntries', fullEntries);
    }

    return this.get('', params, { headers, withCredentials: true });
  }

  getAllByAuthorId(
    authorId: string,
    {
      range,
      sortOrder,
      sortBy
    }: {
      range?: RequestRange;
      sortOrder?: SortOrder | undefined;
      sortBy?: keyof ItineraryResponse;
    }
  ) {
    return this.getAll({ range, sortOrder, authorId, sortBy });
  }

  getById(id: string) {
    return this.get(id);
  }

  create(itinerary: ItineraryCreateRequest) {
    return this.post('', itinerary);
  }

  update(id: string, campsiteIds: string[]) {
    return this.patch(id, { campsiteIds });
  }

  remove(id: string) {
    return this.delete(id);
  }

  search(search: string, range?: RequestRange) {
    return this.get(`search`, { query: search, ...range });
  }

  getAllShared({
    authorId,
    userId,
    range,
    sortOrder
  }: {
    authorId: string;
    userId: string;
    range?: RequestRange;
    sortOrder: SortOrder;
  }): Observable<ItineraryResponse[]> {
    return this.shareApiService.getAllEntities({
      entityType: EntityType.Itinerary,
      userId,
      authorId,
      range,
      sortOrder
    });
  }
}
