import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { TripCampsiteApiService } from '../services';
import { TripCampsite } from './../interfaces/trip.interface';

@Injectable({ providedIn: 'root' })
export class TripCampsiteDataResolver implements Resolve<TripCampsite | undefined> {
  constructor(private tripCampsiteApiService: TripCampsiteApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const id = activatedRouteSnapshot.params?.id;
    const campsiteId = activatedRouteSnapshot.params?.campsiteId;
    return this.tripCampsiteApiService.getById(id, campsiteId);
  }
}
