import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Group, UserProfile } from '@ct/core';

@Component({
  selector: 'ct-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupCardComponent {
  @Input() imgHeight: string;
  @Input() imgWidth: string;
  @Input() objectFit = 'cover';
  @Input() group: Group | undefined;
  @Input() user?: UserProfile | null;
  @Input() link: string;
  @Input() imgCols = 3;
  @Input() contentCols = 9;
  @Input() contentMargin: boolean;
  @Input() linkFullWidth: boolean;
  @Input() protected readonly isAdmin: boolean;

  @Output() joinGroup = new EventEmitter<Group>();
  @Output() deleteGroup = new EventEmitter<Group>();
  @Output() cancelRequest = new EventEmitter<Group>();

  get groupWithImage() {
    return {
      ...this.group,
      featuredPhoto: this.group?.image
    };
  }

  get canJoin(): boolean {
    return (
      Boolean(this.user) &&
      !this.user?.groupIds?.includes(String(this.group?.id)) &&
      !this.user?.pendingReviewGroupIds?.includes(String(this.group?.id)) &&
      !this.user?.pendingInvitationGroupIds?.includes(String(this.group?.id))
    );
  }

  get canAccept(): boolean {
    return Boolean(this.user) && Boolean(this.user?.pendingInvitationGroupIds?.includes(String(this.group?.id)));
  }

  get requestSent(): boolean {
    return Boolean(this.user) && Boolean(this.user?.pendingReviewGroupIds?.includes(String(this.group?.id)));
  }

  onJoin() {
    this.joinGroup.emit(this.group);
  }

  onCancel() {
    this.cancelRequest.emit(this.group);
  }

  onDelete() {
    this.deleteGroup.emit(this.group);
  }
}
