import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../button';
import { DialogModule } from '../dialog';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { ShareDialogComponent } from './share-dialog';
import { SocialSharingComponent } from './social-sharing.component';

@NgModule({
  declarations: [SocialSharingComponent, ShareDialogComponent],
  imports: [
    CommonModule,
    IconModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    InputModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  exports: [SocialSharingComponent, ShareDialogComponent]
})
export class SocialSharingModule {}
