<div
  *ngIf="items?.length"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div *ngFor="let item of items; trackBy: trackByFn">
    <ct-marketplace-item
      class="mb-4"
      [item]="item"
      [showLink]="true"
      [showAuthor]="false"
      imgHeight="200px"
      imgWidth="100%"
      [link]="getLink(item)"
    ></ct-marketplace-item>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</div>
<ng-container *ngIf="!items?.length">
  <p class="flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
</ng-container>
