import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@ct/auth';
import { BaseHttpService, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { YoutubeVideo, YoutubeVideoEditRequest } from '../interfaces';

const endpoint = environment.videoApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class YoutubeVideoApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient, private authService: AuthService) {
    super(httpClient, endpoint);
  }

  getAll({ range, authorId }: { range?: RequestRange; authorId?: string }): Observable<YoutubeVideo[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    return this.get(``, params, { headers, withCredentials: true });
  }

  getById(id: string): Observable<YoutubeVideo | undefined> {
    return this.get(`${id}`);
  }

  remove(id: string): Observable<undefined> {
    return this.delete(`${id}`);
  }

  create(youtubeVideo: YoutubeVideo) {
    return this.post(``, youtubeVideo);
  }

  addToUser(youtubeVideo: YoutubeVideo[]) {
    return this.post(`addVideos`, youtubeVideo);
  }

  update(id: string, youtubeVideo: YoutubeVideoEditRequest) {
    return this.patch(`${id}`, youtubeVideo);
  }

  removeFrom(id: string, dataToRemove: YoutubeVideoEditRequest) {
    return this.post(`${id}/remove`, dataToRemove);
  }

  search(search: string, range: RequestRange, type: string = 'list') {
    return this.get(`search`, { query: search, ...range });
  }

  getAllMyVideos(range?: RequestRange) {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return this.getAll({ range, authorId: identity.guuid });
      })
    );
  }
}
