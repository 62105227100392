import { FriendStatus } from '../enums';
import { UploadedImage } from './uploaded-image.interface';

export enum UserRole {
  Admin = 'admin',
  Moderator = 'moderator'
}

export interface UserProfile {
  id?: string;
  userId: string;
  firstName?: string;
  lastName?: string;
  username: string;
  description?: string;
  createdOn?: Date | string;
  modifiedOn?: Date | string;
  active?: boolean;
  photoId?: string;
  groupIds?: string[];
  pendingReviewGroupIds?: string[];
  pendingInvitationGroupIds?: string[];
  videoChannelIds?: string[];
  photo?: UploadedImage;
  email?: string;
}

export interface RelationUserProfile extends UserProfile {
  friendStatus: FriendStatus;
  canAccept: boolean;
  relationId: string;
}

export interface UserWithRoles extends UserProfile {
  roles: UserRole[];
}
