import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components';

@Component({
  selector: 'ct-add-list-dialog',
  templateUrl: './add-list-dialog.component.html',
  styleUrls: ['./add-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddListDialogComponent {
  public readonly title = new FormControl('', Validators.required);
  public readonly buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.dialogRef.close()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onSave();
      }
    }
  ];

  constructor(private readonly dialogRef: MatDialogRef<AddListDialogComponent>) {}

  private onSave() {
    this.title.markAsTouched();
    this.title.updateValueAndValidity();
    if (this.title.valid) {
      this.dialogRef.close(this.title.value);
    }
  }
}
