<ct-dialog-content
  [titleKey]="isEditMode ? 'GROUPS_FEATURE.EDIT_GROUP' : 'GROUPS_FEATURE.CREATE_GROUP'"
  [closeIcon]="true"
  headerIcon="add-circle"
>
  <form class="form" [formGroup]="form">
    <div class="row">
      <ct-input
        class="col-12"
        formControlName="title"
        [label]="'GROUPS_FEATURE.GROUP_TITLE' | translate"
        [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TITLE_PLACEHOLDER' | translate"
      ></ct-input>
      <ct-select
        class="col-12"
        formControlName="visibility"
        [label]="'GROUPS_FEATURE.CHOOSE_PRIVACY' | translate"
        [options]="privacyOptions"
        [optional]="false"
      ></ct-select>
    </div>
    <div class="row mb-3">
      <h2 class="item-title col-12">{{ 'GROUPS_FEATURE.GROUP_IMAGE' | translate }}</h2>
      <ct-new-item-placeholder
        class="placeholder col-12 col-md-3"
        [img]="form.controls.image?.value?.publicUrl"
        [textKey]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_NEW_PHOTO'"
        [replaceKey]="form.controls.image?.value?.publicUrl ? 'MY_ACCOUNT.MY_TRIPS_FEATURE.REPLACE_PHOTO' : ''"
        (placeholderClicked)="onSelectFeaturedPhotoPlaceholder()"
        (replaced)="onReplaceFeaturedPhoto()"
        (removed)="onRemoveFeaturedPhoto()"
      ></ct-new-item-placeholder>
    </div>
    <div class="flex-space-between">
      <div class="d-flex">
        <ct-button class="mr-2" variant="flat" color="primary" (click)="onCancel()">{{
          'COMMON.CANCEL' | translate
        }}</ct-button>
        <ct-button [disabled]="isLoading" class="mr-2" variant="flat" color="accent" (click)="onSave()">{{
          'COMMON.SAVE' | translate
        }}</ct-button>
      </div>
      <div *ngIf="isEditMode" class="d-flex">
        <ct-button [disabled]="isLoading" class="mr-2" variant="flat" color="warn" (click)="onDelete()">{{
          'COMMON.DELETE' | translate
        }}</ct-button>
      </div>
    </div>
  </form>
</ct-dialog-content>
