import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import {
  CustomValidators,
  DestroyableFeature,
  EMAIL_MAX_LENGTH,
  Features,
  FormStateDispatcher,
  NAME_MAX_LENGTH,
  UserProfile
} from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { default as PhoneNumber } from 'awesome-phonenumber';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { MerchantProfile } from '../../../../../marketplace/interfaces';
import { MerchantProfileApiService } from '../../../../../marketplace/services';

@Component({
  selector: 'ct-edit-merchant-profile',
  templateUrl: './edit-merchant-profile.component.html',
  styleUrls: ['./edit-merchant-profile.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class EditMerchantProfileComponent {
  public readonly destroyed$: Observable<void>;

  public form;
  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private route: ActivatedRoute,
    private formState: FormStateDispatcher,
    private merchantProfileApiService: MerchantProfileApiService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(NAME_MAX_LENGTH)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(NAME_MAX_LENGTH)]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(EMAIL_MAX_LENGTH)
      ]),
      phone: new UntypedFormControl('', [Validators.required, CustomValidators.phoneNumberExtended('US')]),
      address: new UntypedFormGroup({
        street: new UntypedFormControl('', [Validators.required]),
        street2: new UntypedFormControl(''),
        city: new UntypedFormControl('', [Validators.required]),
        state: new UntypedFormControl('', [Validators.required]),
        zip: new UntypedFormControl('', [Validators.required])
      })
    });

    const merchantProfile = this.route.snapshot.data.merchantProfile as MerchantProfile;
    this.form.patchValue(merchantProfile, { emitEvent: false });
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    const { phone: phoneNumber, ...merchantProfile } = this.form.value;

    const phone = new PhoneNumber(phoneNumber, 'US').getNumber('international');

    this.merchantProfileApiService.create({ ...merchantProfile, phone }).subscribe(
      () => null,
      (error) => {
        if (error.status === 400) {
          if (error.error.field === 'address') {
            const addressGroup = this.form.get('address') as UntypedFormGroup;
            Object.values(addressGroup.controls).forEach((control) => {
              control.setErrors({ invalid: true });
            });

            addressGroup.valueChanges.pipe(take(1)).subscribe(() => {
              Object.values(addressGroup.controls).forEach((control) => {
                control.setErrors(null);
              });
            });
          }
        }
      }
    );
  }
}
