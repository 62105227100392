import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@ct/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppBrowserModule } from './app/app.browser.module';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: environment.sentry.tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new Sentry.Replay(),
  ],
  enabled: environment.sentry.enabled,
  sampleRate: environment.sentry.sampleRate,
  environment: environment.sentry.environment,
  tracesSampleRate: environment.sentry.tracesSampleRate,
  replaysSessionSampleRate: environment.sentry.sampleRate,
  replaysOnErrorSampleRate: environment.sentry.tracesSampleRate,
  ignoreErrors: [
    'Non-Error exception captured',
    'Unknown Error',
  ],
});

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
});
