import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { GalleryConfig, GalleryState } from '../../interfaces';

@Component({
  selector: 'ct-gallery-nav',
  templateUrl: './gallery-nav.component.html',
  styleUrls: ['./gallery-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryNavComponent {
  @Input() state: GalleryState | null;
  @Input() config: GalleryConfig | null;
  @Output() action = new EventEmitter<string>();
}
