import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Mode, TripDataResolver, TripTimelineListsResolver } from '@ct/shared';
import { TripTimelineResolver } from '@ct/shared/modules/trip-shared/resolvers/trip-timeline.resolver';

import { FriendsListResolver } from '../../resolvers';
import {
  MyAccountTripEditComponent,
  MyAccountTripItineraryComponent,
  MyAccountTripJournalsComponent,
  MyAccountTripListsComponent,
  MyAccountTripsComponent,
  MyAccountTripTabsComponent,
  MyAccountTripTimelineComponent,
  MyAccountTripWrapperComponent
} from './components';
import {
  MyAccountTripCampsitesDataResolverService,
  MyAccountTripJournalEntriesDataResolverService,
  MyAccountTripsAvailableResolver,
  MyAccountTripsDataResolver,
  MyAccountTripsLastEditedResolver
} from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: MyAccountTripsComponent,
    resolve: {
      trips: MyAccountTripsDataResolver,
      hasContent: MyAccountTripsAvailableResolver,
      lastEdited: MyAccountTripsLastEditedResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: ':id/:slug',
    redirectTo: ':id/:slug/timeline',
    pathMatch: 'full'
  },
  {
    path: ':id/:slug',
    component: MyAccountTripWrapperComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: 'timeline',
            resolve: {
              timeline: TripTimelineResolver,
              trip: TripDataResolver,
              friends: FriendsListResolver
            },
            runGuardsAndResolvers: 'always',
            component: MyAccountTripTabsComponent,
            children: [
              {
                path: '',
                component: MyAccountTripTimelineComponent
              }
            ]
          },
          {
            path: 'campsites',
            resolve: {
              campsites: MyAccountTripCampsitesDataResolverService,
              trip: TripDataResolver,
              friends: FriendsListResolver
            },
            runGuardsAndResolvers: 'always',
            component: MyAccountTripTabsComponent,
            children: [
              {
                path: '',
                component: MyAccountTripItineraryComponent
              }
            ]
          },
          {
            path: 'journals',
            resolve: {
              campsites: MyAccountTripCampsitesDataResolverService,
              journalEntries: MyAccountTripJournalEntriesDataResolverService,
              trip: TripDataResolver,
              friends: FriendsListResolver
            },
            runGuardsAndResolvers: 'always',
            component: MyAccountTripTabsComponent,
            children: [
              {
                path: '',
                component: MyAccountTripJournalsComponent
              }
            ]
          },
          {
            path: 'lists',
            resolve: {
              timeline: TripTimelineListsResolver,
              trip: TripDataResolver,
              friends: FriendsListResolver
            },
            runGuardsAndResolvers: 'always',
            component: MyAccountTripTabsComponent,
            children: [
              {
                path: '',
                component: MyAccountTripListsComponent
              }
            ]
          }
        ]
      },
      {
        path: 'edit',
        component: MyAccountTripEditComponent,
        resolve: {
          trip: TripDataResolver,
          friends: FriendsListResolver
        },
        data: { mode: Mode.Edit }
      }
    ]
  },
  {
    path: 'create',
    component: MyAccountTripWrapperComponent,
    children: [
      {
        path: '',
        component: MyAccountTripEditComponent,
        data: { mode: Mode.Create }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountTripsRoutingModule {}
