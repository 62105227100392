import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AutocompleteModule } from '../autocomplete';
import { TagModule } from './../tag';
import { KeywordInputComponent } from './keyword-input.component';

@NgModule({
  declarations: [KeywordInputComponent],
  imports: [CommonModule, AutocompleteModule, ReactiveFormsModule, TranslateModule, TagModule],
  exports: [KeywordInputComponent]
})
export class KeywordInputModule {}
