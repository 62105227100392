import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

import { EntityItemNewComponent } from './entity-item-new';

@Injectable({
  providedIn: 'root'
})
export class NewCommentService {
  private container: ViewContainerRef | null;

  private containerCleared$ = new Subject();

  get containerCleared() {
    return this.containerCleared$.asObservable();
  }

  create(container: ViewContainerRef) {
    this.container?.clear();
    this.container = container;
    const compRef: ComponentRef<EntityItemNewComponent> = this.container.createComponent(EntityItemNewComponent);
    return compRef.instance;
  }

  clear() {
    this.container?.clear();
    this.containerCleared$.next();
  }

  destroy() {
    this.container?.clear();
    this.container = null;
  }
}
