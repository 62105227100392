import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { Observable } from 'rxjs';

import { SelectOption } from './select.interface';

@Component({
  selector: 'ct-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectComponent<T> extends BaseControlComponent<T> implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly control = new UntypedFormControl(null);

  @Input() public label: string;
  @Input() public readonly options: SelectOption[] | null = [];
  @Input() public readonly placeholder: string;
  @Input() public readonly optional: boolean = true;
  @Input() public readonly showLabel: boolean;

  @Input() public readonly displayFn: (options: SelectOption[] | null, id: string) => SelectOption;
  @Input() public readonly valueFn = ({ value }: SelectOption) => value;
  @Input() public readonly labelFn = ({ label }: SelectOption) => label;
  @Input() public readonly compareFn = (option: SelectOption | string, selected: SelectOption | number | null) => {
    if (selected === null) {
      return false;
    }

    if (typeof option === 'object' && typeof selected === 'object') {
      return option.value === selected.value;
    } else {
      return option === selected;
    }
  };

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  onFocus() {
    this.onTouched?.();
  }

  trackByFn(option: any) {
    return option.value;
  }
}
