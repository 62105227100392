import { Injectable } from '@angular/core';
import { DialogService } from '@ct/components/dialog/services';
import { Span } from '@ct/opentelemetry';

import { YoutubeVideoViewDialogComponent } from '../components/youtube-video-view-dialog';
import { YoutubeDialogViewConfig } from '../interfaces';

@Injectable()
export class YoutubeVideoViewService {
  constructor(private dialogService: DialogService) {}

  @Span()
  showVideoViewDialog(config: YoutubeDialogViewConfig) {
    return this.dialogService.open(YoutubeVideoViewDialogComponent, {
      width: '60vw',
      data: { config }
    });
  }
}
