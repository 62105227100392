import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogPostDataResolver, BlogPostsDataResolver, BlogPostsSearchResolver } from '@ct/shared';

import {
  BlogComponent,
  BlogPostsAllComponent,
  BlogPostsByAuthorComponent,
  BlogPostsByTagComponent,
  BlogPostsComponent,
  BlogPostsSearchComponent,
  BlogPostViewComponent
} from './components';
import { BlogSidebarDataResolver } from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: BlogComponent,
    children: [
      {
        path: '',
        component: BlogPostsAllComponent,
        children: [
          {
            path: '',
            component: BlogPostsComponent,
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            resolve: {
              posts: BlogPostsDataResolver,
              sidebar: BlogSidebarDataResolver
            }
          }
        ]
      },
      {
        path: 'search',
        component: BlogPostsSearchComponent,
        children: [
          {
            path: '',
            component: BlogPostsComponent,
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            resolve: {
              posts: BlogPostsSearchResolver,
              sidebar: BlogSidebarDataResolver
            }
          }
        ]
      },
      {
        path: 'by-author',
        component: BlogPostsByAuthorComponent,
        children: [
          {
            path: '',
            component: BlogPostsComponent,
            resolve: {
              posts: BlogPostsDataResolver,
              sidebar: BlogSidebarDataResolver
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange'
          }
        ]
      },
      {
        path: 'by-tag',
        component: BlogPostsByTagComponent,
        children: [
          {
            path: '',
            component: BlogPostsComponent,
            resolve: {
              posts: BlogPostsDataResolver,
              sidebar: BlogSidebarDataResolver
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange'
          }
        ]
      },
      {
        path: ':id/:slug',
        component: BlogPostViewComponent,
        resolve: {
          post: BlogPostDataResolver,
          sidebar: BlogSidebarDataResolver
        }
      },
      {
        path: ':slug',
        component: BlogPostViewComponent,
        resolve: {
          post: BlogPostDataResolver,
          sidebar: BlogSidebarDataResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogRoutingModule {}
