import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { PostsState, PostsStore } from './posts.store';

@Injectable({ providedIn: 'root' })
export class PostsQuery extends Query<PostsState> {
  selectPosts$ = this.select('posts');

  constructor(protected store: PostsStore) {
    super(store);
  }
}
