import { UntypedFormGroup } from '@angular/forms';
import { DialogService, InformationDialogComponent } from '@ct/components/dialog';
import { LocalStorageService } from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { debounceTime, map, takeUntil, takeWhile } from 'rxjs/operators';

const DEBOUNCE_TIME = 2000;

export interface AutosaveConfig {
  autosaveKey: string;
  excludeFormFields?: string[];
  dialogConfig: {
    titleKey: string;
    messageKey: string;
    confirmKey: string;
    cancelKey: string;
  };
}

export class BaseAutosaveFormComponent {
  public readonly destroyed$: Observable<void>;

  public form: UntypedFormGroup;
  public alive: boolean;

  constructor(
    protected localStorageService: LocalStorageService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    protected config: AutosaveConfig
  ) {}

  setForm(form: UntypedFormGroup) {
    this.form = form;
  }

  getAutosavedItem() {
    const autosavedItem: string | null = this.localStorageService.getItem(this.config?.autosaveKey);
    if (!autosavedItem) {
      return of(null);
    }
    return this.dialogService
      .open(InformationDialogComponent, {
        data: {
          title: this.translateService.instant(this.config?.dialogConfig?.titleKey),
          message: this.translateService.instant(this.config?.dialogConfig?.messageKey),
          confirm: this.translateService.instant(this.config?.dialogConfig?.confirmKey),
          cancel: this.translateService.instant(this.config?.dialogConfig?.cancelKey)
        },
        width: '35vw',
        minWidth: '450px'
      })
      .afterClosed()
      .pipe(map((value) => (value ? JSON.parse(autosavedItem) : null)));
  }

  startAutosave() {
    if (this.form === null || this.form === undefined) {
      throw new Error('Error! Start autosave couldn\'t be executed as "form" is not set');
    }

    this.alive = true;

    this.form?.statusChanges
      ?.pipe(
        takeWhile(() => this.alive),
        takeUntil(this.destroyed$),
        debounceTime(DEBOUNCE_TIME)
      )
      .subscribe(() => (!this.isEmptyForm(this.form.value) ? this.setAutosavedItem() : this.removeAutosavedItem()));
  }

  setAutosavedItem() {
    this.localStorageService.setItem(this.config?.autosaveKey, JSON.stringify(this.form.value));
  }

  removeAutosavedItem() {
    this.localStorageService.removeItem(this.config?.autosaveKey);
  }

  stopAutosave() {
    this.alive = false;
    this.removeAutosavedItem();
  }

  isEmptyForm(form: any) {
    this.config?.excludeFormFields?.forEach((field: string) => delete form[field]);
    return Object.values(form).every(
      (property) => property === null || property === '' || (Array.isArray(property) && !property?.length)
    );
  }
}
