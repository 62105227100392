<ct-confirm
  [title]="data?.title"
  [message]="data?.message"
  [cancel]="data?.cancel"
  [confirm]="data?.confirm"
  [canConfirm]="canConfirm"
  [color]="'accent'"
  (confirmed)="onConfirm()"
  (cancelled)="onCancel()"
></ct-confirm>
