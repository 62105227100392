import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import Hammer from '@egjs/hammerjs';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

import { SlidingDirection } from '../../enums';
import { GalleryConfig, GalleryError, GalleryState, SliderState, WorkerState } from '../../interfaces';

@Component({
  selector: 'ct-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GallerySliderComponent implements OnInit, OnChanges, OnDestroy {
  public sliderState$: Observable<SliderState>; // Stream that emits sliding state

  @Input() public state: GalleryState | null;
  @Input() public config: GalleryConfig | null;

  @Output() public action = new EventEmitter<string | number>();
  @Output() public itemClick = new EventEmitter<number>();
  @Output() public errorItem = new EventEmitter<GalleryError>();

  get isOptimized(): boolean {
    // solution for slider inside lightbox - display only one item at the time in DOM
    // TODO: add some offset to render specific amount of items before & after selected item
    return this.config?.slidingDirection === SlidingDirection.Horizontal;
  }

  private readonly slidingWorker$ = new BehaviorSubject<WorkerState>({ value: 0, active: false });
  private hammer: any; // HammerJS instance
  private resizeSub$: Subscription; // Stream that emits when the view is re-sized

  constructor(private el: ElementRef, private zone: NgZone, @Inject(PLATFORM_ID) private platform: any) {
    // Activate sliding worker
    this.sliderState$ = this.slidingWorker$.pipe(
      map((state: WorkerState) => ({
        style: this.getSliderStyles(state),
        active: state.active
      }))
    );
  }

  ngOnChanges() {
    // Refresh the slider
    this.updateSlider({ value: 0, active: false });
  }

  ngOnInit() {
    if (this.config?.gestures && typeof Hammer !== 'undefined') {
      const direction =
        this.config.slidingDirection === SlidingDirection.Horizontal
          ? Hammer.DIRECTION_HORIZONTAL
          : Hammer.DIRECTION_VERTICAL;

      // Activate gestures
      this.hammer = new Hammer(this.el.nativeElement);
      this.hammer.get('pan').set({ direction });

      this.zone.runOutsideAngular(() => {
        // Move the slider
        this.hammer.on('pan', (e: any) => {
          switch (this.config?.slidingDirection) {
            case SlidingDirection.Horizontal:
              this.updateSlider({ value: e.deltaX, active: true });
              if (e.isFinal) {
                this.updateSlider({ value: 0, active: false });
                this.horizontalPan(e);
              }
              break;
            case SlidingDirection.Vertical:
              this.updateSlider({ value: e.deltaY, active: true });
              if (e.isFinal) {
                this.updateSlider({ value: 0, active: false });
                this.verticalPan(e);
              }
          }
        });
      });
    }

    // Rearrange slider on window resize
    if (isPlatformBrowser(this.platform)) {
      this.resizeSub$ = fromEvent(window, 'resize')
        .pipe(
          debounceTime(200),
          tap(() => this.updateSlider(this.slidingWorker$.value))
        )
        .subscribe();
    }

    setTimeout(() => this.updateSlider({ value: 0, active: false }));
  }

  ngOnDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
    if (this.resizeSub$) {
      this.resizeSub$.unsubscribe();
    }
    this.slidingWorker$.complete();
  }

  /**
   * Convert sliding state to styles
   */
  private getSliderStyles(state: WorkerState): any {
    const itemsLength = this.state?.items?.length as number;
    const currIndex = this.state?.currIndex as number;

    switch (this.config?.slidingDirection) {
      case SlidingDirection.Horizontal:
        return {
          transform: `translate3d(${-(currIndex * this.el.nativeElement.offsetWidth) + state.value}px, 0, 0)`,
          width: `calc(100% * ${itemsLength})`,
          height: '100%'
        };
      case SlidingDirection.Vertical:
        return {
          transform: `translate3d(0, ${-(currIndex * this.el.nativeElement.offsetHeight) + state.value}px, 0)`,
          width: '100%',
          height: `calc(100% * ${itemsLength})`
        };
    }
  }

  private verticalPan(e: any) {
    const itemsLength = this.state?.items?.length as number;
    const panSensitivity = this.config?.panSensitivity as number;

    if (!(e.direction & Hammer.DIRECTION_UP && e.offsetDirection & Hammer.DIRECTION_VERTICAL)) {
      return;
    }
    if (e.velocityY > 0.3) {
      this.prev();
    } else if (e.velocityY < -0.3) {
      this.next();
    } else {
      if (e.deltaY / 2 <= (-this.el.nativeElement.offsetHeight * itemsLength) / panSensitivity) {
        this.next();
      } else if (e.deltaY / 2 >= (this.el.nativeElement.offsetHeight * itemsLength) / panSensitivity) {
        this.prev();
      } else {
        this.action.emit(this.state?.currIndex);
      }
    }
  }

  private horizontalPan(e: any) {
    const itemsLength = this.state?.items?.length as number;
    const panSensitivity = this.config?.panSensitivity as number;

    if (!(e.direction & Hammer.DIRECTION_HORIZONTAL && e.offsetDirection & Hammer.DIRECTION_HORIZONTAL)) {
      return;
    }
    if (e.velocityX > 0.3) {
      this.prev();
    } else if (e.velocityX < -0.3) {
      this.next();
    } else {
      if (e.deltaX / 2 <= (-this.el.nativeElement.offsetWidth * itemsLength) / panSensitivity) {
        this.next();
      } else if (e.deltaX / 2 >= (this.el.nativeElement.offsetWidth * itemsLength) / panSensitivity) {
        this.prev();
      } else {
        this.action.emit(this.state?.currIndex);
      }
    }
  }

  private next() {
    this.action.emit('next');
  }

  private prev() {
    this.action.emit('prev');
  }

  private updateSlider(state: WorkerState) {
    const newState: WorkerState = { ...this.slidingWorker$.value, ...state };
    this.slidingWorker$.next(newState);
  }
}
