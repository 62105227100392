import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { CheckboxComponent } from './checkbox.component';
@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, TranslateModule],
  exports: [CheckboxComponent]
})
export class CheckboxModule {}
