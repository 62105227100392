import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from '@ct/environment';
import { TranslateModule } from '@ngx-translate/core';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { ReCaptchaComponent } from './re-captcha.component';

@NgModule({
  declarations: [ReCaptchaComponent],
  imports: [
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [ReCaptchaComponent]
})
export class ReCaptchaModule {
  public static forFeature(): ModuleWithProviders<ReCaptchaModule> {
    return {
      ngModule: ReCaptchaModule,
      providers: [
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: {
            siteKey: environment.reCaptchaSiteKey
          } as RecaptchaSettings
        }
      ]
    };
  }
}
