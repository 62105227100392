import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { MyAccountOverviewData } from '../interfaces';
import { MyAccountApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MyAccountOverviewDataResolver implements Resolve<MyAccountOverviewData> {
  constructor(private myAccountApiService: MyAccountApiService) {}

  resolve() {
    return this.myAccountApiService.getOverviewData();
  }
}
