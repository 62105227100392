import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeletionDialogComponent, DialogService } from '@ct/components';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  TitleConfig,
  TitleFeature,
  TranscodingState,
  VideoUploadEntity
} from '@ct/core';
import { SortOrder, VideoPlayerDialogComponent } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { MY_ACCOUNT_VIDEOS_FEATURE_LIMIT } from './../../../../constants/my-account.constant';

interface VideoQueryParams {
  offset: number;
  sortOrder: SortOrder;
}

const DEFAULT_SORT = SortOrder.Desc;
const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-my-account-videos',
  templateUrl: './my-account-videos.component.html',
  styleUrls: ['./my-account-videos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature()])
export class MyAccountVideosComponent implements OnInit, OnDestroy {
  public readonly destroyed$: Observable<void>;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_VIDEOS'
  };
  public readonly limit = MY_ACCOUNT_VIDEOS_FEATURE_LIMIT;

  public videos: VideoUploadEntity[] = [];
  public loading = false;

  public queryParams: VideoQueryParams = {
    offset: DEFAULT_OFFSET,
    sortOrder: DEFAULT_SORT
  };

  private interval: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private videoUploadApiService: ChannelVideoUploadApiService
  ) {}

  ngOnInit() {
    this.startVideosStatusListener();
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe(({ videos }) => {
      this.videos = videos;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.stopVideosStatusListener();
  }

  onPlayVideo(video: VideoUploadEntity) {
    this.dialogService.open(VideoPlayerDialogComponent, { data: video });
  }

  onDeleteVideo(video: VideoUploadEntity) {
    const confirm = this.translateService.instant('MY_ACCOUNT.MY_VIDEOS_FEATURE.DELETE.YES_SINGLE');
    const cancel = this.translateService.instant('MY_ACCOUNT.MY_VIDEOS_FEATURE.DELETE.NO_SINGLE');
    const message = this.translateService.instant('MY_ACCOUNT.MY_VIDEOS_FEATURE.DELETE.SINGLE_MESSAGE_SIMPLE');
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          message,
          confirm,
          cancel
        },
        width: '50vw'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.videoUploadApiService.removeVideoById(video.id as string).pipe(catchError((err) => of(err)))
        ),
        switchMap(() => this.loadVideos(this.queryParams))
      )
      .subscribe((videos) => this.refreshVideos(videos));
  }

  onNext(append?: boolean) {
    if (this.videos?.length < this.limit) {
      return;
    }
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadVideos(this.queryParams).subscribe((videos) => {
      return this.refreshVideos(append ? [...this.videos, ...videos] : videos);
    });
  }

  loadVideos({ offset }: VideoQueryParams) {
    this.loading = true;
    return this.videoUploadApiService.getAllVideos({ range: { limit: this.limit, offset } }).pipe(
      tap(() => (this.loading = false)),
      catchError((err) => {
        this.loading = false;
        return throwError(err);
      })
    );
  }

  refreshVideos(videos: VideoUploadEntity[]) {
    this.videos = videos;
    this.changeDetectorRef.detectChanges();
  }

  refreshUrlParams({ offset, sortOrder }: VideoQueryParams) {
    this.router.navigate([], {
      queryParams: { sortOrder, offset, filter },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  onScroll() {
    this.onNext(true);
  }

  private startVideosStatusListener() {
    this.interval = setInterval(() => {
      const processingVideos = this.videos.filter(
        ({ transcodingJobStatus }) => transcodingJobStatus !== TranscodingState.SUCCEEDED
      );

      if (processingVideos.length > 0) {
        this.videoUploadApiService
          .getVideosStatus(processingVideos.map(({ id }) => id as string))
          .subscribe((videos) => {
            const videosMap: Map<string, TranscodingState> = new Map(
              videos.map((video) => [video.id as string, video.transcodingJobStatus])
            );
            this.videos = this.videos.map((video) => {
              if (videosMap.has(video.id as string)) {
                return {
                  ...video,
                  transcodingJobStatus: videosMap.get(video.id as string) as TranscodingState
                };
              }

              return video;
            });

            this.changeDetectorRef.markForCheck();
          });
      }
    }, 30000) as unknown as number;
  }

  private stopVideosStatusListener() {
    clearInterval(this.interval);
  }
}
