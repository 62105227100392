import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class TextareaComponent extends BaseControlComponent<string> implements OnInit {
  public readonly destroyed$: Observable<void>;
  @Input() public label: string;
  @Input() public readonly placeholder: string = '';
  @Input() readonly rows: number = 5;
  @Input() readonly autoSizeMaxRows: number = 5;
  @Input() public readonly type: string = 'text';
  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }
  @Input() public readonly maxlength: number;
  public readonly control = new UntypedFormControl(null);

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value?.trim ? value.trim().replace(/\s{2,}/g, ' ') : value;

    if (trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
  }
}
