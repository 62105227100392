import { Injectable } from '@angular/core';
import { UserProfile } from '@ct/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { UserIdentity } from '../../interfaces';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends QueryEntity<AuthState> {
  constructor(protected store: AuthStore) {
    super(store);
  }

  get profile(): UserProfile | null {
    return this.getValue().profile;
  }

  get profile$(): Observable<UserProfile | null> {
    return this.select('profile');
  }

  get identity(): UserIdentity | null {
    return this.getValue().identity;
  }

  get identity$(): Observable<UserIdentity | null> {
    return this.select('identity');
  }
}
