<div *ngIf="sliderState$ | async; let sliderState" class="g-items-container">
  <div class="g-slider" [class.g-no-transition]="sliderState.active" [ngStyle]="isOptimized ? {} : sliderState.style">
    <ng-container *ngFor="let item of state?.items; let i = index">
      <ct-gallery-item
        *ngIf="isOptimized ? state?.currIndex === i : true"
        [type]="item.type"
        [config]="config"
        [data]="item.data"
        [currIndex]="state?.currIndex"
        [index]="i"
        (tapClick)="itemClick.emit(i)"
        (errorItem)="errorItem.emit({ itemIndex: i, error: $event })"
      >
      </ct-gallery-item>
    </ng-container>
  </div>
</div>
<ng-content></ng-content>
