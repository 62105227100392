<ng-container *ngIf="isLoggedIn$ | async; else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async" [showSearch]='false'>
    <ct-extended-header
      ctHeader
      icon="shopping-cart"
      description="Buy and Sell Used Camping Equipment"
      title="Marketplace"
    ></ct-extended-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div class="viewport-header container">
    <div>
      <article class="row no-gutters">
        <section class="col-12 col-md-9 offset-md-1">
          <h1>{{ 'MARKETPLACE.HEADER.TITLE' | translate }}</h1>
        </section>
        <section class="col-12 col-md-7 offset-md-2">
          <p>{{ 'MARKETPLACE.HEADER.DESCRIPTION' | translate }}</p>
        </section>
      </article>
    </div>
  </div>
  <div class="page-content container">
    <section class="row">
      <section class="d-flex flex-grow-1 justify-content-center buttons-section">
        <div class="button-wrapper" routerLink="/marketplace/sell">
          <ct-icon icon="marketplace-tent"></ct-icon>
          SELL
        </div>
        <div class="button-wrapper" routerLink="/marketplace/community">
          <ct-icon icon="lantern"></ct-icon>
          BUY
        </div>
      </section>
      <article class="col-12 d-flex flex-column justify-content-center first-section">
        <h2 class="text-center">How It Works</h2>
        <p class="d-flex flex-wrap justify-content-center">
          <span class="d-flex">
            <span class="order-number">1</span><span class="option-text">{{ 'Create an Account' | translate }}</span>
          </span>
          <span class="d-flex">
            <span class="order-number">2</span
            ><span class="option-text">{{ 'Sell your items or buy from others.' | translate }}</span>
          </span>
          <span class="d-flex">
            <span class="order-number">3</span
            ><span class="option-text">{{ 'Respond directly to the buyer' | translate }}</span>
          </span>
        </p>
      </article>
    </section>
    <section *ngIf="(isLoggedIn$ | async) === false" class="row orange-bg">
      <article class="col-12 col-lg-6 d-flex flex-column align-items-center offset-lg-3">
        <h3 class="text-center">{{ 'HOME.GET_ACCOUNT' | translate }}</h3>
        <ct-link [link]="['/register']">
          <ct-button variant="raised" color="accent">{{ 'COMMON.REGISTER' | translate }}</ct-button>
        </ct-link>
      </article>
    </section>
  </div>
</ng-template>
