import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TripJournalEntry } from '../../interfaces';

@Component({
  selector: 'ct-trip-entry',
  templateUrl: './trip-entry.component.html',
  styleUrls: ['./trip-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripEntryComponent {
  @Input() entry: TripJournalEntry;
  @Input() link: string | string[];
}
