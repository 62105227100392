import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { DestroyableFeature, Features } from '@ct/core';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class CustomErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl): boolean {
    return control.dirty && control.invalid && control.touched;
  }
}

@Component({
  selector: 'ct-mat-text-editor',
  templateUrl: './mat-text-editor.component.html',
  styleUrls: ['./mat-text-editor.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MatTextEditorComponent },
    {
      provide: ErrorStateMatcher,
      useClass: CustomErrorMatcher
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class MatTextEditorComponent implements OnInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
  public static nextId = 0;
  public readonly destroyed$: Observable<void>;

  @ViewChild(QuillEditorComponent, { read: ElementRef, static: true }) textEditorRef: ElementRef;

  public formControl = new UntypedFormControl('');
  @Input()
  public modules: QuillModules;

  @Input()
  set value(value: string | null) {
    this.formControl.patchValue(value);
    this.stateChanges.next();
  }
  get value(): string | null {
    return this.formControl.value;
  }

  public stateChanges = new Subject<void>();

  @HostBinding()
  id = `mat-text-editor-${MatTextEditorComponent.nextId++}`;

  @Input()
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }
  private _placeholder: string;

  public focused = false;

  get empty(): boolean {
    return !!this.value;
  }

  public shouldLabelFloat = false;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  get errorState(): boolean {
    return this.errorStateMatcher.isErrorState(this.ngControl.control as UntypedFormControl, null);
  }

  public controlType?: string | undefined = 'ct-mat-text-editor';

  public autofilled?: boolean | undefined;

  @HostBinding('attr.aria-describedby') userAriaDescribedBy: string;

  onChange: (value?: string) => void;
  onTouch: (value?: string) => void;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private errorStateMatcher: ErrorStateMatcher,
    @Optional() public parentFormField: MatFormField
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    if (this.parentFormField != null) {
      this.parentFormField._control = this;
    }
  }

  ngOnInit() {
    this.formControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => this.onChange(value));
  }

  setDescribedByIds(ids: string[]): void {
    this.userAriaDescribedBy = ids.join(' ');
  }

  onContainerClick(): void {
    this.onFocusIn();
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this.textEditorRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.focused = false;
      this.onTouch();
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
}
