import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  QueryList,
  TemplateRef
} from '@angular/core';

import { ImageCarouselItemComponent } from './components';

const SLIDE_TIME = 5000;

@Component({
  selector: 'ct-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCarouselComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(ImageCarouselItemComponent)
  public imageCarouselItems: QueryList<ImageCarouselItemComponent>;

  @Input() public auto = false;

  public index = 0;
  public timerId: null | ReturnType<typeof setTimeout> = null;
  public prefetch = false;
  public currentImageCarouselItem: TemplateRef<ImageCarouselItemComponent>;

  get carouselLength() {
    return this.imageCarouselItems.toArray().length;
  }

  get carouselImages() {
    return this.imageCarouselItems.toArray();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: string) {}

  ngAfterViewInit(): void {
    this.render();

    if (this.auto) {
      this.setInterval();
    }
    this.prefetch = true;
    this.changeDetectorRef.detectChanges();
  }

  public render() {
    this.currentImageCarouselItem = this.imageCarouselItems.toArray()[this.index]?.content;
    this.changeDetectorRef.detectChanges();
  }

  public prev() {
    const newIndex = this.index - 1;
    this.index = newIndex < 0 ? this.carouselLength - 1 : newIndex;
    if (this.auto) {
      this.resetInterval();
    }
    this.render();
  }

  public next(resetInterval?: boolean) {
    const newIndex = this.index + 1;
    this.index = newIndex >= this.carouselLength ? 0 : newIndex;
    if (resetInterval && this.auto) {
      this.resetInterval();
    }
    this.render();
  }

  public onDot(index: number) {
    this.index = index;
    this.render();
  }

  setInterval() {
    if (isPlatformBrowser(this.platformId)) {
      this.timerId = setInterval(() => this.next(), SLIDE_TIME);
    }
  }

  private resetInterval() {
    this.clearInterval();
    this.setInterval();
  }

  private clearInterval() {
    if (this.timerId && isPlatformBrowser(this.platformId)) {
      clearInterval(this.timerId);
    }
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }
}
