import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { IconModule } from './../icon';
import { TabsMobileComponent } from './tabs-mobile.component';

@NgModule({
  declarations: [TabsMobileComponent],
  imports: [CommonModule, IconModule, TranslateModule, PipesModule],
  exports: [TabsMobileComponent]
})
export class TabsMobileModule {}
