import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ct/components/button';
import { DateModule } from '@ct/components/date';
import { DialogModule } from '@ct/components/dialog';
import { InputModule } from '@ct/components/input';
import { MediaSharedModule } from '@ct/shared/modules';
import { TranslateModule } from '@ngx-translate/core';

import { CreateTripDialogComponent } from './create-trip-dialog.component';

@NgModule({
  declarations: [CreateTripDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    DateModule,
    InputModule,
    MediaSharedModule
  ],
  exports: [CreateTripDialogComponent]
})
export class CreateTripDialogModule {}
