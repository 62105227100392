import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressBarMode as ProgressBarMode } from '@angular/material/legacy-progress-bar';

@Component({
  selector: 'ct-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() public readonly mode: ProgressBarMode = 'determinate';
  @Input() public readonly color: ThemePalette;
  @Input() public readonly value: number;
  @Input() public readonly bufferValue: number = 100;
}
