<mat-label *ngIf="showLabel && label">
  <span>{{ label }}</span>
  <span *ngIf="required"> * </span>
</mat-label>
<mat-form-field appearance="outline">
  <input
    matInput
    autocomplete="off"
    [formControl]="control"
    [type]="type"
    [placeholder]="(!showLabel && label) || placeholder"
    [maxlength]="maxlength"
    [mask]="mask"
    [readonly]="readonly"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  <mat-icon *ngIf="prefixIcon" matPrefix (click)="onPrefixIcon()">{{ prefixIcon }}</mat-icon>
  <mat-icon *ngIf="suffixIcon" matSuffix (click)="onSuffixIcon()">{{ suffixIcon }}</mat-icon>
  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'COMMON.VALIDATIONS.REQUIRED' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <ng-container *ngIf="control.getError('minlength') as error">
      {{ 'COMMON.VALIDATIONS.MIN_LENGTH' | translate: { length: error.requiredLength } }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error">
      {{ 'COMMON.VALIDATIONS.MAX_LENGTH' | translate: { length: error.requiredLength } }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('integer')">
    {{ 'COMMON.VALIDATIONS.INTEGER' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('email')">
    {{ 'COMMON.VALIDATIONS.EMAIL' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('matchEmail')">
    {{ 'COMMON.VALIDATIONS.MATCH_EMAIL' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanum')">
    {{ 'COMMON.VALIDATIONS.ALPHANUM' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('phoneNumber')">
    {{ 'COMMON.VALIDATIONS.PHONE_NUMBER' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('passwordNoMatch')">
    {{ 'COMMON.VALIDATIONS.PASSWORDS_DO_NOT_MATCH' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('passwordIsNotValid')">
    {{ 'COMMON.VALIDATIONS.PASSWORD_IS_NOT_VALID' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('verificationCode')">
    {{ 'COMMON.VALIDATIONS.VERIFICATION_CODE_SHOULD_BE' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('emailAlreadyInUse')">
    {{ 'COMMON.VALIDATIONS.EMAIL_ALREADY_IN_USE' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('codeHasExpiried')">
    {{ 'COMMON.VALIDATIONS.CODE_HAS_EXPIRIED' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('emailIsNotValid')">
    {{ 'COMMON.VALIDATIONS.EMAIL_IS_NOT_VALID' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('username')">
    {{ 'COMMON.VALIDATIONS.USERNAME_IS_NOT_VALID' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('usernameAlreadyInUse')">
    {{ 'COMMON.VALIDATIONS.USERNAME_ALREADY_IN_USE' | translate }}
  </mat-error>
</mat-form-field>
