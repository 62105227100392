import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { InputModule } from '../input';
import { ProgressBarModule } from '../progress-bar';
import { PasswordsComponent, PasswordStrengthComponent } from './components';

@NgModule({
  declarations: [PasswordsComponent, PasswordStrengthComponent],
  imports: [CommonModule, InputModule, ReactiveFormsModule, TranslateModule, ProgressBarModule],
  exports: [PasswordsComponent, PasswordStrengthComponent]
})
export class PasswordModule {}
