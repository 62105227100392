<ct-card-section class="mb-5" [title]="'MY_ACCOUNT.MY_PROFILE_FEATURE.CHANGE_PASSWORD' | translate">
  <form class="mt-3 form" [formGroup]="form" (ngSubmit)="onConfirm()">
    <div class="row">
      <div class="col-12">
        <p class="description">{{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.ENTER_YOUR_NEW_PASSWORD' | translate }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <ct-passwords formControlName="passwords"></ct-passwords>
      </div>
    </div>
    <div class="row no-gutters mt-1">
      <ct-button class="col-6 col-md-2 pr-2" variant="flat" color="primary" (click)="onCancel()" [fullWidth]="true">{{
        'MY_ACCOUNT.MY_PROFILE_FEATURE.CANCEL' | translate
      }}</ct-button>
      <ct-button class="col-6 col-md-2" type="submit" variant="flat" color="accent" [fullWidth]="true">{{
        'MY_ACCOUNT.MY_PROFILE_FEATURE.CONFIRM' | translate
      }}</ct-button>
    </div>
  </form>
</ct-card-section>
