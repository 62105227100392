import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@ct/environment';
import { MenuItem, translateMenuItems } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';

import { notLoggedInHomeMenuItems } from '../../constants';

@Component({
  selector: 'ct-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeHeaderComponent {
  public menuItems: MenuItem[] = translateMenuItems(notLoggedInHomeMenuItems, (key: string) =>
    this.translateService.get(key)
  );

  public collapsed = true;
  @ViewChild('headerElement') headerElement: ElementRef<HTMLDivElement>;

  protected readonly isProd = environment.production;
  protected readonly externalUrl = 'https://camping.tools';

  constructor(
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {}

  closeMenu() {
    if (!this.collapsed) {
      this.collapsed = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:scroll')
  transitionBackgroundColor() {
    const scrollPosition = window.scrollY || window.pageYOffset;
    if (!scrollPosition) {
      this.renderer.removeStyle(this.headerElement.nativeElement, 'backgroundColor');
      return;
    }
    const y = 1 + scrollPosition / 100 + 0.4;
    if (y > 3) {
      return;
    }
    const opacity = 1 - 1 / y;
    this.renderer.setStyle(this.headerElement.nativeElement, 'backgroundColor', `rgba(0,0,0,${opacity})`);
  }
}
