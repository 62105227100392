<ng-container *ngIf="isLoggedIn$ | async; else defaultTemplate">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async" [showSearch]='false'>
    <ct-extended-header
      ctHeader
      icon="shopping-cart"
      description="Buy and Sell Used Camping Equipment"
      title="Marketplace"
    ></ct-extended-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultTemplate>
  <div class="container">
    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
  </div>
</ng-template>

<ng-template #defaultContent>
  <div class="row flex-md-row flex-column-reverse">
    <div class="col-12 col-md-3 pt-4 categories-filter">
      <h2>Categories</h2>
      <p class="mb-1">
        <a (click)="clearSelection($event)" class="clear-filter">X {{ 'MARKETPLACE.CLEAR_FILTER' | translate }}</a>
      </p>
      <ct-data-list
        class="mb-4"
        [column]="listColumn"
        [data]="categories"
        [selectedItem]="selectedEntity"
        (selectedItemChange)="onEntitySelected($event)"
        (dataListReady)="onDataListReady($event)"
      ></ct-data-list>
    </div>
    <div class="col-12 col-md-9 mt-4 marketplace-items">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
