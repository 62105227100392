import { AgmCoreModule } from '@agm/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule, VAPID_KEY } from '@angular/fire/compat/messaging';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SiteSearchModule } from '@ct/app-content';
import { HomeModule } from '@ct/app-content/home';
import { AuthModule, AuthService } from '@ct/auth';
import { VersionCheckModule } from '@ct/components';
import { ButtonModule } from '@ct/components/button';
import { DEFAULT_DIALOG_CONFIG } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { LinkModule } from '@ct/components/link';
import { AppInjector, PipesModule } from '@ct/core';
import { environment } from '@ct/environment';
import { LayoutModule } from '@ct/layout';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppRoutingModule } from './app-routing.module';
import {
  AppComponent,
  CookiesNoticeComponent,
  DmcaComponent,
  FaqComponent,
  FooterComponent,
  HeaderComponent,
  HomeHeaderComponent,
  LegalNoticeComponent,
  NotificationsListComponent,
  PrivacyPolicyComponent,
  TermsAndConditionsComponent
} from './components';

export const getIdentityFactory = (authService: AuthService) => {
  return (): Promise<unknown> =>
    authService
      .getIdentity()
      .pipe(catchError(() => of(null)))
      .toPromise();
};

export const getProfileFactory = (authService: AuthService) => {
  return (): Promise<unknown> =>
    authService
      .getUserProfile()
      .pipe(catchError(() => of(null)))
      .toPromise();
};

export const refreshFcmTokenFactory = (authService: AuthService) => {
  return (): Promise<unknown> =>
    authService
      .refreshFcmToken()
      .pipe(catchError(() => of(null)))
      .toPromise();
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeHeaderComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    DmcaComponent,
    LegalNoticeComponent,
    CookiesNoticeComponent,
    FaqComponent,
    NotificationsListComponent
  ],
  imports: [
    OverlayModule,
    BrowserModule.withServerTransition({ appId: 'app' }),
    NoopAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first) .
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppRoutingModule,
    AuthModule.forRoot(),
    ButtonModule,
    HttpClientModule,
    IconModule,
    LinkModule,
    TranslateModule,
    HomeModule,
    SiteSearchModule,
    PipesModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places']
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    LayoutModule,
    VersionCheckModule,
    MatExpansionModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: getIdentityFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getProfileFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: refreshFcmTokenFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: DEFAULT_DIALOG_CONFIG as MatDialogConfig
    },
    { provide: VAPID_KEY, useValue: environment.firebaseConfig.vapidKey }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public injector: Injector) {
    // set global injector
    AppInjector.setInjector(injector);
  }
}
