<ct-card-section class="mb-5" [title]="'MY_ACCOUNT.MY_PROFILE_FEATURE.DOWNLOAD_MEDIA' | translate">
  <p>All archives will be removed after 90 days since last creation date.</p>
  <div *ngIf="archive">
    <p>Archives generation date: {{ modifiedOn | date : 'MM/dd/yyyy' }}</p>
    <p>Amount of archives (max 500Mb each): {{ archive.amount }}</p>
    <p>Total images: {{ archive.imagesCount }}</p>
    <br />
    <div *ngFor="let link of links; let i = index">
      <a [href]="link" target="_blank"
        ><ct-icon icon="cloud_download"></ct-icon> Archive {{ i + 1 }} of {{ archive.amount }}<span></span
      ></a>
    </div>
  </div>
</ct-card-section>
