import { Injectable } from '@angular/core';
import { UserProfile } from '@ct/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { UserIdentity } from '../../interfaces';

export interface AuthState {
  identity: UserIdentity | null;
  profile: UserProfile | null;
}

export function createInitialState(): AuthState {
  return {
    identity: null,
    profile: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  cache: {
    ttl: 3600
  }
})
export class AuthStore extends EntityStore<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
