import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AuthApiService, AuthService } from '@ct/auth';
import { CustomValidators, EMAIL_MAX_LENGTH, Features, FormStateDispatcher, TitleConfig, TitleFeature } from '@ct/core';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-profile-change-email',
  templateUrl: './my-account-profile-change-email.component.html',
  styleUrls: ['./my-account-profile-change-email.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountProfileChangeEmailComponent {
  @ViewChild('stepper') private stepper: MatStepper;

  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_CHANGE_EMAIL'
  };

  public confirmCurrentEmailForm = new UntypedFormGroup({
    newEmailAddress: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(EMAIL_MAX_LENGTH)
    ])
  });

  public confirmNewEmailForm = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, CustomValidators.verificationCode])
  });

  public currentEmailAddress$ = this.authService.getIdentity().pipe(map((identity) => identity?.username));

  public get newEmailAddress() {
    return this.confirmCurrentEmailForm.get('newEmailAddress')?.value;
  }

  constructor(
    private formState: FormStateDispatcher,
    private authApiService: AuthApiService,
    private authService: AuthService
  ) {}

  start() {
    this.nextStep();
  }

  onConfirmCurrentEmailAddress() {
    this.formState.onSubmit.notify();

    if (this.confirmCurrentEmailForm.invalid) {
      return;
    }

    const { newEmailAddress } = this.confirmCurrentEmailForm.value;

    this.authApiService.requestChangeEmail(newEmailAddress).subscribe(
      () => this.nextStep(),
      (error) =>
        error?.status === 400 ? this.confirmCurrentEmailForm.controls.code.setErrors({ codeHasExpiried: true }) : null
    );
  }

  onConfirmNewEmailAddress() {
    this.formState.onSubmit.notify();

    if (this.confirmNewEmailForm.invalid) {
      return;
    }

    const { code } = this.confirmNewEmailForm.value;

    this.authApiService
      .completeChangeEmail(code, this.newEmailAddress)
      .pipe(switchMap(() => this.authService.getIdentity({ force: true })))
      .subscribe(
        () => this.nextStep(),
        (error) =>
          error?.status === 400 ? this.confirmNewEmailForm.controls.code.setErrors({ codeHasExpiried: true }) : null
      );
  }

  nextStep() {
    (this.stepper as any).selected.completed = true;
    this.stepper?.next();
  }
}
