import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { trackById } from '../../../../helpers/track-by-id.helper';
import { Video } from './../../../../interfaces';

@Component({
  selector: 'ct-video-grid',
  templateUrl: './video-grid.component.html',
  styleUrls: ['./video-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoGridComponent {
  @Input() videos: Video[] | undefined;

  @Input() col = 2;
  @Input() videoHeight = '150px';
  @Input() limit: number | undefined;
  @Input() showPlaceholder: boolean | undefined = false;
  @Input() selectable: boolean | undefined = false;

  get _videos() {
    return this.videos?.slice(0, this.limit);
  }

  public trackByFn = trackById;

  onPlay(id: string | undefined) {
    // TODO: show popup
  }
}
