<div
  class="row wrap"
  *ngIf="elements.length > 0; else noTimeline"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div class="element" *ngFor="let element of elements">
    <div class="line"></div>
    <div class="left d-md-flex">
      <div class="date" *ngIf="element.hasDate">
        <span class="day">{{ element.date | date : 'd' }}</span>
        <span class="month">{{ element.date | date : 'MMM' }}</span>
      </div>
    </div>
    <div class="right main-content">
      <ng-container *ngIf="element.type === tripEntityType.Video">
        <ng-container *ngTemplateOutlet="video; context: { element: this.element }"></ng-container>
      </ng-container>
      <ng-container *ngIf="element.type === 'videoGrid'">
        <ng-container *ngTemplateOutlet="videoGrid; context: { element: this.element }"></ng-container>
      </ng-container>
      <ng-container *ngIf="element.type === tripEntityType.Campsite">
        <ng-container *ngTemplateOutlet="campsite; context: { element: this.element }"></ng-container>
      </ng-container>
      <ng-container *ngIf="element.type === tripEntityType.CheckIn">
        <ng-container *ngTemplateOutlet="checkIn; context: { element: this.element }"></ng-container>
      </ng-container>
      <ng-container *ngIf="element.type === tripEntityType.Journal">
        <ng-container *ngTemplateOutlet="journal; context: { element: this.element }"></ng-container>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</div>

<ng-template #noTimeline>
  <ct-empty-call-to-action
    *ngIf="isAuthor"
    buttonIcon="add-circle"
    emptyMessageTextKey="MY_ACCOUNT.EMPTY_ENTITY.NO_CONTENT"
    buttonTextKey="MY_ACCOUNT.EMPTY_ENTITY.ADD_NOW"
    (buttonClick)="addDialog()"
  ></ct-empty-call-to-action>
</ng-template>

<ng-template #video let-element="element">
  <ng-container *ngTemplateOutlet="elementHeader; context: { element: this.element }"></ng-container>
  <div class="body">
    <div class="video-body" #videoContainer>
      <ct-youtube-player
        videoLink="{{ element?.video | youtubeId }}"
        [width]="videoContainer.offsetWidth - 10"
        [height]="((videoContainer.offsetWidth - 10) * 9) / 16"
      ></ct-youtube-player>
    </div>
  </div>
</ng-template>

<ng-template #videoGrid let-element="element">
  <ng-container *ngTemplateOutlet="elementHeader; context: { element: this.element }"></ng-container>
  <div class="body">
    <div class="video-body" #videoContainer>
      <ct-youtube-player
        videoLink="{{ element?.video | youtubeId }}"
        [width]="videoContainer.offsetWidth"
        [height]="(videoContainer.offsetWidth * 9) / 16"
      ></ct-youtube-player>
    </div>
  </div>
</ng-template>

<ng-template #campsite let-element="element">
  <ng-container *ngTemplateOutlet="elementHeader; context: { element: this.element }"></ng-container>
  <div class="body campsite">
    <img
      class="background"
      alt=""
      [src]="getCroppedThumbPublicUrl(element?.campsite?.featuredPhoto?.publicUrl, 400) || 'assets/images/map.png'"
      loading="lazy"
    />
    <div class="campsite-body">
      <div class="body-title">{{ element.campsite?.title }}</div>
    </div>
  </div>
</ng-template>

<ng-template #checkIn let-element="element">
  <ng-container *ngTemplateOutlet="elementHeader; context: { element: this.element }"></ng-container>
  <div class="body check-in-body">
    <!--    TODO: add translate lines for checked in -->
    <div class="check-in-title">
      {{ 'Checked in at' | translate }} {{ element.campsite?.title }}: {{ element.campsite?.formatted_address }}
    </div>
  </div>
</ng-template>

<ng-template #journal let-element="element">
  <ct-journal-entry
    [journalEntry]="element.journal"
    (edit)="elementEdit(element.type, element)"
    [canEdit]="isAuthor"
  ></ct-journal-entry>
</ng-template>

<ng-template
  #elementHeader
  let-element="element"
  let-showTitle="element?.showTitle"
  let-type="element?.type"
  let-date="element?.date"
>
  <div class="header">
    <div class="title" *ngIf="showTitle">
      <ct-icon
        *ngIf="type === 'photos' || type === 'videos' || type === 'videoGrid'"
        class="title-icon"
        icon="collections"
      ></ct-icon>
      <ct-icon *ngIf="type === 'campsite' || type === 'checkIn'" class="title-icon" icon="cabin"></ct-icon>
      <span>{{ type | translate }}</span>
    </div>
    <span class="header-details">{{ date | date : 'longDate' }} | {{ date | date : 'shortTime' }}</span>
    <div *ngIf="isAuthor" class="edit-icon">
      <ct-icon
        *ngIf="type === tripEntityType.Campsite || type === tripEntityType.Journal"
        icon="edit"
        (click)="elementEdit(type, element)"
      ></ct-icon>
    </div>
  </div>
</ng-template>
