<article class="row">
  <div class="col-12 col-md-{{ imgCols }}">
    <ct-link [link]="[link]">
      <ct-image-with-tags
        class="image"
        [tags]="showTags ? item?.tags : []"
        tagLocation="bottom"
        [src]="featured"
        [publicUrl]="publicUrl"
        [thumbnail]="thumbnail"
        [height]="imgHeight"
        [noImagePlaceholderPath]="placeholderPath"
        [width]="imgWidth"
        [objectFit]="objectFit"
        [altText]="item?.title + ' featured photo'"
      ></ct-image-with-tags>
    </ct-link>
  </div>
  <div
    class="col-12 col-md-{{ contentCols }} ml-2 ml-md-{{ contentMargin ? 4 : 0 }}"
    [class.pr-4]="contentCols === 12"
    [class.ml-md-1]="contentCols === 12"
  >
    <div class="headline" [class.mt-2]="isHorizontal">
      <ct-link [link]="[link]">
        <h3 class="title">{{ item?.title }}</h3>
      </ct-link>
      <ng-container></ng-container>
    </div>
    <ct-author
      *ngIf="showAuthor"
      class="author"
      [userId]="item?.author?.userId"
      [username]="item?.author?.username"
      [photo]="item?.author?.photo?.xsPreview"
      [date]="creationDate"
    ></ct-author>
    <p class="short-description" [innerHTML]="description | striphtml | safe: 'html'"></p>
    <ct-link class="button-wrapper mb-2" *ngIf="showLink" [link]="[link]">
      <ct-button variant="flat" [fullWidth]="linkFullWidth" class="link-button">READ MORE </ct-button>
    </ct-link>
    <div class="row">
      <div class="col-12">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</article>
