import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { TagsState, TagsStore } from './tags.store';

@Injectable({ providedIn: 'root' })
export class TagsQuery extends QueryEntity<TagsState> {
  constructor(protected store: TagsStore) {
    super(store);
  }
}
