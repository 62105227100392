import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

import { ForgotPasswordVerification, UserIdentity } from '../interfaces';

const endpoint = environment.identityApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  login(username: string, password: string) {
    return this.post(`authentications`, { username, password });
  }

  getIdentity() {
    return this.get(`users`);
  }

  register(user: any, captcha: string): Observable<UserIdentity> {
    const headers = {
      [HeaderType.GoogleRecaptcha]: captcha
    };
    return this.post(`users`, user, {}, { headers });
  }

  verify(userId: string, verificationCode: string) {
    return this.put(`users/${userId}`, { verificationCode });
  }

  resendVerificationCode(userId: string) {
    return this.post(`users/${userId}/verifications`, {});
  }

  forgotPassword(emailAddress: string, captcha: string) {
    const headers = {
      [HeaderType.GoogleRecaptcha]: captcha
    };
    return this.post(`forgotten-passwords`, { emailAddress }, {}, { headers });
  }

  forgotPasswordVerification(body: ForgotPasswordVerification) {
    return this.put(`forgotten-passwords`, body);
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.put(`authentications`, { refreshToken });
  }

  requestChangeEmail(newEmailAddress: string): Observable<any> {
    return this.post(`email-changes`, {
      newEmailAddress
    });
  }

  completeChangeEmail(code: string, newEmailAddress: string): Observable<any> {
    return this.put(`email-changes`, {
      code,
      newEmailAddress
    });
  }

  changePassword(newPassword: string, confirmNewPassword: string) {
    return this.put(`password-changes`, {
      newPassword,
      confirmNewPassword
    });
  }

  logout(): Observable<null> {
    return this.delete(`authentications`);
  }
}
