import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlogPost } from '@ct/core';
import { entitySlugUrl } from '@ct/shared';

@Component({
  selector: 'ct-featured-posts',
  templateUrl: './featured-posts.component.html',
  styleUrls: ['./featured-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedPostsComponent {
  @Input() posts: BlogPost[] | null = [];

  getLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }
}
