import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

const endpoint = environment.videoApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class VideoUploadApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll(): Observable<any[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.get(`upload-test`, null, {
      headers,
      withCredentials: true
    });
  }

  getByFilename(fileName: string): Observable<any> {
    return this.get(`upload-test/${fileName}`, { withCredentials: true });
  }

  uploadWithProgress(file: File) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    const formData = new FormData();
    formData.append('file', file);
    // use http client directly as baseHttpService does not support reportProgress property
    return this.httpClient.post(this.URL + `/upload`, formData, { headers, reportProgress: true, observe: 'events' });
  }

  upload(file: File) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    const formData = new FormData();
    formData.append('file', file);
    return this.post(`upload`, formData, {}, { headers });
  }
}
