import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  FilterPaneModule,
  IconModule,
  ImageBannerModule,
  InputModule,
  LinkModule,
  TextareaModule,
  UserAvatarModule,
  WidgetModule
} from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule, TripSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';

import { MarketplaceModule } from '../marketplace';
import { UserProfileComponent } from './components';
import { UserProfileRoutingModule } from './user-profile-routing.module';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    ImageBannerModule,
    TranslateModule,
    ReactiveFormsModule,
    TextareaModule,
    InputModule,
    ButtonModule,
    IconModule,
    UserAvatarModule,
    FilterPaneModule,
    TripSharedModule,
    LinkModule,
    BlogSharedModule,
    MarketplaceModule,
    LayoutModule,
    WidgetModule
  ]
})
export class UserProfileModule {}
