<form class="form" [formGroup]="form">
  <div class="row">
    <ct-input
      class="col-12 col-md-3"
      [showLabel]="true"
      [label]="'MARKETPLACE.ITEM_SEARCH.SEARCH_LABEL' | translate"
      [placeholder]="'MARKETPLACE.ITEM_SEARCH.SEARCH_PLACEHOLDER' | translate"
      formControlName="search"
      [suffixIcon]="form.value.search ? 'clear' : ''"
      (suffixIconClicked)="onClearSearch()"
    ></ct-input>
    <ct-select
      class="col-6 col-md-2"
      formControlName="categoryId"
      [showLabel]="true"
      [label]="'MARKETPLACE.ITEM_SEARCH.CATEGORY' | translate"
      [placeholder]="'COMMON.SELECT' | translate"
      [options]="categoriesList"
      [optional]="false"
    ></ct-select>
    <ct-select
      class="col-6 col-md-2"
      formControlName="subCategoryId"
      [showLabel]="true"
      [label]="'MARKETPLACE.ITEM_SEARCH.SUBCATEGORY' | translate"
      [placeholder]="'COMMON.SELECT' | translate"
      [options]="subCategoriesList"
      [optional]="false"
    ></ct-select>
    <ct-google-places
      formControlName="location"
      class="col-12 col-md-3"
      [label]="'MARKETPLACE.ITEM_SEARCH.LOCATION' | translate"
      (locationChanged)="onLocation($event)"
      [placeholder]="'MARKETPLACE.ITEM_SEARCH.LOCATION_PLACEHOLDER' | translate"
      (setCurrentLocation)="onCurrentLocation()"
    ></ct-google-places>
    <ct-button
      class="col-6 col-md-1 mt-md-4 mb-md-0 mb-4"
      [disabled]="loading"
      variant="flat"
      color="accent"
      (click)="onSearch()"
      >{{ 'filter' | translate }}</ct-button
    >
    <div class="col-6 col-md-1 mt-md-4 mb-md-0 mb-4">
      <a class="clear-link" (click)="onClearAll()">{{ 'MARKETPLACE.ITEM_SEARCH.CLEAR_ALL' | translate }}</a>
    </div>
  </div>
</form>
<div
  *ngIf="items?.length"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div *ngFor="let item of items; trackBy: trackByFn">
    <ct-marketplace-item
      class="mb-4"
      [item]="item"
      [showLink]="true"
      imgHeight="220px"
      imgWidth="100%"
      [link]="getLink(item)"
    ></ct-marketplace-item>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</div>

<ng-container *ngIf="!items?.length && !otherItems?.length">
  <p class="flex-center">{{ 'No Results' | translate }}</p>
</ng-container>
<ng-container *ngIf="!items?.length && otherItems?.length">
  <p class="text-bold">{{ "Unfortunately we can't find anything near your selected area." | translate }}</p>
  <p class="text-bold">{{ 'Please, have a look at other items that could be interesting for you:' | translate }}</p>

  <div *ngFor="let item of otherItems">
    <ct-marketplace-item
      class="mb-4"
      [item]="item"
      [showLink]="true"
      imgHeight="220px"
      imgWidth="100%"
      [link]="getLink(item)"
    ></ct-marketplace-item>
  </div>
</ng-container>
