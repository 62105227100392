<div class="header">
  <div class="container">
    <ct-link *ngIf="!isProd" [link]="['/']" (click)="closeMenu()" class="logo-container">
      <img class="logo" alt="logo" src="assets/logos/main-logo.png" />
    </ct-link>
    <a *ngIf="isProd" [href]="externalUrl" class="logo-container">
      <img class="logo" alt="logo" src="assets/logos/main-logo.png" />
    </a>
    <ct-search-autocomplete *ngIf='showSearch' class="search-box"></ct-search-autocomplete>
    <ct-icon class="menu-icon" icon="menu" (click)="collapsed = !collapsed"></ct-icon>
    <nav class="navbar" [ngClass]="collapsed ? 'collapsed' : 'expanded'">
      <div class="navbar-menu">
        <ul>
          <li>
            <a href="https://camping.tools">{{ 'HEADER.MENU.HOME' | translate }}</a>
          </li>
          <li *ngFor="let menuItem of menuItems">
            <a routerLinkActive="active" [routerLink]="menuItem.link" (click)="closeMenu()">{{
              menuItem.title$ | async
            }}</a>
          </li>
        </ul>
      </div>
      <div class="navbar-buttons">
        <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
          <ng-container *ngTemplateOutlet="loggedIn"></ng-container>
        </ng-container>
      </div>
    </nav>
  </div>
</div>

<ng-template #loggedIn>
  <ct-link [link]="['/marketplace/sell']">
    <ct-button class="pr-2" variant="flat" color="primary" (click)="closeMenu()">{{
      'Sell Item' | translate
    }}</ct-button>
  </ct-link>
  <ct-link [link]="['/my-account/overview']">
    <ct-button class="pr-2" variant="flat" color="accent" (click)="closeMenu()">{{
      'HEADER.BUTTONS.MY_ACCOUNT' | translate
    }}</ct-button>
  </ct-link>
  <ct-button variant="stroked" (click)="onLogout()">
    {{ 'HEADER.BUTTONS.LOGOUT' | translate }}
    <ct-icon class="pl-2" icon="exit"></ct-icon>
  </ct-button>
</ng-template>

<ng-template #notLoggedIn>
  <ct-link [link]="['/register']">
    <ct-button class="pr-2 pl-2" variant="flat" color="accent" (click)="closeMenu()">{{
      'COMMON.REGISTER' | translate
    }}</ct-button>
  </ct-link>
  <ct-link [link]="['/login']" [queryParams]="queryParams" [queryParamsHandling]="'merge'">
    <ct-button variant="stroked" (click)="closeMenu()"> {{ 'HEADER.BUTTONS.LOGIN' | translate }}</ct-button>
  </ct-link>
</ng-template>
