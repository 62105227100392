import { NgModule } from '@angular/core';
import { Meter } from '@opentelemetry/sdk-metrics-base';

import { MetricService } from './metric.service';

@NgModule({
  providers: [
    {
      provide: Meter,
      useFactory: (metricService: MetricService) => metricService.getMeter(),
      deps: [MetricService]
    }
  ]
})
export class OpenTelemetryMetricModule {}
