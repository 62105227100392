import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ButtonModule } from '@ct/components/button';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { InputModule } from '@ct/components/input';
import { TranslateModule } from '@ngx-translate/core';

import { MediaSharedModule } from '../../modules/media-shared';
import { YoutubeVideoAddDialogComponent } from './components/youtube-video-add-dialog';
import { YoutubeVideoGridComponent } from './components/youtube-video-grid';
import { YoutubeVideoViewDialogComponent } from './components/youtube-video-view-dialog';
import { YoutubeIdArrayPipe, YoutubeIdPipe, YoutubeObjectArrayPipe, YoutubeObjectPipe } from './pipes';
import { YoutubeVideoAddService, YoutubeVideoApiService, YoutubeVideoViewService } from './services';
import { YoutubePlayerComponent } from './youtube.component';

@NgModule({
  imports: [
    YouTubePlayerModule,
    TranslateModule,
    DialogModule,
    MatTabsModule,
    InputModule,
    ReactiveFormsModule,
    CommonModule,
    ButtonModule,
    IconModule,
    MediaSharedModule,
    ImageModule
  ],
  declarations: [
    YoutubePlayerComponent,
    YoutubeVideoAddDialogComponent,
    YoutubeVideoViewDialogComponent,
    YoutubeIdPipe,
    YoutubeIdArrayPipe,
    YoutubeObjectPipe,
    YoutubeObjectArrayPipe,
    YoutubeVideoGridComponent
  ],
  exports: [
    YoutubePlayerComponent,
    YoutubeVideoGridComponent,
    YoutubeIdPipe,
    YoutubeIdArrayPipe,
    YoutubeObjectPipe,
    YoutubeObjectArrayPipe
  ],
  providers: [YoutubeVideoApiService, YoutubeVideoAddService, YoutubeVideoViewService]
})
export class YoutubeModule {}
