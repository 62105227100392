import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ChannelVideoUploadApiService } from '@ct/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ChannelResolver implements Resolve<any> {
  constructor(private channelVideoUploadApiService: ChannelVideoUploadApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const channelName = activatedRouteSnapshot.params?.channelName;
    return this.channelVideoUploadApiService.getAllForChannel(channelName).pipe(catchError(() => of([])));
  }
}
