import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  CheckboxModule,
  DialogModule,
  IconModule,
  InputModule,
  LinkModule,
  PasswordModule,
  TextareaModule
} from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { TranslateModule } from '@ngx-translate/core';

import { MyAccountSharedModule } from '../my-account-shared';
import { DownloadMediaInfoComponent, MyAccountProfileDownloadMediaComponent } from './components';
import { MyAccountProfileComponent } from './components/my-account-profile/my-account-profile.component';
import { MyAccountProfileChangeEmailComponent } from './components/my-account-profile-change-email';
import { MyAccountProfileChangePasswordComponent } from './components/my-account-profile-change-password';
import { MyAccountProfileContentComponent } from './components/my-account-profile-content/my-account-profile-content.component';
import { MyAccountProfileRoutingModule } from './my-account-profile-routing.module';

@NgModule({
  declarations: [
    MyAccountProfileComponent,
    MyAccountProfileChangeEmailComponent,
    MyAccountProfileChangePasswordComponent,
    MyAccountProfileContentComponent,
    MyAccountProfileDownloadMediaComponent,
    DownloadMediaInfoComponent
  ],
  imports: [
    CommonModule,
    MyAccountProfileRoutingModule,
    TranslateModule,
    CardModule,
    CheckboxModule,
    MyAccountSharedModule,
    TextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    InputModule,
    RouterModule,
    PasswordModule,
    LinkModule,
    MatStepperModule,
    LayoutModule,
    IconModule,
    DialogModule
  ]
})
export class MyAccountProfileModule {}
