import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MarketplaceItem } from '../../../../../marketplace/interfaces';

@Component({
  selector: 'ct-my-account-overview-marketplace',
  templateUrl: './my-account-overview-marketplace.component.html',
  styleUrls: ['./my-account-overview-marketplace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountOverviewMarketplaceComponent {
  @Input() items: MarketplaceItem[] | undefined;

  constructor(private router: Router, private route: ActivatedRoute) {}

  click(id: string | undefined) {
    this.router.navigate([`../marketplace/${id}`], { relativeTo: this.route });
  }
}
