<div class="header">
  <span class="header-details"
    >{{ journalEntry?.date | date : 'longDate' }} | {{ journalEntry?.date | date : 'shortTime' }}</span
  >
  <div *ngIf="canEdit" class="edit-icon">
    <ct-icon icon="edit" (click)="onEdit()"></ct-icon>
  </div>
</div>
<div class="body journal-entry">
  <ct-text-editor-view class="mb-4" [content]="journalEntry?.body"></ct-text-editor-view>
  <ng-container
    *ngIf="
      showMedia &&
      (journalEntry.photoIds?.length || journalEntry.videoIds?.length) &&
      !photos?.length &&
      !videos?.length
    "
  >
    <p>Loading media...</p>
    <ct-spinner-simple class="loading-media-spinner"></ct-spinner-simple>
  </ng-container>
  <section *ngIf="(photos?.length || videos?.length) && showMedia">
    <h3 class="section-title">{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.MEDIA' | translate }}</h3>
    <ct-photo-gallery *ngIf="photos.length" [photos]="photos"></ct-photo-gallery>
    <ct-video-item-preview *ngFor="let video of videos" [video]="video" (selectVideo)="onPlayVideo(video)">
    </ct-video-item-preview>
  </section>
</div>
