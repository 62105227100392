import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadedImage } from '@ct/core';

@Component({
  selector: 'ct-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoComponent {
  @Input() photo: UploadedImage | null | undefined;
  @Input() height = '100%';
  @Input() width = '100%';

  @Input() removable: boolean | undefined = false;
  @Input() showAccess: boolean | undefined = false;
  @Input() multiselect: boolean | undefined = false;
  @Input() altText: string | undefined;

  @Output() removed = new EventEmitter<void>();
  @Output() accessChanged = new EventEmitter<void>();
  @Output() selectionChanged = new EventEmitter<void>();

  onAccessChanged($event: MouseEvent) {
    $event.stopPropagation();
    this.accessChanged.emit();
  }

  onSelectionChanged($event: MouseEvent) {
    $event.stopPropagation();
    this.selectionChanged.emit();
  }
}
