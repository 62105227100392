<div class="row mt-5">
  <div class="col-12">
    <div class="trip">
      <form class="form" [formGroup]="form">
        <div class="row">
          <ct-input
            class="col-12"
            formControlName="title"
            [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TRIP_TITLE' | translate"
            [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TITLE_PLACEHOLDER' | translate"
          ></ct-input>
        </div>
        <div class="row">
          <ct-date-picker
            class="col-12 col-md-6"
            formControlName="startDate"
            [label]="'COMMON.DATE.START' | translate"
            [placeholder]="'COMMON.DATE.START_PLACEHOLDER' | translate"
            [max]="form.controls?.endDate?.value"
          ></ct-date-picker>
          <ct-date-picker
            class="col-12 col-md-6"
            formControlName="endDate"
            [label]="'COMMON.DATE.END' | translate"
            [placeholder]="'COMMON.DATE.END_PLACEHOLDER' | translate"
            [min]="form.controls?.startDate?.value"
            [startDate]="form.controls?.startDate?.value"
          ></ct-date-picker>
        </div>
        <div class="flex-space-between">
          <div class="d-flex">
            <ct-button class="mr-2" variant="flat" color="primary" (click)="onCancel()">{{
              'COMMON.CANCEL' | translate
            }}</ct-button>
            <ct-button [disabled]="isLoading" class="mr-2" variant="flat" color="accent" (click)="onSave()">{{
              (isEditMode ? 'COMMON.UPDATE' : 'COMMON.SAVE') | translate
            }}</ct-button>
          </div>
          <ct-button *ngIf="isEditMode" [disabled]="isLoading" variant="stroked" color="warn" (click)="onDelete()">{{
            'COMMON.DELETE' | translate
          }}</ct-button>
        </div>
      </form>
    </div>
  </div>
</div>
