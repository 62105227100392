import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TabHeader } from '../tab-header/tab-header.interface';

@Component({
  selector: 'ct-tabs-mobile',
  templateUrl: './tabs-mobile.component.html',
  styleUrls: ['./tabs-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsMobileComponent {
  @Input() tabs: TabHeader[];

  get activeTab(): TabHeader {
    return this.tabs?.find(({ selected }) => selected) as TabHeader;
  }

  get otherTabs(): TabHeader[] {
    return (this.tabs ?? []).filter(({ selected }) => !selected);
  }

  expanded: boolean;

  @Output() tabChange = new EventEmitter<TabHeader>();

  onTabClicked(tab: TabHeader): void {
    this.tabChange.emit(tab);
    this.expanded = false;
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
