import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ct-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate('300ms ease-in', style({ opacity: 1 }))])])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryImageComponent implements OnDestroy {
  private readonly state$ = new BehaviorSubject<'loading' | 'success' | 'failed'>('loading');

  public readonly state = this.state$.asObservable();

  public progress = 0;

  @Input() isThumbnail: boolean;

  @Input() src: string;
  @Input() original?: string;
  @Input() imageDescription: string;
  public imageUrl: string;

  @Output() errorItem = new EventEmitter<Error>();
  public loadError: Error | undefined;

  @HostBinding('class.g-image-loaded') get imageLoadSuccess(): boolean {
    return !!this.imageUrl;
  }

  @HostBinding('class.g-image-error') get imageLoadFailed(): boolean {
    return !!this.loadError;
  }

  @HostBinding('class.g-thumbnail-image') get isThumb(): boolean {
    return !!this.isThumbnail;
  }

  ngOnDestroy() {
    this.state$.complete();
  }

  onProgress({ loaded, total }: { loaded: number; total: number }) {
    this.progress = (loaded * 100) / total;
  }

  onLoaded(blobUrl: string) {
    this.imageUrl = `url("${blobUrl}")`;
    this.state$.next('success');
  }

  onError(err: Error) {
    this.loadError = err;
    this.state$.next('failed');
    this.errorItem.emit(err);
  }
}
