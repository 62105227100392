<div *ngIf="!img" class="placeholder" [ngStyle]="{ height: height, width: width }" (click)="placeholderClicked.emit()">
  <div class="text">{{ textKey | translate }}</div>
</div>

<ct-image
  *ngIf="img"
  class="image"
  [ngStyle]="{ height: height, width: width }"
  [src]="img"
  [removable]="true"
  (removed)="removed.emit()"
></ct-image>

<div *ngIf="replaceKey" class="replace" (click)="replaced.emit()">{{ replaceKey | translate }}</div>
