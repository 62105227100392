<ct-autocomplete
  [label]="label || ('MY_ACCOUNT.MY_POSTS_FEATURE.TAGS' | translate)"
  [placeholder]="placeholder || ('MY_ACCOUNT.MY_POSTS_FEATURE.TAGS_PLACEHOLDER' | translate)"
  [optionsFn]="optionsFn"
  [valueFn]="valueFn"
  [labelFn]="labelFn"
  [displayFn]="displayFn"
  [maxlength]="maxlength"
  [minlength]="minlength"
  [formControl]="autocompleteControl"
  [focusAfterSubmit]="true"
  [hint]="false"
  [searchOnInit]="searchOnInit"
  [noMatchOption]="noMatchOption"
  [attr.compact]="true"
  (changed)="changed($event)"
></ct-autocomplete>
<div class="tags">
  <ct-tag
    class="mr-1 mb-1"
    *ngFor="let tag of control.value"
    [removable]="true"
    [name]="tag?.name"
    (removed)="removed(tag)"
  ></ct-tag>
</div>
