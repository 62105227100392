<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header ctHeader icon="collections" title="Media"></ct-extended-header>

  <div ctContent>
    <ct-tab-header
      class="d-none d-md-flex"
      [tabs]="tabs"
      style="margin-bottom: -1px"
      (tabChange)="onFilterChanged($event)"
    >
      <ct-icon ctTabPrefix class="add__icon" title="Upload Photo" icon="add-circle" (click)="addItems()"></ct-icon>
    </ct-tab-header>
    <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)">
      <ct-icon ctTabPrefix class="add__icon" icon="add-circle" (click)="addItems()"></ct-icon>
    </ct-tabs-mobile>
    <router-outlet></router-outlet>
  </div>
</ct-layout>
