<div class="tab-mobile__wrapper" [class.tab-mobile__wrapper--expanded]="expanded">
  <div class="tab-mobile--prefix">
    <ng-content select="[ctTabPrefix]"></ng-content>
  </div>
  <div class="tab-mobile--suffix">
    <ct-icon class="tab-mobile--toggle" [icon]="expanded ? 'arrow-up' : 'arrow-down'" (click)="toggle()"></ct-icon>
  </div>
  <span *ngIf="activeTab" class="tab-mobile tab-mobile--active" (click)="onTabClicked(activeTab)">
    {{ activeTab.labelKey | translate }}
  </span>
  <div class="tab-mobile--other">
    <span *ngFor="let tab of otherTabs | filterShouldShow" class="tab-mobile" (click)="onTabClicked(tab)">
      {{ tab.labelKey | translate }}
    </span>
  </div>
</div>
