import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HeaderType, SortOrder } from '../enums';
import { RequestRange, TripListItem } from '../interfaces';
import { BaseHttpService } from './base-http.service';

const endpoint = environment.listItemsApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class ListItemsApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll(
    listId?: string,
    range?: RequestRange,
    sortOrder: SortOrder = SortOrder.Desc,
    authorId?: string
  ): Observable<TripListItem[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (listId !== undefined && listId !== null) {
      params = params.append('listId', listId);
    }
    return this.get(``, params, { headers, withCredentials: true });
  }

  create(entity: TripListItem): Observable<TripListItem> {
    return this.post<TripListItem>(``, entity);
  }

  bulkUpdate(entities: TripListItem[]): Observable<TripListItem[]> {
    return this.patch(`batch`, { items: entities });
  }

  update(id: string, body: Partial<TripListItem>) {
    const data = this.toUpdateRequest(body);
    return this.patch(`${id}`, data);
  }

  remove(id: string) {
    return this.delete(`${id}`);
  }

  toUpdateRequest(body: Partial<TripListItem>): Partial<TripListItem> {
    const { id, listId, ...rest } = body;
    return rest;
  }
}
