import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Trip } from '../../interfaces';

@Component({
  selector: 'ct-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripComponent {
  @Input() trip: Trip | undefined;
  @Input() link: string[] | string;
  @Input() extraLink: boolean;
  @Input() showAuthor: boolean;
  @Input() entriesLimit: number | undefined = 3;

  get entries() {
    return this.trip?.entries?.slice(0, this.entriesLimit);
  }
}
