import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlogPost } from '@ct/core';
import { entitySlugUrl } from '@ct/shared';

@Component({
  selector: 'ct-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {
  @Input() posts: BlogPost[] | null = [];
  @Input() unregisteredBannerImages: string[] = [];
  @Input() loggedIn: boolean | null = false;

  getLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }
}
