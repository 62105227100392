import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProfileApiService, UserProfileQuery } from '@ct/auth';
import { UserProfile } from '@ct/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-marketplace-items-by-author',
  templateUrl: './marketplace-items-by-author.component.html',
  styleUrls: ['./marketplace-items-by-author.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketplaceItemsByAuthorComponent implements OnInit {
  public user$ = this.query.selectActive() as Observable<UserProfile>;

  constructor(
    private route: ActivatedRoute,
    private userProfileApiService: UserProfileApiService,
    private query: UserProfileQuery
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe().subscribe(({ merchantId }) => {
      if (!this.query.getEntity(merchantId)) {
        this.userProfileApiService.getByUserId(merchantId).subscribe();
      } else {
        this.userProfileApiService.setActive(merchantId);
      }
    });
  }
}
