import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorComponent {
  @Input() username: string | undefined;
  @Input() userId: string | undefined;
  @Input() photo: string | undefined;
  @Input() date: string | Date | undefined;
}
