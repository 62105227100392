import { ɵDirectiveDef } from '@angular/core';

import { MetaTagConfig, SeoConfig } from '../../interfaces';
import { Writable } from '../../models';
import { AppInjector, MetaTagService } from '../../services';

export function MetaTagFeature() {
  return <T extends { seoConfig?: SeoConfig; metaTagConfig?: MetaTagConfig }>(
    directiveDef: Writable<ɵDirectiveDef<T>>
  ) => {
    const { factory, type } = directiveDef;

    directiveDef.factory = () => {
      const instance = factory?.(type) as T;
      const injector = AppInjector.getInjector();

      if (!injector) {
        throw new Error(`Injector not found! Please, provide it or remove MetaTagFeature() decorator`);
      }
      if (!instance.seoConfig && !instance.metaTagConfig) {
        throw new Error(
          `'seoConfig' or 'metaTagСonfig' are not defined in the ${type?.name}! Please, provide it or remove MetaTagFeature() decorator`
        );
      }
      const config: MetaTagConfig = (instance.seoConfig?.metaTagConfig || instance?.metaTagConfig) as MetaTagConfig;
      const { tags } = config;
      const metaTagService = injector.get(MetaTagService);
      metaTagService.updateTags(tags);
      return instance;
    };
  };
}
