<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header
    ctHeader
    icon="cabin"
    title="Trips"
    description="Create past and future trips to store media, memories and stories"
  ></ct-extended-header>

  <div ctContent>
    <ng-container *ngIf="hasContent; else noTrips">
      <div class="row">
        <ct-trip-card
          [trip]="isMyTripsTab ? lastEdited : trips[0]"
          [canEdit]="isMyTripsTab"
          [isHeading]="true"
          (edit)="editTrip(lastEdited)"
          (share)="shareTrip(lastEdited)"
          (openTrip)="openTrip(lastEdited)"
          class="trips__last-edited col-12"
        >
          <span *ngIf="isMyTripsTab" ctTripCardExtraInfo>Last Edited</span>
          <ct-tab-header
            ctTripCardFooter
            class="d-none d-md-flex"
            style="margin-bottom: -1rem"
            backgroundColor="#fafafa"
            [tabs]="tabs"
            (tabChange)="onFilterChanged($event)"
          >
            <ct-icon
              ctTabPrefix
              class="add-trip__icon"
              title="Create New Trip"
              icon="add-circle"
              (click)="addTrip()"
            ></ct-icon>
          </ct-tab-header>
        </ct-trip-card>
      </div>
      <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)">
        <ct-icon ctTabPrefix class="add-trip__icon" icon="add-circle" (click)="addTrip()"></ct-icon>
      </ct-tabs-mobile>
      <div
        class="row px-4 px-md-5 mb-4 pt-4 trips-list"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      >
        <ct-trip-card
          class="col-12 col-md-4 mb-3"
          *ngFor="let trip of trips"
          [trip]="trip"
          [link]="getLink(trip)"
        ></ct-trip-card>
        <ng-container *ngIf="!trips?.length && isMyTripsTab">
          <ng-container *ngTemplateOutlet="noTrips"></ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="showLoadButton"
        class="load-more"
        [ngClass]="{ disabled: loading }"
        (click)="onScroll()"
        disabled="loading"
      >
        Load More
      </div>
    </ng-container>

    <ng-template #noTrips>
      <ct-empty-call-to-action
        buttonIcon="add-circle"
        emptyMessageTextKey="MY_ACCOUNT.EMPTY_ENTITY.NO_TRIPS"
        buttonTextKey="MY_ACCOUNT.NEW_TRIP"
        (buttonClick)="addTrip()"
      ></ct-empty-call-to-action>
    </ng-template>
  </div>
</ct-layout>
