import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AuthorModule,
  ButtonModule,
  GalleryModule,
  ImageBannerModule,
  ImageModule,
  InputModule,
  LikeModule,
  LinkModule,
  NavigationModule,
  SidebarModule,
  TagModule,
  TextEditorModule,
  UserAvatarModule,
  WidgetModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { BlogRoutingModule } from './blog-routing.module';
import {
  BlogComponent,
  BlogPostsAllComponent,
  BlogPostsByAuthorComponent,
  BlogPostsByTagComponent,
  BlogPostsComponent,
  BlogPostsSearchComponent,
  BlogPostViewComponent,
  BlogSidebarComponent
} from './components';

@NgModule({
  declarations: [
    BlogComponent,
    BlogSidebarComponent,
    BlogPostsComponent,
    BlogPostViewComponent,
    BlogPostsByAuthorComponent,
    BlogPostsByTagComponent,
    BlogPostsSearchComponent,
    BlogPostsAllComponent
  ],
  exports: [BlogSidebarComponent],
  imports: [
    CommonModule,
    BlogRoutingModule,
    TranslateModule,
    ImageBannerModule,
    BlogSharedModule,
    TagModule,
    ImageModule,
    TextEditorModule,
    LikeModule,
    NavigationModule,
    AuthorModule,
    SidebarModule,
    LinkModule,
    InputModule,
    ReactiveFormsModule,
    GalleryModule,
    UserAvatarModule,
    PipesModule,
    LayoutModule,
    WidgetModule,
    InfiniteScrollModule,
    ButtonModule
  ]
})
export class BlogModule {}
