import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  EmptyCallToActionModule,
  EntityCommentsModule,
  GalleryModule,
  IconModule,
  LikeModule,
  TabHeaderModule,
  TabsMobileModule,
  UserAvatarModule,
  WidgetModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule, MediaSharedModule, TripSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { BlogModule } from '../../../blog';
import { MyAccountExploreComponent, MyAccountExploreEntityComponent } from './components';
import { MyAccountExploreRoutingModule } from './my-account-explore-routing.module';

@NgModule({
  declarations: [MyAccountExploreComponent, MyAccountExploreEntityComponent],
  imports: [
    CommonModule,
    MyAccountExploreRoutingModule,
    LayoutModule,
    WidgetModule,
    RouterModule,
    TranslateModule,
    UserAvatarModule,
    MediaSharedModule,
    BlogSharedModule,
    TripSharedModule,
    EmptyCallToActionModule,
    IconModule,
    InfiniteScrollModule,
    TabHeaderModule,
    TabsMobileModule,
    GalleryModule,
    PipesModule,
    LikeModule,
    EntityCommentsModule,
    BlogModule
  ]
})
export class MyAccountExploreModule {}
