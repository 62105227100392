import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranscodingState, VideoUploadEntity } from '@ct/core/interfaces/video-upload.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoItemComponent {
  public readonly destroyed$: Observable<void>;
  @Input()
  protected readonly video: VideoUploadEntity;
  @Input()
  protected readonly removable: boolean;

  @Output()
  protected readonly selectVideo = new EventEmitter<VideoUploadEntity>();
  @Output()
  protected readonly removeVideo = new EventEmitter<VideoUploadEntity>();

  protected transcodingState = TranscodingState;

  protected get disabled(): boolean {
    return this.video.transcodingJobStatus !== TranscodingState.SUCCEEDED;
  }

  onPlayVideo(): void {
    this.selectVideo.emit(this.video);
  }

  onDeleteVideo(): void {
    this.removeVideo.emit(this.video);
  }
}
