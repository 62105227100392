import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserProfile } from '@ct/core';

@Component({
  selector: 'ct-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserHeaderComponent {
  @Input()
  user: UserProfile | null;
}
