import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserProfile } from '@ct/core';
import { getCroppedThumbPublicUrl } from '@ct/shared/helpers';

@Component({
  selector: 'ct-user-row',
  templateUrl: './user-row.component.html',
  styleUrls: ['./user-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRowComponent {
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  @Input() user: UserProfile;
  @Input() clickable: boolean;
  @Output() userClick: EventEmitter<void> = new EventEmitter<void>();
}
