import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, UserNotifications } from '@ct/core';
import { environment } from '@ct/environment';
import { map } from 'rxjs/operators';

const endpoint = environment.userProfileApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class UserNotificationsApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getById(id: string) {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.get(`notifications/${id}`, {}, { headers, withCredentials: true });
  }

  getByUserId(id: string) {
    const params = new HttpParams().append('userId', id);
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.get(`notifications`, params, { headers, withCredentials: true }).pipe(map(([data]) => data));
  }

  create(notificationSettings: UserNotifications) {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.post('notifications', notificationSettings, {}, { headers });
  }

  update(id: string, notificationSettings: Partial<UserNotifications>) {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.patch(`notifications/${id}`, notificationSettings, {}, { headers });
  }
}
