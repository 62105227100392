import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@ct/auth';
import { BaseHttpService, FriendStatus, RelationUserProfile, UserProfile } from '@ct/core';
import { environment } from '@ct/environment';
import { SortOrder, UserRelation } from '@ct/shared';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { FriendsQuery, FriendsStore } from '../services/store';

export interface UserRelationsFilterParams {
  userId: string;
  status?: FriendStatus;
  sortOrder?: SortOrder | undefined;
  match?: string;
}

const endpoint = environment.userRelationsApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class UserRelationsApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private store: FriendsStore,
    private friendsQuery: FriendsQuery
  ) {
    super(httpClient, endpoint);
  }

  getAll({ userId, sortOrder = SortOrder.Desc, status }: UserRelationsFilterParams): Observable<RelationUserProfile[]> {
    let params = new HttpParams();
    params = params.append('userId', userId);
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (status !== undefined && status !== null) {
      params = params.append('status', status);
    }
    return this.get(``, params);
  }

  getAllMyFriends(options?: { force: boolean }): Observable<RelationUserProfile[]> {
    if (!options?.force && this.friendsQuery.getAll().length) {
      return this.friendsQuery.selectAll();
    }

    return this.getAllMyFriendsApi();
  }

  getAllMyFriendsApi(): Observable<RelationUserProfile[]> {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return this.getAll({ userId: identity.guuid });
      }),
      tap((friends) => {
        this.store.set(friends);
      })
    );
  }

  getPossibleFriends({
    match,
    userId,
    sortOrder = SortOrder.Desc
  }: UserRelationsFilterParams): Observable<UserProfile[]> {
    let params = new HttpParams();
    params = params.append('userId', userId);
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (match !== undefined && match !== null) {
      params = params.append('match', match);
    }
    return this.get(`possible-friends`, params);
  }

  update(id: string, userProfile: UserRelation) {
    return this.patch(id, { ...userProfile }, {}, {}).pipe(
      tap((friend) => {
        this.store.upsert(userProfile.userId, friend);
      })
    );
  }

  create({ userId }: Partial<UserRelation>) {
    return this.post('', { userId }, {}, {}).pipe(
      tap((friend) => {
        this.store.upsert(userId, friend);
      })
    );
  }

  remove(id: string) {
    return this.delete(id);
  }

  removeByUserId(userId: string) {
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.delete('', params).pipe(
      tap(() => {
        this.store.remove(userId);
      })
    );
  }
}
