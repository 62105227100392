<header>
  <ct-icon *ngIf="icon" [icon]="icon" class="mr-2"></ct-icon>
  {{ titleKey | translate }}
</header>
<div class="widget-content">
  <ng-content></ng-content>
  <a *ngIf="showMore" [routerLink]="showMoreLink" [queryParams]="queryParams">
    <ct-button [fullWidth]="true" variant="flat" class="mt-4 widget-button">{{
      'COMMON.VIEW_ALL' | translate
    }}</ct-button>
  </a>
</div>
