import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ct-cookies-notice',
  templateUrl: './cookies-notice.component.html',
  styleUrls: ['./cookies-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesNoticeComponent {
  @Output()
  closeNotice = new EventEmitter<void>();

  onCloseNotice() {
    this.closeNotice.emit();
  }
}
