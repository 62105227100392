<div class="row flex-md-row flex-column">
  <div class="col-12 col-md-9">
    <ng-container *ngIf="posts?.length">
      <ct-widget infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <a
          style="width: 100%; display: flex; margin-bottom: 1rem"
          href="https://autoclubsouth.aaa.com/insurance/rv-insurance.aspx"
        >
          <img
            alt="AAA - RV Insurance"
            src="assets/ad-banners/aaa_rv_insurance.png"
            loading="lazy"
            style="width: 100%"
          />
        </a>
        <div *ngFor="let post of posts; trackBy: trackByFn">
          <ct-blog-post-card
            class="mb-4"
            [post]="post"
            [showLink]="true"
            [showStatus]="false"
            imgHeight="220px"
            imgWidth="100%"
            [link]="getLink(post)"
          ></ct-blog-post-card>
        </div>
      </ct-widget>
      <div
        *ngIf="showLoadButton"
        class="load-more"
        [ngClass]="{ disabled: loading }"
        (click)="onScroll()"
        disabled="loading"
      >
        Load More
      </div>
    </ng-container>
    <ng-container *ngIf="!posts?.length">
      <p class="flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
    </ng-container>
  </div>
  <div class="col-12 col-md-3 pt-2 pt-md-0">
    <ct-blog-sidebar></ct-blog-sidebar>
  </div>
</div>
