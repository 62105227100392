import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService, UserProfileApiService } from '@ct/auth';
import { ImageUploadService } from '@ct/components';
import { UploadedImage, UserProfile } from '@ct/core';
import { MyAccountPhotoApiService } from '@ct/shared';
import { of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-header',
  templateUrl: './my-account-header.component.html',
  styleUrls: ['./my-account-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountHeaderComponent implements OnInit {
  @Output() public buttonClicked = new EventEmitter<MouseEvent>();

  public user: UserProfile | null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private userProfileApiService: UserProfileApiService
  ) {}

  ngOnInit() {
    this.authService
      .getUserProfile()
      .pipe(catchError(() => of(null)))
      .subscribe((user) => {
        this.user = user;
        this.changeDetectorRef.detectChanges();
      });
  }

  onChangePhoto() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PROFILE_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(
        take(1),
        filter((photos) => photos?.length),
        switchMap(([photo]: UploadedImage[]) =>
          this.userProfileApiService.update(this.user?.id as string, { photoId: photo?.id })
        ),
        switchMap((userProfile: UserProfile) => this.authService.updateUserProfile(userProfile)),
        switchMap(() => this.authService.getUserProfile()),
        catchError(() => of(null))
      )
      .subscribe((userProfile: UserProfile | null) => {
        this.user = userProfile;
        this.changeDetectorRef.detectChanges();
      });
  }
}
