import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';

import { JournalEntryState, JournalEntryStore } from './journal-entry.store';

@Injectable({ providedIn: 'root' })
export class JournalEntryQuery extends QueryEntity<JournalEntryState> {
  constructor(protected store: JournalEntryStore) {
    super(store);
  }

  selectBy(searchTripId: string, searchCampsiteId?: string) {
    return this.selectAll({
      filterBy: ({ tripId, campsiteId }) => {
        const sameTrip = String(tripId) === String(searchTripId);
        return searchCampsiteId ? String(campsiteId) === String(searchCampsiteId) && sameTrip : sameTrip;
      },
      sortBy: 'date',
      sortByOrder: Order.DESC
    });
  }
}
