import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  AutocompleteModule,
  ImageBannerModule,
  LinkModule,
  NavigationModule,
  SidebarModule,
  TagModule,
  UserAvatarModule
} from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule, TripSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';

import { MarketplaceModule } from '../marketplace';
import { SearchResultsComponent } from './search-results';
import { SiteSearchComponent } from './site-search.component';
import { SiteSearchRoutingModule } from './site-search-routing.module';

@NgModule({
  declarations: [SiteSearchComponent, SearchResultsComponent],
  imports: [
    CommonModule,
    SiteSearchRoutingModule,
    TranslateModule,
    ImageBannerModule,
    AutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    SidebarModule,
    TagModule,
    LinkModule,
    UserAvatarModule,
    NavigationModule,
    BlogSharedModule,
    TripSharedModule,
    MatTabsModule,
    MarketplaceModule,
    LayoutModule
  ]
})
export class SiteSearchModule {}
