import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@ct/environment';
import { OpenTelemetryModule } from '@ct/opentelemetry';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app.module';
import { AppComponent } from './components';
import { I18nBrowserModule } from './i18n/i18n-browser.module';

@NgModule({
  imports: [
    BrowserModule,
    AppModule,
    I18nBrowserModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    OpenTelemetryModule.forRoot(environment.opentelemetry)
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      deps: [Sentry.TraceService],
      useFactory: () => () => Sentry.TraceService,
      multi: true
    }
  ]
})
export class AppBrowserModule {}
