import { Observable } from 'rxjs';

import { MenuItem } from '../interfaces';

export const translateMenuItems = (
  items: MenuItem[],
  asyncTranslator: (key: string) => Observable<string>
) =>
  items.map((item: MenuItem) => ({
    ...item,
    title$: asyncTranslator(item.titleKey)
  }));
