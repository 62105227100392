import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  constructor(protected httpClient: HttpClient) {}

  getGeolocation(): Observable<any> {
    return this.httpClient.post(
      `https://www.googleapis.com/geolocation/v1/geolocate`,
      {},
      { params: { key: environment.googleMapsApiKey } }
    );
  }
}
