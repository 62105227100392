<div class="header w-100">
  <ng-container *ngIf="isMobile">
    <ct-link [link]="['/my-account/explore']" class="logo-container">
      <img class="logo" alt="logo" src="assets/logos/logo-small.png" loading="lazy" />
    </ct-link>
    <ct-icon
      icon="menu"
      class="ml-4 side-menu"
      [class.side-menu--opened]="sidebarShown"
      (click)="onToggleSidebar()"
    ></ct-icon>
  </ng-container>
  <div class="container">
    <div class="row">
      <div class="col-5 d-flex align-items-center">
        <ng-container *ngIf="!isMobile">
          <ng-content></ng-content>
        </ng-container>
      </div>
      <div class="col-7 d-flex justify-content-end align-items-center">
        <ct-search-autocomplete *ngIf="!isMobile && showSearch" class="search-box mr-2"></ct-search-autocomplete>

        <div class="navbar-buttons">
          <ct-icon
            *ngIf="isMobile"
            class="add-trip__button"
            color="secondary"
            icon="add-circle"
            (click)="trip?.id ? addToTripDialog() : openNewTripDialog()"
          ></ct-icon>
          <ct-link [link]="['/faq']">
            <ct-icon icon="help"></ct-icon>
          </ct-link>
          <ct-link [link]="['/my-account/friends']">
            <ct-icon icon="groups"></ct-icon>
          </ct-link>
          <ct-notifications class="mr-2" [notifications]="notifications || [] | slice : 0 : 10"></ct-notifications>
          <div class="d-flex flex-row align-items-center position-relative" (click)="onToggleHeaderMenu($event)">
            <ct-user-avatar class="user__photo" [src]="user?.photo?.path"></ct-user-avatar>
            <ct-icon icon="down"></ct-icon>
            <nav class="navbar" [ngClass]="headerMenuCollapsed ? 'collapsed' : 'expanded'">
              <div class="navbar-buttons">
                <ct-button
                  *ngFor="let button of userButtons"
                  [variant]="button.variant"
                  [fullWidth]="true"
                  (click)="button.clicked()"
                >
                  <div class="d-flex align-items-center">
                    {{ button.labelKey | translate }}
                  </div>
                </ct-button>
                <ct-button variant="flat" [fullWidth]="true" (click)="onLogout()">
                  <div class="d-flex align-items-center" [ngClass]="logoutButtonClass">
                    {{ 'HEADER.BUTTONS.LOGOUT' | translate }}
                    <ct-icon class="pl-2" icon="exit"></ct-icon>
                  </div>
                </ct-button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
