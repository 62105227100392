import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { fromEvent, Subscription, SubscriptionLike } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { GalleryConfig, GalleryItem } from '../../gallery';
import { LightboxAction } from '../enums';
import { LightboxConfig } from '../interfaces';
import { LightboxService } from './../services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[lightbox]'
})
export class LightboxDirective implements OnInit, OnDestroy {
  public clickEvent: SubscriptionLike = Subscription.EMPTY;

  @Input() galleryItemIndex = 0;
  @Input() galleryId = 'root';
  @Input() galleryItems: GalleryItem[] | undefined = [];
  @Input() lightboxConfig: LightboxConfig | undefined;
  @Input() galleryConfig: GalleryConfig | undefined;

  @Output() galleryItemEdit = new EventEmitter<{ data: any }>();
  @Output() galleryItemClose = new EventEmitter();

  constructor(private lightboxService: LightboxService, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    this.clickEvent = fromEvent(this.el.nativeElement, 'click')
      .pipe(
        switchMap(() =>
          this.lightboxService.open(
            this.galleryItemIndex,
            this.galleryId,
            { panelClass: 'fullscreen', ...this.lightboxConfig },
            this.galleryConfig,
            this.galleryItems
          )
        )
      )
      .subscribe(({ action, data }) => {
        if (action === LightboxAction.Edit) {
          return this.galleryItemEdit.emit(data);
        } else if (action === LightboxAction.Close) {
          return this.galleryItemClose.emit(action);
        }
      });
  }

  ngOnDestroy() {
    this.clickEvent.unsubscribe();
  }
}
