import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { identity, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

const DEBOUNCE_TIME = 500;

interface AutocompleteOption<T = any> {
  label?: string;
  disabled?: boolean;
  value?: T;
}

@Component({
  selector: 'ct-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class AutocompleteComponent<T> extends BaseControlComponent<T> implements OnInit, OnChanges {
  public readonly destroyed$: Observable<void>;
  public readonly control = new UntypedFormControl(null);

  @Input() public readonly placeholder: string;
  @Input() public readonly optional: boolean = true;
  @Input() public readonly hint: boolean = true;
  @Input() public readonly searchOnInit: boolean = false;
  @Input() public readonly label: string;
  @Input() public readonly focusAfterSubmit: boolean = false;

  @Input() public readonly valueFn = identity;
  @Input() public readonly displayFn = identity;
  @Input() public readonly labelFn: (value: T) => T | string = identity;
  @Input() public readonly optionsFn: (search: string) => Observable<T[]>;
  @Input() public readonly minlength: number = 2;
  @Input() public readonly maxlength: number = 100;
  @Input() public prefixIcon: string;
  @Input() public suffixIcon: string;

  @Input() readonly customOptionsTemplate?: TemplateRef<unknown>;
  @Input() public readonly noMatchOption: AutocompleteOption | null;

  public options$: Observable<T[] | null>;

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Output() readonly changed = new EventEmitter<T | string | null>();
  @Output() readonly selected = new EventEmitter<T | string | null>();
  @Output() public suffixIconClicked = new EventEmitter<void>();
  @Output() public prefixIconClicked = new EventEmitter<void>();

  @ViewChild(MatAutocompleteTrigger, {
    static: true
  })
  readonly autocomplete: MatAutocompleteTrigger;
  @ViewChild(MatInput, {
    read: ElementRef,
    static: true
  })
  readonly input: ElementRef<HTMLInputElement>;

  public readonly trackByFn = (_index: number, element: T) => element;
  public readonly viewToModelParser = (value: T | null) => (value ? this.valueFn(value) : null);

  get length() {
    return this.input?.nativeElement.value.length;
  }

  get open() {
    return this.autocomplete.panelOpen;
  }

  get active() {
    return this.input?.nativeElement === document.activeElement;
  }

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.optionsFn || changes.searchOnInit) && this.searchOnInit && this.optionsFn) {
      this.options$ = this.optionsFn('');
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);

    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(DEBOUNCE_TIME),
        filter(() => this.length >= this.minlength),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        const { value } = this.input.nativeElement;
        this.changeDetector.markForCheck();
        this.options$ = this.optionsFn(value);
      });
  }

  onFocus() {
    this.onTouched?.();
  }

  onEnterKeyDown(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    const value = this.valueFn(event.option.value);

    this.closeAutocomplete();
    this.changed.emit(value as T);
    this.selected.emit(value);
  }

  onSelectValue() {
    const { value } = this.control;

    this.changed.emit(value as string);

    return this.closeAutocomplete();
  }

  openAutocomplete() {
    this.autocomplete.openPanel();
    this.input.nativeElement.focus();
  }

  closeAutocomplete() {
    this.autocomplete.closePanel();
    if (this.focusAfterSubmit) {
      this.input.nativeElement.focus();
    } else {
      this.input.nativeElement.blur();
    }
  }

  onSuffixIcon() {
    this.suffixIconClicked.emit();
  }

  onPrefixIcon() {
    this.prefixIconClicked.emit();
  }
}
