import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  TranscodingState,
  VideoUploadEntity
} from '@ct/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ct-video-player-dialog',
  templateUrl: './video-player-dialog.component.html',
  styleUrls: ['./video-player-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class VideoPlayerDialogComponent {
  public static dialogConfig: MatDialogConfig = {
    width: '95vw',
    height: '78vh'
  };
  public readonly destroyed$: Observable<void>;
  protected readonly loadedVideo$: Subject<boolean> = new Subject<boolean>();
  protected video: VideoUploadEntity;

  protected get m3u8() {
    return this.loadedFiles?.find((file) => file.fileName === 'manifest.m3u8')?.publicUrl;
  }

  protected get mpd() {
    return this.loadedFiles?.find((file) => file.fileName === 'manifest.mpd')?.publicUrl;
  }

  private loadedFiles: any[];
  private interval: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: VideoUploadEntity,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.video = this.data;
    this.onPlayVideo();
    if (this.video.transcodingJobStatus !== TranscodingState.SUCCEEDED) {
      this.interval = setInterval(() => this.onPlayVideo(), 5000) as unknown as number;
    }
  }

  decodeUrl(url: string): string {
    return decodeURIComponent(url);
  }

  onPlayVideo() {
    this.videoUploadApiService
      .getFilesByVideoId(this.video.id as string)
      .pipe(takeUntil(this.loadedVideo$))
      .subscribe((files) => {
        this.loadedFiles = files;
        this.cdr.markForCheck();
        if (this.mpd && this.m3u8) {
          this.interval && clearInterval(this.interval);
          this.interval = 0;
          this.loadedVideo$.next(true);
          this.loadedVideo$.complete();
        }
      });
  }
}
