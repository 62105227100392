import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterItem, SpinnerService } from '@ct/components';
import { FriendStatus, RelationUserProfile, UserProfile } from '@ct/core';
import { EntityType, getCroppedThumbPublicUrl, ShareApiService, Trip } from '@ct/shared';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { FriendsQuery } from '../../../../services';

enum FriendsStatus {
  AvailableFriends = 'available-friends',
  FriendsWithAccess = 'friends-with-access'
}

@Component({
  selector: 'ct-share-trip-dialog',
  templateUrl: './share-trip-dialog.component.html',
  styleUrls: ['./share-trip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareTripDialogComponent implements OnInit {
  public readonly friendsStatus = FriendsStatus;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  public filters: FilterItem[] = [
    { name: FriendsStatus.AvailableFriends, labelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARE.AVAILABLE_FRIENDS' },
    { name: FriendsStatus.FriendsWithAccess, labelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARED_BY_ME' }
  ];
  public activeFilter: FriendsStatus = FriendsStatus.AvailableFriends;
  public trip: Trip;
  public linkControl: UntypedFormControl = new UntypedFormControl();
  public activeFriends$: Observable<RelationUserProfile[]> = this.query.selectAll({
    // TODO filter friends who have access already
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });
  public availableFriends: UserProfile[] = [];
  public friendsWithAccess: UserProfile[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { trip: Trip },
    private query: FriendsQuery,
    private shareApiService: ShareApiService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.trip = this.data.trip;
    this.activeFriends$.pipe(take(1)).subscribe((friends) => {
      this.availableFriends = friends.filter(
        ({ userId }) => !this.friendsWithAccess.some(({ userId: friendId }) => userId === friendId)
      );
      this.cdr.detectChanges();
    });
    this.spinnerService.show();
    this.shareApiService
      .getAllFriendsWithAccess({ entityType: EntityType.Trip, entityId: this.trip?.id })
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe((friends) => {
        this.friendsWithAccess = friends;
        this.availableFriends = this.availableFriends.filter(
          ({ userId }) => !this.friendsWithAccess.some(({ userId: friendId }) => userId === friendId)
        );
        this.cdr.detectChanges();
      });
  }

  onShare(user: UserProfile) {
    this.spinnerService.show();
    this.shareApiService
      .shareEntity(user.userId, this.trip.id as string, EntityType.Trip)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(() => {
        this.friendsWithAccess = [...this.friendsWithAccess, user];
        this.availableFriends = this.availableFriends.filter(({ userId }) => userId !== user.userId);
        this.cdr.detectChanges();
      });
  }

  onRemove(user: UserProfile) {
    this.spinnerService.show();
    this.shareApiService
      .removeEntity(user.userId, this.trip.id as string)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(() => {
        this.availableFriends = [...this.availableFriends, user];
        this.friendsWithAccess = this.friendsWithAccess.filter(({ userId }) => userId !== user.userId);
        this.cdr.detectChanges();
      });
  }

  onFilterChanged(filter: FilterItem) {
    this.activeFilter = filter.name;
  }
}
