import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { SpinnerOverlayComponent } from '../components';

const DELAY = 1500;

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private overlayRef: OverlayRef | null = null;
  private isLoading = new Subject<{ loading: boolean; instant?: boolean }>();

  constructor(private overlay: Overlay) {
    this.isLoading
      .pipe(switchMap(({ loading, instant }) => (instant ? of(loading) : of(loading).pipe(debounceTime(DELAY)))))
      .subscribe((value) => (value ? this.attachSpinner() : this.detachSpinner()));
  }

  public show(options?: { instant: boolean }) {
    this.isLoading.next({ loading: true, instant: options?.instant });
  }

  public hide() {
    this.isLoading.next({ loading: false });
  }

  private attachSpinner() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    if (this.overlayRef.hasAttached()) {
      return;
    }

    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    this.overlayRef.attach(spinnerOverlayPortal);
  }

  private detachSpinner() {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
