import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

import { FacebookCard, FacebookCardType, SocialMedia, TwitterCard, TwitterCardType } from '../interfaces';
import { TitleService } from '../services/title.service';

const SOCIAL_MEDIA_TAG = {
  FACEBOOK_TYPE: 'og:type',
  FACEBOOK_TITLE: 'og:title',
  FACEBOOK_DESCRIPTION: 'og:description',
  FACEBOOK_URL: 'og:url',
  FACEBOOK_IMAGE: 'og:image',
  TWITTER_CARD: 'twitter:card',
  TWITTER_TITLE: 'twitter:title',
  TWITTER_SITE: 'twitter:site',
  TWITTER_DESCRIPTION: 'twitter:description',
  TWITTER_IMAGE: 'twitter:image',
  TWITTER_IMAGE_ALT: 'twitter:image:alt',
  DESCRIPTION: 'description'
};

@Injectable({
  providedIn: 'root'
})
export class MetaTagService extends Meta {
  constructor(@Inject(DOCUMENT) public document: any, private titleService: TitleService) {
    super(document);
  }

  /*
    Facebook card docs:

    og:title - The title of your article without any branding such as your site name.
    og:description - A brief description of the content, usually between 2 and 4 sentences. This will displayed below the title of the post on Facebook.
    og:type - The type of media of your content. This tag impacts how your content shows up in News Feed. If you don't specify a type,the default is website
    og:url - The canonical URL for your page
    og:image - The URL of the image that appears when someone shares the content to Facebook.

    Ref: https://developers.facebook.com/docs/sharing/webmasters/
  */
  setFacebook({ title, description, type, url, image, titleSuffix }: FacebookCard) {
    titleSuffix = titleSuffix || this.titleService.getDefaultSuffix();
    const tags = [
      { property: SOCIAL_MEDIA_TAG.FACEBOOK_TYPE, content: type || FacebookCardType.Article },
      { property: SOCIAL_MEDIA_TAG.FACEBOOK_TITLE, content: title + titleSuffix }
    ];

    if (description) {
      tags.push({ property: SOCIAL_MEDIA_TAG.FACEBOOK_DESCRIPTION, content: description as string });
    }

    if (url) {
      tags.push({ property: SOCIAL_MEDIA_TAG.FACEBOOK_URL, content: url as string });
    }

    if (image) {
      tags.push({ property: SOCIAL_MEDIA_TAG.FACEBOOK_IMAGE, content: image as string });
    }

    this.updateTags(tags);
  }

  /*
    Twitter card docs:

    twitter:card - Must be set to a value of “summary”
    twitter:site - The Twitter @username the card should be attributed to.
    twitter:title - A concise title for the related content.
    twitter:description - A description that concisely summarizes the content as appropriate for presentation within a Tweet.
    twitter:image - A URL to a unique image representing the content of the page.
    twitter:image:alt - A text description of the image conveying the essential nature of an image to users who are visually impaired.

    Ref: https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/summary
  */
  setTwitter({ card, site, title, description, image, imageAlt, titleSuffix }: TwitterCard) {
    titleSuffix = titleSuffix || this.titleService.getDefaultSuffix();
    const tags = [
      { name: SOCIAL_MEDIA_TAG.TWITTER_CARD, content: (card as string) || TwitterCardType.Summary },
      { name: SOCIAL_MEDIA_TAG.TWITTER_TITLE, content: (title + titleSuffix) as string }
    ];

    if (site) {
      tags.push({ name: SOCIAL_MEDIA_TAG.TWITTER_SITE, content: site as string });
    }

    if (description) {
      tags.push({ name: SOCIAL_MEDIA_TAG.TWITTER_DESCRIPTION, content: description as string });
    }

    if (image) {
      tags.push({ name: SOCIAL_MEDIA_TAG.TWITTER_IMAGE, content: image as string });
    }

    if (imageAlt) {
      tags.push({ name: SOCIAL_MEDIA_TAG.TWITTER_IMAGE_ALT, content: imageAlt as string });
    }

    this.updateTags(tags);
  }

  setSocialMedia({ title, description, twitterCardType, facebookCardType, image }: SocialMedia) {
    this.setFacebook({ title, description, type: facebookCardType, image });
    this.setTwitter({ card: twitterCardType, title, description, image });
  }

  updateTags(tags: MetaDefinition[] = []) {
    tags.forEach((tag) => this.updateTag(tag));
  }

  removeTags(attributes: string[]) {
    attributes.forEach((attr) => this.removeTag(attr));
  }

  removeSocialMediaTags() {
    Object.values(SOCIAL_MEDIA_TAG).forEach((value) =>
      value.startsWith('twitter') ? this.removeTag(`name="${value}"`) : this.removeTag(`property="${value}"`)
    );
  }

  setMetaDescription(text: string) {
    this.updateTag({ name: SOCIAL_MEDIA_TAG.DESCRIPTION, content: text });
  }

  addKeywords(keywords?: string) {
    const defaultKeywords = `Camping Trip Ideas, Camping Journal, Camping Tools, rv camping, camp grounds, camping journal book, best camping trips, camping trips near you, campfires, camping trip planner, rv camping journal, campsites, tents, trailers, camping marketplace, travel blogs`;
    this.updateTag({
      name: 'keywords',
      content: keywords ? `${defaultKeywords}, ${keywords}` : defaultKeywords
    });
  }
}
