import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { default as PhoneNumber } from 'awesome-phonenumber';

export function PhoneNumberExtendedValidator(countryCode?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    const isValid = new PhoneNumber(value, countryCode).isValid();

    if (isValid) {
      return null;
    }

    return { phone: true };
  };
}
