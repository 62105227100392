import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { LinkModule } from '../link';
import { SidebarSectionComponent, SidebarSectionItemComponent } from './components';

@NgModule({
  declarations: [SidebarSectionComponent, SidebarSectionItemComponent],
  imports: [CommonModule, LinkModule, TranslateModule, PipesModule],
  exports: [SidebarSectionComponent, SidebarSectionItemComponent]
})
export class SidebarModule {}
