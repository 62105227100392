<div class="row" *ngIf="items?.length">
  <ct-my-account-marketplace-item
    class="col-12 col-md-4"
    *ngFor="let item of items"
    [item]="item"
    (clicked)="click(item?.id)"
  ></ct-my-account-marketplace-item>
</div>
<p *ngIf="!items?.length" class="flex-center">
  <span>{{ 'COMMON.EMPTY' | translate }}</span>
</p>
