import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService, SpinnerService, TabHeader } from '@ct/components';
import {
  EnrichedExploreTimeline,
  FriendStatus,
  GroupTimelineType,
  RelationUserProfile,
  TitleConfig,
  UserProfile
} from '@ct/core';
import {
  CreateTripDialogComponent,
  EntityType,
  ExploreApiService,
  getCroppedThumbPublicUrl,
  LikeApiService,
  trackById,
  TripJournalEntry
} from '@ct/shared';
import { WritingJournalAddDialogComponent } from '@ct/shared/modules/add-to-writings-dialog';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';
import { filter, finalize, first, take } from 'rxjs/operators';

import { MY_ACCOUNT_EXPLORE_FEATURE_LIMIT } from '../../../../constants';
import { FriendsQuery } from '../../../../services/store/friends.query';
import { UserRelationsApiService } from '../../../../services/user-relations-api.service';

export enum ExploreStatus {
  All = 'all',
  Trips = 'trips',
  Groups = 'groups'
}

interface ExploreQueryParams {
  offset: number;
  filter: ExploreStatus;
}

const DEFAULT_OFFSET = 0;
const DEFAULT_FILTER = ExploreStatus.All;

@Component({
  selector: 'ct-my-account-explore',
  templateUrl: './my-account-explore.component.html',
  styleUrls: ['./my-account-explore.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountExploreComponent implements OnInit {
  public readonly limit = MY_ACCOUNT_EXPLORE_FEATURE_LIMIT;

  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_TRIPS'
  };

  public queryParams: ExploreQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER
  };

  public tabs: TabHeader[] = [
    { name: ExploreStatus.All, labelKey: 'All', selected: true },
    { name: ExploreStatus.Trips, labelKey: 'Friends' },
    { name: ExploreStatus.Groups, labelKey: 'Groups' }
  ];

  public hasContent: boolean;
  public timelines: EnrichedExploreTimeline[] = [];
  public readonly timelineType = GroupTimelineType;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public loading = false;
  public showLoadButton = true;

  protected readonly trackById = trackById;
  protected readonly EntityType = EntityType;

  get isMyTripsTab(): boolean {
    return this.queryParams.filter === ExploreStatus.All;
  }

  activeCommentsEntityId: string | null;

  private friends$: Observable<RelationUserProfile[]> = this.friendsQuery.selectAll({
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private exploreApiService: ExploreApiService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private friendsQuery: FriendsQuery,
    private likeApiService: LikeApiService,
    private userRelationsApiService: UserRelationsApiService
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe(({ offset, filter }) => {
      this.queryParams = {
        ...this.queryParams,
        filter: filter ?? this.queryParams.filter,
        offset: +offset || this.queryParams.offset
      };
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        selected: tab.name === this.queryParams.filter
      }));
      this.loadData(this.queryParams);
    });

    this.friends$.pipe(first()).subscribe((friends) => {
      if (!friends.length) {
        this.userRelationsApiService.getAllMyFriends().subscribe();
      }
    });
  }

  addTrip() {
    this.dialogService.open(CreateTripDialogComponent);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadData(this.queryParams);
  }

  addMemory(): void {
    this.dialogService
      .open(WritingJournalAddDialogComponent, {
        data: {
          friends$: this.friends$
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.queryParams = {
          ...this.queryParams,
          offset: DEFAULT_OFFSET
        };
        this.loadData(this.queryParams);
      });
  }

  onFilterChanged({ name }: TabHeader) {
    if (name === this.queryParams.filter) {
      return;
    }
    this.queryParams = {
      ...this.queryParams,
      offset: DEFAULT_OFFSET,
      filter: name as ExploreStatus
    };
    this.tabs = this.tabs.map((tab) => ({
      ...tab,
      selected: tab.name === name
    }));
    this.loadData(this.queryParams);
  }

  loadData(params: ExploreQueryParams) {
    return this.loadEntities(params).subscribe((entities) => this.refreshEntities(entities));
  }

  loadEntities({ offset, filter }: ExploreQueryParams) {
    this.spinnerService.show();
    this.loading = true;
    let action$: Observable<EnrichedExploreTimeline[]>;
    switch (filter) {
      case ExploreStatus.Trips:
        action$ = this.exploreApiService.getTripsTimeline({ limit: this.limit, offset });
        break;
      case ExploreStatus.Groups:
        action$ = this.exploreApiService.getGroupsTimeline({ limit: this.limit, offset });
        break;
      case ExploreStatus.All:
      default:
        action$ = this.exploreApiService.getAll({ limit: this.limit, offset });
        break;
    }
    return action$.pipe(
      finalize(() => {
        this.loading = false;
        this.spinnerService.hide();
      })
    );
  }

  toggleComments(entity: EnrichedExploreTimeline) {
    this.activeCommentsEntityId === entity.id
      ? (this.activeCommentsEntityId = null)
      : (this.activeCommentsEntityId = entity?.id ?? null);
    this.changeDetectorRef.markForCheck();
  }

  getEntityType(entity: EnrichedExploreTimeline): EntityType {
    if (entity.groupId && entity.id === entity.metadataId) {
      return EntityType.GroupTimeline;
    }

    const type = entity.type;

    switch (type) {
      case GroupTimelineType.Journal:
        return EntityType.JournalEntry;
      default:
        return type as unknown as EntityType;
    }
  }

  refreshEntities(entities: EnrichedExploreTimeline[]) {
    if (this.queryParams.offset === 0) {
      this.timelines = [...entities];
    } else {
      this.timelines.push(...entities);
    }
    this.showLoadButton = !(entities.length === 0 || entities.length < this.limit);
    this.loading = false;
    this.hasContent = Boolean(this.timelines?.length);
    this.changeDetectorRef.detectChanges();

    if (!this.queryParams.offset) {
      // Refresh route only when filter changes in order to avoid flickering and scroll jumping to the top of the page
      this.refreshUrlQueryParams(this.queryParams);
    }
  }

  refreshUrlQueryParams({ filter }: ExploreQueryParams) {
    this.router
      .navigate([], {
        queryParams: { filter },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
      .then(() => this.changeDetectorRef.detectChanges());
  }

  toJournalEntry(entity: TripJournalEntry | undefined): TripJournalEntry {
    return entity as TripJournalEntry;
  }

  getTripLink(timeline: EnrichedExploreTimeline): string[] {
    return ['/my-account/trips', timeline.entityId as string, timeline.trip?.slug as string, 'timeline'];
  }

  getStoryLink(timeline: EnrichedExploreTimeline): string {
    const slug = timeline.story?.slug as string;
    const slugArray = slug.split('-');
    const id = slugArray[slugArray.length - 1];
    return [
      '/stories',
      id === String(timeline.entityId) ? (timeline.story?.slug as string) : `${slug}-${timeline.entityId}`
    ].join('/');
  }

  onLike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .like({
        entityId: entity.metadataId as string,
        entityType: EntityType.GroupTimeline
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  onDislike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .dislike({
        entityId: entity.metadataId as string,
        userId: this.authQuery.profile?.userId as string
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }
}
