<ct-entity-item
  class="comment"
  [body]="comment?.body"
  [userPhoto]="thumbnail"
  [username]="comment?.author?.username"
  [createdOn]="comment?.createdOn"
  [modifiedOn]="comment?.modifiedOn"
  [editable]="isAuthor"
  [deletable]="isAuthor"
  (edited)="edited.emit()"
  (deleted)="deleted.emit()"
>
</ct-entity-item>
