import { DOCUMENT } from '@angular/common';
import { Directive, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { fromEvent, Observable, Subject, Subscription, zip } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[lazyImage]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LazyImage implements OnChanges, OnDestroy {
  private imageLoader$ = new Subject<string>();
  private loaderSub$ = Subscription.EMPTY;

  @Input('lazyImage') src: string;

  @Output() loaded = new EventEmitter<string>();
  @Output() errorItem = new EventEmitter<Error>();

  constructor(@Inject(DOCUMENT) private document: any) {
    this.loaderSub$ = this.imageLoader$.pipe(switchMap((imageSrc: string) => this.nativeLoader(imageSrc))).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['src'] && changes['src'].previousValue !== changes['src'].currentValue) {
      this.loadImage(this.src);
    }
  }

  ngOnDestroy() {
    this.loaderSub$.unsubscribe();
    this.imageLoader$.complete();
  }

  loadImage(imagePath: string) {
    this.imageLoader$.next(imagePath);
  }

  /**
   * Native image loader, does not emit progress
   * @param url
   */

  nativeLoader(url: string): Observable<any> {
    const img = this.document.createElement('img');
    // Stop previously loading
    img.src = url;
    // Image load success
    const loadSuccess = fromEvent(img, 'load').pipe(tap(() => this.loaded.emit(url)));
    // Image load failed
    const loadError = fromEvent(img, 'error').pipe(
      tap(() => this.errorItem.emit(new Error(`[lazyImage]: The image ${url} did not load`)))
    );
    return zip(loadSuccess, loadError);
  }
}
