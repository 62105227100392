import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { DEFAULT_DIALOG_CONFIG } from '../constants';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  public open<T, R = any>(component: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig): MatDialogRef<T, R> {
    const dialogRef: MatDialogRef<T, R> = this.matDialog.open<T>(component, {
      ...DEFAULT_DIALOG_CONFIG,
      ...(component as any)?.dialogConfig,
      ...config
    });
    return dialogRef;
  }

  public closeAll() {
    this.matDialog.closeAll();
  }
}
