import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MARKETPLACE_ITEMS_LIMIT } from '../constants';
import { MarketplaceItem } from '../interfaces';
import { MarketplaceApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MarketplaceMyItemsDataResolver implements Resolve<MarketplaceItem[]> {
  constructor(private marketplaceApiService: MarketplaceApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = (activatedRouteSnapshot.queryParams?.offset as number) || 0;
    const merchantId = activatedRouteSnapshot.queryParams?.merchantId as string;
    const categoryId = activatedRouteSnapshot.queryParams?.categoryId as string;
    const subCategoryId = activatedRouteSnapshot.queryParams?.subCategoryId as string;
    return this.marketplaceApiService.getAllMyItems({
      range: { limit: MARKETPLACE_ITEMS_LIMIT, offset },
      merchantId,
      categoryId,
      subCategoryId,
      type: 'list'
    });
  }
}
