import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthQuery, AuthService } from '@ct/auth';
import {
  CanonicalConfig,
  CanonicalFeature,
  FacebookCardType,
  Features,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-marketplace-home',
  templateUrl: './marketplace-home.component.html',
  styleUrls: ['./marketplace-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature()])
export class MarketplaceHomeComponent {
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MARKETPLACE.IMG_TITLE',
      suffixKey: 'MAIN.DESCRIPTION_SHORT'
    },
    socialMediaConfig: {
      titleKey: 'MARKETPLACE.IMG_TITLE',
      titleSuffixKey: 'MAIN.DESCRIPTION_SHORT',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/home.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    }
  };
  public canonicalConfig: CanonicalConfig = {
    canonicalUrl: '/marketplace'
  };

  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}
}
