<mat-form-field appearance="outline">
  <input
    matInput
    [formControl]="control"
    [placeholder]="label || placeholder"
    [matAutocomplete]="autocomplete"
    [maxlength]="maxlength"
    (keydown.enter)="onEnterKeyDown($event)"
    (keyup.enter)="onSelectValue()"
    (focus)="onFocus()"
  />
  <mat-autocomplete #autocomplete (optionSelected)="onSelectOption($event)" [displayWith]="displayFn">
    <ng-container *ngIf="options$ | async as options">
      <ng-container *ngIf="options && !options.length && length >= minlength">
        <mat-option
          *ngIf="noMatchOption; else defaultNoMatchOption"
          class="mat-no-match-option"
          [disabled]="noMatchOption?.disabled"
          [value]="noMatchOption?.value"
        >
          <ng-container> {{ noMatchOption.label }} </ng-container>
        </mat-option>
        <ng-template #defaultNoMatchOption>
          <mat-option class="mat-no-match-option" [disabled]="true">
            <ng-container> {{ 'COMMON.VALIDATIONS.NO_MATCH_FOUND' | translate }} </ng-container>
          </mat-option>
        </ng-template>
      </ng-container>

      <ng-template #simpleOptions>
        <ng-container *ngFor="let option of options; trackBy: trackByFn">
          <mat-option [value]="option">
            {{ labelFn(option) }}
          </mat-option>
        </ng-container>
      </ng-template>

      <ng-container *ngIf="customOptionsTemplate; else simpleOptions">
        <mat-option *ngFor="let option of options; trackBy: trackByFn" [value]="option">
          <ng-container
            [ngTemplateOutlet]="customOptionsTemplate"
            [ngTemplateOutletContext]="{ option: option }"
          ></ng-container>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
  <mat-icon *ngIf="prefixIcon" matPrefix (click)="onPrefixIcon()">{{ prefixIcon }}</mat-icon>
  <mat-icon *ngIf="suffixIcon" matSuffix (click)="onSuffixIcon()">{{ suffixIcon }}</mat-icon>
  <mat-hint *ngIf="hint && active && length < minlength">
    {{ 'COMMON.VALIDATIONS.MIN_LENGTH' | translate : { length: minlength } }}
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'COMMON.VALIDATIONS.REQUIRED' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('nomatch')">
    {{ 'COMMON.VALIDATIONS.NO_MATCH' | translate }}
  </mat-error>
</mat-form-field>
