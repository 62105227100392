import { Pipe, PipeTransform } from '@angular/core';
import * as striptags from 'striptags';

@Pipe({
  name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string | undefined): string {
    return value ? striptags(value) : '';
  }
}
