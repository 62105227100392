<ng-container *ngIf="!simple && (isLoggedIn$ | async); else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div [class.container]="!simple">
    <div class="row">
      <div class="col-12">
        <h1 *ngIf="!simple" class="text-center">{{ 'COMMON.FAQ.TITLE' | translate }}</h1>
        <section class="how-to-videos">
          <article>
            <h2>How-To Videos</h2>
            <mat-accordion>
              <mat-expansion-panel
                *ngFor="let video of videos; let i = index"
                (afterCollapse)="onCollapse(iframeVideo)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> {{ i + 1 }}. {{ video.titleKey | translate }} </mat-panel-title>
                </mat-expansion-panel-header>
                <iframe
                  #iframeVideo
                  class="welcome-video"
                  [src]="video.videoLinkKey | translate | safe : 'resourceUrl'"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </mat-expansion-panel>
            </mat-accordion>
          </article>
        </section>
      </div>
    </div>
  </div>
</ng-template>
