<section class="comment">
  <ct-user-avatar class="photo" height="48px" width="48px" [src]="userPhoto"></ct-user-avatar>
  <div class="content">
    <div class="headline">
      <span class="username">{{ username }}</span>
      <span class="created"
        >{{ isModified ? ('MY_ACCOUNT.MY_POSTS_FEATURE.EDITED' | translate) : '' }}
        {{ (isModified ? modifiedOn : createdOn) | dateTime : 'MMM. d, y AT h:mm a' | uppercase }}</span
      >
    </div>
    <ct-text-editor-view class="body" [content]="body"></ct-text-editor-view>
    <div class="actions-wrapper">
      <span *ngIf="reply" class="reply mr-2" (click)="replied.emit()">{{
        'MY_ACCOUNT.MY_POSTS_FEATURE.REPLY' | translate
      }}</span>
      <span *ngIf="editable" class="action mr-2" (click)="edited.emit()"> {{ 'COMMON.EDIT' | translate }}</span>
      <span *ngIf="deletable" class="action mr-2" (click)="deleted.emit()">{{ 'COMMON.DELETE' | translate }}</span>
    </div>
  </div>
</section>
<div class="children">
  <ng-content></ng-content>
</div>
