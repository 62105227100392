import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@ct/auth';
import { DialogService, ShareDialogComponent } from '@ct/components';
import { BlogPost, BlogPostWithFeaturedImage, UserProfile } from '@ct/core';
import { environment } from '@ct/environment';
import { BlogPostApiService, BlogPostPhotoViewType, entitySlugUrl, ImageUploadApiService } from '@ct/shared';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ct-blog-post-view',
  templateUrl: './blog-post-view.component.html',
  styleUrls: ['./blog-post-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostViewComponent {
  public currentUser$: Observable<UserProfile | null> = this.authService
    .getUserProfile()
    .pipe(catchError(() => of(null)));
  public photoViewType = BlogPostPhotoViewType.Gallery;
  public post: BlogPostWithFeaturedImage;

  get isLoggedIn$(): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => !!user));
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private blogPostApiService: BlogPostApiService,
    private imageUploadApiService: ImageUploadApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.route.data.pipe(map(({ post }) => post)).subscribe((post) => {
      this.post = post;
      this.cdr.markForCheck();
    });
  }

  onRefresh() {
    this.blogPostApiService
      .getById(this.post.id as string)
      .pipe(
        switchMap((post) => {
          if (post?.featuredPhotoId) {
            return this.imageUploadApiService.getMetadata(post.featuredPhotoId).pipe(
              map((metadata) => ({
                ...post,
                featuredPhotoTitle: metadata?.photoTitle,
                featuredPhotoKeywords: metadata?.keywords
              })),
              catchError((err) => {
                console.error(err);
                return of(post as BlogPostWithFeaturedImage);
              })
            );
          }
          return of(post as BlogPostWithFeaturedImage);
        })
      )
      .subscribe({
        next: (post) => {
          this.post = post;
          this.cdr.markForCheck();
        },
        error: () => this.router.navigate(['/stories'])
      });
  }

  shareStory(item: BlogPost) {
    const url: string = this.getSharingUrl(item);

    this.dialogService.open(ShareDialogComponent, {
      data: {
        url: url || globalThis.location.href,
        text: item?.title,
        disabled: false
      },
      width: '300px'
    });
  }

  private getSharingUrl(item: BlogPost): string {
    // TODO: window fix for SSR, should be replaced with APP level injection.
    let location = undefined;
    if (typeof globalThis !== 'undefined' && globalThis?.location) {
      location = globalThis.location.origin;
    }
    const baseUrl = location || environment.webUrl;

    return entitySlugUrl(`${baseUrl}/stories/`, item);
  }
}
