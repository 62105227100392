<div class="filter-container">
  <ct-filter-pane
    class="filter-pane"
    [filters]="filters"
    [activeFilter]="queryParams.filter"
    (filterChanged)="onFilterChanged($event)"
  ></ct-filter-pane>
  <div class="d-flex">
    <div class="mt-3 mr-3">{{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.SORT_BY' | translate }}</div>
    <ct-select [formControl]="sortControl" [options]="sortOptions" [optional]="false"></ct-select>
  </div>
</div>
<div class="d-flex justify-content-between align-items-center select-actions">
  <div class="d-flex mb-3 mb-md-0">
    <ct-toggle [formControl]="toggleSelectionControl">{{
      'MY_ACCOUNT.MY_PHOTOS_FEATURE.TOGGLE_SELECTION' | translate
    }}</ct-toggle>
    <ct-filter-pane
      *ngIf="multiselect"
      class="ml-2 flex-shrink-0"
      [filters]="selectionFilters"
      (filterChanged)="onSelectionFilterChanged($event)"
    ></ct-filter-pane>
  </div>
  <div *ngIf="someSelected && multiselect" class="d-flex">
    <ct-button variant="stroked" color="warn" (click)="onDeletePhotos()">
      <span>{{ 'COMMON.DELETE' | translate }} ({{ selected.length }})</span>
    </ct-button>
    <ct-button variant="stroked" color="primary" (click)="onBulkAccessChanged(true)" class="ml-md-2">
      <span>{{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE' | translate }} ({{ selected.length }})</span>
    </ct-button>
    <ct-button variant="stroked" color="primary" (click)="onBulkAccessChanged(false)" class="ml-md-2">
      <span>{{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC' | translate }} ({{ selected.length }})</span>
    </ct-button>
  </div>
</div>
<ct-photo-grid
  [photos]="photos"
  [showPlaceholder]="true"
  [editable]="true"
  [showAccess]="true"
  [multiselect]="multiselect"
  (placeholderSelected)="onAddPhoto()"
  (edit)="onEditPhoto($event)"
  (accessChanged)="onAccessChanged($event)"
  (selectionChanged)="onSelectionChanged($event)"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
></ct-photo-grid>
<div *ngIf="showLoadButton" class="load-more" [ngClass]="{ disabled: loading }" (click)="onScroll()" disabled="loading">
  Load More
</div>
