import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { identity, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'ct-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class TagSelectComponent<T extends { id?: string; name?: string }>
  extends BaseControlComponent<T>
  implements OnInit
{
  public readonly destroyed$: Observable<void>;
  public readonly control = new UntypedFormControl([]);
  public readonly autocompleteControl = new UntypedFormControl(null);

  @Input() public readonly placeholder: string = '';
  @Input() public readonly label: string = '';
  @Input() public readonly minlength: number = 2;
  @Input() public readonly maxlength: number = 120;
  @Input() public readonly canFilter: boolean = true;
  @Input() public readonly canAdd: boolean = true;
  @Input() public readonly searchOnInit: boolean = false;
  @Input() public readonly optionsFn: (search: string) => Observable<T[]>;
  @Input() public readonly checkFn: (search: string) => Observable<T>;
  @Input() public readonly createFn: (name: string) => Observable<T>;
  @Input() public readonly labelFn: (value: T) => T | string = identity;
  @Input() public readonly valueFn = identity;
  @Input() public readonly displayFn = identity;

  public get noMatchOption() {
    return this.canAdd
      ? {
          label: this.translateService.instant('COMPONENTS.TAG_SELECT.NO_MATCH', {
            tag: this.autocompleteControl.value
          }),
          value: { name: this.autocompleteControl.value },
          disabled: false
        }
      : null;
  }

  constructor(
    @Optional() @Inject(NgControl) public readonly ctrl: FormControlName,
    @Optional() public readonly formState: FormStateDispatcher | null,
    public readonly changeDetector: ChangeDetectorRef,
    public translateService: TranslateService
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  changed(value: T | string | null) {
    this.clearInput();

    // if value added by Enter
    if (typeof value === 'string') {
      return this.checkFn(value)
        .pipe(switchMap((tag) => (tag || !this.canAdd ? of(tag) : this.createFn(value))))
        .subscribe((tag: T) => this.update(this.control.value, tag));
    }

    // if selected "no match found" option
    if (!value?.id) {
      if (!this.canAdd) {
        return;
      }
      return this.createFn(value?.name as string).subscribe((tag) => this.update(this.control.value, tag));
    }

    // if selected existing option
    this.update(this.control.value, { ...value });
  }

  update(tags: T[] = [], tag: T) {
    if (!tag) {
      return;
    }
    const duplicate = tags?.find((item: T) => item.id === tag.id);
    if (duplicate) {
      return;
    }
    this.control.patchValue([...(tags || []), tag]);
    this.changeDetector.detectChanges();
  }

  removed(tag: T) {
    const tags = this.control.value?.filter((item: T) => item.name !== tag.name);
    this.control.patchValue(tags);
    this.changeDetector.detectChanges();
  }

  clearInput() {
    this.autocompleteControl.patchValue(undefined);
  }
}
