import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlogPost } from '@ct/core';
import { entitySlugUrl } from '@ct/shared';

@Component({
  selector: 'ct-my-account-overview-posts',
  templateUrl: './my-account-overview-posts.component.html',
  styleUrls: ['./my-account-overview-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountOverviewPostsComponent {
  @Input() posts: BlogPost[] | undefined;

  getLink(post: BlogPost) {
    return entitySlugUrl(`../posts/view/`, post);
  }
}
