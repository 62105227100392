<div class="d-flex justify-content-between align-items-end">
  <div class="flex-center mb-1">
    <ct-image *ngIf="image" class="image" [src]="image"></ct-image>
    <h2 *ngIf="title" class="title">{{ title }}</h2>
  </div>
  <ct-link *ngIf="extraActionLabel" [link]="extraActionLink" [queryParams]="extraActionQueryParams">
    <div class="extra-action">{{ extraActionLabel }}</div>
  </ct-link>
  <ng-content select="[ctCardHeader]"></ng-content>
</div>
<div *ngIf="showLine" class="line"></div>
<ng-content></ng-content>
