import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { getBiggerCroppedPublicUrl } from '@ct/shared/helpers';

import { ImageLoading } from '../../enums';

const DEFAULT_IMAGE_PLACEHOLDER = 'assets/placeholders/no-image-placeholder-1280x800.png';
const DEFAULT_IMAGE_PLACEHOLDER_THUMBNAIL = 'assets/placeholders/no-image-placeholder-100x66.jpg';

@Component({
  selector: 'ct-progressive-image',
  templateUrl: './progressive-image.component.html',
  styleUrls: ['./progressive-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressiveImageComponent implements AfterViewInit, OnChanges {
  @Input() publicUrl: string | undefined;
  @Input() thumbnail: string | undefined;
  @Input() height = '100%';
  @Input() width = '100%';
  @Input() preserveAspectRatio = true;
  @Input() objectFit = 'cover';
  @Input() backgroundRepeat: boolean;
  @Input() noImagePlaceholder = false;
  @Input() set noImagePlaceholderPath(value: string) {
    this._noImagePlaceholderPath = value;
  }
  get noImagePlaceholderPath(): string {
    return this._noImagePlaceholderPath ?? DEFAULT_IMAGE_PLACEHOLDER;
  }

  get optimizedPublicUrl(): string {
    if (this.publicUrl?.includes('img.youtube') || this.publicUrl?.includes('transcoding-output')) {
      return this.publicUrl;
    }
    return getBiggerCroppedPublicUrl(this.publicUrl, { width: this.width, height: this.height });
  }
  @Input() loading: ImageLoading = ImageLoading.Auto;
  @Input() altText: string | undefined;

  @Output() thumbnailLoaded = new EventEmitter();
  @Output() imageLoaded = new EventEmitter();

  private originalImageContainer: HTMLElement;
  private originalImage: HTMLImageElement;
  private thumbnailImageContainer: HTMLElement;
  private thumbnailImage: HTMLImageElement;
  private _noImagePlaceholderPath = DEFAULT_IMAGE_PLACEHOLDER;

  constructor(public elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.publicUrl?.currentValue !== changes.publicUrl?.previousValue) {
      this.loadImage();
    }
  }

  ngAfterViewInit() {
    this.loadImage();
  }

  async loadImage() {
    const container: HTMLElement = this.elementRef.nativeElement.querySelector('.progressive-img__container');
    if (await this.shouldLoadBgImage()) {
      Array.from(container.children).forEach((child) => this.renderer.removeChild(container, child));
      container.classList.add('progressive-img__container--background');
      container.style.backgroundImage = `url(${this.noImagePlaceholderPath})`;
    } else {
      container.classList.remove('progressive-img__container--background');
      container.style.backgroundImage = '';
      this.loadThumbnailImage();
      this.loadOriginalImage();
    }
  }

  loadThumbnailImage() {
    const container: HTMLElement = this.elementRef.nativeElement.querySelector('.progressive-img__container');
    if (!this.thumbnailImageContainer) {
      this.thumbnailImageContainer = this.renderer.createElement('div');
      this.thumbnailImageContainer.classList.add('thumbnail-container');

      this.renderer.appendChild(container, this.thumbnailImageContainer);
    }

    if (!this.thumbnailImage) {
      this.thumbnailImage = this.renderer.createElement('img');
      this.thumbnailImage.loading = 'lazy';
      this.thumbnailImage.classList.add('blur-image');
    } else if (this.thumbnailImageContainer.hasChildNodes()) {
      this.renderer.removeChild(this.thumbnailImageContainer, this.thumbnailImage);
    }

    if (this.preserveAspectRatio) {
      this.thumbnailImage.style.objectFit = this.objectFit || 'cover';
    }
    this.thumbnailImage.style.height = this.height;
    this.thumbnailImage.style.width = this.width;
    this.thumbnailImage.alt = this.altText as string;

    this.thumbnailImage.onload = (event: Event) => {
      this.renderer.appendChild(this.thumbnailImageContainer, this.thumbnailImage);
      this.thumbnailLoaded.emit({ loaded: true, event });
    };

    this.thumbnailImage.onerror = (event: string | Event) => {
      this.thumbnailLoaded.emit({ loaded: false, event });
    };

    this.thumbnailImage.src =
      this.noImagePlaceholder && !this.thumbnail ? DEFAULT_IMAGE_PLACEHOLDER_THUMBNAIL : (this.thumbnail as string);
  }

  async loadOriginalImage() {
    const container: HTMLElement = this.elementRef.nativeElement.querySelector('.progressive-img__container');
    if (!this.originalImageContainer) {
      this.originalImageContainer = this.renderer.createElement('div');
      this.originalImageContainer.classList.add('original-container');
      this.renderer.appendChild(container, this.originalImageContainer);
    }

    if (!this.originalImage) {
      this.originalImage = this.renderer.createElement('img');
      this.originalImage.loading = 'lazy';
    } else if (this.originalImageContainer.hasChildNodes()) {
      this.renderer.removeChild(this.originalImageContainer, this.originalImage);
    }
    this.renderer.setAttribute(this.originalImage, 'loading', this.loading);

    if (this.preserveAspectRatio) {
      this.originalImage.style.objectFit = 'cover';
    }

    this.originalImage.style.width = this.width;
    this.originalImage.style.height = this.height;
    this.originalImage.alt = this.altText as string;

    this.originalImage.onload = (event: Event) => {
      this.originalImage.classList.add('clear-image');
      this.renderer.appendChild(this.originalImageContainer, this.originalImage);
      this.imageLoaded.emit({ loaded: true, event });
    };

    this.originalImage.onerror = (event: string | Event) => {
      this.imageLoaded.emit({ loaded: false, event });
    };

    const image = this.optimizedPublicUrl;
    this.originalImage.src = this.noImagePlaceholder && !image ? this.noImagePlaceholderPath : (image as string);
  }

  private async shouldLoadBgImage() {
    const image = this.optimizedPublicUrl;

    return this.noImagePlaceholder && !image && this.backgroundRepeat;
  }
}
