import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogSidebarDataResolver } from '@ct/app-content/blog/resolvers';
import { BlogPostDataResolver, Mode } from '@ct/shared';

import { MyAccountPostCreateComponent } from './components/my-account-post-create';
import { MyAccountPostViewComponent } from './components/my-account-post-view';
import { MyAccountWritingsComponent } from './components/my-account-writings';
import {
  MyAccountJournalEntriesDataResolverService,
  MyAccountPostsDataResolver,
  MyAccountTripsLastEditedResolver
} from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: MyAccountWritingsComponent,
    resolve: {
      stories: MyAccountPostsDataResolver,
      journalEntries: MyAccountJournalEntriesDataResolverService,
      lastEdited: MyAccountTripsLastEditedResolver
    }
  },
  {
    path: 'view/:id/:slug',
    component: MyAccountPostViewComponent,
    resolve: {
      post: BlogPostDataResolver,
      sidebar: BlogSidebarDataResolver
    }
  },
  {
    path: 'view/:slug',
    component: MyAccountPostViewComponent,
    resolve: {
      post: BlogPostDataResolver,
      sidebar: BlogSidebarDataResolver
    }
  },
  {
    path: 'create',
    component: MyAccountPostCreateComponent,
    data: { mode: Mode.Create }
  },
  {
    path: 'edit/:id/:slug',
    component: MyAccountPostCreateComponent,
    data: { mode: Mode.Edit },
    resolve: {
      post: BlogPostDataResolver
    }
  },
  {
    path: 'edit/:slug',
    component: MyAccountPostCreateComponent,
    data: { mode: Mode.Edit },
    resolve: {
      post: BlogPostDataResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountWritingsRoutingModule {}
