import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MyAccountComponent } from './components';
import { MyAccountOverviewComponent } from './modules/my-account-overview';
import { MyAccountOverviewDataResolver } from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: MyAccountComponent,
    children: [
      {
        path: 'trips/list',
        loadChildren: () => import('./modules/my-account-trips').then((m) => m.MyAccountTripsModule)
      },
      {
        path: 'writings',
        loadChildren: () => import('./modules/my-account-writings').then((m) => m.MyAccountWritingsModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('./modules/my-account-groups').then((m) => m.MyAccountGroupsModule)
      },
      {
        path: 'media',
        loadChildren: () => import('./modules/my-account-media').then((m) => m.MyAccountMediaModule)
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./modules/my-account-marketplace').then((m) => m.MyAccountMarketplaceModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/my-account-profile').then((m) => m.MyAccountProfileModule)
      },
      {
        path: 'friends',
        loadChildren: () => import('./modules/my-account-friends').then((m) => m.MyAccountFriendsModule)
      },
      {
        path: 'trips',
        loadChildren: () => import('./modules/my-account-trips').then((m) => m.MyAccountTripsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/my-account-dashboard').then((m) => m.MyAccountDashboardModule)
      },
      {
        path: 'explore',
        loadChildren: () => import('./modules/my-account-explore').then((m) => m.MyAccountExploreModule)
      }
    ]
  },
  {
    path: 'overview',
    component: MyAccountOverviewComponent,
    resolve: {
      data: MyAccountOverviewDataResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountRoutingModule {}
