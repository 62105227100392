import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@ct/auth';
import { SpinnerService } from '@ct/components';
import { RequestRange } from '@ct/core';
import {
  EntityType,
  ShareApiService,
  SortOrder,
  Trip,
  TripApiService,
  TripJournalEntryApiService,
  TripSharingStatus,
  TripStore
} from '@ct/shared';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MyAccountTripApiService extends TripApiService {
  constructor(
    protected httpClient: HttpClient,
    protected tripJournalEntryApiService: TripJournalEntryApiService,
    protected shareApiService: ShareApiService<Trip>,
    protected store: TripStore,
    protected spinnerService: SpinnerService,
    private authService: AuthService
  ) {
    super(httpClient, tripJournalEntryApiService, shareApiService, spinnerService, store);
  }

  getAllMyTrips(range?: RequestRange, status?: TripSharingStatus, sortOrder?: SortOrder | undefined) {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        if (status === TripSharingStatus.SharedWithMe) {
          return this.shareApiService.getAllEntities({
            entityType: EntityType.Trip,
            userId: identity.guuid,
            range,
            sortOrder
          });
        } else if (status === TripSharingStatus.SharedByMe) {
          return this.shareApiService.getAllEntities({
            entityType: EntityType.Trip,
            authorId: identity.guuid,
            range,
            sortOrder
          });
        }
        return this.getAllByAuthorId(identity.guuid, { range, sortOrder });
      })
    );
  }

  getMyLastEdited(): Observable<Trip> {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return this.getAllByAuthorId(identity.guuid, { range: { limit: 1 }, sortBy: 'modifiedOn' });
      }),
      map((trips) => trips[0])
    );
  }

  getTripsAvailable(): Observable<boolean> {
    return this.get('are-available');
  }
}
