import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    @Optional() @Inject('SERVER_HOST') private SERVER_HOST: string,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  getOrigin() {
    if (isPlatformServer(this.platformId)) {
      return this.SERVER_HOST;
    } else {
      return document.location.origin;
    }
  }
}
