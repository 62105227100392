<ng-container *ngIf="load" [ngSwitch]="type">
  <ng-container *ngSwitchCase="Types.Image">
    <ct-gallery-image
      [src]="data.src"
      [original]="data.original"
      [imageDescription]="config?.imageDescriptionPrefix + ' image ' + (index + 1)"
      (errorItem)="errorItem.emit($event)"
    ></ct-gallery-image>
  </ng-container>

  <ct-gallery-video
    *ngSwitchCase="Types.Video"
    [src]="data.src"
    [poster]="data.poster"
    [controlsEnabled]="data.controls"
    [play]="isAutoPlay"
    [pause]="currIndex !== index"
    (errorItem)="errorItem.emit($event)"
  ></ct-gallery-video>

  <ct-gallery-iframe
    *ngSwitchCase="Types.Youtube"
    [src]="youtubeSrc"
    [autoplay]="isAutoPlay"
    [pause]="currIndex !== index"
  ></ct-gallery-iframe>

  <ct-gallery-iframe *ngSwitchCase="Types.Iframe" [src]="data.src"></ct-gallery-iframe>
</ng-container>
