<div class="photo mt-3">
  <form class="form" [formGroup]="form">
    <div class="row mb-3">
      <ct-progressive-image
        class="col-12"
        [height]="'30vh'"
        [width]="'100%'"
        [publicUrl]="photo?.publicUrl"
        [thumbnail]="photo?.xsPreview"
      >
      </ct-progressive-image>
    </div>
    <div class="row">
      <ct-input
        [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.CAPTION' | translate"
        class="col-12"
        formControlName="description"
      ></ct-input>
    </div>
    <div class="row">
      <ct-select
        class="col-12"
        [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.WAYPOINT' | translate"
        [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ASSIGN_CAMPSITE' | translate"
        formControlName="campsiteId"
        [options]="campsites"
      ></ct-select>
    </div>
    <div class="flex-space-between">
      <div class="d-flex">
        <ct-button variant="stroked" color="warn" class="mr-2" (click)="onDelete()">{{
          'COMMON.DELETE' | translate
        }}</ct-button>
        <ct-button class="mr-2" variant="flat" color="primary" (click)="onCancel()">{{
          'COMMON.CANCEL' | translate
        }}</ct-button>
        <ct-button variant="flat" color="accent" (click)="onSave()">{{ 'COMMON.UPDATE' | translate }}</ct-button>
      </div>
    </div>
  </form>
</div>
