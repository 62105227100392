import { AgmGeocoder, MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {
  constructor(private agmGeocoder: AgmGeocoder, private mapsAPILoader: MapsAPILoader) {
    this.mapsAPILoader.load().then(() => this.isApiLoadedSubject.next(true));
  }

  private isApiLoadedSubject = new BehaviorSubject(false);

  geocode(lat: number, lng: number): Observable<google.maps.GeocoderResult[]> {
    return this.isApiLoadedSubject.asObservable().pipe(
      filter(Boolean),
      switchMap(() => {
        const location = new google.maps.LatLng(lat, lng);
        return this.agmGeocoder.geocode({ location }).pipe(
          catchError((status) => {
            console.log('Geocoding service: geocoder failed due to: ' + status);
            return throwError(status);
          })
        );
      })
    );
  }

  codeAddress(address: string): Observable<google.maps.GeocoderResult[]> {
    return this.isApiLoadedSubject.asObservable().pipe(
      switchMap((loaded) => {
        if (loaded) {
          return this.agmGeocoder.geocode({ address }).pipe(
            catchError((status) => {
              console.log('Geocoding service: geocode was not successful for the following reason: ' + status);
              return throwError(status);
            })
          );
        }
        return of([] as google.maps.GeocoderResult[]);
      })
    );
  }
}
