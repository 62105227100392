import { Injectable } from '@angular/core';
import { RelationUserProfile } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export type FriendsState = EntityState<RelationUserProfile>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'friends',
  cache: {
    ttl: 3600
  },
  idKey: 'userId'
})
export class FriendsStore extends EntityStore<FriendsState> {
  constructor() {
    super();
  }
}
