<div class="img-carousel-container">
  <ng-container [ngTemplateOutlet]="currentImageCarouselItem"></ng-container>
  <a class="prev" (click)="prev()">
    <ct-icon icon="nav"></ct-icon>
  </a>
  <a class="next" (click)="next(true)"> <ct-icon icon="nav"></ct-icon> </a>
  <div class="dots-container">
    <span
      *ngFor="let dot of imageCarouselItems; let i = index"
      class="dot"
      [class.active]="index === i"
      (click)="onDot(i)"
    ></span>
  </div>
</div>
<!-- This line is needed for image prefetching -->
<ng-container *ngIf="prefetch">
  <img class="d-none" *ngFor="let carouselImage of carouselImages" [src]="carouselImage.src" loading="lazy" />
</ng-container>
