import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BlogPostStatus } from '@ct/core';

@Component({
  selector: 'ct-blog-post-status',
  templateUrl: './blog-post-status.component.html',
  styleUrls: ['./blog-post-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostStatusComponent {
  @Input() status: BlogPostStatus | undefined;

  get statusKey() {
    if (this.status === BlogPostStatus.Draft) {
      return 'MY_ACCOUNT.MY_POSTS_FEATURE.UNPUBLISHED'; // TODO: move i18n
    }
    if (this.status === BlogPostStatus.Published) {
      return 'MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISHED'; // TODO: move i18n
    }

    if (this.status === BlogPostStatus.PublishedPrivately) {
      return 'MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISHED_PRIVATELY'; // TODO: move i18n
    }

    if (this.status === BlogPostStatus.FlaggedForReview) {
      return 'MY_ACCOUNT.MY_POSTS_FEATURE.IN_REVIEW'; // TODO: move i18n
    }

    if (this.status === BlogPostStatus.WaitingApproval) {
      return 'MY_ACCOUNT.MY_POSTS_FEATURE.WAITING_REVIEW'; // TODO: move i18n
    }

    if (this.status === BlogPostStatus.Suspended) {
      return 'MY_ACCOUNT.MY_POSTS_FEATURE.SUSPENDED'; // TODO: move i18n
    }

    return '';
  }

  get color(): ThemePalette {
    switch (this.status) {
      case BlogPostStatus.Published:
        return 'primary';
      case BlogPostStatus.Suspended:
        return 'warn';
      case BlogPostStatus.Draft:
      case BlogPostStatus.WaitingApproval:
        return 'accent';
      case BlogPostStatus.FlaggedForReview:
      case BlogPostStatus.PublishedPrivately:
      default:
        return;
    }
  }
}
