import { Injectable } from '@angular/core';
import { Tag, UploadedImage, UserProfile } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Trip, TripJournalEntry } from '../../interfaces';

export interface TripState extends EntityState<Trip> {
  id?: string;
  authorId: string;
  author: UserProfile;
  active: boolean;
  title: string;
  slug: string;
  photoIds: string[];
  campsiteIds: string[];
  videoIds: string[];
  routeLink: string;
  entryIds: string[];
  createdOn: Date;
  modifiedOn: Date;
  startDate: Date;
  endDate: Date;
  isPrivate: boolean;
  photos: UploadedImage[];
  entries: TripJournalEntry[];
  tags: Tag[];
  youtubeVideos?: string[];
  // TODO: fix any
  videos: any[];
  campsites: any[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'trip',
  cache: {
    ttl: 3600
  },
  idKey: 'id'
})
export class TripStore extends EntityStore<TripState> {
  constructor() {
    super();
  }
}
