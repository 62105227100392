import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ct-keyword-input',
  templateUrl: './keyword-input.component.html',
  styleUrls: ['./keyword-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class KeywordInputComponent extends BaseControlComponent<string> implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly control = new UntypedFormControl([]);
  public readonly autocompleteControl = new UntypedFormControl(null);

  @Input() public readonly placeholder = '';
  @Input() public readonly label = '';
  @Input() public readonly maxlength = 120;

  public get noMatchOption() {
    return {
      label: this.autocompleteControl.value,
      value: this.autocompleteControl.value,
      disabled: false
    };
  }

  public optionsFn = () => of([]);

  constructor(
    @Optional() @Inject(NgControl) public readonly ctrl: FormControlName,
    @Optional() public readonly formState: FormStateDispatcher | null,
    public readonly changeDetector: ChangeDetectorRef,
    public translateService: TranslateService
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  changed(value: string | null) {
    this.clearInput();

    if (!value?.trim()) {
      return;
    }

    this.update(this.control.value, value as string);
  }

  update(keywords: string[] = [], keyword: string) {
    const duplicate = keywords?.find((item: string) => item === keyword);
    if (duplicate) {
      return;
    }
    this.control.patchValue([...(keywords || []), keyword]);
    this.changeDetector.detectChanges();
  }

  removed(keyword: string) {
    const keywords = this.control.value?.filter((item: string) => item !== keyword);
    this.control.patchValue(keywords);
    this.changeDetector.detectChanges();
  }

  clearInput() {
    this.autocompleteControl.patchValue(undefined);
  }
}
