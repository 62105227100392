import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityComment, getCroppedThumbPublicUrl, UserProfile } from '@ct/core';

@Component({
  selector: 'ct-entity-comment',
  templateUrl: './entity-comment.component.html',
  styleUrls: ['./entity-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityCommentComponent {
  @Input() comment: EntityComment;
  @Input() user: UserProfile | null;

  @Output() edited = new EventEmitter();
  @Output() deleted = new EventEmitter();

  public get isAuthor() {
    return this.comment?.authorId === this.user?.userId;
  }

  public get thumbnail(): string {
    return getCroppedThumbPublicUrl(this.comment?.author?.photo?.publicUrl);
  }
}
