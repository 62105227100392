import { Injectable } from '@angular/core';
import { CanLoad, NavigationCancel, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.events.pipe(first((_) => _ instanceof NavigationCancel)).subscribe((event: any) => {
            this.router.navigate(['/login'], {
              queryParams: {
                returnUrl: event.url
              }
            });
          });
          return false;
        }
        return true;
      })
    );
  }
}
