import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BlogPost } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {
  static scriptType = 'application/json+ld';
  static blogPostingSchema = ({ title, featuredPhoto, author, publishedOn, modifiedOn }: BlogPost) => {
    const { webUrl } = environment;
    return {
      '@context': 'https://schema.org/',
      '@type': 'BlogPosting',
      headline: title,
      image: {
        '@type': 'ImageObject',
        url: featuredPhoto?.publicUrl
        // "width": "",
        // "height": ""
      },
      author: {
        '@type': 'Person',
        name: author.username
      },
      publisher: {
        '@type': 'Organization',
        name: 'Camping.Tools',
        logo: {
          '@type': 'ImageObject',
          url: `${webUrl}/assets/logos/main-logo.png`,
          width: '400',
          height: '123'
        }
      },
      datePublished: getFormattedDate(publishedOn as Date),
      dateModified: getFormattedDate(modifiedOn as Date)
    };
  };
  static websiteSchema = () => {
    const { webUrl } = environment;
    return {
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      name: 'Camping.Tools',
      url: webUrl,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${webUrl}/site-search?query={search_term_string}&from=schema`,
        'query-input': 'required name=search_term_string'
      }
    };
  };

  constructor(@Inject(DOCUMENT) private document: Document) {}

  removeStructuredData(className?: string): void {
    const els: Element[] = [];
    (className && [className]) ||
      ['structured-data', 'structured-data-org'].forEach((c) => {
        if (c) {
          els.push(...Array.from(this.document.head.getElementsByClassName(c)));
        }
      });
    els.forEach((el) => this.document.head.removeChild(el));
  }

  insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
    let script: HTMLScriptElement;
    let shouldAppend = false;
    if (this.document.head.getElementsByClassName(className).length) {
      script = this.document.head.getElementsByClassName(className)[0] as HTMLScriptElement;
    } else {
      script = this.document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = JsonLdService.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this.document.head.appendChild(script);
    }
  }
}

function getFormattedDate(date: Date | string): string {
  const parsed = new Date(date);
  return parsed.getUTCFullYear() + '-' + (parsed.getUTCMonth() + 1) + '-' + parsed.getUTCDate();
}
