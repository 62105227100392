import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from './../button';
import { IconModule } from './../icon';
import {
  ConfirmComponent,
  DeletionDialogComponent,
  DialogContentComponent,
  DiscardChangesDialogComponent
} from './components';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { DialogService } from './services';

@NgModule({
  declarations: [
    DialogContentComponent,
    ConfirmComponent,
    DeletionDialogComponent,
    DiscardChangesDialogComponent,
    InformationDialogComponent
  ],
  imports: [CommonModule, MatDialogModule, TranslateModule, ButtonModule, IconModule, PipesModule],
  providers: [DialogService],
  exports: [
    DialogContentComponent,
    ConfirmComponent,
    DeletionDialogComponent,
    DiscardChangesDialogComponent,
    InformationDialogComponent
  ]
})
export class DialogModule {}
