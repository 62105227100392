import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthQuery, AuthService, UserProfileApiService } from '@ct/auth';
import { DeletionDialogComponent, DialogService, ImageUploadService, SelectOption } from '@ct/components';
import { SpinnerService } from '@ct/components/spinner/services';
import { DestroyableFeature, Features, FormStateDispatcher, Group, GroupType, UserProfile } from '@ct/core';
import { MyAccountPhotoApiService } from '@ct/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { Mode } from '../../../enums';
import { GroupApiService } from '../services';

@Component({
  selector: 'ct-create-group-dialog',
  templateUrl: './create-group-dialog.component.html',
  styleUrls: ['./create-group-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class CreateGroupDialogComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public readonly form = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    visibility: new UntypedFormControl(GroupType.Public, [Validators.required]),
    image: new UntypedFormControl('')
  });
  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public isLoading = false;
  public readonly privacyOptions: SelectOption[] = [
    {
      label: GroupType.Public,
      value: GroupType.Public
    },
    {
      label: GroupType.Private,
      value: GroupType.Private
    }
  ];

  get isEditMode() {
    return this.data?.mode === Mode.Edit;
  }

  get isAdmin(): boolean {
    return this.data?.isAdmin;
  }

  get group(): Group {
    return this.data.group;
  }

  get groupId() {
    return this.data.group?.id;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CreateGroupDialogComponent>,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private router: Router,
    private authQuery: AuthQuery,
    private formState: FormStateDispatcher,
    private groupApiService: GroupApiService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private userProfileApiService: UserProfileApiService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.isEditMode && this.group) {
      this.form.patchValue(this.group);
    }
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    (!this.isEditMode
      ? this.groupApiService.create({ ...this.form.value }).pipe(
          switchMap((group) =>
            this.loggedInUser$.pipe(
              take(1),
              switchMap((userProfile) => this.userProfileApiService.getById(userProfile?.id as string)),
              switchMap((userProfile) => this.authService.updateUserProfile(userProfile)),
              map(() => group)
            )
          )
        )
      : this.groupApiService.update(this.groupId, { ...this.form.value })
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((group) => {
        this.router.navigate(['my-account/groups', group?.id]);
        this.dialogRef.close(group);
      });
  }

  onDelete() {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.DELETE.TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.DELETE.MESSAGE'),
          confirm: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.DELETE.YES'),
          cancel: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.DELETE.NO')
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          this.isLoading = true;
          this.spinnerService.show();
          this.changeDetectorRef.markForCheck();

          return this.groupApiService.delete(this.groupId);
        }),
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((group) => {
        this.router.navigate(this.isAdmin ? ['groups/all'] : ['my-account/groups']);
        this.dialogRef.close(group);
      });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSelectFeaturedPhotoPlaceholder() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_FEATURED_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(([photo]) => !!photo)
      )
      .subscribe(([photo]) => {
        this.form.controls.image.patchValue(photo);
        this.changeDetectorRef.detectChanges();
      });
  }

  onReplaceFeaturedPhoto() {
    this.onSelectFeaturedPhotoPlaceholder();
  }

  onRemoveFeaturedPhoto() {
    this.form.controls.image.patchValue(null);
  }
}
