import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Trip, TripSharingStatus } from '@ct/shared';

import { MY_ACCOUNT_TRIPS_FEATURE_LIMIT } from '../../../constants';
import { MyAccountTripApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MyAccountTripsDataResolver implements Resolve<Trip[]> {
  constructor(private myAccountTripApiService: MyAccountTripApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const filter = activatedRouteSnapshot.queryParams?.filter as TripSharingStatus;
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.myAccountTripApiService.getAllMyTrips({ limit: MY_ACCOUNT_TRIPS_FEATURE_LIMIT, offset }, filter);
  }
}
