<div class="navigation">
  <div class="pb-1 d-flex justify-content-between">
    <span class="nav-item" [class.disabled]="disabledPrev" (click)="onPrev()">
      &#60; {{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.PREV' | translate }}
    </span>
    <span class="nav-item" [class.disabled]="disabledNext" (click)="onNext()">
      {{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.NEXT' | translate }} &#62;
    </span>
  </div>
</div>
