import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@ct/core';
import { environment } from '@ct/environment';

import { EntityType } from '../../../enums';

const endpoint = environment.likeApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class LikeApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll({ entityType, entityId }: { entityId: string; entityType: EntityType }) {
    let params = new HttpParams();
    params = params.append('entityType', entityType);
    if (entityId !== undefined && entityId !== null) {
      params = params.append('entityId', entityId);
    }
    return this.get('', params);
  }

  like(likeRequest: { entityId: string; entityType: EntityType }) {
    return this.post(``, likeRequest);
  }

  dislike({ entityId, userId }: { entityId: string; userId: string }) {
    return this.delete(`${userId}/${entityId}`);
  }
}
