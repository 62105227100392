import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconModule } from '../icon';
import { UserAvatarModule } from '../user-avatar';
import { ChannelRowComponent } from './channel-row.component';

@NgModule({
  declarations: [ChannelRowComponent],
  imports: [CommonModule, IconModule, UserAvatarModule, RouterModule],
  exports: [ChannelRowComponent]
})
export class ChannelRowModule {}
