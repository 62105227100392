import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DestroyableFeature,
  FacebookCardType,
  Features,
  MetaTagService,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleService,
  TwitterCardType
} from '@ct/core';
import { entitySlugUrl, trackById } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MARKETPLACE_ITEMS_LIMIT } from '../../constants';
import { MarketplaceItem } from '../../interfaces';
import { MarketplaceApiService } from '../../services';
import { MarketplaceQuery } from '../../services/state';

interface MarketplaceQueryParams {
  offset: number;
  merchantId?: string;
  categoryId?: string;
  subCategoryId?: string;
  search?: string;
  location?: string;
  lat?: number;
  lon?: number;
}

interface Location {
  city?: string;
  state?: string;
  zip?: string;
  lat?: number;
  lng?: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-marketplace-selling',
  templateUrl: './marketplace-selling.component.html',
  styleUrls: ['./marketplace-selling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), DestroyableFeature()])
export class MarketplaceSellingComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly limit = MARKETPLACE_ITEMS_LIMIT;
  public seoConfig: SeoConfig = {
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.MARKETPLACE',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/marketplace.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/marketplace/selling'
    }
  };
  public isLoading = false;

  public loading = false;
  public showLoadButton = true;
  public items: MarketplaceItem[] = [];

  public queryParams: MarketplaceQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public location: Location | null;
  public trackByFn = trackById;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: TitleService,
    private metaTagService: MetaTagService,
    private translateService: TranslateService,
    private marketplaceApiService: MarketplaceApiService,
    public marketplaceQuery: MarketplaceQuery
  ) {}

  ngOnInit() {
    this.marketplaceQuery.selectItems$.pipe(takeUntil(this.destroyed$)).subscribe((items) => {
      this.refreshItems(items);
      this.initSeo();
    });
  }

  initSeo() {
    this.titleService.setTitleWithSuffix(this.translateService.instant('MAIN.FEATURES.MARKETPLACE'), 'All Items');
    this.metaTagService.setMetaDescription('All latest items for sale');
  }

  getLink(item: MarketplaceItem) {
    return entitySlugUrl('/marketplace/', item);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadMoreItems(this.queryParams);
  }

  refreshItems(items: MarketplaceItem[]) {
    if (this.queryParams.offset === 0) {
      this.items = [...(items ?? [])];
    } else {
      this.items.push(...(items ?? []));
    }
    this.showLoadButton = !(items.length === 0 || items.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  refreshUrlQueryParams({
    offset,
    merchantId,
    categoryId,
    subCategoryId,
    search,
    location,
    lat,
    lon
  }: MarketplaceQueryParams) {
    this.loading = true;

    this.router.navigate([], {
      queryParams: { offset, merchantId, categoryId, subCategoryId, search, location, lat, lon },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  loadMoreItems({ offset, merchantId, categoryId, subCategoryId }: MarketplaceQueryParams) {
    return this.marketplaceApiService.getAllMyItems({
      range: { limit: MARKETPLACE_ITEMS_LIMIT, offset },
      merchantId,
      categoryId,
      subCategoryId,
      type: 'list'
    });
  }
}
