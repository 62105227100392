import { ɵDirectiveDef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { SeoConfig, TitleConfig } from '../../interfaces';
import { Writable } from '../../models';
import { AppInjector, TitleService } from '../../services';

export function TitleFeature() {
  return <T extends { seoConfig?: SeoConfig; titleConfig?: TitleConfig }>(directiveDef: Writable<ɵDirectiveDef<T>>) => {
    const { factory, type } = directiveDef;

    directiveDef.factory = () => {
      const instance = factory?.(type) as T;
      const injector = AppInjector.getInjector();

      if (!injector) {
        throw new Error(`AppInjector not found! Please, provide it or remove TitleFeature() decorator`);
      }
      if (!instance.seoConfig && !instance.titleConfig) {
        throw new Error(
          `'seoConfig' or 'titleConfig' are not defined in the ${type?.name}! Please, provide it or remove TitleFeature() decorator`
        );
      }
      const { titleKey, suffixKey } = (instance.seoConfig?.titleConfig || instance?.titleConfig) as TitleConfig;
      const translateService = injector.get(TranslateService);
      const titleService = injector.get(TitleService);

      translateService
        .get(titleKey as string)
        .pipe(
          take(1),
          switchMap((title) =>
            (suffixKey ? translateService.get(suffixKey as string).pipe(take(1)) : of(null)).pipe(
              map((suffix) => [title, suffix])
            )
          )
        )
        .subscribe(([title, suffix]) =>
          suffix ? titleService.setTitleWithSuffix(title, suffix) : titleService.setTitle(title)
        );

      return instance;
    };
  };
}
