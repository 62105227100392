import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Category } from '../interfaces';
import { CategoryApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class CategoryDataResolver implements Resolve<Category[]> {
  constructor(private categoryApiService: CategoryApiService) {}

  resolve() {
    return this.categoryApiService.getAllCategories({
      type: 'list'
    });
  }
}
