import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DialogService } from '../';
import { ShareDialogComponent } from './share-dialog';

@Component({
  selector: 'ct-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialSharingComponent {
  @Input() readonly isActive: boolean;
  @Input() readonly text: string | undefined;
  @Input() readonly url: string | undefined;
  @Input() readonly buttonTextKey: string = 'MY_ACCOUNT.MY_POSTS_FEATURE.SHARE.TITLE';

  constructor(private readonly dialogService: DialogService) {}

  openShareDialog() {
    this.dialogService.open(ShareDialogComponent, {
      data: {
        url: this.url || globalThis.location.href,
        text: this.text,
        disabled: !this.isActive
      },
      width: '300px'
    });
  }
}
