import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '@ct/core/services/local-storage.service';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NotificationApiService } from './notification-api.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseCloudMessagingService {
  public readonly fcmDeviceTokenKey: string = 'FCM_DEVICE_TOKEN';
  constructor(
    private fireMessaging: AngularFireMessaging,
    private notificationsApiService: NotificationApiService,
    private localStorageService: LocalStorageService,
    private snackBar: MatSnackBar
  ) {}

  requestToken(userId?: string): void {
    this.fireMessaging.requestToken.subscribe({
      next: (token) => {
        if (token) {
          this.setFcmDeviceToken(token);
          this.notificationsApiService
            .registerToken(token)
            .pipe(switchMap(() => this.listenForMessages()))
            .pipe(switchMap(() => this.notificationsApiService.getNotifications(userId as string)))
            .subscribe();
        }
      },
      error: (err) => {
        console.error('Fetching FCM token failed: ', err);
      }
    });
    this.notificationsApiService.getNotifications(userId as string).subscribe();
  }

  listenForMessages(): Observable<any> {
    const subject = new Subject<void>();
    setTimeout(() => {
      this.fireMessaging.messages.subscribe((message: any) => {
        this.snackBar.open(message.notification.body, 'close', {
          duration: 10000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom'
        });

        subject.next();
      });
    });
    return subject;
  }

  public setFcmDeviceToken(token: string): void {
    this.localStorageService.setItem(this.fcmDeviceTokenKey, token);
  }

  public removeFcmDeviceToken(): void {
    this.localStorageService.removeItem(this.fcmDeviceTokenKey);
  }

  public getFcmDeviceToken(): string {
    return this.localStorageService.getItem(this.fcmDeviceTokenKey) as string;
  }
}
