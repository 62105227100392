import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ImageSize, SlidingDirection, ThumbnailsPosition } from '../../enums';
import { GalleryConfig, GalleryError, GalleryState } from '../../interfaces';

@Component({
  selector: 'ct-gallery-core',
  templateUrl: './gallery-core.component.html',
  styleUrls: ['./gallery-core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryCoreComponent {
  @Input() state: GalleryState | null;
  @Input() config: GalleryConfig | null;
  @Output() action = new EventEmitter<string | number>();
  @Output() itemClick = new EventEmitter<number>();
  @Output() thumbClick = new EventEmitter<number>();
  @Output() errorItem = new EventEmitter<GalleryError>();

  @HostBinding('attr.thumbPosition') get thumbPosition(): ThumbnailsPosition {
    return this.config?.thumbPosition as ThumbnailsPosition;
  }

  @HostBinding('attr.slidingDirection') get slidingDirection(): SlidingDirection {
    return this.config?.slidingDirection as SlidingDirection;
  }

  @HostBinding('attr.disableThumb') get disableThumb(): boolean {
    return this.config?.disableThumb as boolean;
  }

  @HostBinding('attr.imageSize') get imageSize(): ImageSize {
    return this.config?.imageSize as ImageSize;
  }

  get itemsLength() {
    return this.state?.items?.length as number;
  }
}
