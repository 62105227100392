<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header
    ctHeader
    icon="book"
    title="Writings"
    description="Write private memories or publish stories to the community"
  ></ct-extended-header>

  <div ctContent>
    <ng-container *ngIf="posts?.length || journalEntries?.length; else noStories">
      <div
        class="row"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      >
        <ct-writing-card
          [story]="lastEdited"
          [canEdit]="true"
          (edit)="editStory(lastEdited)"
          (share)="shareStory(lastEdited)"
          (openStory)="openStory(lastEdited)"
          class="writings__last-edited col-12"
        >
          <ct-tab-header
            class="d-none d-md-flex"
            ctCardFooter
            style="margin-bottom: -1rem"
            [tabs]="tabs"
            (tabChange)="onFilterChanged($event)"
          >
            <ct-icon
              ctTabPrefix
              class="add-writings__icon"
              title="Add New"
              icon="add-circle"
              (click)="addWriting()"
            ></ct-icon>
          </ct-tab-header>
        </ct-writing-card>
      </div>
      <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)">
        <ct-icon ctTabPrefix class="add-writings__icon" icon="add-circle" (click)="addWriting()"></ct-icon>
      </ct-tabs-mobile>
      <ng-container *ngIf="isStoriesTab">
        <div class="px-4 px-md-5 py-4 writings-list">
          <ct-social-sharing
            [isActive]="true"
            [url]="shareAllUrl"
            buttonTextKey="MY_ACCOUNT.MY_POSTS_FEATURE.SHARE_ALL"
            class="ml-md-auto mb-1"
          ></ct-social-sharing>
          <ct-blog-post-card
            *ngFor="let post of posts; trackBy: trackByFn"
            [post]="post"
            [showLink]="true"
            [showStatus]="true"
            [showAuthor]="false"
            imgHeight="200px"
            imgWidth="100%"
            class="mb-4 main-content"
            [link]="getLink(post)"
          ></ct-blog-post-card>
          <ng-container *ngIf="!posts?.length && isStoriesTab">
            <ng-container *ngTemplateOutlet="noStories"></ng-container>
          </ng-container>
        </div>
        <div
          *ngIf="showLoadButton"
          class="load-more"
          [ngClass]="{ disabled: loading }"
          (click)="onScroll()"
          disabled="loading"
        >
          Load More
        </div>
      </ng-container>

      <ng-container *ngIf="isJournalsTab">
        <div class="px-4 px-md-5 py-4 writings-list">
          <ct-journal-entry
            *ngFor="let entry of journalEntries; trackBy: trackByFn"
            class="mb-4 main-content"
            [journalEntry]="entry"
            [canEdit]="true"
            (edit)="editJournal(entry)"
          ></ct-journal-entry>
          <ng-container *ngIf="!journalEntries?.length && isStoriesTab">
            <ng-container *ngTemplateOutlet="noStories"></ng-container>
          </ng-container>
        </div>
        <div
          *ngIf="showLoadButton"
          class="load-more"
          [ngClass]="{ disabled: loading }"
          (click)="onScroll()"
          disabled="loading"
        >
          Load More
        </div>
      </ng-container>
    </ng-container>

    <ng-template #noStories>
      <ct-empty-call-to-action
        buttonIcon="add-circle"
        emptyMessageTextKey="MY_ACCOUNT.EMPTY_ENTITY.NO_STORIES"
        buttonTextKey="MY_ACCOUNT.MY_POSTS_FEATURE.NEW_POST"
        (buttonClick)="addWriting()"
      ></ct-empty-call-to-action>
    </ng-template>
  </div>
</ct-layout>
