<ct-link link="/register" class="signup-banner">
  <img
    src="assets/images/story-signup-banner.png"
    alt="Woman holding phone and smiling"
    srcset="assets/images/story-signup-banner@2x.png 2x"
    loading="lazy"
  />
  <div class="signup-content">
    <p>The best online camping journal</p>
    <ct-button variant="flat" color="accent">{{ 'COMMON.SIGN_UP' | translate | uppercase }}</ct-button>
  </div>
</ct-link>
