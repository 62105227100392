<div class="row">
  <div
    class="col-6 col-md-{{ col }}  video"
    *ngFor="let video of _videos; let index = index"
    (click)="onVideoPlay(video.youtubeId)"
  >
    <ct-progressive-image
      class="video"
      height="150px"
      publicUrl="https://img.youtube.com/vi/{{ video.youtubeId | youtubeId }}/0.jpg"
      [thumbnail]="'https://img.youtube.com/vi/{{ video.youtubeId | youtubeId }}/0.jpg'"
      [altText]="'youtube video {{ video.youtubeId }}'"
    >
    </ct-progressive-image>
    <div class="action-container">
      <ct-icon
        *ngIf="multiselect"
        class="checkbox"
        [icon]="video?.selected ? 'checkbox-checked' : 'checkbox'"
        (click)="selectionChanged.emit(video)"
      ></ct-icon>
      <ct-icon *ngIf="removable && !multiselect" class="remove" icon="remove" (click)="removed.emit(video)"></ct-icon>
    </div>
  </div>
  <ct-new-item-placeholder
    *ngIf="showPlaceholder"
    [height]="'100%'"
    [textKey]="'MY_ACCOUNT.MY_VIDEOS_FEATURE.ADD_NEW_VIDEO'"
    class="placeholder col-6 col-md-{{ col }} mb-2"
    (placeholderClicked)="onAddVideo($event)"
  ></ct-new-item-placeholder>
</div>
<p *ngIf="!_videos?.length && !showPlaceholder" class="flex-center">
  <span>{{ 'COMMON.EMPTY' | translate }}</span>
</p>
