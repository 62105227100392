<ct-dialog-content
  [titleKey]="isEditMode ? 'MY_ACCOUNT.MY_TRIPS_FEATURE.EDIT_TRIP' : 'MY_ACCOUNT.MY_TRIPS_FEATURE.NEW_TRIP'"
  [closeIcon]="true"
  headerIcon="add-circle"
>
  <form class="form" [formGroup]="form">
    <div class="row">
      <ct-input
        class="col-12"
        formControlName="title"
        [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TRIP_TITLE' | translate"
        [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TITLE_PLACEHOLDER' | translate"
      ></ct-input>
    </div>
    <div class="row">
      <ct-date-picker
        class="col-12 col-md-6"
        formControlName="startDate"
        [label]="'COMMON.DATE.START' | translate"
        [placeholder]="'COMMON.DATE.START_PLACEHOLDER' | translate"
        [max]="form.controls?.endDate?.value"
      ></ct-date-picker>
      <ct-date-picker
        class="col-12 col-md-6"
        formControlName="endDate"
        [label]="'COMMON.DATE.END' | translate"
        [placeholder]="'COMMON.DATE.END_PLACEHOLDER' | translate"
        [min]="form.controls?.startDate?.value"
        [startDate]="form.controls?.endDate?.value || form.controls?.startDate?.value"
      ></ct-date-picker>
    </div>
    <div class="row mb-3">
      <h2 class="item-title col-12">{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.FEATURED_PHOTO' | translate }}</h2>
      <ct-new-item-placeholder
        class="placeholder col-12 col-md-3"
        [img]="form.controls.featuredPhoto?.value?.publicUrl"
        [textKey]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_NEW_PHOTO'"
        [replaceKey]="form.controls.featuredPhoto?.value?.publicUrl ? 'MY_ACCOUNT.MY_TRIPS_FEATURE.REPLACE_PHOTO' : ''"
        (placeholderClicked)="onSelectFeaturedPhotoPlaceholder()"
        (replaced)="onReplaceFeaturedPhoto()"
        (removed)="onRemoveFeaturedPhoto()"
      ></ct-new-item-placeholder>
    </div>
    <div class="flex-space-between">
      <div class="d-flex flex-grow-1">
        <ct-button class="mr-2" variant="flat" color="primary" (click)="onCancel()">{{
          'COMMON.CANCEL' | translate
        }}</ct-button>
        <ct-button [disabled]="isLoading" class="mr-2" variant="flat" color="accent" (click)="onSave()">{{
          'COMMON.SAVE' | translate
        }}</ct-button>
        <ct-button *ngIf="isEditMode" class="ml-auto" variant="flat" color="warn" (click)="onDelete()">{{
          'COMMON.DELETE' | translate
        }}</ct-button>
      </div>
    </div>
  </form>
</ct-dialog-content>
