import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BlogPost } from '@ct/core';

import { BLOG_POSTS_FEATURE_LIMIT } from '../constants';
import { BlogPostApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class BlogPostsDataResolver implements Resolve<BlogPost[]> {
  constructor(private blogPostApiService: BlogPostApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    const authorId = activatedRouteSnapshot.queryParams?.authorId as string;
    const tagId = activatedRouteSnapshot.queryParams?.tagId as string;
    return this.blogPostApiService.getAll({
      range: { limit: BLOG_POSTS_FEATURE_LIMIT, offset },
      authorId,
      tagId,
      type: 'list'
    });
  }
}
