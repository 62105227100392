<mat-form-field appearance="outline">
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="control"
    [min]="min"
    [max]="max"
    [placeholder]="label || placeholder"
    (focus)="onFocus()"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker [startAt]="startDate" #picker></mat-datepicker>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'COMMON.VALIDATIONS.REQUIRED' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('matDatepickerMin')">
    {{ 'COMMON.VALIDATIONS.MIN_DATE' | translate: { date: (min | date) } }}</mat-error
  >
  <mat-error *ngIf="control.hasError('matDatepickerMax')">
    {{ 'COMMON.VALIDATIONS.MAX_DATE' | translate: { date: (max | date) } }}
  </mat-error>
</mat-form-field>
