<div class="container login">
  <form class="form" [formGroup]="form" (ngSubmit)="onLogIn()">
    <h1 class="title">{{ 'AUTH.LOGIN.TITLE' | translate }}</h1>
    <div class="row">
      <ct-input
        type="email"
        [label]="'AUTH.LOGIN.EMAIL_ADDRESS' | translate"
        [placeholder]="'AUTH.LOGIN.EMAIL_ADDRESS_PLACEHOLDER' | translate"
        lightLabel="true"
        class="col-12 col-md-4"
        formControlName="email"
      ></ct-input>
    </div>
    <div class="row">
      <ct-input
        type="password"
        [label]="'AUTH.LOGIN.PASSWORD' | translate"
        [placeholder]="'AUTH.LOGIN.PASSWORD_PLACEHOLDER' | translate"
        lightLabel="true"
        class="col-12 col-md-4"
        formControlName="password"
      ></ct-input>
    </div>
    <div class="row pt-2">
      <div class="col-12 col-md-4">
        <ct-button variant="flat" color="accent" [fullWidth]="true">{{
          'AUTH.LOGIN.LOG_IN_BUTTON' | translate
        }}</ct-button>
      </div>
    </div>
    <div class="row pt-4" *ngIf="showError">
      <div class="col-12 col-md-4 d-flex justify-content-center">
        <h3 class="mat-error">{{ 'AUTH.LOGIN.INVALID_EMAIL_OR_PASSWORD' | translate }}</h3>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col-12 col-md-4 d-flex justify-content-center">
        <ct-link class="forgot-password-link" [link]="['/forgot-password']" underline="true">{{
          'AUTH.LOGIN.FORGOT_PASSWORD' | translate
        }}</ct-link>
      </div>
    </div>
  </form>
</div>
