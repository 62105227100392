<ng-container *ngIf="isLoggedIn$ | async">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ct-banner
        [posts]="blogPosts$ | async"
        [loggedIn]="isLoggedIn$ | async"
        [unregisteredBannerImages]="unregisteredBannerImages"
      ></ct-banner>
      <div class="container">
        <div class="featured">
          <ct-featured-posts *ngIf="blogPosts$ | async as posts" [posts]="posts"></ct-featured-posts>
          <ct-featured-photos class="mt-5" *ngIf="photos$ | async as photos" [photos]="photos"></ct-featured-photos>
        </div>
      </div>
    </div>
  </ct-layout>
</ng-container>

<ng-container *ngIf="(isLoggedIn$ | async) !== true">
  <header class="video-header">
    <video
      #videoElement
      autoplay
      loop
      playsinline
      muted
      (loadedmetadata)="onLoadedMetadata(videoElement)"
      preload="auto"
      poster="assets/images/campingtools_bgloop.jpg"
    >
      <source src="assets/videos/campingtools_bgloop.webm" type="video/webm" />
      <source src="assets/videos/campingtools_bgloop-medium-q.mp4" type="video/mp4" />
    </video>
    <div class="darker-background"></div>
  </header>
  <div class="viewport-header mb-5 pb-5 container">
    <div>
      <article class="row mt-5">
        <section class="col-12 col-md-5 offset-md-1">
          <h1>{{ 'HOME.HEADER.TITLE' | translate }}</h1>
        </section>
        <section class="col-12 col-md-6">
          <p>{{ 'HOME.HEADER.DESCRIPTION' | translate }}</p>
          <ct-link [link]="['/register']">
            <ct-button variant="flat" color="accent">{{ 'COMMON.REGISTER' | translate }}</ct-button>
          </ct-link>

          <div>
            <ct-button variant="icon" class="w-auto video-button mb-2 mt-5" (click)="showIntroVideo()">
              <ct-icon icon="play"></ct-icon>
              <span class="video-button-text">Watch Video on How It Works</span>
            </ct-button>
          </div>
        </section>
      </article>
    </div>
  </div>
  <div class="page-content container">
    <section class="row">
      <section class="col-12 col-md-6">
        <img
          src="assets/images/mature-couple-looking-at-photos-in-family-album-ne-w-5-btjsk.png"
          alt="Mature couple looking at photos in family album"
          class="couple-image"
          srcset="assets/images/mature-couple-looking-at-photos-in-family-album-ne-w-5-btjsk@2x.png 2x"
          loading="lazy"
        />
      </section>
      <article class="col-12 col-md-5 d-flex flex-column justify-content-center">
        <h2 class="text-right">{{ 'HOME.ARTICLES.EVERY_MOMENT.TITLE' | translate }}</h2>
        <p>
          {{ 'HOME.ARTICLES.EVERY_MOMENT.DESCRIPTION' | translate }}
        </p>
      </article>
    </section>
    <section class="row light-grey-bg">
      <article class="col-12 col-md-5 offset-md-1 d-flex flex-column justify-content-center">
        <h2>{{ 'HOME.ARTICLES.PLAN_TRIP.TITLE' | translate }}</h2>
        <p>
          {{ 'HOME.ARTICLES.PLAN_TRIP.DESCRIPTION' | translate }}
        </p>
        <p
          class="features-list"
          [innerHTML]="'HOME.ARTICLES.PLAN_TRIP.FEATURES.LIST_1' | translate | safe : 'html'"
        ></p>
        <p
          class="features-list"
          [innerHTML]="'HOME.ARTICLES.PLAN_TRIP.FEATURES.LIST_2' | translate | safe : 'html'"
        ></p>
      </article>
      <section class="col-12 col-md-6">
        <img
          src="assets/images/my-account.png"
          alt="My account screen"
          class="my-account-screen"
          srcset="assets/images/my-account@2x.png 2x"
          loading="lazy"
        />
      </section>
    </section>
    <section class="row">
      <section class="col-12 col-md-6">
        <img
          src="assets/images/trip-photo-on-computer-screen.png"
          alt="Trip photo on computer screen"
          class="trip-screen"
          srcset="assets/images/trip-photo-on-computer-screen@2x.png 2x"
          loading="lazy"
        />
      </section>
      <article class="col-12 col-md-5 d-flex flex-column justify-content-center">
        <h2 class="text-right">{{ 'HOME.ARTICLES.CAPTURE_TRIP.TITLE' | translate }}</h2>
        <p>
          {{ 'HOME.ARTICLES.CAPTURE_TRIP.DESCRIPTION' | translate }}
        </p>
        <p
          class="features-list"
          [innerHTML]="'HOME.ARTICLES.CAPTURE_TRIP.FEATURES.LIST_1' | translate | safe : 'html'"
        ></p>
        <p
          class="features-list"
          [innerHTML]="'HOME.ARTICLES.CAPTURE_TRIP.FEATURES.LIST_2' | translate | safe : 'html'"
        ></p>
      </article>
    </section>
    <section class="row orange-bg">
      <article class="col-12 col-md-7 offset-md-1">
        <h3>{{ 'HOME.ARTICLES.CAMPING_MEMORIES.TITLE' | translate }}</h3>
        <p class="text-white">
          {{ 'HOME.ARTICLES.CAMPING_MEMORIES.DESCRIPTION' | translate }}
        </p>
      </article>
      <section class="col-12 col-md-4 d-flex justify-content-center align-items-center">
        <ct-link [link]="['/register']">
          <ct-button variant="raised" color="basic">{{ 'COMMON.REGISTER' | translate }}</ct-button>
        </ct-link>
      </section>
    </section>
    <section class="row light-grey-bg">
      <article class="col-12 col-md-5 offset-md-1 d-flex flex-column justify-content-center">
        <h2>{{ 'HOME.ARTICLES.SHARE.TITLE' | translate }}</h2>
        <p>
          {{ 'HOME.ARTICLES.SHARE.DESCRIPTION' | translate }}
        </p>
      </article>
      <section class="col-12 col-md-6">
        <img src="assets/images/share.png" class="share-image" alt="share flow" loading="lazy" />
      </section>
    </section>
    <section class="row">
      <section class="col-12 col-md-6">
        <img
          src="assets/images/rv-with-transparent-background.png"
          alt="rv with transparent background"
          class="rv-image"
          srcset="assets/images/rv-with-transparent-background@2x.png 2x"
          loading="lazy"
        />
      </section>
      <article class="col-12 col-md-5 d-flex flex-column justify-content-center">
        <h2 class="text-right">{{ 'HOME.ARTICLES.SELL.TITLE' | translate }}</h2>
        <p>
          {{ 'HOME.ARTICLES.SELL.DESCRIPTION' | translate }}
        </p>
      </article>
    </section>
    <section class="row orange-bg">
      <article class="col-12 col-lg-6 d-flex flex-column align-items-center offset-lg-3">
        <h3 class="text-center">{{ 'HOME.GET_ACCOUNT' | translate }}</h3>
        <ct-link [link]="['/register']">
          <ct-button variant="raised" color="basic">{{ 'COMMON.REGISTER' | translate }}</ct-button>
        </ct-link>
      </article>
    </section>
  </div>
</ng-container>
