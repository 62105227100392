import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImageUploadService } from '@ct/components';
import { DeletionDialogComponent, DialogService } from '@ct/components/dialog';
import { SpinnerService } from '@ct/components/spinner/services';
import { DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { MyAccountPhotoApiService } from '@ct/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { Mode } from '../../../../enums';
import { toSlug } from '../../../../helpers/to-slug.helper';
import { Trip } from '../../interfaces';
import { ItineraryApiService } from '../../services/itinerary-api.service';
import { TripApiService } from '../../services/trip-api.service';

@Component({
  selector: 'ct-create-trip-dialog',
  templateUrl: './create-trip-dialog.component.html',
  styleUrls: ['./create-trip-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class CreateTripDialogComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public readonly form = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    slug: new UntypedFormControl(''),
    startDate: new UntypedFormControl(null, [Validators.required]),
    endDate: new UntypedFormControl(null),
    featuredPhoto: new UntypedFormControl('')
  });
  public isLoading = false;

  get isEditMode() {
    return this.data?.mode === Mode.Edit;
  }

  get trip(): Trip {
    return this.data.trip;
  }

  get tripId() {
    return this.data.trip?.id;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CreateTripDialogComponent>,
    private router: Router,
    private formState: FormStateDispatcher,
    private tripApiService: TripApiService,
    private itineraryApiService: ItineraryApiService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.isEditMode && this.trip) {
      this.form.patchValue(this.trip);
    }

    this.form.controls.title.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((title) => this.form.controls.slug.patchValue(toSlug(title)));
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    (!this.isEditMode
      ? this.tripApiService
          .create({ ...this.form.value })
          .pipe(
            switchMap((trip) =>
              this.itineraryApiService
                .create({ tripId: trip.id })
                .pipe(map((itinerary) => ({ ...trip, itineraryId: itinerary.id })))
            )
          )
      : this.tripApiService.update(this.tripId, { ...this.form.value })
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((trip) => {
        this.router.navigate(['my-account/trips', trip?.id, toSlug(trip?.title), 'timeline']);
        this.dialogRef.close(trip);
      });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onDelete() {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_TRIP_TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_TRIP_MESSAGE')
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.tripApiService.delete(this.trip.id as string))
      )
      .subscribe(() => {
        this.dialogRef.close();
        this.router.navigate(['my-account/trips']);
      });
  }

  onSelectFeaturedPhotoPlaceholder() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_FEATURED_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(([photo]) => !!photo)
      )
      .subscribe(([photo]) => {
        this.form.controls.featuredPhoto.patchValue(photo);
        this.changeDetectorRef.detectChanges();
      });
  }

  onReplaceFeaturedPhoto() {
    this.onSelectFeaturedPhotoPlaceholder();
  }

  onRemoveFeaturedPhoto() {
    this.form.controls.featuredPhoto.patchValue(null);
  }
}
