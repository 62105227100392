import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-welcome-video',
  templateUrl: './welcome-video.component.html',
  styleUrls: ['./welcome-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeVideoComponent {
  @Input() public readonly title: string | null | undefined;
}
