import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthorModule,
  ButtonModule,
  CardModule,
  DialogModule,
  GalleryModule,
  IconModule,
  ImageCarouselModule,
  LinkModule,
  TagModule,
  WidgetModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';

import { HomeComponent, WelcomeVideoComponent, WhyRegisterComponent } from './components';
import { BannerComponent } from './components/banner/banner.component';
import { FeaturedPhotosComponent } from './components/featured-photos/featured-photos.component';
import { FeaturedPostsComponent } from './components/featured-posts/featured-posts.component';
import { HomeRoutingModule } from './home-routing.module';

@NgModule({
  declarations: [
    HomeComponent,
    WhyRegisterComponent,
    BannerComponent,
    FeaturedPostsComponent,
    FeaturedPhotosComponent,
    WelcomeVideoComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ImageCarouselModule,
    ButtonModule,
    RouterModule,
    LinkModule,
    TranslateModule,
    PipesModule,
    AuthorModule,
    TagModule,
    CardModule,
    DialogModule,
    IconModule,
    BlogSharedModule,
    GalleryModule,
    LayoutModule,
    WidgetModule
  ]
})
export class HomeModule {}
