<article class="row">
  <div class="col-12 col-md-{{ imgCols }}">
    <ct-link [link]="[link]">
      <ct-image-with-tags
        class="image"
        [tags]="showTags ? post?.tags : []"
        tagLocation="bottom"
        [src]="featured"
        [publicUrl]="publicUrl"
        [thumbnail]="thumbnail"
        [height]="imgHeight"
        [noImagePlaceholderPath]="placeholderPath"
        [width]="imgWidth"
        [objectFit]="objectFit"
        [altText]="post?.title + ' featured photo'"
      ></ct-image-with-tags>
    </ct-link>
  </div>
  <div
    class="col-12 col-md-{{ contentCols }} ml-2 ml-md-{{ contentMargin ? 4 : 0 }}"
    [class.pr-4]="contentCols === 12"
    [class.ml-md-1]="contentCols === 12"
  >
    <div class="headline" [class.mt-2]="isHorizontal">
      <ct-link [link]="[link]">
        <h3 class="title">{{ post?.title }}</h3>
      </ct-link>
      <ng-container></ng-container>
    </div>
    <ct-author
      *ngIf="showAuthor"
      class="author"
      [userId]="post?.author?.userId"
      [username]="post?.author?.username"
      [photo]="post?.author?.photo?.xsPreview"
      [date]="post?.createdOn"
    ></ct-author>
    <div class="d-flex justify-content-between">
      <ct-blog-post-status *ngIf="showStatus" [status]="status"></ct-blog-post-status>
      <div *ngIf="showFlags" class="flag" (click)="clickFlag.emit()">
        <ct-icon class="icon" size="fit" title="flag" icon="flag"></ct-icon>
        <span class="flag__note">{{ post?.flags }}</span>
      </div>
    </div>
  </div>
</article>
