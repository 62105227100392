import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ListsApiService } from '@ct/core/services/lists-api.service';
import { forkJoin } from 'rxjs';

import { TripApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class TripTimelineListsResolver implements Resolve<unknown | undefined> {
  constructor(private tripApiService: TripApiService, private listsApiService: ListsApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const id = activatedRouteSnapshot.params?.id;
    return this.getData(id);
  }

  private getData(id: string) {
    return forkJoin({
      lists: this.listsApiService.getAllForTrip(id),
      trip: this.tripApiService.getById(id)
    });
  }
}
