import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';

import { NotificationsState, NotificationStore } from './notification.store';

@Injectable({ providedIn: 'root' })
export class NotificationQuery extends QueryEntity<NotificationsState> {
  selectNotifications$ = this.selectAll({
    sortBy: 'createdOn',
    sortByOrder: Order.DESC
  });

  constructor(protected store: NotificationStore) {
    super(store);
  }
}
