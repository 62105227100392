<div *ngIf="sliderState$ | async; let sliderState" class="g-thumbs-container">
  <div class="g-slider" [class.g-no-transition]="sliderState.active" [ngStyle]="sliderState.style">
    <ct-gallery-thumb
      *ngFor="let item of state?.items; let i = index"
      [type]="item.type"
      [config]="config"
      [data]="item.data"
      [currIndex]="state?.currIndex"
      [index]="i"
      [tapClickDisabled]="config?.disableThumb"
      (tapClick)="thumbClick.emit(i)"
      (errorItem)="errorItem.emit({ itemIndex: i, error: $event })"
    ></ct-gallery-thumb>
  </div>
</div>
