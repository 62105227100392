import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BlogSidebarDataResolver } from '../../../blog/resolvers';
import { MyAccountExploreComponent, MyAccountExploreEntityComponent } from './components';
import { TimelineEntryDataResolver } from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: MyAccountExploreComponent
  },
  {
    path: ':id',
    component: MyAccountExploreEntityComponent,
    resolve: {
      entity: TimelineEntryDataResolver,
      sidebar: BlogSidebarDataResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountExploreRoutingModule {}
