import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UploadedImage } from '@ct/core';

import { ImageUploadApiService } from './../../../services';

@Injectable({ providedIn: 'root' })
export class PhotoDataResolver implements Resolve<UploadedImage | undefined> {
  constructor(private imageUploadApiService: ImageUploadApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const id = activatedRouteSnapshot.params?.photoId;
    return this.imageUploadApiService.getById(id);
  }
}
