import { MenuItem } from '@ct/shared';

export const loggedInMenuItems: MenuItem[] = [
  {
    name: 'my-account',
    titleKey: 'HEADER.BUTTONS.MY_ACCOUNT',
    link: '/my-account/overview'
  },
  {
    name: 'logout',
    titleKey: 'HEADER.BUTTONS.LOGOUT',
    link: ''
  }
];

export const notLoggedInMenuItems: MenuItem[] = [
  {
    name: 'register',
    titleKey: 'COMMON.REGISTER',
    link: '/register'
  },
  {
    name: 'login',
    titleKey: 'HEADER.BUTTONS.LOGIN',
    link: '/login'
  }
];

export const basicMenuItems: MenuItem[] = [
  // FIXME: commented as we're pointing home page to Webflow now
  // {
  //   name: 'home',
  //   titleKey: 'HEADER.MENU.HOME',
  //   link: '/home'
  // },
  {
    name: 'blog',
    titleKey: 'HEADER.MENU.BLOG',
    link: '/stories'
  },
  {
    name: 'marketplace',
    titleKey: 'HEADER.MENU.MARKETPLACE',
    link: '/marketplace'
  },
  // TODO: uncomment it, once Search API works
  // {
  //   name: 'site-search',
  //   titleKey: 'HEADER.MENU.SITE_SEARCH',
  //   link: '/site-search'
  // },
  {
    name: 'faq',
    titleKey: 'COMMON.FAQ.TITLE',
    link: '/faq'
  },
  {
    name: 'contact-us',
    titleKey: 'HEADER.MENU.CONTACT_US',
    link: '/contact-us'
  }
];

export const notLoggedInHomeMenuItems: MenuItem[] = [
  {
    name: 'blog',
    titleKey: 'HEADER.MENU.TRAVEL_STORIES',
    link: '/stories'
  },
  {
    name: 'marketplace',
    titleKey: 'HEADER.MENU.CAMPING_MARKETPLACE',
    link: '/marketplace'
  }
];
