import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AutocompleteModule } from '@ct/components/autocomplete';
import { ButtonModule } from '@ct/components/button';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { LinkModule } from '@ct/components/link';
import { NotificationsModule } from '@ct/components/notifications';
import { UserAvatarModule } from '@ct/components/user-avatar';
import { AddToTripDialogModule } from '@ct/shared/modules/trip-shared/modules/add-to-trip-dialog';
import { CreateTripDialogModule } from '@ct/shared/modules/trip-shared/modules/create-trip-dialog';
import { TranslateModule } from '@ngx-translate/core';

import {
  ExtendedHeaderComponent,
  LayoutFooterComponent,
  LayoutHeaderComponent,
  LayoutSidebarComponent,
  SearchAutocompleteComponent,
  UserHeaderComponent
} from './components';
import { LayoutComponent } from './layout.component';

const MODULES: any[] = [
  CommonModule,
  RouterModule,
  ButtonModule,
  IconModule,
  MatSidenavModule,
  MatToolbarModule,
  LinkModule,
  MatListModule,
  TranslateModule,
  AutocompleteModule,
  ReactiveFormsModule,
  CreateTripDialogModule,
  DialogModule,
  AddToTripDialogModule,
  UserAvatarModule,
  NotificationsModule
];

const COMPONENTS: any[] = [
  LayoutComponent,
  LayoutFooterComponent,
  LayoutHeaderComponent,
  LayoutSidebarComponent,
  SearchAutocompleteComponent,
  ExtendedHeaderComponent,
  UserHeaderComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  exports: [...COMPONENTS]
})
export class LayoutModule {}
