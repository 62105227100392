import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthQuery, AuthService } from '@ct/auth';
import {
  CanonicalFeature,
  CustomValidators,
  EMAIL_MAX_LENGTH,
  FacebookCardType,
  Features,
  FormStateDispatcher,
  MESSAGE_MAX_LENGTH,
  NAME_MAX_LENGTH,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { EmailApiService } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature()])
export class ContactUsComponent {
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.CONTACT_US'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.CONTACT_US',
      descriptionKey: 'CONTACT_US.SUB_TITLE',
      image: 'assets/previews/contact-us.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/contact-us'
    }
  };

  public readonly form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.maxLength(NAME_MAX_LENGTH)]),
    lastName: new UntypedFormControl('', [Validators.maxLength(NAME_MAX_LENGTH)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(EMAIL_MAX_LENGTH)]),
    phone: new UntypedFormControl('', [CustomValidators.phoneNumber]),
    body: new UntypedFormControl('', [Validators.required, Validators.maxLength(MESSAGE_MAX_LENGTH)]),
    captcha: new UntypedFormControl(null, [Validators.required])
  });

  public isSent = false;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  constructor(
    private formState: FormStateDispatcher,
    private emailApiService: EmailApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}

  onSendMessage() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    const { captcha, ...formValue } = this.form.value;

    this.emailApiService.contactUs({ ...formValue }, captcha).subscribe(
      () => {
        this.isSent = true;
        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.form.controls.captcha.reset();
      }
    );
  }
}
