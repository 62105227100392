import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';

import { TextEditorComponent, TextEditorViewComponent } from './components';
import { MatTextEditorComponent } from './components/mat-text-editor/mat-text-editor.component';

@NgModule({
  declarations: [TextEditorComponent, TextEditorViewComponent, MatTextEditorComponent],
  imports: [CommonModule, QuillModule.forRoot(), ReactiveFormsModule, MatFormFieldModule, TranslateModule],
  exports: [TextEditorComponent, TextEditorViewComponent, QuillModule]
})
export class TextEditorModule {}
