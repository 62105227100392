<div *ngIf="isMobile" class="mobile-app-banner__wrapper">
  <div class="mobile-app-banner">
    <img alt="logo" src="assets/logos/logo-small-transparent.png" loading="lazy" />
    <div>
      <p class="mobile-app-banner__title">Camping.Tools</p>
      <p class="mobile-app-banner__title">Travel Companion</p>
    </div>
    <button class="mobile-app-banner__button" (click)="onOpenApp()">Open app</button>
  </div>
</div>
<iframe #mobileIframe width="1" height="1" style="visibility: hidden"></iframe>

<ng-container *ngIf="(isLoggedIn$ | async) === true || isSimple; else defaultLayout">
  <router-outlet></router-outlet>
</ng-container>

<ct-version-check></ct-version-check>

<ng-template #defaultLayout>
  <header>
    <ng-container *ngIf="isTransparent; else defaultHeader">
      <ct-home-header></ct-home-header>
    </ng-container>
    <ng-template #defaultHeader>
      <ct-header [showSearch]="shouldShowSearch"></ct-header>
    </ng-template>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <ct-footer></ct-footer>
  </footer>
</ng-template>
