import { Injectable } from '@angular/core';
import { DialogService } from '@ct/components/dialog/services/dialog.service';
import { Span } from '@ct/opentelemetry';

import { YoutubeVideoAddDialogComponent } from '../components/youtube-video-add-dialog';
import { YoutubeDialogAddConfig } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class YoutubeVideoAddService {
  constructor(private dialogService: DialogService) {}

  @Span()
  showVideoAddDialog(config: YoutubeDialogAddConfig) {
    return this.dialogService.open(YoutubeVideoAddDialogComponent, {
      width: '50vw',
      data: { config }
    });
  }
}
