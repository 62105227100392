import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UploadedImage } from '@ct/core';
import { MyAccountPhotoApiService, SortOrder } from '@ct/shared';

import { MY_ACCOUNT_PHOTOS_FEATURE_LIMIT } from '../../../constants';

@Injectable({ providedIn: 'root' })
export class MyAccountPhotosDataResolver implements Resolve<UploadedImage[]> {
  constructor(private myAccountPhotoApiService: MyAccountPhotoApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const sortOrder = activatedRouteSnapshot.queryParams?.sortOrder as SortOrder;
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.myAccountPhotoApiService.getAllMyPhotos({
      range: { limit: MY_ACCOUNT_PHOTOS_FEATURE_LIMIT, offset },
      sortOrder
    });
  }
}
