import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UserProfileApiService } from '@ct/auth';
import { UserProfile } from '@ct/core';

@Injectable({ providedIn: 'root' })
export class BlogPostsUserResolver implements Resolve<UserProfile> {
  constructor(private userProfileApiService: UserProfileApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const authorId = activatedRouteSnapshot.queryParams?.authorId as string;
    return this.userProfileApiService.getByUserId(authorId);
  }
}
