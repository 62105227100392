import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconComponent, IconSetComponent } from './components';
import { IconService } from './services';

@NgModule({
  declarations: [IconComponent, IconSetComponent],
  providers: [IconService],
  imports: [CommonModule, MatIconModule],
  exports: [IconComponent, IconSetComponent]
})
export class IconModule {}
