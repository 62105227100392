<div class="like">
  <ct-icon
    class="icon"
    size="fit"
    title="like"
    icon="like"
    [ngClass]="{ disabled: loading || !user, accent: isLiked && !loading }"
  ></ct-icon>
  <span class="count">{{ count || 0 }}</span>
</div>
