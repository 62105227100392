import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';

import { defaultState } from '../constants';
import { GalleryAction } from '../enums';
import { GalleryConfig, GalleryError, GalleryItem, GalleryState } from '../interfaces';
import { IframeItem, ImageItem, VideoItem, YoutubeItem } from '../models';

const filterActions = (actions: string[]) => {
  return filter((state: GalleryState) => actions.indexOf(state.action as string) > -1);
};

export class GalleryRef {
  public readonly itemClick = new Subject<number>(); // Stream that emits on item click
  public readonly thumbClick = new Subject<number>(); // Stream that emits on thumbnail click
  public readonly error = new Subject<GalleryError>(); // Stream that emits on an error occurs
  public readonly state: Observable<GalleryState>; // Stream that emits gallery state
  public readonly config: Observable<GalleryConfig>; // Stream that emits gallery config

  // Stream that emits when gallery is initialized/reset
  get initialized(): Observable<GalleryState> {
    return this.state.pipe(filterActions([GalleryAction.INITIALIZED]));
  }

  // Stream that emits when items is changed (items loaded, item added, item removed)
  get itemsChanged(): Observable<GalleryState> {
    return this.state.pipe(filterActions([GalleryAction.ITEMS_CHANGED]));
  }

  // Stream that emits when current item is changed
  get indexChanged(): Observable<GalleryState> {
    return this.state.pipe(filterActions([GalleryAction.INDEX_CHANGED]));
  }

  // Stream that emits when the player should start or stop
  get playingChanged(): Observable<GalleryState> {
    return this.state.pipe(filterActions([GalleryAction.PLAY, GalleryAction.STOP]));
  }

  // Stream that emits when the player should start or stop
  private get playerActions(): Observable<GalleryState> {
    return this.state.pipe(filterActions([GalleryAction.PLAY, GalleryAction.STOP, GalleryAction.INDEX_CHANGED]));
  }

  private readonly state$: BehaviorSubject<GalleryState>;
  private readonly config$: BehaviorSubject<GalleryConfig>;

  constructor(config: GalleryConfig, private deleteInstance: () => void) {
    this.state$ = new BehaviorSubject<GalleryState>(defaultState);
    this.config$ = new BehaviorSubject<GalleryConfig>(config);
    this.state = this.state$.asObservable();
    this.config = this.config$.asObservable();
  }

  /**
   * Activate player actions listener
   */
  activatePlayer(): Observable<GalleryState> {
    return this.playerActions.pipe(
      switchMap((e: GalleryState) =>
        e.isPlaying
          ? of({}).pipe(
              delay(this.config$.value.playerInterval as number),
              tap(() => this.next())
            )
          : EMPTY
      )
    );
  }

  /**
   * Set gallery state
   */
  private setState(state: GalleryState) {
    this.state$.next({ ...this.state$.value, ...state });
  }

  /**
   * Set gallery config
   */
  setConfig(config: GalleryConfig) {
    this.config$.next({ ...this.config$.value, ...config });
  }

  /**
   * Add gallery item
   */
  add(item: GalleryItem, active?: boolean) {
    const items = [...(this.state$.value.items as GalleryItem[]), item];
    this.setState({
      action: GalleryAction.ITEMS_CHANGED,
      items,
      hasNext: items.length > 1,
      currIndex: active ? items.length - 1 : this.state$.value.currIndex
    });
  }

  /**
   * Add image item
   */
  addImage(data: any, active?: boolean) {
    this.add(new ImageItem(data), active);
  }

  /**
   * Add video item
   */
  addVideo(data: any, active?: boolean) {
    this.add(new VideoItem(data), active);
  }

  /**
   * Add iframe item
   */
  addIframe(data: any, active?: boolean) {
    this.add(new IframeItem(data), active);
  }

  /**
   * Add youtube item
   */
  addYoutube(data: any, active?: boolean) {
    this.add(new YoutubeItem(data), active);
  }

  /**
   * Remove gallery item
   */
  remove(i: number) {
    const items = [
      ...(this.state$.value.items as GalleryItem[]).slice(0, i),
      ...(this.state$.value.items as GalleryItem[]).slice(i + 1, (this.state$.value.items as GalleryItem[]).length)
    ];
    this.setState({
      action: GalleryAction.ITEMS_CHANGED,
      items,
      hasNext: items.length > 1,
      hasPrev: i > 0
    });
  }

  /**
   * Load items and reset the state
   */
  load(items: GalleryItem[]) {
    if (items) {
      this.setState({
        action: GalleryAction.ITEMS_CHANGED,
        items,
        hasNext: items.length > 1,
        hasPrev: false
      });
    }
  }

  /**
   * Set active item
   */
  set(i: number) {
    if (i !== this.state$.value.currIndex) {
      this.setState({
        action: GalleryAction.INDEX_CHANGED,
        currIndex: i,
        hasNext: i < (this.state$.value.items as GalleryItem[]).length - 1,
        hasPrev: i > 0
      });
    }
  }

  /**
   * Next item
   */
  next() {
    if (this.state$.value.hasNext) {
      this.set((this.state$.value.currIndex as number) + 1);
    }
  }

  /**
   * Prev item
   */
  prev() {
    if (this.state$.value.hasPrev) {
      this.set((this.state$.value.currIndex as number) - 1);
    }
  }

  /**
   * Start gallery player
   */
  play(interval?: number) {
    if (interval) {
      this.setConfig({ playerInterval: interval });
    }
    this.setState({ action: GalleryAction.PLAY, isPlaying: true });
  }

  /**
   * Stop gallery player
   */
  stop() {
    this.setState({ action: GalleryAction.STOP, isPlaying: false });
  }

  /**
   * Reset gallery to initial state
   */
  reset() {
    this.setState(defaultState);
  }

  /**
   * Destroy gallery
   */
  destroy() {
    this.state$.complete();
    this.config$.complete();
    this.itemClick.complete();
    this.thumbClick.complete();
    this.deleteInstance();
  }
}
