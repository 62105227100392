import { Injectable } from '@angular/core';

import { TripCampsite } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class TripCampsiteFactory {
  toRequest(campsite: Partial<TripCampsite>): Partial<TripCampsite> {
    const { id, featuredPhoto, ...value } = campsite;

    if (featuredPhoto !== undefined) {
      return {
        ...value,
        featuredPhotoId: (featuredPhoto?.id as string) ?? null
      };
    }

    return { ...value };
  }
}
