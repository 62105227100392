import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MarketplaceApiService, MerchantProfileApiService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class CanEditGuard implements CanActivate {
  constructor(
    private merchantProfileApiService: MerchantProfileApiService,
    private marketplaceApiService: MarketplaceApiService,
    private router: Router
  ) {}

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.merchantProfileApiService.getMyProfile().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.router.navigate(['/marketplace/create-merchant'], { queryParams: { returnUrl: state.url } });
        } else if (error.status === 400) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return of(null);
      }),
      switchMap((merchantProfile) => {
        if (!merchantProfile) {
          return of(false);
        }

        const slugWithId = routeSnapshot.params.slugWithId;
        const slugArray = slugWithId.split('-');
        const id = slugArray[slugArray.length - 1];

        return this.marketplaceApiService.getById(id).pipe(
          map(({ merchantId }) => {
            return merchantId === merchantProfile.userId;
          })
        );
      })
    );
  }
}
