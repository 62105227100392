import { ApplicationRef, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { DestroyableFeature, Features } from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { concat, forkJoin, interval, Observable } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';

const UPDATE_CHECK_INTERVAL = 5 * 60 * 1000; // every 5 mins;

@Component({
  selector: 'ct-version-check',
  templateUrl: './version-check.component.html',
  styleUrls: ['./version-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class VersionCheckComponent implements OnInit {
  readonly destroyed$: Observable<void>;

  @Input()
  readonly forceReload: boolean;

  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | null;

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly appRef: ApplicationRef,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.swUpdate.versionUpdates
      .pipe(
        takeUntil(this.destroyed$),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(async () => {
        if (this.forceReload) {
          // force reload page with new files
          return await this.refreshApp();
        }
        // open toast with info about update - SW checks updates on each network request
        this.openSnackbar();
      });
    this.checkUpdate();
  }

  openSnackbar() {
    const message$ = this.translateService.get('MAIN.VERSION_CHECK.MESSAGE');
    const action$ = this.translateService.get('MAIN.VERSION_CHECK.ACTION');
    if (!this.snackBarRef) {
      forkJoin([message$, action$]).subscribe(([message, action]) => {
        this.snackBarRef = this.snackBar.open(message, action);
        this.snackBarRef
          .onAction()
          .pipe(take(1))
          .subscribe(async () => {
            this.snackBarRef = null;
            await this.refreshApp();
          });
      });
    }
  }

  checkUpdate() {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
    const checkInterval$ = interval(UPDATE_CHECK_INTERVAL);
    const checkIntervalOnceAppIsStable$ = concat(appIsStable$, checkInterval$);

    // check for updates manually if no network calls done for defined period of time
    checkIntervalOnceAppIsStable$.subscribe(async () => {
      if (this.swUpdate.isEnabled) {
        await this.swUpdate.checkForUpdate();
      }
    });
  }

  async refreshApp() {
    await this.swUpdate.activateUpdate();
    document.location.reload();
  }
}
