<mat-form-field appearance="outline">
  <textarea
    class="textarea"
    [rows]="rows"
    [formControl]="control"
    matInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    [cdkAutosizeMaxRows]="rows"
    [placeholder]="label || placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
  ></textarea>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'COMMON.VALIDATIONS.REQUIRED' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <ng-container *ngIf="control.getError('minlength') as error">
      {{ 'COMMON.VALIDATIONS.MIN_LENGTH' | translate: { length: error.requiredLength } }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error">
      {{ 'COMMON.VALIDATIONS.MAX_LENGTH' | translate: { length: error.requiredLength } }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanum')">
    {{ 'COMMON.VALIDATIONS.ALPHANUM' | translate }}
  </mat-error>
</mat-form-field>
