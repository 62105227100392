import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'ct-card-section',
  templateUrl: './card-section.component.html',
  styleUrls: ['./card-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardSectionComponent {
  @Input() public readonly image: string;
  @Input() public readonly title: string;
  @Input() public readonly extraActionLabel: string;
  @Input() public readonly extraActionLink: string[];
  @Input() public readonly extraActionQueryParams: Params;

  @Input() public readonly showLine: boolean = true;
}
