import { EntityType } from '@ct/shared/enums/share-entity-type.enum';

import { EntityThread } from './entity-thread.interface';
import { LikeSummary } from './like-summary.interface';

export enum VideoOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape'
}

export enum TranscodingState {
  PROCESSING_STATE_UNSPECIFIED = 'PROCESSING_STATE_UNSPECIFIED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export class VideoUploadEntity {
  readonly id?: string;
  readonly authorId: string;
  readonly name: string;
  readonly fileName: string;
  readonly duration: number;
  readonly width: number;
  readonly height: number;
  readonly thumbnailUrl?: string;
  readonly orientation: VideoOrientation;
  readonly transcodingJobId?: string;
  readonly transcodingJobStatus: TranscodingState;
  readonly createdOn: Date;
  readonly modifiedOn: Date;
}

export class EnrichedVideoUploadEntity extends VideoUploadEntity {
  readonly type: EntityType.Video;
  metadata?: {
    likes?: LikeSummary;
    threads?: EntityThread[];
  };
  metadataId?: string;
}
