import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'ct-gallery-iframe',
  templateUrl: './gallery-iframe.component.html',
  styleUrls: ['./gallery-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryIframeComponent {
  @Input() src: string;

  @Input() set pause(shouldPause: boolean) {
    if (this.iframe.nativeElement) {
      if (shouldPause) {
        const iframe: HTMLIFrameElement = this.iframe.nativeElement;
        iframe.src = null as any; // TODO: fix any
      }
    }
  }

  @Input() autoplay: boolean;

  @ViewChild('iframe', { static: true }) iframe: ElementRef;
}
