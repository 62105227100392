<iframe
  *ngIf='autoplay'
  #iframe
  frameborder="0"
  allowfullscreen
  allow="autoplay"
  [src]="src | safe: 'resourceUrl'"
>
</iframe>
<iframe
  *ngIf='!autoplay'
  #iframe
  frameborder="0"
  allowfullscreen
  allow=""
  [src]="src | safe: 'resourceUrl'"
>
</iframe>
