<div class="card" [class.card--heading]="isHeading">
  <ct-progressive-image
    *ngIf="image || imageUrl"
    [publicUrl]="imageUrl ?? image?.publicUrl"
    [thumbnail]="imageThumbnail ?? image?.xsPreview"
    [noImagePlaceholder]="true"
    [noImagePlaceholderPath]="noImagePlaceholderPath"
    [backgroundRepeat]="true"
    [objectFit]="'cover'"
  ></ct-progressive-image>
  <div class="text-wrapper">
    <ct-link *ngIf="link; else noLink" [link]="link" class="w-100">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ct-link>
  </div>
</div>

<ng-template #noLink>
  <div class="w-100">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <div class="card__date">
    <ng-content select="[ctCardTitlePrefixInfo]"></ng-content>
    <ng-container *ngIf="hasDateRange">
      {{ item?.startDate | date : 'MMM d yyyy' | uppercase }}
      - {{ (item?.endDate | date : 'MMM d yyyy') || (item ? ('COMMON.DATE.NOW' | translate) : ('' | uppercase)) }}
      &nbsp;&nbsp;&nbsp;
    </ng-container>
    <ng-container *ngIf="hasExactDate">
      &nbsp;&nbsp;&nbsp;
      {{ item?.publishedOn ?? item?.createdOn | date : 'MMM d yyyy' | uppercase }}
      &nbsp;&nbsp;&nbsp;
    </ng-container>
    <ng-content select="[ctCardTitleSuffixInfo]"></ng-content>
  </div>
  <div class="card__title--wrapper mb-1" [title]="item?.title">
    <span class="card__title">{{ item?.title }}</span>
    <span class="card__actions">
      <ng-content select="[ctCardActions]"></ng-content>
    </span>
  </div>
  <ng-content select="[ctCardFooter]"></ng-content>
</ng-template>
