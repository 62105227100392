import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ct-discard-changes-dialog',
  templateUrl: './discard-changes-dialog.component.html',
  styleUrls: ['./discard-changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscardChangesDialogComponent {
  constructor(private dialogRef: MatDialogRef<DiscardChangesDialogComponent>) {}

  onConfirm() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
