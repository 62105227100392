import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@ct/components/spinner/services';
import { BaseHttpService, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { SetActiveOptions } from '@datorama/akita';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { EntityType, SortOrder } from '../../../enums';
import { ShareApiService } from '../../../services/share-api.service';
import { Trip } from '../interfaces';
import { TripStore } from './state';
import { TripJournalEntryApiService } from './trip-journal-entry-api.service';

const endpoint = environment.tripApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class TripApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    protected tripJournalEntryApiService: TripJournalEntryApiService,
    protected shareApiService: ShareApiService<Trip>,
    protected spinnerService: SpinnerService,
    protected store: TripStore
  ) {
    super(httpClient, endpoint);
  }

  setActive(id: string) {
    this.store.setActive(id as SetActiveOptions); // exactly same code doesn't require type cast in tag-api.service...
  }

  getAll({
    range,
    sortOrder = SortOrder.Desc,
    authorId,
    fullEntries = true,
    sortBy
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    authorId?: string;
    fullEntries?: boolean;
    sortBy?: keyof Trip;
  }): Observable<Trip[]> {
    this.store.setLoading(true);
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (fullEntries !== undefined && fullEntries !== null) {
      params = params.append('fullEntries', fullEntries);
    }

    return this.get('', params, { headers, withCredentials: true }).pipe(
      finalize(() => this.store.setLoading(false)),
      tap((trips) => {
        if (trips?.length) {
          this.store.upsertMany(trips);
        }
      })
    );
  }

  getAllByAuthorId(
    authorId: string,
    {
      range,
      sortOrder,
      sortBy
    }: {
      range?: RequestRange;
      sortOrder?: SortOrder | undefined;
      sortBy?: keyof Trip;
    }
  ) {
    return this.getAll({ range, sortOrder, authorId, sortBy });
  }

  getById(tripId: string) {
    this.store.setLoading(true);
    return this.get(tripId).pipe(
      tap((trip) => {
        this.store.upsert(tripId, trip);
        this.store.setLoading(false);
        this.setActive(tripId);
      })
    );
  }

  getTimeline(tripId: string, range?: RequestRange) {
    this.spinnerService.show();
    this.store.setLoading(true);
    let params = new HttpParams();
    params = params.append('tripId', tripId);
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    return this.get(`v2/timeline`, params).pipe(finalize(() => this.spinnerService.hide()));
  }

  getTimelineMedia(tripId: string, range?: RequestRange) {
    this.spinnerService.show();
    this.store.setLoading(true);
    let params = new HttpParams();
    params = params.append('tripId', tripId);
    params = params.append('isMedia', true);
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    return this.get(`v2/timeline`, params).pipe(finalize(() => this.spinnerService.hide()));
  }

  removeTimeline(id: string) {
    return this.delete(`v2/timeline/${id}`);
  }

  create(trip: Trip) {
    const body = this.toRequest(trip);
    return this.post('', body);
  }

  update(id: string, trip: Partial<Trip>) {
    const body = this.toRequest(trip);
    return this.patch(id, body);
  }

  remove(id: string) {
    return this.delete(id);
  }

  search(search: string, range?: RequestRange) {
    return this.get(`search`, { query: search, ...range });
  }

  getAllShared({
    authorId,
    userId,
    range,
    sortOrder
  }: {
    authorId: string;
    userId: string;
    range?: RequestRange;
    sortOrder: SortOrder;
  }): Observable<Trip[]> {
    return this.shareApiService.getAllEntities({
      entityType: EntityType.Trip,
      userId,
      authorId,
      range,
      sortOrder
    });
  }

  private toRequest(trip: Partial<Trip>): Partial<Trip> {
    const { id, featuredPhoto, ...value } = trip;

    if (featuredPhoto !== undefined) {
      return {
        ...value,
        featuredPhotoId: (featuredPhoto?.id as string) ?? null
      };
    }

    return { ...value };
  }
}
