import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@ct/core';

import { UserAvatarModule } from '../user-avatar';
import { AuthorComponent } from './author.component';

@NgModule({
  declarations: [AuthorComponent],
  imports: [CommonModule, PipesModule, UserAvatarModule, RouterModule],
  exports: [AuthorComponent]
})
export class AuthorModule {}
