import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, RequestRange, Tag } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SortOrder } from '../enums';
import { TagsStore } from './tag-state';

const endpoint = environment.tagApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class TagApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient, protected store: TagsStore) {
    super(httpClient, endpoint);
  }

  getAll(name: string, sortOrder: SortOrder = SortOrder.Desc, authorId?: string): Observable<any[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (name !== undefined && name !== null) {
      params = params.append('name', name);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    return this.get('', params, { headers, withCredentials: true }).pipe(
      tap((tags) => {
        this.store.set(tags);
      })
    );
  }

  create(name: string) {
    return this.post('', { name });
  }

  getByName(name: string) {
    return this.getAll(name)
      .pipe(map((tags) => tags.find((tag: Tag) => tag.name?.toLowerCase() === name?.toLowerCase())))
      .pipe(
        tap((tag) => {
          if (tag) {
            this.store.upsert(tag.id, tag);
          }
        })
      );
  }

  getById(id: string): Observable<Tag> {
    return this.get(`${id}`).pipe(
      tap((tag) => {
        this.store.upsert(tag.id, tag);
        this.setActive(id);
      })
    );
  }

  setActive(id: string) {
    this.store.setActive(id);
  }

  search(search: string, range?: RequestRange) {
    return this.get(`search`, { query: search, ...range });
  }
}
