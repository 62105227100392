<ct-card-wrapper
  [item]="cardItem"
  [link]="link"
  [hasExactDate]="true"
  [isHeading]="true"
  [imageThumbnail]="thumbnail"
  [imageUrl]="publicUrl"
  [noImagePlaceholderPath]="placeholderPath"
>
  <span *ngIf="story" ctCardActions>
    <ct-icon *ngIf="canEdit" icon="edit" class="mr-2" title="edit" (click)="onEditClick()"></ct-icon>
    <ct-icon *ngIf="canEdit || canShare" icon="share" class="mr-2" title="share" (click)="onShareClick()"></ct-icon>
    <ct-icon *ngIf="canOpen" icon="left-arrow-square" title="open" (click)="onOpenClick()"></ct-icon>
  </span>
  <ng-container ctCardTitlePrefixInfo>
    <ct-author
      [userId]="story?.author?.userId"
      [username]="story?.author?.username"
      [photo]="getCroppedThumbPublicUrl(story?.author?.photo?.publicUrl)"
    ></ct-author>
  </ng-container>
  <div class="tags-wrapper" ctCardTitleSuffixInfo>
    <span *ngIf="showLastEdit">Last Edited</span>
    <div class="tags">
      <ct-link
        *ngFor="let tag of story?.tags"
        [link]="['/stories/by-tag']"
        [queryParams]="{ tagId: tag.id, authorId: null }"
        inline="true"
      >
        <ct-tag class="ml-1" [name]="tag.name"></ct-tag>
      </ct-link>
    </div>
  </div>
  <ng-container ctCardFooter>
    <ng-content select="[ctCardFooter]"></ng-content>
  </ng-container>
</ct-card-wrapper>
