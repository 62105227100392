import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService extends Title {
  private defaultTitleSuffix = '';
  private sepator = ' | ';

  setTitle(title: string): void {
    super.setTitle(title + this.defaultTitleSuffix);
  }

  setTitleWithoutSuffix(title: string) {
    super.setTitle(title);
  }

  setTitleWithSuffix(title: string, suffix: string) {
    super.setTitle(title + this.sepator + suffix);
  }

  setDefaultSuffix(suffix: string) {
    this.defaultTitleSuffix = this.sepator + suffix;
  }

  getDefaultSuffix() {
    return this.defaultTitleSuffix;
  }
}
