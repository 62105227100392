<ng-container *ngIf="isLoggedIn$ | async; else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <ct-image-banner img="contact-us" [title]="'CONTACT_US.IMG_TITLE' | translate"></ct-image-banner>
  <div class="container contact-us">
    <div *ngIf="!isSent">
      <h1 class="title text-center">{{ 'CONTACT_US.TITLE' | translate }}</h1>
      <h4 class="text-center pl-1 pr-1">
        {{ 'CONTACT_US.SUB_TITLE' | translate }}
      </h4>
      <div class="p-3">
        <form class="mt-3 form" [formGroup]="form" (ngSubmit)="onSendMessage()">
          <div class="row">
            <ct-input
              [label]="'CONTACT_US.FIRST_NAME' | translate"
              [placeholder]="'CONTACT_US.FIRST_NAME_PLACEHOLDER' | translate"
              class="col-12"
              formControlName="firstName"
            ></ct-input>
          </div>
          <div class="row">
            <ct-input
              [label]="'CONTACT_US.LAST_NAME' | translate"
              [placeholder]="'CONTACT_US.LAST_NAME_PLACEHOLDER' | translate"
              class="col-12"
              formControlName="lastName"
            ></ct-input>
          </div>
          <div class="row">
            <ct-input
              type="email"
              [label]="'CONTACT_US.EMAIL_ADDRESS' | translate"
              [placeholder]="'CONTACT_US.EMAIL_ADDRESS_PLACEHOLDER' | translate"
              class="col-12"
              formControlName="email"
            ></ct-input>
          </div>
          <div class="row">
            <ct-input
              [label]="'CONTACT_US.PHONE_NUMBER' | translate"
              [placeholder]="'CONTACT_US.PHONE_NUMBER_PLACEHOLDER' | translate"
              class="col-12"
              formControlName="phone"
            ></ct-input>
          </div>
          <div class="row">
            <ct-textarea
              [label]="'CONTACT_US.MESSAGE' | translate"
              [placeholder]="'CONTACT_US.MESSAGE_PLACEHOLDER' | translate"
              class="col-12"
              formControlName="body"
            ></ct-textarea>
          </div>
          <div class="row">
            <ct-re-captcha class="col-12" formControlName="captcha"></ct-re-captcha>
          </div>
          <div class="row">
            <div class="col-12">
              <ct-button variant="flat" color="accent">{{ 'CONTACT_US.SEND_MESSAGE_BUTTON' | translate }}</ct-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <ct-contact-us-gratitude *ngIf="isSent" mode="dark" size="large"></ct-contact-us-gratitude>
  </div>
</ng-template>
