<div class="ct-dialog-content">
  <div class="ct-dialog-content__header">
    <div class="ct-dialog-content__header-wrapper">
      <ct-icon *ngIf="headerIcon" class="ct-dialog-content__header-icon" size="fit" [icon]="headerIcon"></ct-icon>
      <div class="title-wrapper">
        <h2 *ngIf="title || titleKey" class="ct-dialog-content__title" mat-dialog-title>
          {{ title || (titleKey | translate) }}
        </h2>
        <div *ngIf="subtitle" class="ct-dialog-content__subtitle">
          {{ subtitle }}
        </div>
      </div>
    </div>
    <div class="ct-dialog-content__icons-wrapper">
      <ct-icon
        *ngIf="backIcon"
        class="ct-dialog-content__close-icon"
        size="fit"
        icon="left"
        (click)="back.emit()"
      ></ct-icon>
      <ct-icon
        *ngIf="closeIcon"
        class="ct-dialog-content__close-icon"
        size="fit"
        icon="close"
        [mat-dialog-close]="closeIconParams"
      ></ct-icon>
    </div>
  </div>
  <mat-dialog-content class="ct-dialog-content__content">
    <ng-content></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="buttons?.length" class="ct-dialog-content__actions" [attr.align]="alignButtons">
    <ct-button
      *ngFor="let button of buttons"
      class="mr-2"
      [variant]="button.variant || 'flat'"
      [color]="button.color || 'accent'"
      [disabled]="button.disabled"
      (click)="onButtonClicked(button)"
      >{{ button.labelKey | translate }}</ct-button
    >
  </mat-dialog-actions>
</div>
