import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { PasswordStrength } from './password-strength.enum';

export interface Strength {
  text$: Observable<string>;
  color: ThemePalette | undefined;
  name: string;
  percent: number;
}

@Component({
  selector: 'ct-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() public readonly password: string;
  @Input() public strength: Strength | undefined;

  public get percent(): number {
    return this.strength?.percent || 0;
  }

  private strengthMap = new Map([
    [
      PasswordStrength.LessThanRequired,
      {
        text$: this.translateService.get('COMPONENTS.PASSWORD_STRENGTH.LESS_THAN_REQUIRED', {
          length: PASSWORD_MIN_LENGTH
        }),
        color: 'warn' as ThemePalette,
        name: PasswordStrength.LessThanRequired,
        percent: 0
      }
    ],
    [
      PasswordStrength.MoreThanRequired,
      {
        text$: this.translateService.get('COMPONENTS.PASSWORD_STRENGTH.MORE_THAN_REQUIRED', {
          length: PASSWORD_MAX_LENGTH
        }),
        color: 'warn' as ThemePalette,
        name: PasswordStrength.MoreThanRequired,
        percent: 0
      }
    ],
    [
      PasswordStrength.NoPassword,
      {
        text$: this.translateService.get('COMPONENTS.PASSWORD_STRENGTH.NO_PASSWORD'),
        color: 'warn' as ThemePalette,
        name: PasswordStrength.NoPassword,
        percent: 0
      }
    ],
    [
      PasswordStrength.Weak,
      {
        text$: this.translateService.get('COMPONENTS.PASSWORD_STRENGTH.WEAK'),
        color: 'warn' as ThemePalette,
        name: PasswordStrength.Weak,
        percent: 100 / 3 // 1/3
      }
    ],
    [
      PasswordStrength.JustOk,
      {
        text$: this.translateService.get('COMPONENTS.PASSWORD_STRENGTH.JUST_OK'),
        color: 'primary' as ThemePalette,
        name: PasswordStrength.JustOk,
        percent: (100 / 3) * 2 // 2/3
      }
    ],
    [
      PasswordStrength.Strong,
      {
        text$: this.translateService.get('COMPONENTS.PASSWORD_STRENGTH.STRONG'),
        color: undefined,
        name: PasswordStrength.Strong,
        percent: 100 // 3/3
      }
    ]
  ]);

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.password?.currentValue !== changes?.password?.previousValue) {
      this.strength = this.checkStrength(changes.password.currentValue);
    }
  }

  checkStrength(password: string): Strength | undefined {
    if (!password) {
      return this.strengthMap.get(PasswordStrength.NoPassword);
    }
    if (password?.length < PASSWORD_MIN_LENGTH) {
      return this.strengthMap.get(PasswordStrength.LessThanRequired);
    }
    if (password?.length > PASSWORD_MAX_LENGTH) {
      return this.strengthMap.get(PasswordStrength.MoreThanRequired);
    }
    const hasLower: boolean = new RegExp('(?=.*[a-z])').test(password);
    const hasUpper: boolean = new RegExp('(?=.*[A-Z])').test(password);
    const hasNum: boolean = new RegExp('(?=.*\\d)').test(password);
    const hasSpecial: boolean = new RegExp('[!@#$%^&*(),.?":{}|<>]').test(password);
    const mainScore: number =
      [hasLower, hasUpper, hasNum, hasSpecial].reduce((acc, check) => (acc += check ? 1 : 0), 0) * 10;
    const lengthScore: number = this.getLengthScore(password.length);
    const score: number = mainScore + lengthScore;

    return this.getStrength(score);
  }

  getStrength(score: number): Strength | undefined {
    if (score > 50) {
      return this.strengthMap.get(PasswordStrength.Strong);
    }
    if (score > 30 && score <= 50) {
      return this.strengthMap.get(PasswordStrength.JustOk);
    }
    if (score > 0 && score <= 30) {
      return this.strengthMap.get(PasswordStrength.Weak);
    }
  }

  getLengthScore(length: number): number {
    if (length >= 8 && length < 10) {
      return 10;
    }
    if (length >= 10 && length < 15) {
      return 20;
    }
    if (length >= 15) {
      return 30;
    }
    return 0;
  }
}
