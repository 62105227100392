import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { ButtonType, ButtonVariant } from './button.interface';

@Component({
  selector: 'ct-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() readonly type: ButtonType;
  @Input() readonly color: ThemePalette | string;
  @Input() readonly variant: ButtonVariant | undefined;
  @Input() readonly fullWidth: boolean;
  @Input() @HostBinding('attr.disabled') readonly disabled: boolean | undefined;
}
