import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { ButtonBase, ButtonVariant } from '../../../button';
import { IconType } from '../../../icon';

export interface DialogButton extends ButtonBase {
  labelKey: string;
  clicked: () => void;
  disabled?: boolean;
  color?: ThemePalette;
  variant?: ButtonVariant;
}

@Component({
  selector: 'ct-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContentComponent {
  @Input() buttons: DialogButton[] = [];
  @Input() titleKey: string;
  @Input() headerIcon?: IconType;
  @Input() subtitle?: string;
  @Input() title: string | null | undefined;
  @Input() alignButtons: 'start' | 'end' = 'start';
  @Input() closeIcon: boolean | undefined = true;
  @Input() backIcon: boolean | undefined;
  @Input() closeIconParams: any;

  @Output() buttonClicked = new EventEmitter<DialogButton>();

  @Output() back = new EventEmitter<void>();

  onButtonClicked(button: DialogButton) {
    if (button.clicked) {
      button.clicked();
    }
    return this.buttonClicked.emit(button);
  }
}
