import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { Category, MarketplaceItem, SubCategory } from '../../interfaces';

export interface MarketplaceState {
  items: MarketplaceItem[];
  otherItems?: MarketplaceItem[];
  categories: Category[];
  subCategories: SubCategory[];
}

export function createInitialState(): MarketplaceState {
  return {
    items: [],
    otherItems: [],
    categories: [],
    subCategories: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'marketplace' })
export class MarketplaceStore extends Store<MarketplaceState> {
  constructor() {
    super(createInitialState());
  }
}
