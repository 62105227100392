<form [formGroup]="form">
  <ct-input
    class="main-password"
    [formControl]="passwordControl"
    [label]="'AUTH.REGISTER.PASSWORD' | translate"
    [type]="hiddenPassword ? 'password' : 'text'"
    [suffixIcon]="hiddenPassword ? 'visibility_off' : 'visibility'"
    [attr.lightLabel]="lightLabel"
    (suffixIconClicked)="onPasswordIcon()"
  ></ct-input>
  <ct-password-strength [password]="passwordControl?.value" [attr.lightLabel]="lightLabel"></ct-password-strength>
  <ct-input
    [formControl]="confirmPasswordControl"
    [label]="'AUTH.REGISTER.CONFIRM_PASSWORD' | translate"
    [type]="hiddenConfirmPassword ? 'password' : 'text'"
    [suffixIcon]="hiddenConfirmPassword ? 'visibility_off' : 'visibility'"
    [attr.lightLabel]="lightLabel"
    (suffixIconClicked)="onConfirmPasswordIcon()"
  ></ct-input>
</form>
