import { UntypedFormGroup, ValidationErrors, Validator } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IDestroyable } from '../lifecycle';
import { BaseControl } from './base-control.class';

export abstract class BaseFormComponent<T, R = T> extends BaseControl<T> implements Validator, IDestroyable {
  readonly destroyed$: Observable<void>;
  abstract readonly form: UntypedFormGroup;
  protected onValidatorChange?: () => void;
  protected onChanged?: (value: T) => void;
  protected onTouched?: () => void;

  protected readonly viewToModelParser?: (value: T | R | null) => T | R | null = identity;
  protected readonly modelToViewFormatter?: (value: T | R | null) => T | R | null = identity;

  get value(): T {
    return this.form.value;
  }

  writeValue(value: T | null): void {
    if (value) {
      return this.form.patchValue(this.modelToViewFormatter?.(value) as never, {
        emitEvent: false
      });
    }
  }

  registerOnChange(fn: (value: T) => void) {
    super.registerOnChange(fn);

    this.form.valueChanges.pipe<T>(takeUntil(this.destroyed$)).subscribe((value) => {
      this.onChanged?.(this.viewToModelParser?.(value) as T);
      this.onTouched?.();
    });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      return this.form.disable();
    }
    this.form.enable();
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : { invalid: true };
  }
}
