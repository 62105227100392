import { AnyFunction, spanContextProvider, wrap } from './trace.utils';

export function Traceable(): ClassDecorator {
  return (target: Function): void => {
    const prototype = target.prototype as Record<string | symbol, AnyFunction>;

    for (const property of Reflect.ownKeys(prototype)) {
      const descriptor = Reflect.getOwnPropertyDescriptor(prototype, property);
      const original = descriptor?.value as AnyFunction;

      if (original instanceof Function && original !== target) {
        Reflect.defineProperty(prototype, property, {
          ...descriptor,
          value: wrap(original, spanContextProvider(original.name))
        });
      }
    }
  };
}
