import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SpinnerService } from '@ct/components';
import { finalize } from 'rxjs/operators';

import { VideoUploadApiService } from './video-upload-api.service';

@Component({
  selector: 'ct-video-poc',
  templateUrl: './video-poc.component.html',
  styleUrls: ['./video-poc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPocComponent {
  protected files: any[] = [];
  protected selectedVideoName: string;
  protected selectedFiles: any[];
  protected get m3u8() {
    return this.selectedFiles?.find((file) => file.fileName === 'manifest.m3u8')?.publicUrl;
  }
  protected get mpd() {
    return this.selectedFiles?.find((file) => file.fileName === 'manifest.mpd')?.publicUrl;
  }

  protected showEditPOC = false;

  constructor(
    private spinnerService: SpinnerService,
    private videoUploadApiService: VideoUploadApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.videoUploadApiService.getAll().subscribe((files) => {
      this.files = files;
      this.changeDetectorRef.markForCheck();
    });
  }

  toggleEditPOC() {
    this.showEditPOC = !this.showEditPOC;
  }
  onSelectFiles(files: File[]) {
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .uploadWithProgress(files[0])
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  onPlayVideo(video: { fileName: string }) {
    console.log(video);
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .getByFilename(video.fileName)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((file) => {
        this.selectedFiles = file;
        console.log(file);
        console.log(this.m3u8);
        this.selectedVideoName = video.fileName;
      });
    // launch Video.js player using m3u playlist from hls
  }

  decodeUrl(url: string): string {
    return decodeURIComponent(url);
  }
}
