import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ChannelVideoUploadApiService } from '@ct/core';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MyChannelResolver implements Resolve<any> {
  constructor(private channelVideoUploadApiService: ChannelVideoUploadApiService) {}

  resolve() {
    return this.channelVideoUploadApiService.getAllVideos().pipe(catchError(() => []));
  }
}
