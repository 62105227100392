import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType } from '@ct/core';
import { environment } from '@ct/environment';

import { ContactUs } from '../interfaces';

const endpoint = environment.emailApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class EmailApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  contactUs(contact: ContactUs, captcha: string) {
    const headers = {
      [HeaderType.ContentType]: 'application/json',
      [HeaderType.Accept]: 'application/json',
      [HeaderType.GoogleRecaptcha]: captcha
    };
    return this.post('contact-us-messages', contact, {}, { headers });
  }
}
