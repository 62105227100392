import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@ct/auth';
import { RequestRange } from '@ct/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SortOrder } from '../enums';
import { ImageUploadApiService } from './image-upload-api.service';

export interface GetMyPhotosOptions {
  range?: RequestRange;
  sortOrder?: SortOrder | undefined;
  isPrivate?: boolean;
  isMarketplaceItem?: boolean;
}

@Injectable({ providedIn: 'root' })
export class MyAccountPhotoApiService extends ImageUploadApiService {
  constructor(protected httpClient: HttpClient, private authService: AuthService) {
    super(httpClient);
  }

  getAllMyPhotos(options?: GetMyPhotosOptions) {
    const { range, sortOrder, isPrivate, isMarketplaceItem } = options ?? {};
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return this.getAll({
          authorId: identity?.guuid,
          range,
          sortOrder,
          isPrivate,
          isMarketplaceItem
        });
      })
    );
  }
}
