import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogButton } from '../../../dialog';

@Component({
  selector: 'ct-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmComponent implements OnInit {
  @Input() public readonly allowCancel: boolean = true;
  @Input() public readonly color: ThemePalette = 'accent';
  @Input() public readonly title: string | null | undefined;
  @Input() public readonly message: string | null | undefined;
  @Input() public readonly confirm: string | null | undefined;
  @Input() public readonly cancel: string | null | undefined;
  @Input() public readonly canConfirm: boolean = true;

  @Output() confirmed = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  public buttons: DialogButton[] = [];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    const buttons: DialogButton[] = [
      {
        labelKey: this.cancel || this.translateService.instant('COMMON.CANCEL'),
        clicked: () => this.cancelled.emit(),
        color: 'primary',
        variant: 'link'
      }
    ];

    if (this.canConfirm) {
      buttons.push({
        labelKey: this.confirm || this.translateService.instant('COMMON.CONFIRM'),
        clicked: () => this.confirmed.emit(),
        color: this.color || 'accent',
        variant: 'flat'
      });
    }

    this.buttons = [...buttons];
  }
}
