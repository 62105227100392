import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { localePipeConfig } from '../../constants';

const locale = localePipeConfig.en; // TODO get current locale from translate service

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {
  transform(value: Date | number | string | undefined, format = 'MM/dd/yyyy HH:mm'): string {
    if (!value) {
      return '';
    }

    return formatDate(value, format, locale);
  }
}
