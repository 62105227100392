import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService } from '@ct/components';
import { TitleService } from '@ct/core';
import {
  AddToTripDialogComponent,
  CampsiteQuery,
  getCroppedThumbPublicUrl,
  Mode,
  Trip,
  TripCampsite,
  TripCampsiteApiService
} from '@ct/shared';
import { CampsiteAddDialogComponent } from '@ct/shared/modules/trip-shared/modules/add-to-trip-dialog/components';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { CAMPISITE_ALL_FILTER } from '../../constants';

const LIMIT = 10;
const DEFAULT_OFFSET = 0;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-my-account-trip-itinerary',
  templateUrl: './my-account-trip-itinerary.component.html',
  styleUrls: ['./my-account-trip-itinerary.component.scss']
})
export class MyAccountTripItineraryComponent implements OnInit, OnDestroy {
  campsites: TripCampsite[];

  public loading = false;
  public checkInLoading = false;
  public showLoadButton = true;
  public offset = 0;
  public trip: Trip;
  public queryParams = {
    campsiteId: CAMPISITE_ALL_FILTER.id,
    offset: DEFAULT_OFFSET,
    filter: 'timeline'
  };

  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  get isAuthor(): boolean {
    return this.authQuery.getValue().profile?.userId === this.trip?.authorId;
  }

  private destroyed$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private authQuery: AuthQuery,
    private campsiteQuery: CampsiteQuery,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.trip = this.route.snapshot.data?.trip;
    this.titleService.setTitle(this.trip?.title);

    this.campsiteQuery
      .selectByTripId(this.trip.id as string)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((campsites) => {
        this.campsites = campsites;
        if (this.campsites.length === 0 || this.campsites.length < LIMIT) {
          this.showLoadButton = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.tripCampsiteApiService
      .getAllByTripId(this.trip.id as string, { range: { limit: LIMIT, offset: this.offset + LIMIT } })
      .subscribe((campsites) => {
        this.offset += LIMIT;
        this.campsites = [...this.campsites, ...campsites];
        if (campsites.length === 0 || campsites.length < LIMIT) {
          this.showLoadButton = false;
        }
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  addDialog() {
    this.dialogService
      .open(AddToTripDialogComponent, { data: this.trip })
      .afterClosed()
      .pipe(
        take(1),
        filter((options) => options && Boolean(options.reload)),
        switchMap(() =>
          this.tripCampsiteApiService.getAllByTripId(this.trip.id as string, {
            range: {
              offset: 0,
              limit: LIMIT
            }
          })
        )
      )
      .subscribe((campsites) => {
        this.campsites = campsites || [];
        this.changeDetectorRef.detectChanges();
      });
  }

  editCampsite(item: TripCampsite) {
    this.dialogService
      .open(CampsiteAddDialogComponent, {
        data: {
          campsite: item,
          mode: Mode.Edit,
          trip: this.trip
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((campsite) => {
        const itemIndex = this.campsites.findIndex(({ id }) => String(id) === String((campsite as TripCampsite).id));
        const elements = [...this.campsites];
        elements[itemIndex] = { ...(campsite as TripCampsite) };
        this.campsites = elements;
        this.changeDetectorRef.detectChanges();
      });
  }

  onCheckIn(item: TripCampsite) {
    this.checkInLoading = true;
    this.changeDetectorRef.detectChanges();
    const checkInTime = new Date();
    this.tripCampsiteApiService
      .update(this.trip.id as string, item.id as string, { checkInTime })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          const itemIndex = this.campsites.findIndex(({ id }) => String(id) === String(item.id));
          const elements = [...this.campsites];
          elements[itemIndex] = { ...item, checkInTime };
          this.campsites = elements;
          this.checkInLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        error: () => {
          this.checkInLoading = false;
          this.changeDetectorRef.detectChanges();
        }
      });
  }
}
