import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-image-banner',
  templateUrl: './image-banner.component.html',
  styleUrls: ['./image-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageBannerComponent {
  @Input() public readonly img: string;
  @Input() public readonly title: string;
  @Input() public readonly prefix: string = 'assets/banners/';
  @Input() public readonly ext: string = '.png';
}
