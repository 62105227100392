import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { SortOrder } from '@ct/shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Category, MarketplaceItem, SubCategory } from '../interfaces';
import { MarketplaceStore } from './state';

const endpoint = environment.marketplaceApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class CategoryApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient, protected store: MarketplaceStore) {
    super(httpClient, endpoint);
  }

  getAllCategories({
    range,
    sortOrder = SortOrder.Asc,
    type,
    sortBy = 'order'
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    sortBy?: keyof Category;
    type?: string;
  }): Observable<Category[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    return this.get(`category`, params, { headers, withCredentials: true }).pipe(
      tap((categories) => {
        if (type === 'list') {
          // save to store only for list requests.
          this.store.update({ categories });
        }
      })
    );
  }

  getCategoryById(id: string): Observable<MarketplaceItem> {
    return this.get(`category/${id}`);
  }

  removeCategory(id: string): Observable<undefined> {
    return this.delete(`category/${id}`);
  }

  createCategory(item: Category) {
    return this.post('category', item);
  }

  updateCategory(id: string, item: Category) {
    return this.patch(`category/${id}`, item);
  }

  getAllSubCategories({
    range,
    sortOrder = SortOrder.Desc,
    type
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    type?: string;
  }): Observable<SubCategory[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    return this.get(`sub-category`, params, { headers, withCredentials: true }).pipe(
      tap((subCategories) => {
        if (type === 'list') {
          // save to store only for list requests.
          this.store.update({ subCategories });
        }
      })
    );
  }

  getSubCategoryById(id: string): Observable<MarketplaceItem> {
    return this.get(`sub-category/${id}`);
  }

  removeSubCategory(id: string): Observable<undefined> {
    return this.delete(`sub-category/${id}`);
  }

  createSubCategory(item: SubCategory) {
    return this.post('sub-category', item);
  }

  updateSubCategory(id: string, item: SubCategory) {
    return this.patch(`sub-category/${id}`, item);
  }
}
