import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

const LINK_ID = 'canonical-url';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setURL(url?: string) {
    const { webUrl } = environment;
    const canonicalURL = url == undefined ? this.document.URL : `${webUrl}${url}`;
    let link: HTMLLinkElement = this.document.querySelector(`#${LINK_ID}`) as HTMLLinkElement;
    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('id', LINK_ID);
      this.document.head.appendChild(link);
    }
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canonicalURL);
  }
}
