<ct-dialog-content
  titleKey="MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_JOURNAL_ENTRY"
  headerIcon="add-circle"
  [closeIconParams]="{ reload: false }"
  [backIcon]="true"
  [buttons]="buttons"
  [alignButtons]="'end'"
  (back)="onBack()"
>
  <div class="journal-entry">
    <form class="form" [formGroup]="form">
      <div class="row">
        <ct-text-editor class="col-12" formControlName="body" [modules]="modules"></ct-text-editor>
      </div>
      <div class="row">
        <ct-tag-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TAGS' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TAGS_PLACEHOLDER' | translate"
          [optionsFn]="optionsFn"
          [createFn]="createFn"
          [checkFn]="checkFn"
          [labelFn]="labelFn"
          formControlName="tags"
        ></ct-tag-select>
      </div>
      <div class="row">
        <ct-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.WAYPOINT' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ASSIGN_WAYPOINT' | translate"
          formControlName="campsiteId"
          [options]="campsites$ | async"
        ></ct-select>
      </div>
      <div class="mb-3">
        <h1 class="item-title">{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.PHOTOS' | translate }}</h1>
        <div class="photo-grid-container">
          <ct-photo-grid
            [photos]="form.controls.photos?.value"
            [showPlaceholder]="true"
            [removable]="true"
            [imageHeight]="'140px'"
            (removed)="onRemovePhoto($event)"
            (placeholderSelected)="onAddPhotos()"
            [draggable]="true"
          >
          </ct-photo-grid>
        </div>
      </div>
    </form>
  </div>
</ct-dialog-content>
