import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ct/components/button';
import { DateModule } from '@ct/components/date';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { InputModule } from '@ct/components/input';
import { SelectModule } from '@ct/components/select';
import { TagSelectModule } from '@ct/components/tag-select';
import { TextEditorModule } from '@ct/components/text-editor';
import { GoogleMapsModule } from '@ct/shared/modules';
import { TranslateModule } from '@ngx-translate/core';

import { MediaSharedModule } from '../../../media-shared';
import { CreateTripDialogModule } from '../create-trip-dialog';
import { AddToTripDialogComponent } from './add-to-trip-dialog.component';
import { CampsiteAddDialogComponent, JournalAddDialogComponent } from './components';

@NgModule({
  declarations: [AddToTripDialogComponent, CampsiteAddDialogComponent, JournalAddDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    DateModule,
    InputModule,
    IconModule,
    MediaSharedModule,
    TagSelectModule,
    TextEditorModule,
    SelectModule,
    CreateTripDialogModule,
    GoogleMapsModule
  ],
  exports: [AddToTripDialogComponent]
})
export class AddToTripDialogModule {}
