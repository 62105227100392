import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ct/auth/services/auth.service';
import { MenuItem } from '@ct/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';

export const menuItems: MenuItem[] = [
  {
    name: 'home',
    titleKey: 'HEADER.MENU.HOME',
    link: '/home'
  },
  {
    name: 'blog',
    titleKey: 'HEADER.MENU.BLOG',
    link: '/stories'
  },
  {
    name: 'marketplace',
    titleKey: 'HEADER.MENU.MARKETPLACE',
    link: '/marketplace/community'
  },
  // TODO: uncomnent it, once Search API works
  // {
  //   name: 'site-search',
  //   titleKey: 'HEADER.MENU.SITE_SEARCH',
  //   link: '/site-search'
  // },
  {
    name: 'faq',
    titleKey: 'COMMON.FAQ.TITLE',
    link: '/faq'
  },
  {
    name: 'contact-us',
    titleKey: 'HEADER.MENU.CONTACT_US',
    link: '/contact-us'
  },
  {
    name: 'my-account',
    titleKey: 'HEADER.BUTTONS.MY_ACCOUNT',
    link: '/my-account/explore'
  },
  {
    name: 'logout',
    titleKey: 'HEADER.BUTTONS.LOGOUT',
    link: ''
  }
];

@Component({
  selector: 'ct-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutFooterComponent {
  public menuItems: MenuItem[] = menuItems.map((item) => ({
    ...item,
    title$: this.translateService.get(item.titleKey)
  }));

  public get year() {
    return new Date().getFullYear().toString();
  }

  constructor(private translateService: TranslateService, private router: Router, private authService: AuthService) {}

  onMenuItem(menuItem: MenuItem) {
    if (menuItem.name === 'logout') {
      return this.authService.logout().subscribe();
    }
    if (menuItem.name === 'login' && !this.router.url.includes(menuItem.link)) {
      return this.router.navigate([menuItem.link], { queryParams: { returnUrl: this.router.url } });
    }
    return false;
  }
}
