<quill-editor
  [formControl]="formControl"
  [required]="required"
  [placeholder]="placeholder"
  [readOnly]="disabled"
  [class.disabled]="disabled"
  [modules]="modules"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
></quill-editor>
