import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';

import { CampsiteState, CampsiteStore } from './campsite.store';

@Injectable({ providedIn: 'root' })
export class CampsiteQuery extends QueryEntity<CampsiteState> {
  constructor(protected store: CampsiteStore) {
    super(store);
  }

  selectByTripId(searchTripId: string) {
    return this.selectAll({
      filterBy: ({ tripId }) => String(tripId) === String(searchTripId),
      sortBy: 'startDate',
      sortByOrder: Order.DESC
    });
  }
}
