import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, ImageUploadService, SpinnerService } from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import { FormStateDispatcher } from '@ct/core';
import { GeocoderService } from '@ct/shared/modules/google-maps/services/geocoder.service';
import { GeolocationService } from '@ct/shared/modules/google-maps/services/geolocation.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { filter, finalize, take } from 'rxjs/operators';

import { Mode } from '../../../../../../enums';
import { MyAccountPhotoApiService } from '../../../../../../services';
import { Trip, TripCampsite } from '../../../../interfaces';
import { TripCampsiteApiService } from '../../../../services';
import GeocoderResult = google.maps.GeocoderResult;
import PlaceResult = google.maps.places.PlaceResult;
import { GOOGLE_PLACES_DEFAULT_OPTIONS } from '@ct/shared/modules/google-maps/components/google-places';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

interface Location {
  city?: string;
  state?: string;
  zip?: string;
  lat?: number;
  lng?: number;
  formatted_address?: string;
  phone?: string;
}

@Component({
  selector: 'ct-campsite-add-dialog',
  templateUrl: './campsite-add-dialog.component.html',
  styleUrls: ['./campsite-add-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampsiteAddDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public static dialogConfig: MatDialogConfig = {
    ...DEFAULT_DIALOG_CONFIG,
    backdropClass: [...(DEFAULT_DIALOG_CONFIG.backdropClass ?? []), 'waypoint-backdrop']
  };

  public isLoading = false;

  public readonly form = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    formatted_address: new UntypedFormControl('', [Validators.required]),
    lon: new UntypedFormControl(0, [Validators.required]),
    lat: new UntypedFormControl(0, [Validators.required]),
    startDate: new UntypedFormControl(null),
    endDate: new UntypedFormControl(null),
    phone: new UntypedFormControl(null),
    featuredPhoto: new UntypedFormControl('')
  });

  public location: Location | null;

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onSave();
      }
    }
  ];

  public readonly placesOptions: Options;

  get trip(): Trip {
    return this.data.trip;
  }

  get tripId(): string {
    return this.data.trip.id as string;
  }

  get isEditMode() {
    return this.data?.mode === Mode.Edit;
  }

  get campsite(): TripCampsite {
    return this.data?.campsite;
  }

  private topScrollPosition = '0px';

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private dialogRef: MatDialogRef<CampsiteAddDialogComponent>,
    private formState: FormStateDispatcher,
    private spinnerService: SpinnerService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private geolocationService: GeolocationService,
    private geocoderService: GeocoderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    const { componentRestrictions: _componentRestrictions, ...rest } = GOOGLE_PLACES_DEFAULT_OPTIONS;
    this.placesOptions = {
      ...rest,
      types: []
    } as unknown as Options;
  }

  ngOnInit() {
    if (this.isEditMode && this.campsite) {
      this.form.patchValue(this.campsite);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elementStyle: CSSStyleDeclaration = this.getHtmlElement()?.style ?? ({} as CSSStyleDeclaration);
      this.topScrollPosition = elementStyle.top ?? '0px';
      elementStyle.top = '0px';
    });
  }

  ngOnDestroy() {
    const elementStyle: CSSStyleDeclaration = this.getHtmlElement()?.style ?? ({} as CSSStyleDeclaration);
    elementStyle.top = this.topScrollPosition;
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    return (
      !this.isEditMode
        ? this.tripCampsiteApiService.create(this.tripId, { ...this.form.value, itineraryId: this.trip.itineraryId })
        : this.tripCampsiteApiService.update(this.tripId, this.campsite.id as string, this.form.value)
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((campsite: TripCampsite) => {
        this.dialogRef.close({ campsite });
      });
  }

  onSelectFeaturedPhotoPlaceholder() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_FEATURED_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(([photo]) => !!photo)
      )
      .subscribe(([photo]) => {
        this.form.controls.featuredPhoto.patchValue(photo);
        this.changeDetectorRef.detectChanges();
      });
  }

  onReplaceFeaturedPhoto() {
    this.onSelectFeaturedPhotoPlaceholder();
  }

  onRemoveFeaturedPhoto() {
    this.form.controls.featuredPhoto.patchValue(null);
  }

  onCancel() {
    this.dialogRef.close({ reload: false });
  }

  onBack() {
    this.dialogRef.close();
  }

  onLocation(data: Address) {
    this.location = this.extractCity(data);
    this.form.get('formatted_address')?.setValue(this.location.formatted_address);
    this.form.get('lon')?.setValue(this.location.lng);
    this.form.get('lat')?.setValue(this.location.lat);
    this.form.get('phone')?.setValue(this.location.phone);
  }

  onCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.setLocationFromCoords(position.coords.latitude, position.coords.longitude);
          }
        },
        () => {
          return this.geolocationService.getGeolocation().subscribe((geo) => {
            if (geo.location) {
              this.setLocationFromCoords(geo.location.lat, geo.location.lng);
            }
          });
        },
        { timeout: 10000 }
      );
    }
  }

  setLocationFromCoords(lat: number, lon: number) {
    this.geocoderService.geocode(lat, lon).subscribe((results) => {
      this.location = this.extractCity(results[0]);
      this.form.get('formatted_address')?.setValue(this.location.formatted_address);
      this.form.get('lon')?.setValue(this.location.lng);
      this.form.get('lat')?.setValue(this.location.lat);
      this.form.get('phone')?.setValue(this.location.phone);
    });
  }

  extractCity(place: GeocoderResult | Address) {
    const address = place.address_components;
    const formatted_address = place.formatted_address;
    const phone = (place as PlaceResult).international_phone_number;
    let city, state, zip;
    address?.forEach((component) => {
      const types = component.types;
      if (types.indexOf('locality') > -1) {
        city = component.long_name;
      }

      if (types.indexOf('administrative_area_level_1') > -1) {
        state = component.short_name;
      }

      if (types.indexOf('postal_code') > -1) {
        zip = component.long_name;
      }
    });
    const lat = place.geometry?.location.lat();
    const lng = place.geometry?.location.lng();
    return { city, state, zip, lat, lng, formatted_address, phone };
  }

  private getHtmlElement(): HTMLElement | null {
    return document.querySelector('.cdk-global-scrollblock') as HTMLElement;
  }
}
