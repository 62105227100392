<ct-image-banner img="register" [title]="'AUTH.VERIFY.IMG_TITLE' | translate"></ct-image-banner>
<div class="container verify">
  <h1 class="text-center pl-5 pr-5 pb-2">
    {{ 'AUTH.VERIFY.SUB_TITLE' | translate }}
  </h1>
  <ct-card>
    <form class="mt-3 form" [formGroup]="form" (ngSubmit)="onVerify()">
      <ct-card-section [showLine]="false">
        <div class="row mb-3">
          <div class="col-12">
            {{ 'AUTH.VERIFY.WE_JUST_SEND_VERIFICATION_CODE' | translate }} <strong>{{ username }}</strong>
          </div>
        </div>
        <div class="row">
          <ct-input
            [label]="'AUTH.VERIFY.CODE' | translate"
            [placeholder]="'AUTH.VERIFY.CODE_PLACEHOLDER' | translate"
            [mask]="'000-000'"
            class="col-12"
            formControlName="code"
          ></ct-input>
        </div>
      </ct-card-section>
      <ct-card-section>
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-center">
            <ct-button type="submit" class="mr-2" variant="flat" color="accent">{{
              'AUTH.VERIFY.VERIFY_BUTTON' | translate
            }}</ct-button>
            <ct-button type="button" variant="stroked" (click)="onRequestCode()" [disabled]="isDisabledResend">{{
              'AUTH.VERIFY.RESEND_CODE_BUTTON' | translate
            }}</ct-button>
          </div>
          <div class="note mt-3" *ngIf="isDisabledResend">
            {{ 'AUTH.VERIFY.NEXT_CODE_AVAILABLE' | translate }}
            <strong><ct-count-down [count]="resendCodeTime" (finished)="onCountdownFinished()"></ct-count-down></strong>
          </div>
        </div>
      </div>
      </ct-card-section>
    </form>
  </ct-card>
</div>
