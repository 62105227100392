<ng-container *ngIf="series && showSeries">
  <nav class="series">
    <header class="series__header">
      <h3 class="series__header-text">{{ series.title }} ({{ stories.length }} Part Series)</h3>
    </header>
    <div class="series__list">
      <ng-container *ngFor="let story of stories; let i = index">
        <a
          [routerLink]="[getLink(story)]"
          routerLinkActive="series__link--active"
          class="series__link"
          title="Published on {{ story.publishedOn | date }}"
        >
          <span class="series__number">{{ i + 1 }}</span>
          <span class="series__title">{{ story.title }}</span>
        </a>
      </ng-container>
    </div>
  </nav>
</ng-container>
