<ng-container *ngIf="isLoggedIn$ | async; else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-extended-header ctHeader icon="article" title="Stories"></ct-extended-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div class="container">
    <div class="blog">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
