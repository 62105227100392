import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthQuery, AuthService } from '@ct/auth';
import {
  FacebookCardType,
  Features,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature()])
export class BlogComponent {
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.BLOG'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.BLOG',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/blog.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    }
  };

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private notificationQuery: NotificationQuery
  ) {}
}
