import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  CardModule,
  CheckboxModule,
  DialogModule,
  FileUploadSharedModule,
  FilterPaneModule,
  IconModule,
  ImageModule,
  ImageUploadModule,
  InputModule,
  LightboxModule,
  NavigationModule,
  SelectModule,
  TabHeaderModule,
  TabsMobileModule,
  TagSelectModule,
  ToggleModule
} from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { MediaSharedModule, VideoItemModule, YoutubeModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MyAccountSharedModule } from '../my-account-shared';
import { AddMediaDialogComponent } from './components';
import { MyAccountMediaComponent } from './components/my-account-media';
import { MyAccountPhotosComponent } from './components/my-account-photos';
import { MyAccountPhotosEditDialogComponent } from './components/my-account-photos-edit-dialog';
import { MyAccountVideosComponent } from './components/my-account-videos';
import { MyAccountMediaRoutingModule } from './my-account-media-routing.module';

@NgModule({
  declarations: [
    MyAccountMediaComponent,
    MyAccountPhotosComponent,
    MyAccountPhotosEditDialogComponent,
    MyAccountVideosComponent,
    AddMediaDialogComponent
  ],
  imports: [
    CommonModule,
    MyAccountMediaRoutingModule,
    CardModule,
    MyAccountSharedModule,
    TranslateModule,
    ImageModule,
    IconModule,
    InputModule,
    ReactiveFormsModule,
    ButtonModule,
    SelectModule,
    ToggleModule,
    DialogModule,
    ImageUploadModule,
    NavigationModule,
    LightboxModule,
    MediaSharedModule,
    TagSelectModule,
    CheckboxModule,
    FilterPaneModule,
    LayoutModule,
    TabHeaderModule,
    TabsMobileModule,
    InfiniteScrollModule,
    YoutubeModule,
    FileUploadSharedModule,
    VideoItemModule
  ]
})
export class MyAccountMediaModule {}
