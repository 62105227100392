import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'ct-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountDownComponent implements OnInit {
  public counter$: Observable<number>;

  @Input() public count = 0;

  @Output() finished = new EventEmitter<void>();

  ngOnInit() {
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count),
      tap(() => {
        if (this.count === 0) {
          this.finished.emit();
        }
      })
    );
  }
}
