<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header
    ctHeader
    icon="cabin"
    [title]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TRIPS' | translate"
    [description]="'MY_ACCOUNT.MY_TRIPS_FEATURE.CREATE_DESCRIPTION' | translate"
  ></ct-extended-header>

  <div ctContent>
    <router-outlet></router-outlet>
  </div>
</ct-layout>
