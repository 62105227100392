<ng-container *ngIf="!simple && (isLoggedIn$ | async); else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div [class.container]="!simple">
    <div class="row">
      <div class="col-12">
        <h1 *ngIf="!simple" class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.TITLE' | translate }}</h1>
        <h2 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.EFFECTIVE_DATE' | translate }}</h2>
        <h3 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.GENERAL_TITLE' | translate }}</h3>
        <h4 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.GENERAL_SUBTITLE' | translate }}</h4>
        <div class="text-border">
          THERE IS A 14-DAY FREE TRIAL FOR THE CAMPING TOOLS MOBILE APP. AFTER YOUR FREE TRIAL EXPIRES, YOU MAY PURCHASE
          AN AUTO-RENEW SUBSCRIPTION FOR THE APP BY REGISTERING YOUR CREDIT CARD. YOUR SUBSCRIPTION WILL AUTO-RENEW
          (MONTHLY OR ANNUALLY PER YOUR BILLING PLAN) UNTIL YOU CANCEL. CANCEL AT ANY TIME VIA YOUR APPLE ACCOUNT. TO
          AVOID BEING CHARGED, YOU MUST CANCEL AT LEAST ONE DAY BEFORE YOUR NEXT RENEWAL DATE. THERE WILL BE NO REFUNDS
          FOR PREPAID FEES.
        </div>
        <div [innerHTML]="'COMMON.LEGAL.TERMS_AND_CONDITIONS.GENERAL_TERMS' | translate"></div>
        <div [innerHTML]="'COMMON.LEGAL.CONTACT_ADDRESS' | translate" class="contact-address ml-5"></div>
        <h3 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.ADDITIONAL_BUYER_TITLE' | translate }}</h3>
        <h4 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.ADDITIONAL_BUYER_SUBTITLE' | translate }}</h4>
        <div [innerHTML]="'COMMON.LEGAL.TERMS_AND_CONDITIONS.ADDITIONAL_BUYER_TERMS' | translate"></div>
        <h3 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.ADDITIONAL_MERCHANT_TITLE' | translate }}</h3>
        <h4 class="text-center">{{ 'COMMON.LEGAL.TERMS_AND_CONDITIONS.ADDITIONAL_MERCHANT_SUBTITLE' | translate }}</h4>
        <div [innerHTML]="'COMMON.LEGAL.TERMS_AND_CONDITIONS.ADDITIONAL_MERCHANT_TERMS' | translate"></div>
        <div [innerHTML]="'COMMON.LEGAL.CONTACT_ADDRESS' | translate" class="contact-address ml-5"></div>
        <div>
          <p>End of Terms.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
