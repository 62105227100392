import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogButton } from '@ct/components';
import {
  CustomValidators,
  EMAIL_MAX_LENGTH,
  FormStateDispatcher,
  MESSAGE_MAX_LENGTH,
  NAME_MAX_LENGTH,
  UserProfile
} from '@ct/core';

import { MarketplaceItem } from '../../interfaces';
import { MarketplaceMessage } from '../../interfaces/marketplace-message.interface';
import { MarketplaceMessagingApiService } from '../../services/marketplace-messaging-api.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-contact-seller-dialog',
  templateUrl: './contact-seller-dialog.component.html',
  styleUrls: ['./contact-seller-dialog.component.scss'],
  providers: [FormStateDispatcher]
})
export class ContactSellerDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { item?: MarketplaceItem; currentUser?: UserProfile },
    private formState: FormStateDispatcher,
    private messagingApiService: MarketplaceMessagingApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  buttons: DialogButton[] = [];
  messages: MarketplaceMessage[] = [];

  public form: UntypedFormGroup;
  public isSent = false;
  public tabIndex = 0;

  initForm() {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.data?.currentUser?.firstName, [
        Validators.required,
        Validators.maxLength(NAME_MAX_LENGTH)
      ]),
      lastName: new UntypedFormControl(this.data?.currentUser?.lastName, [
        Validators.required,
        Validators.maxLength(NAME_MAX_LENGTH)
      ]),
      email: new UntypedFormControl(this.data?.currentUser?.email, [
        Validators.required,
        Validators.email,
        Validators.maxLength(EMAIL_MAX_LENGTH)
      ]),
      phone: new UntypedFormControl('', [Validators.required, CustomValidators.phoneNumberExtended('US')]),
      itemId: new UntypedFormControl(this.data?.item?.id),
      body: new UntypedFormControl('', [Validators.required, Validators.maxLength(MESSAGE_MAX_LENGTH)]),
      captcha: new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    this.initForm();
    this.messagingApiService.getMy({ itemId: this.data?.item?.id }).subscribe((data) => {
      this.messages = data;
      this.changeDetectorRef.detectChanges();
    });
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      this.form.controls.captcha.reset();
      return;
    }

    const { captcha, ...formValue } = this.form.value;

    this.isSent = true;
    this.messagingApiService.sendMessage({ ...formValue }, captcha).subscribe(
      (message) => {
        this.messages.unshift(message);
        this.tabIndex = 1;
        this.isSent = false;
        this.initForm();
        this.form.controls.captcha.reset();
        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.form.controls.captcha.reset();
      }
    );
  }
}
