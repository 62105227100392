import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { Observable } from 'rxjs';
@Component({
  selector: 'ct-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class InputComponent extends BaseControlComponent<string> implements OnInit {
  public readonly destroyed$: Observable<void>;
  @Input() public label: string;
  @Input() public mask: string;
  @Input() public prefixIcon: string;
  @Input() public suffixIcon: string;
  @Input() public readonly: boolean;
  @Input() public showLabel: boolean;
  @Input() public readonly placeholder: string = '';
  @Input() public readonly type: string = 'text';
  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }
  @Input() public readonly maxlength: number;
  public readonly control = new UntypedFormControl(null);

  @Output() public suffixIconClicked = new EventEmitter<void>();
  @Output() public prefixIconClicked = new EventEmitter<void>();

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value?.trim ? value.trim().replace(/\s{2,}/g, ' ') : value;

    if (trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
  }

  onSuffixIcon() {
    this.suffixIconClicked.emit();
  }

  onPrefixIcon() {
    this.prefixIconClicked.emit();
  }
}
