import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, NotificationStatus, NotificationWithAuthor } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationStore } from './notification-state';

const endpoint = environment.notificationsApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class NotificationApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient, public store: NotificationStore) {
    super(httpClient, endpoint);
  }

  registerToken(token: string) {
    return this.post('token', { token });
  }

  getNotifications(userId: string): Observable<NotificationWithAuthor[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.get('user', params, { headers }).pipe(
      tap((notifications) => {
        this.store.upsertMany(notifications);
      })
    );
  }

  updateStatus(notificationIds: string[]): Observable<NotificationWithAuthor[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.post('status', { notificationIds, status: NotificationStatus.Seen }, {}, { headers }).pipe(
      tap((notifications) => {
        this.store.upsertMany(notifications);
      })
    );
  }

  removeToken(token: string) {
    if (token) {
      const headers = {
        [HeaderType.ScotcroFirebaseMessagingToken]: token
      };
      return this.delete('token', {}, { body: { token }, headers });
    } else {
      return of(null);
    }
  }

  cleanupLocalNotifications() {
    this.store.reset();
  }
}
