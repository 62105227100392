import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ct-notification-in-app',
  templateUrl: './notification-in-app.component.html',
  styleUrls: ['./notification-in-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationInAppComponent {
  static dialogConfig: MatDialogConfig = {
    width: '100vw',
    maxWidth: '480px',
    height: '100vh',
    maxHeight: '500px',
    hasBackdrop: false,
    panelClass: ['notification-in-app'],
    scrollStrategy: new NoopScrollStrategy(),
    closeOnNavigation: true,
    position: {
      bottom: '0px',
      right: '0px'
    },
    role: 'alertdialog'
  };

  @Input() public readonly title: string | null | undefined;

  constructor(private dialogRef: MatDialogRef<NotificationInAppComponent>) {}

  onClose() {
    this.dialogRef.close();
  }
}
