import { NgModule } from '@angular/core';
import { Meter } from '@opentelemetry/sdk-metrics-base';

import { MetricService } from '../metric.service';
import { NoopMetricService } from './noop-metric.service';

@NgModule({
  providers: [
    {
      provide: MetricService,
      useClass: NoopMetricService
    },
    {
      provide: Meter,
      useFactory: (metricService: MetricService) => metricService.getMeter(),
      deps: [MetricService]
    }
  ]
})
export class OpenTelemetryNoopMetricModule {}
