import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityType } from '@ct/shared/enums/share-entity-type.enum';

import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base-http.service';

const endpoint = environment.threadApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class CommentApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  create(entityId: string, threadId: string, body: string, entityType: EntityType = EntityType.BlogPost) {
    return this.post(`${threadId}/comments`, { body, entityId, entityType });
  }

  update(threadId: string, commentId: string, body: string) {
    return this.patch(`${threadId}/comments/${commentId}`, { body });
  }

  remove(threadId: string, commentId: string) {
    return this.delete(`${threadId}/comments/${commentId}`);
  }
}
