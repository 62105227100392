import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ChannelVideoUploadApiService, VideoChannelEntity } from '@ct/core';

@Injectable({ providedIn: 'root' })
export class ChannelsResolver implements Resolve<VideoChannelEntity[]> {
  constructor(private channelVideoUploadApiService: ChannelVideoUploadApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.channelVideoUploadApiService.getAll({ range: { limit: 20, offset } });
  }
}
