import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { DataListApi, DataListColumn, DataListItem } from '@ct/components';
import {
  CanonicalFeature,
  DestroyableFeature,
  FacebookCardType,
  Features,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Category, TreeCategory, TreeSubCategory } from '../../interfaces';

interface MarketplaceQueryParams {
  categoryId?: string;
  subCategoryId?: string;
}

@Component({
  selector: 'ct-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature(), DestroyableFeature()])
export class MarketplaceComponent implements AfterViewInit, OnInit {
  public readonly destroyed$: Observable<void>;
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.MARKETPLACE'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.MARKETPLACE',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/marketplace.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/marketplace'
    }
  };
  public listColumn: DataListColumn = {
    field: 'title',
    align: 'left'
  };

  public categories: TreeCategory[];
  public selectedEntity: TreeCategory | TreeSubCategory | null;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  private dataListApi: DataListApi;

  private nodesFromRoot: TreeCategory[] = [];

  private previousSelectedEntity: TreeCategory | TreeSubCategory | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {
    this.route.data.pipe(take(1)).subscribe(({ categories }) => {
      this.categories = categories?.map((category: Category) => new TreeCategory(category)) ?? [];
    });
  }

  ngAfterViewInit() {
    this.route.queryParams.pipe(take(1)).subscribe(({ categoryId, subCategoryId }) => {
      const category = (this.categories ?? []).find(({ id }) => id === categoryId);
      if (category) {
        if (subCategoryId) {
          this.previousSelectedEntity = category.subCategories.find(
            ({ id }) => id === subCategoryId
          ) as TreeSubCategory;
        } else {
          this.previousSelectedEntity = category;
        }
        this.selectedEntity = this.previousSelectedEntity;
        this.cdr.detectChanges();
      }
    });
    this.expandFromRootToCurrentEntity(this.previousSelectedEntity as TreeCategory);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
      const { categoryId, subCategoryId } = queryParams;
      const category: TreeCategory = categoryId && (this.categories ?? []).find(({ id }) => id === categoryId);
      if (subCategoryId) {
        this.selectedEntity = category?.children.find(({ id }) => id === subCategoryId) as TreeSubCategory;
      } else {
        this.selectedEntity = category;
      }
    });
  }

  clearSelection(event: MouseEvent) {
    event.preventDefault();
    this.previousSelectedEntity = null;
    this.dataListApi?.listSelection.clear();
    this.dataListApi?.treeControl.collapseAll();
    this.cdr.detectChanges();
    this.refreshUrlQueryParams({});
  }

  onEntitySelected(entity: DataListItem | null) {
    if (this.previousSelectedEntity && this.dataListApi?.listSelection.isSelected(entity)) {
      this.previousSelectedEntity = null;
      this.dataListApi?.listSelection.clear();
      this.cdr.detectChanges();
      return this.refreshUrlQueryParams({});
    }
    if (entity === this.previousSelectedEntity) {
      return;
    }

    this.previousSelectedEntity = entity as TreeSubCategory;
    if (this.previousSelectedEntity.categoryId) {
      this.refreshUrlQueryParams({
        categoryId: this.previousSelectedEntity.categoryId,
        subCategoryId: this.previousSelectedEntity.id
      });
    } else {
      this.refreshUrlQueryParams({ categoryId: this.previousSelectedEntity.id });
    }
    this.expandFromRootToCurrentEntity(entity as TreeCategory);
  }

  expandFromRootToCurrentEntity(entity: TreeCategory | null) {
    if (!entity) {
      return;
    }
    this.nodesFromRoot = [];
    this.walkFromCurrentNodeToRoot(entity);
    this.nodesFromRoot.forEach(({ id }) => {
      const node = this.dataListApi?.findById(id);
      this.dataListApi?.treeControl?.expand(node);
    });
  }

  walkFromCurrentNodeToRoot(entity: TreeCategory | TreeSubCategory) {
    if ((entity as TreeSubCategory)?.categoryId) {
      const category = (this.categories ?? []).find(({ id }) => id === (entity as TreeSubCategory).categoryId);
      this.nodesFromRoot.push(category as TreeCategory);
    }
  }

  onDataListReady(dataListApi: DataListApi) {
    this.dataListApi = dataListApi;
  }

  refreshUrlQueryParams({ categoryId, subCategoryId }: MarketplaceQueryParams) {
    let route = this.route;

    while (route.firstChild && route.firstChild !== route) {
      route = route.firstChild;
    }

    this.router.navigate(['/marketplace/community'], {
      queryParams: { categoryId, subCategoryId },
      queryParamsHandling: 'merge',
      relativeTo: route
    });
  }
}
