<div class="image">
  <ct-progressive-image
    [noImagePlaceholder]="true"
    [noImagePlaceholderPath]="noImagePlaceholderPath"
    [height]="height"
    [width]="width"
    [publicUrl]="publicUrl"
    [thumbnail]="thumbnail"
    [objectFit]="objectFit"
    [altText]="altText"
  >
  </ct-progressive-image>
  <div class="tag" [class.tag--bottom]="tagLocation === 'bottom'">
    <ct-tag class="mr-1" *ngFor="let tag of tags" [name]="tag?.name"></ct-tag>
  </div>
</div>
