import { DataListItem } from '@ct/components';

import { SubCategory, TreeSubCategory } from './sub-category.interface';

export interface Category {
  id?: string;
  title?: string;
  slug?: string;
  description?: string;
  createdOn?: Date;
  authorId?: string;
  order?: number;
  subCategories?: SubCategory[];
}

export class TreeCategory extends DataListItem implements Category {
  id: string;
  title: string;
  description: string;
  createdOn: Date;
  authorId: string;
  subCategories: SubCategory[];
  children: TreeSubCategory[] = [];

  constructor(category: Category) {
    super();
    this.id = category.id ?? '';
    this.title = category.title ?? '';
    this.description = category.description ?? '';
    this.createdOn = category.createdOn ?? new Date();
    this.authorId = category.authorId ?? '';
    this.subCategories = category.subCategories ?? [];
    this.children = this.subCategories.map((subCategory) => new TreeSubCategory(subCategory));
  }
}
