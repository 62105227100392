import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranscodingState, VideoUploadEntity } from '@ct/core/interfaces/video-upload.interface';
import { environment } from '@ct/environment';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ct-video-item-preview',
  templateUrl: './video-item-preview.component.html',
  styleUrls: ['./video-item-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoItemPreviewComponent {
  public readonly destroyed$: Observable<void>;
  @Input()
  protected readonly video: VideoUploadEntity;
  protected readonly loadedVideo$: Subject<boolean> = new Subject<boolean>();
  protected readonly loading$: Subject<boolean> = new Subject<boolean>();

  protected transcodingState = TranscodingState;

  protected get m3u8() {
    return `https://storage.googleapis.com/${environment.transcodedVideosBucket}/${this.video.fileName}/manifest.m3u8`;
  }

  protected get mpd() {
    return `https://storage.googleapis.com/${environment.transcodedVideosBucket}/${this.video.fileName}/manifest.mpd`;
  }

  decodeUrl(url: string): string {
    return decodeURIComponent(url);
  }

  onPlayVideo(): void {
    if (this.video.transcodingJobStatus !== TranscodingState.SUCCEEDED) {
      return;
    }

    this.loading$.next(true);
    this.loading$.next(false);
    this.loadedVideo$.next(true);
  }
}
