import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { DialogService, ImageItem, InformationDialogComponent, ThumbnailsPosition } from '@ct/components';
import {
  CanonicalService,
  DestroyableFeature,
  FacebookCardType,
  Features,
  MetaTagService,
  TitleService,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { environment } from '@ct/environment';
import { entitySlugUrl, getBiggerPublicUrl, getCroppedThumbPublicUrl, stripTags } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { MarketplaceItemCondition } from '../../enums';
import { Address, MarketplaceItem } from '../../interfaces';
import { ContactSellerDialogComponent } from '../contact-seller-dialog/contact-seller-dialog.component';

const BACKGROUND_CLASS = 'marketplace-item__background';

@Component({
  selector: 'ct-marketplace-item-extended',
  templateUrl: './marketplace-item-extended.component.html',
  styleUrls: ['./marketplace-item-extended.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class MarketplaceItemExtendedComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly destroyed$: Observable<void>;
  public item: MarketplaceItem;
  public item$: Observable<MarketplaceItem> = this.route.data.pipe(map(({ item }) => item));
  public currentUser: UserProfile | null;

  public images: ImageItem[] = [];
  public thumbnailsPosition = ThumbnailsPosition.Bottom;

  get isAuthor(): boolean {
    return this.authQuery.getValue().profile?.userId === this.item?.merchantId;
  }

  get amazonUrl(): string {
    return this.item?.amazonAffiliateUrl as string;
  }

  get isLoggedIn(): boolean {
    return !!this.authQuery.getValue().profile?.userId;
  }

  get editLink(): string[] {
    return [entitySlugUrl(this.route.snapshot.data.baseRoute, this.item), 'edit'];
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly authQuery: AuthQuery,
    private readonly titleService: TitleService,
    private readonly metaTagService: MetaTagService,
    private readonly canonicalService: CanonicalService,
    private readonly dialogService: DialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.item$.pipe(takeUntil(this.destroyed$)).subscribe((item) => {
      this.item = item;
      this.setSeo(item);
      this.images = this.item?.photos?.map(
        (photo) =>
          new ImageItem({
            original: photo.path,
            src: getBiggerPublicUrl(photo.publicUrl),
            thumb: getCroppedThumbPublicUrl(photo.publicUrl),
            data: photo
          })
      ) as ImageItem[];
    });

    this.authService
      .getUserProfile()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (userProfile) => {
          this.currentUser = userProfile;
        },
        () => {
          this.currentUser = null;
        }
      );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        document.querySelector('mat-sidenav-content > .container')?.classList.add(BACKGROUND_CLASS);
      }
    });
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('mat-sidenav-content > .container')?.classList.remove(BACKGROUND_CLASS);
    }
  }

  get haveAlternateContact() {
    return this.item?.alternateContact && Object.values(this.item.alternateContact).some(Boolean);
  }

  get hasPhotos(): boolean {
    return Boolean(this.item?.photos?.length);
  }

  get mapUrl(): string {
    return `https://www.google.com/maps/embed/v1/place?key=${
      environment.googleMapsApiKey
    }&q=${this.getEncodedAddress()}`;
  }

  get thumbnail(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].xsPreview : undefined;
  }

  get publicUrl(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].publicUrl : undefined;
  }

  get featured(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].path : undefined;
  }

  get condition(): string {
    switch (this.item?.condition) {
      case MarketplaceItemCondition.New:
        return this.translateService.instant('MARKETPLACE.CONDITION.NEW');
      case MarketplaceItemCondition.NewInPackage:
        return this.translateService.instant('MARKETPLACE.CONDITION.NEW_IN_PACKAGE');
      case MarketplaceItemCondition.LikeNew:
        return this.translateService.instant('MARKETPLACE.CONDITION.LIKE_NEW');
      case MarketplaceItemCondition.UsedSomeWear:
        return this.translateService.instant('MARKETPLACE.CONDITION.USED_SOME_WEAR');
      case MarketplaceItemCondition.UsedWell:
        return this.translateService.instant('MARKETPLACE.CONDITION.USED_WELL');
      default:
        return '';
    }
  }

  setSeo(marketplaceItem: MarketplaceItem): void {
    const canonicalUrl = entitySlugUrl(`/marketplace/`, marketplaceItem);
    this.titleService.setTitle(marketplaceItem?.title as string);
    this.canonicalService.setURL(canonicalUrl);
    const stripedHtml = marketplaceItem?.description?.replace(/<[^>]+>/g, '').slice(0, 200) as string;
    this.metaTagService.setMetaDescription(stripedHtml);

    this.metaTagService.setSocialMedia({
      title: marketplaceItem?.title as string,
      description: stripTags(marketplaceItem?.description as string),
      twitterCardType: TwitterCardType.SummaryLargeImage,
      facebookCardType: FacebookCardType.Article,
      image: marketplaceItem?.photos?.[0]?.publicUrl as string
    });
  }

  getEncodedAddress() {
    const address = this.getAddressString(this.item?.address);
    return encodeURIComponent(address);
  }

  private getAddressString(address?: Address): string {
    if (!address) {
      return '';
    }
    const { street, street2, city, state, zip, country } = address;

    let addressString = '';

    if (street2) {
      addressString += `${street2} `;
    }

    if (street) {
      addressString += `${street} `;
    }

    if (city) {
      addressString += `${city}, `;
    }

    if (state) {
      addressString += `${state}, `;
    }

    if (zip) {
      addressString += `${zip}, `;
    }

    if (country) {
      addressString += `${country}`;
    }

    return addressString;
  }

  contactSeller() {
    if (this.isLoggedIn) {
      this.dialogService
        .open(ContactSellerDialogComponent, {
          data: {
            currentUser: this.currentUser,
            item: this.item
          },
          width: '800px'
        })
        .afterClosed();
    } else {
      this.dialogService
        .open(InformationDialogComponent, {
          data: {
            title: this.translateService.instant('MARKETPLACE.LOGIN_DIALOG.TITLE'),
            message: this.translateService.instant('MARKETPLACE.LOGIN_DIALOG.MESSAGE'),
            confirm: this.translateService.instant('MARKETPLACE.LOGIN_DIALOG.CONFIRM'),
            cancel: this.translateService.instant('MARKETPLACE.LOGIN_DIALOG.CANCEL')
          },
          width: '25vw',
          minWidth: '450px'
        })
        .afterClosed()
        .pipe(
          map((value) =>
            value
              ? this.router.navigate(['/login'], { queryParams: { returnUrl: `/marketplace/${this.item.id}` } })
              : null
          )
        )
        .subscribe();
    }
  }
}
