import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FilterPaneComponent } from './filter-pane.component';

@NgModule({
  declarations: [FilterPaneComponent],
  imports: [CommonModule, TranslateModule],
  exports: [FilterPaneComponent]
})
export class FilterPaneModule {}
