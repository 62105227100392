import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { IconModule } from './../icon';
import { TabHeaderComponent } from './tab-header.component';

@NgModule({
  declarations: [TabHeaderComponent],
  imports: [CommonModule, IconModule, TranslateModule, PipesModule],
  exports: [TabHeaderComponent]
})
export class TabHeaderModule {}
