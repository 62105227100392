import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'ct-entity-item',
  templateUrl: './entity-item.component.html',
  styleUrls: ['./entity-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityItemComponent {
  @Input() createdOn: string | Date | undefined;
  @Input() modifiedOn: string | Date | undefined;
  @Input() username: string | undefined;
  @Input() userPhoto: string | undefined;
  @Input() body: string | undefined;
  @Input() reply: boolean;
  @Input() editable: boolean;
  @Input() deletable: boolean;

  @Output() replied = new EventEmitter();
  @Output() edited = new EventEmitter();
  @Output() deleted = new EventEmitter();

  get isModified() {
    // TODO: change condition
    return +new Date(this.createdOn as string) !== +new Date(this.modifiedOn as string);
  }
}
