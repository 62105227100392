<ct-card-section [title]="'MY_ACCOUNT.MY_MARKETPLACE' | translate">
  <ct-filter-pane
    class="my-4"
    [filters]="filters"
    [activeFilter]="queryParams.filter"
    (filterChanged)="onFilterChanged($event)"
  ></ct-filter-pane>
  <ng-container *ngIf="items?.length">
    <div *ngFor="let item of items">
      <ct-marketplace-item
        [item]="item"
        [showLink]="true"
        imgHeight="220px"
        imgWidth="100%"
        [link]="getLink(item)"
      ></ct-marketplace-item>
      <div class="line"></div>
    </div>
    <ct-navigation
      class="mt-3"
      (next)="onNext()"
      (prev)="onPrev()"
      [disabledPrev]="queryParams.offset <= 0 || loading"
      [disabledNext]="(items?.length || 0) < limit || loading"
    ></ct-navigation>
  </ng-container>
  <ng-container *ngIf="!items?.length">
    <p class="flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
  </ng-container>
</ct-card-section>
