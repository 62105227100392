import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { JournalEntryStore } from '@ct/shared/modules';
import { SetActiveOptions } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TripJournalEntryFactory } from '../factories';
import { TripJournalEntry } from '../interfaces';

const endpoint = environment.tripApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class TripJournalEntryApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    private tripJournalEntryFactory: TripJournalEntryFactory,
    private store: JournalEntryStore
  ) {
    super(httpClient, endpoint);
  }

  getAllByTripId(
    tripId: string,
    {
      range,
      campsiteId
    }: {
      range?: RequestRange;
      campsiteId?: string;
    }
  ): Observable<TripJournalEntry[]> {
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (campsiteId !== undefined && campsiteId !== null && campsiteId !== 'all') {
      params = params.append('campsiteId', campsiteId);
    }
    this.store.setLoading(true);
    return this.get(`${tripId}/journal-entries`, params).pipe(
      tap((journalEntries) => {
        this.store.setLoading(false);
        if (journalEntries?.length) {
          this.store.upsertMany(journalEntries);
        }
      })
    );
  }

  getById(tripId: string, journalEntryId: string) {
    this.store.setLoading(true);
    return this.get(`${tripId}/journal-entries/${journalEntryId}`).pipe(
      tap((journalEntry) => {
        this.store.upsert(journalEntryId, journalEntry);
        this.store.setLoading(false);
        this.setActive(journalEntry);
      })
    );
  }

  create(tripId: string, entry: TripJournalEntry) {
    const request = this.tripJournalEntryFactory.toRequest(entry);
    return this.post(`${tripId}/journal-entries`, request);
  }

  update(tripId: string, entryId: string, entry: TripJournalEntry) {
    const request = this.tripJournalEntryFactory.toRequest(entry);
    return this.patch(`${tripId}/journal-entries/${entryId}`, request);
  }

  remove(tripId: string, entryId: string) {
    this.store.setLoading(true);
    return this.delete(`${tripId}/journal-entries/${entryId}`).pipe(
      tap(() => {
        this.store.remove(entryId);
        this.store.setLoading(false);
      })
    );
  }

  setActive(id: string) {
    this.store.setActive(id as SetActiveOptions); // exactly same code doesn't require type cast in tag-api.service...
  }
}
