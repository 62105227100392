import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from '@ct/auth';
import { BlogPostApiService, SortOrder, TripApiService } from '@ct/shared';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MarketplaceApiService } from '../../marketplace';
import { UserProfileData } from '../interfaces';

const BLOG_LIMIT = 10;
const MARKETPLACE_LIMIT = 10;
const TRIPS_LIMIT = 10;

@Injectable({ providedIn: 'root' })
export class UserProfileDataResolver implements Resolve<UserProfileData> {
  constructor(
    private blogPostApiService: BlogPostApiService,
    private tripApiService: TripApiService,
    private marketplaceApiService: MarketplaceApiService,
    private authService: AuthService
  ) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const userId = activatedRouteSnapshot.queryParams?.userId;
    return this.getProfileData(userId);
  }

  private getProfileData(authorId: string): Observable<UserProfileData> {
    return forkJoin([
      this.blogPostApiService.getAll({
        authorId,
        range: { limit: BLOG_LIMIT, offset: 0 }
      }),
      this.authService.getIdentity().pipe(
        switchMap((identity) => {
          if (!identity) {
            return of([]);
          }
          return this.tripApiService.getAllShared({
            authorId,
            userId: identity.guuid,
            range: { limit: TRIPS_LIMIT, offset: 0 },
            sortOrder: SortOrder.Desc
          });
        })
      ),
      this.marketplaceApiService.getAll({
        merchantId: authorId,
        range: { limit: MARKETPLACE_LIMIT, offset: 0 }
      })
    ]).pipe(
      map(([posts, trips, marketplaceItems]) => {
        return {
          posts,
          trips,
          marketplaceItems
        };
      })
    );
  }
}
