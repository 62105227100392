import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { Features, RemoveSocialMediaMetaTagsFeature, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { MyAccountOverviewData } from '../../../../interfaces';
import { MY_ACCOUNT_PHOTOS_OVERVIEW_LIMIT } from './../../../../constants/my-account.constant';

@Component({
  selector: 'ct-my-account-overview',
  templateUrl: './my-account-overview.component.html',
  styleUrls: ['./my-account-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([RemoveSocialMediaMetaTagsFeature(), TitleFeature()])
export class MyAccountOverviewComponent {
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_OVERVIEW'
  };

  get data$(): Observable<MyAccountOverviewData> {
    return this.route.data.pipe(
      map(({ data }) => data),
      shareReplay(1)
    );
  }

  get photosLimit() {
    return MY_ACCOUNT_PHOTOS_OVERVIEW_LIMIT;
  }

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private route: ActivatedRoute,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}
}
