import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BlogPost } from '@ct/core';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../constants';
import { MyAccountBlogPostApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MyAccountPostsDataResolver implements Resolve<BlogPost[]> {
  constructor(private myAccountBlogPostApiService: MyAccountBlogPostApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.myAccountBlogPostApiService.getAllByAuthorId({ limit: MY_ACCOUNT_POSTS_FEATURE_LIMIT, offset });
  }
}
