import { Inject, Injectable, Optional } from '@angular/core';

import { defaultConfig } from '../constants';
import { GalleryConfig } from '../interfaces';
import { GALLERY_CONFIG } from '../tokens';
import { GalleryRef } from './gallery-ref';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private readonly instances = new Map<string, GalleryRef>();
  public config: GalleryConfig;

  constructor(@Optional() @Inject(GALLERY_CONFIG) config: GalleryConfig) {
    this.config = config ? { ...defaultConfig, ...config } : defaultConfig;
  }

  ref(id = 'root', config?: GalleryConfig): GalleryRef {
    if (this.instances.has(id)) {
      const galleryRef: GalleryRef = this.instances.get(id) as GalleryRef;
      if (config) {
        galleryRef.setConfig({ ...this.config, ...config });
      }
      return galleryRef;
    } else {
      return this.instances
        .set(id, new GalleryRef({ ...this.config, ...config }, this.deleteInstance(id)))
        .get(id) as GalleryRef;
    }
  }

  destroyAll() {
    this.instances.forEach((ref: GalleryRef) => ref.destroy());
  }

  resetAll() {
    this.instances.forEach((ref: GalleryRef) => ref.reset());
  }

  private deleteInstance(id: string) {
    return () => {
      if (this.instances.has(id)) {
        this.instances.delete(id);
      }
    };
  }
}
