import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeletionDialogComponent, DialogService, ImageUploadService, SpinnerService } from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import {
  BlogPostStatus,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  LocalStorageService,
  Tag,
  UploadedImage
} from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { QuillModules } from 'ngx-quill';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { BaseAutosaveFormComponent } from '../../../../classes';
import { Mode } from '../../../../enums';
import { toSlug } from '../../../../helpers';
import { ImageMetadata } from '../../../../interfaces';
import { MyAccountPhotoApiService, TagApiService } from '../../../../services';
import { BlogPostApiService } from '../../../blog-shared/services';
import { YoutubeVideo, YoutubeVideoAddService, YoutubeVideoApiService } from '../../../youtube';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

const MAX_BODY_LENGTH = 7 * 1000 * 1000;

const BLOG_POST_AUTOSAVE_CONFIG = {
  autosaveKey: 'BLOG_POST_CREATE_AUTOSAVE',
  excludeFormFields: ['status'],
  dialogConfig: {
    titleKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.TITLE',
    messageKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.MESSAGE',
    confirmKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.CONFIRM',
    cancelKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.CANCEL'
  }
};

@Component({
  selector: 'ct-story-add-dialog',
  templateUrl: './story-add-dialog.component.html',
  styleUrls: ['./story-add-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class StoryAddDialogComponent extends BaseAutosaveFormComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public modules: QuillModules = QUILL_MODULES_CONFIG;
  public isLoading = false;

  public readonly form = new UntypedFormGroup({
    id: new UntypedFormControl(),
    title: new UntypedFormControl('', [Validators.required]),
    slug: new UntypedFormControl(''),
    tags: new UntypedFormControl([]),
    featuredPhoto: new UntypedFormControl(''),
    featuredPhotoTitle: new UntypedFormControl(''),
    featuredPhotoKeywords: new UntypedFormControl(''),
    featuredYoutubeVideo: new UntypedFormControl(''),
    body: new UntypedFormControl('', [Validators.required, Validators.maxLength(MAX_BODY_LENGTH)]),
    status: new UntypedFormControl(BlogPostStatus.Draft),
    photos: new UntypedFormControl([]),
    videos: new FormArray([]),
    youtubeVideos: new FormArray([]),
    allowComments: new UntypedFormControl(true)
  });

  public get buttons(): DialogButton[] {
    const cancelButton: DialogButton = {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.dialogRef.close()
    };

    const switchToDraftButton: DialogButton = {
      labelKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.SWITCH_TO_DRAFT',
      clicked: () => {
        this.onSwitchDraft();
      },
      color: 'primary'
    };

    const saveDraftButton: DialogButton = {
      labelKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.SAVE_DRAFT',
      clicked: () => {
        this.onSaveDraft();
      },
      color: 'primary'
    };

    const publishButton: DialogButton = {
      labelKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISH',
      clicked: () => {
        this.onPublish();
      },
      color: 'accent'
    };

    const updateButton: DialogButton = {
      labelKey: 'COMMON.UPDATE',
      clicked: () => {
        this.onUpdate();
      },
      color: !this.isDraft ? 'accent' : 'primary'
    };

    if (this.isEditMode) {
      if (this.isDraft) {
        return [cancelButton, updateButton, publishButton];
      } else {
        return [cancelButton, switchToDraftButton, updateButton];
      }
    } else {
      return [cancelButton, saveDraftButton, publishButton];
    }
  }

  get story() {
    return this.data.story;
  }

  get status() {
    return this.form.controls.status?.value;
  }

  get photos() {
    return this.form.controls.photos?.value;
  }

  get youtubeVideos() {
    return this.form.get('youtubeVideos') as FormArray;
  }

  get isDraft() {
    return this.status === BlogPostStatus.Draft;
  }

  get isEditMode() {
    return this.data?.mode === Mode.Edit;
  }

  public readonly optionsFn = (match: string) => this.tagApiService.getAll(match);

  public readonly createFn = (name: string) => this.tagApiService.create(name);

  public readonly checkFn = (name: string) => this.tagApiService.getByName(name);

  public readonly labelFn = ({ name }: Tag) => name as string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private dialogRef: MatDialogRef<StoryAddDialogComponent>,
    protected localStorageService: LocalStorageService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    private formState: FormStateDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private imageUploadService: ImageUploadService,
    private blogPostApiService: BlogPostApiService,
    private tagApiService: TagApiService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private snackBar: MatSnackBar,
    private youtubeVideoAddService: YoutubeVideoAddService,
    private youtubeVideoApiService: YoutubeVideoApiService
  ) {
    super(localStorageService, dialogService, translateService, BLOG_POST_AUTOSAVE_CONFIG);
  }

  ngOnInit() {
    if (this.isEditMode && this.story) {
      this.loadPostAndPhotos();
    } else {
      this.setForm(this.form);
      this.getAutosavedItem()
        .pipe(take(1))
        .subscribe((autosavedPost) => {
          if (!autosavedPost) {
            this.stopAutosave();
            this.startAutosave();
            return;
          }
          this.form.patchValue(autosavedPost);
          this.startAutosave();
        });
    }
    this.generateSlugFromTitle();
  }

  loadPostAndPhotos() {
    const photosRequest$ = this.story.photoIds?.length
      ? this.myAccountPhotoApiService.getByIds(this.story.photoIds)
      : of([]);
    photosRequest$
      .pipe(map((photos) => ({ ...this.story, photos: photos.map((photo) => ({ ...photo, id: String(photo?.id) })) })))
      .subscribe(({ disableComments, ...post }) => {
        this.form.patchValue({ ...post, allowComments: !disableComments });
        for (const video of this.story.youtubeVideos) {
          this.youtubeVideos.push(new UntypedFormControl(video));
        }
      });
  }

  generateSlugFromTitle() {
    this.form.controls.title.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((title) => this.form.controls.slug.patchValue(toSlug(title)));
  }

  onSwitchDraft() {
    this.createOrUpdatePost(BlogPostStatus.Draft);
  }

  onUpdate() {
    this.createOrUpdatePost();
  }

  onSaveDraft() {
    this.createOrUpdatePost(BlogPostStatus.Draft);
  }

  onPublish() {
    this.createOrUpdatePost(BlogPostStatus.Published);
  }

  createOrUpdatePost(status?: BlogPostStatus) {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { id, allowComments, featuredPhotoTitle, featuredPhotoKeywords, ...postForm } = this.form.value;
    postForm.youtubeVideos = postForm.youtubeVideos.filter((v: string | null) => !!v);
    const blogPostPayload = { ...postForm, disableComments: !allowComments };
    if (status) {
      blogPostPayload.status = status;
    }
    return (!id ? this.blogPostApiService.create(blogPostPayload) : this.blogPostApiService.update(id, blogPostPayload))
      .pipe(
        switchMap((post) => {
          if (!blogPostPayload.featuredPhotoId) {
            return of(post);
          }
          const updatedMetadata: ImageMetadata = {
            photoTitle: featuredPhotoTitle,
            keywords: featuredPhotoKeywords
          };
          return this.myAccountPhotoApiService
            .updateMetadata(blogPostPayload.featuredPhotoId, updatedMetadata)
            .pipe(map(() => post));
        }),
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.stopAutosave();
        this.dialogRef.close(true);
      });
  }

  onDelete() {
    const title = this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.DELETE_POST_TITLE');
    const message = this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.DELETE_POST_MESSAGE');
    const { id } = this.form.value;
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title,
          message
        },
        width: '50vw'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.blogPostApiService.remove(id).pipe(
            catchError((err) => {
              console.log(err);
              if (err.status === 403) {
                return throwError(err);
              }
              return of(null);
            })
          )
        )
      )
      .subscribe(
        () => {
          this.stopAutosave();
          this.dialogRef.close(true);
        },
        () => {
          const snackbarMessage = this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.FORBIDDEN_DELETE_POST');
          const close = this.translateService.instant('COMMON.CLOSE');
          this.snackBar.open(snackbarMessage, close, { duration: 10000 });
        }
      );
  }

  onSelectFeaturedPhotoPlaceholder() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.ADD_FEATURED_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(([photo]) => !!photo),
        switchMap(([photo]) =>
          this.myAccountPhotoApiService.getMetadata(photo.id).pipe(map((metadata) => ({ metadata, photo })))
        )
      )
      .subscribe(({ photo, metadata }) => {
        this.form.controls.featuredPhoto.patchValue(photo);
        this.form.controls.featuredPhotoTitle.patchValue(metadata?.photoTitle);
        this.form.controls.featuredPhotoKeywords.patchValue(metadata?.keywords);
        this.changeDetectorRef.detectChanges();
      });
  }

  onReplaceFeaturedPhoto() {
    this.onSelectFeaturedPhotoPlaceholder();
  }

  onRemoveFeaturedPhoto() {
    this.form.controls.featuredPhoto.patchValue(undefined);
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTOS',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
        this.changeDetectorRef.detectChanges();
      });
  }

  onRemovePhoto({ id }: UploadedImage) {
    const photos = this.photos.filter((photo: UploadedImage) => photo.id !== id);
    this.form.controls.photos.patchValue(photos);
    this.changeDetectorRef.detectChanges();
  }

  onAddVideos() {
    this.youtubeVideoAddService
      .showVideoAddDialog({
        titleKey: 'MY_ACCOUNT.ADD_VIDEOS',
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_VIDEO',
        secondTabKey: 'MY_ACCOUNT.MY_VIDEOS',
        getAllVideosFn: () => this.youtubeVideoApiService.getAllMyVideos(),
        addVideoLinkFn: (link: string) => {
          return of({ youtubeId: link });
        }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((videos) => {
        for (const video of videos) {
          this.youtubeVideos.push(new UntypedFormControl(video.youtubeId));
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  removeVideo(video: YoutubeVideo) {
    this.youtubeVideos.removeAt(this.youtubeVideos.value.findIndex((videoId: string) => videoId === video.youtubeId));
  }
}
