import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export interface CTContextContext<T> {
  $implicit: T;
  ctContext: T;
}

@Directive({
  selector: '[ctContext]',
  exportAs: 'ctx'
})
export class CTContextDirective<T> {
  static ngTemplateGuard_supyContext: 'binding';

  viewRef?: EmbeddedViewRef<CTContextContext<T>>;

  static ngTemplateContextGuard<T>(
    _dir: CTContextDirective<T>,
    _context: CTContextContext<T>
  ): _context is CTContextContext<T> {
    return true;
  }

  @Input() set ctContext(context: T) {
    if (!this.viewRef) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, {
        $implicit: context,
        ctContext: context
      });
    } else {
      this.viewRef.context.$implicit = context;
      this.viewRef.context.ctContext = context;
    }
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<CTContextContext<T>>
  ) {}
}
