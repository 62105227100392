import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService, UserIdentity, UserNotificationsApiService, UserProfileApiService } from '@ct/auth';
import { DialogService } from '@ct/components';
import { Features, TitleConfig, TitleFeature } from '@ct/core';
import { MyAccountPhotoApiService } from '@ct/shared';
import { switchMap, take } from 'rxjs/operators';

import { DownloadMediaInfoComponent } from '../download-media-info';

const PASSWORD_VALUE = '*********';

@Component({
  selector: 'ct-my-account-profile-content',
  templateUrl: './my-account-profile-content.component.html',
  styleUrls: ['./my-account-profile-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountProfileContentComponent implements OnInit {
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_PROFILE'
  };

  public descriptionControl: UntypedFormControl = new UntypedFormControl('');
  public emailAddressControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true });
  public passwordControl: UntypedFormControl = new UntypedFormControl({ value: PASSWORD_VALUE, disabled: true });
  public userId: string | undefined;

  public notificationsForm: UntypedFormGroup = this.formBuilder.group({
    blogPostComments: true,
    threadReplies: true
  });
  public archiving: boolean;

  private identityUserId: string;
  private notificationsConfigId: string;

  constructor(
    private authService: AuthService,
    private userProfileApiService: UserProfileApiService,
    private userNotificationsApiService: UserNotificationsApiService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.authService
      .getUserProfile()
      .pipe(take(1))
      .subscribe((user) => {
        this.userId = user?.id;
        this.identityUserId = user.userId;
        this.descriptionControl.patchValue(user.description);
        this.getNotificationSettings();
      });

    this.authService
      .getIdentity()
      .pipe(take(1))
      .subscribe((user: UserIdentity | null) => {
        this.emailAddressControl.patchValue(user?.username);
      }); // Identity: username = email
  }

  onSaveSettings() {
    this.userProfileApiService
      .update(this.userId as string, { description: this.descriptionControl?.value })
      .pipe(switchMap((userProfile) => this.authService.updateUserProfile(userProfile)))
      .subscribe();
  }

  onSaveNotificationSettings() {
    this.userNotificationsApiService
      .update(this.notificationsConfigId as string, this.notificationsForm.value)
      .subscribe();
  }

  onStartDownload() {
    this.archiving = true;
    this.cdr.markForCheck();
    this.myAccountPhotoApiService.startArchiving().subscribe();
    this.dialogService.open(DownloadMediaInfoComponent);
  }

  private getNotificationSettings() {
    this.userNotificationsApiService.getByUserId(this.identityUserId as string).subscribe((result) => {
      this.notificationsConfigId = result.id;
      this.notificationsForm.patchValue(result);
    });
  }
}
