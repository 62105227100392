<ct-dialog-content
  titleKey="MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_TO_TRIP"
  [closeIcon]="true"
  headerIcon="add-circle"
  [subtitle]="subtitle"
>
  <section class="options-grid">
    <article *ngFor="let option of addOptions" class="option" (click)="onOptionSelect(option)">
      <ct-icon [icon]="option.icon" size="fit"></ct-icon>
      <p class="mb-0">{{ option.titleKey | translate }}</p>
    </article>
  </section>
  <ct-button [fullWidth]="true" class="mt-4" variant="raised" color="secondary" (click)="openNewTripDialog()"
    >Create New Trip
  </ct-button>
</ct-dialog-content>
