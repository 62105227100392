<ct-dialog-content titleKey="MY_ACCOUNT.MY_POSTS_FEATURE.SHARE.TITLE" [closeIcon]="true">
  <div>
    <div class="social-links mb-2">
      <a class="social-link" target="_blank" (click)="openWindow(facebookSharingUrl)" [class.disabled]="isDisabled">
        <ct-icon class="social-link__icon" size="fit" icon="facebook" [class.disabled]="isDisabled"></ct-icon>
      </a>

      <a class="social-link" target="_blank" (click)="openWindow(twitterSharingUrl)" [class.disabled]="isDisabled">
        <ct-icon class="social-link__icon" size="fit" icon="twitter" [class.disabled]="isDisabled"></ct-icon>
      </a>
    </div>

    <ct-input
      class="main-pasxsword"
      [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.SHARE.COPY_LABEL' | translate"
      type="text"
      [formControl]="linkControl"
      [readonly]="true"
      suffixIcon="content_copy"
      [attr.lightLabel]="false"
      (click)="selectAll()"
      (suffixIconClicked)="copyLink()"
    ></ct-input>
  </div>
</ct-dialog-content>
