import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from '../../../dialog';
import { FileUploadSharedModule } from '../file-upload-shared';
import { FileUploadService } from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule, TranslateModule, DialogModule, MatTabsModule, FileUploadSharedModule],
  providers: [FileUploadService],
  exports: []
})
export class FileUploadModule {}
