import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CardModule, FilterPaneModule, InputModule, NavigationModule } from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { TranslateModule } from '@ngx-translate/core';

import { MarketplaceModule } from '../../../marketplace';
import { MyAccountSharedModule } from '../my-account-shared';
import {
  EditMerchantProfileComponent,
  MyAccountMarketplaceComponent,
  MyAccountMarketplaceWrapperComponent
} from './components';
import { MyAccountMarketplaceRoutingModule } from './my-account-marketplace-routing.module';

@NgModule({
  declarations: [MyAccountMarketplaceComponent, EditMerchantProfileComponent, MyAccountMarketplaceWrapperComponent],
  imports: [
    CommonModule,
    MyAccountMarketplaceRoutingModule,
    MarketplaceModule,
    CardModule,
    MyAccountSharedModule,
    FilterPaneModule,
    NavigationModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
    InputModule,
    LayoutModule
  ]
})
export class MyAccountMarketplaceModule {}
