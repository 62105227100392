import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComingSoonComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() showDescription = true;
  @Input() fontSize: string;
}
