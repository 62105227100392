<div class="container">
  <div class="footer">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 logo">
            <img
              alt="logo"
              src="assets/logos/main-logo-light.png"
              srcset="assets/logos/main-logo-light@2x.png 2x, assets/logos/main-logo-light@3x.png 3x"
              loading="lazy"
            />
          </div>
        </div>
        <div class="row navbar">
          <div class="col-12">
            <span class="nav-item" *ngFor="let menuItem of menuItems; let last = last">
              <a class="nav-link" [routerLink]="menuItem.link" (click)="onMenuItem(menuItem)"
                >{{ menuItem.title$ | async }}
              </a>
              <span class="vertical-divider" *ngIf="!last">|</span>
            </span>
          </div>
        </div>
        <!--        TODO: uncomment once we have links to social networks-->
        <!--        <div class="row iconbar">-->
        <!--          <div class="col-12 icons">-->
        <!--            <ct-icon size="fit" icon="social:facebook"></ct-icon>-->
        <!--            <ct-icon size="fit" icon="social:twitter"></ct-icon>-->
        <!--            <ct-icon size="fit" icon="social:instagram"></ct-icon>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="row copyright">
          <div class="col-12">
            <p class="copyright-text">
              <span class="pr-2">{{ 'FOOTER.COPYRIGHT' | translate : { year: year } }}</span>
              <a class="pr-2" routerLink="/legal">{{ 'COMMON.LEGAL.LEGAL_NOTICES.FOOTER_TITLE' | translate }}</a>
              <a class="pr-2" routerLink="/dmca">{{ 'COMMON.LEGAL.DMCA.FOOTER_TITLE' | translate }}</a>
              <a class="pr-2" routerLink="/privacy-policy">{{ 'COMMON.LEGAL.PRIVACY_POLICY.TITLE' | translate }}</a>
              <a class="pr-2" routerLink="/terms-and-conditions">{{
                'COMMON.LEGAL.TERMS_AND_CONDITIONS.TITLE' | translate
              }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<img class="footer-treeline" src="assets/footer/footer-treeline.png" loading="lazy" />
