export enum EntityType {
  Trip = 'trip',
  BlogPost = 'blogPost',
  Photo = 'photo',
  Video = 'video',
  JournalEntry = 'journalEntry',
  Campsite = 'campsite',
  MarketplaceItem = 'marketplaceItem',
  Itinerary = 'itinerary',
  List = 'list',
  ListItem = 'listItem',
  Thread = 'thread',
  Comment = 'comment',
  Group = 'group',
  Like = 'like',
  User = 'user',
  GroupTimeline = 'groupTimeline'
}
