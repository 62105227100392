import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormStateDispatcher, UserProfile } from '@ct/core';
import { QuillModules } from 'ngx-quill';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [['bold', 'italic', { list: 'bullet' }, 'link']]
};

@Component({
  selector: 'ct-entity-item-new',
  templateUrl: './entity-item-new.component.html',
  styleUrls: ['./entity-item-new.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityItemNewComponent {
  @Input() user: UserProfile | null;
  @Input() cancelable: boolean | undefined = true;
  @Input() submitButtonKey = 'MY_ACCOUNT.MY_POSTS_FEATURE.POST_COMMENT';
  @Input() cancelButtonKey = 'COMMON.CANCEL';

  public form = new UntypedFormGroup({
    body: new UntypedFormControl('', [Validators.required])
  });

  public modules: QuillModules = QUILL_MODULES_CONFIG;

  @Output() submitted = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  constructor(private formState: FormStateDispatcher) {}

  onSubmit() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    const value = this.form.value?.body;
    this.submitted.emit(value);

    this.form.reset();
    this.form.markAsPristine();
  }

  onCancel() {
    this.cancelled.emit();
  }
}
