import { forwardRef, ɵDirectiveDef, Provider, Type } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { identity } from 'rxjs';

function ValueAccessorFeature(accessorTypeFn?: () => Type<unknown>) {
  return function ValueAccessor<T extends Type<unknown>>(directiveDef: ɵDirectiveDef<T>) {
    const { providersResolver, type } = directiveDef;
    if (providersResolver === null || providersResolver === undefined) {
      throw new Error(
        'Error! Add empty "providers" array into your component! An example: @Component({providers: []})'
      );
    }
    const processProvidersFn = (fn: (providers: Provider[]) => Provider[]) => (providers: Provider[]) => {
      return fn([
        ...providers,
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => accessorTypeFn?.() ?? type),
          multi: true
        }
      ]);
    };

    directiveDef.providersResolver = (def, fn = identity) => {
      providersResolver?.(def, processProvidersFn(fn));
    };
  };
}

export { ValueAccessorFeature };
