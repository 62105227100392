import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@ct/auth';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
  MY_ACCOUNT_PHOTOS_SIDEBAR_LIMIT,
  MY_ACCOUNT_TRIPS_SIDEBAR_LIMIT,
  MY_ACCOUNT_VIDEOS_SIDEBAR_LIMIT
} from '../../constants';
import { MyAccountOverviewData } from '../../interfaces';

@Component({
  selector: 'ct-my-account-sidebar',
  templateUrl: './my-account-sidebar.component.html',
  styleUrls: ['./my-account-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountSidebarComponent {
  get data$(): Observable<MyAccountOverviewData> {
    return this.route.data.pipe(
      map(({ data }) => data),
      shareReplay(1)
    );
  }

  public searchControl: UntypedFormControl = new UntypedFormControl();

  get photosLimit() {
    return MY_ACCOUNT_PHOTOS_SIDEBAR_LIMIT;
  }

  get videosLimit() {
    return MY_ACCOUNT_VIDEOS_SIDEBAR_LIMIT;
  }

  get tripsLimit() {
    return MY_ACCOUNT_TRIPS_SIDEBAR_LIMIT;
  }

  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  getLink(feature: string, id?: string | undefined, slug?: string | undefined) {
    const path: string[] = [];
    const pathPrefix = 'my-account';
    if (feature) {
      path.push(`/${pathPrefix}/${feature}`);
    }
    if (id) {
      path.push(id);
    }
    if (slug) {
      path.push(slug);
    }
    return path;
  }

  onLogout() {
    this.authService.logout().subscribe(() => this.authService.redirectToLogin());
  }

  onSearch() {
    // TODO: search by API
  }
}
