import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components/dialog';

import { YoutubeDialogViewConfig } from '../../interfaces';

@Component({
  selector: 'ct-youtube-video-view-dialog',
  templateUrl: './youtube-video-view-dialog.component.html',
  styleUrls: ['./youtube-video-view-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeVideoViewDialogComponent {
  public config: YoutubeDialogViewConfig;
  public videoId: string;

  public closeButton: DialogButton = {
    labelKey: 'COMPONENTS.FILE_UPLOAD.CLOSE',
    clicked: () => this.dialogRef.close()
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: YoutubeDialogViewConfig },
    private dialogRef: MatDialogRef<YoutubeVideoViewDialogComponent>
  ) {
    this.config = { ...this.config, ...this.data?.config };
    this.videoId = this.config.videoId;
  }

  get buttons() {
    return [];
  }
}
