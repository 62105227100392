<ct-dialog-content
  titleKey="MARKETPLACE.ITEM_DETAILS.REACH_OUT"
  [closeIcon]="true"
  [buttons]="buttons"
  alignButtons="end"
>
  <mat-tab-group [(selectedIndex)]="tabIndex" [ngClass]="{ 'hide-tabs': messages.length === 0 }">
    <mat-tab [label]="'MARKETPLACE.ITEM_DETAILS.CONTACT_TAB' | translate">
      <ng-container *ngIf="!isSent">
        <h1 class="title mb-0 text-center">
          {{ 'MARKETPLACE.ITEM_DETAILS.SEND_TO_SELLER' | translate }} - {{ data?.item?.title }}
        </h1>
        <div class="p-3">
          <form class="form" [formGroup]="form">
            <div class="row">
              <ct-input
                [label]="'CONTACT_US.FIRST_NAME' | translate"
                [placeholder]="'CONTACT_US.FIRST_NAME_PLACEHOLDER' | translate"
                class="col-6"
                formControlName="firstName"
              ></ct-input>
              <ct-input
                [label]="'CONTACT_US.LAST_NAME' | translate"
                [placeholder]="'CONTACT_US.LAST_NAME_PLACEHOLDER' | translate"
                class="col-6"
                formControlName="lastName"
              ></ct-input>
            </div>
            <div class="row">
              <ct-input
                type="email"
                [label]="'CONTACT_US.EMAIL_ADDRESS' | translate"
                [placeholder]="'CONTACT_US.EMAIL_ADDRESS_PLACEHOLDER' | translate"
                class="col-6"
                formControlName="email"
              ></ct-input>
              <ct-input
                [label]="'CONTACT_US.PHONE_NUMBER' | translate"
                [placeholder]="'CONTACT_US.PHONE_NUMBER_PLACEHOLDER' | translate"
                class="col-6"
                formControlName="phone"
              ></ct-input>
            </div>
            <div class="row">
              <ct-textarea
                [label]="'CONTACT_US.MESSAGE' | translate"
                [placeholder]="'CONTACT_US.MESSAGE_PLACEHOLDER' | translate"
                class="col-12"
                formControlName="body"
              ></ct-textarea>
            </div>
            <div class="row">
              <ct-re-captcha class="col-12 col-md-6" formControlName="captcha"></ct-re-captcha>
              <div class="col-12 col-md-6 send-button mt-md-4">
                <ct-button [variant]="'flat'" [color]="'accent'" (click)="onSave()">{{
                  'MARKETPLACE.ITEM_DETAILS.SEND_BUTTON' | translate
                }}</ct-button>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="isSent">
        <ct-spinner></ct-spinner>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="'MARKETPLACE.ITEM_DETAILS.MESSAGES_TAB' | translate" *ngIf="messages.length > 0">
      <div *ngFor="let message of messages">
        <b>{{ message.createdOn | dateTime }}:</b> {{ message.body }}
      </div>
    </mat-tab>
  </mat-tab-group>
</ct-dialog-content>
