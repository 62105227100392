<div class="row" *ngIf="trips?.length">
  <ct-trip-card
    class="col-12 col-md-4 mb-3"
    *ngFor="let trip of trips"
    [trip]="trip"
    [link]="getLink(trip)"
  ></ct-trip-card>
</div>
<p *ngIf="!trips?.length" class="flex-center">
  <span>{{ 'COMMON.EMPTY' | translate }}</span>
</p>
