import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

import { IconType } from '../icon';

@Component({
  selector: 'ct-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetComponent {
  @Input() readonly icon: IconType;
  @Input() readonly titleKey: string;
  @Input() readonly showMore: boolean | null;
  @Input() readonly showMoreLink: string[] | string;
  @Input() readonly queryParams: Params;
}
