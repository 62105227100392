import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { QuillModules } from 'ngx-quill';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class TextEditorComponent extends BaseControlComponent<string> implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly control = new UntypedFormControl('');

  @Input() public label: string;
  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }
  @Input() public readonly placeholder: string = '';
  @Input() public modules: QuillModules;

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  onFocus() {
    this.onTouched?.();
  }
}
