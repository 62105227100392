<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async" [showSearch]='false'>
  <ct-extended-header ctHeader icon="shopping-cart" title="Marketplace"></ct-extended-header>

  <div ctContent>
    <ct-image-banner
      img="register"
      [title]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.IMG_TITLE' | translate"
    ></ct-image-banner>
    <div class="container register">
      <h1 class="title text-center pb-5">{{ 'MARKETPLACE.MERCHANT_PROFILE.CREATE.TITLE' | translate }}</h1>
      <ct-card>
        <form class="mt-3 form" [formGroup]="form" (ngSubmit)="onRegister()">
          <ct-card-section [showLine]="false">
            <div class="row">
              <ct-input
                [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.FIRST_NAME' | translate"
                [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.FIRST_NAME_PLACEHOLDER' | translate"
                class="col-12 col-md-6"
                formControlName="firstName"
              ></ct-input>
              <ct-input
                [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.LAST_NAME' | translate"
                [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.LAST_NAME_PLACEHOLDER' | translate"
                class="col-12 col-md-6"
                formControlName="lastName"
              ></ct-input>
            </div>
            <div class="row">
              <ct-input
                type="email"
                [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.EMAIL_ADDRESS' | translate"
                [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.EMAIL_ADDRESS_PLACEHOLDER' | translate"
                class="col-12 col-md-6"
                formControlName="email"
              ></ct-input>
              <ct-input
                [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.PHONE_NUMBER' | translate"
                [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.PHONE_NUMBER_PLACEHOLDER' | translate"
                class="col-12 col-md-6"
                formControlName="phone"
              ></ct-input>
            </div>
            <div formGroupName="address">
              <div class="row">
                <ct-input
                  [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.ADDRESS' | translate"
                  [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.ADDRESS_PLACEHOLDER' | translate"
                  class="col-12 col-md-4"
                  formControlName="street"
                ></ct-input>
                <ct-input
                  [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.ADDRESS2' | translate"
                  [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.ADDRESS2_PLACEHOLDER' | translate"
                  class="col-12 col-md-4"
                  formControlName="street2"
                ></ct-input>
              </div>
              <div class="row">
                <ct-input
                  [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.CITY' | translate"
                  [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.CITY_PLACEHOLDER' | translate"
                  class="col-12 col-md-4"
                  formControlName="city"
                ></ct-input>
                <ct-input
                  [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.STATE' | translate"
                  [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.STATE_PLACEHOLDER' | translate"
                  class="col-12 col-md-4"
                  formControlName="state"
                ></ct-input>
                <ct-input
                  [label]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.ZIP' | translate"
                  [placeholder]="'MARKETPLACE.MERCHANT_PROFILE.CREATE.ZIP_PLACEHOLDER' | translate"
                  class="col-12 col-md-4"
                  type="number"
                  formControlName="zip"
                ></ct-input>
              </div>
            </div>
            <div class="row mb-5">
              <ct-checkbox class="col-12" formControlName="acceptToC"
                ><span [innerHTML]="'COMMON.LEGAL.TERMS_AND_CONDITIONS.AGREE_CHECKBOX_LABEL' | translate"></span
              ></ct-checkbox>
            </div>
          </ct-card-section>
          <div class="row">
            <div class="col-12">
              <ct-button variant="flat" color="accent">{{
                'MARKETPLACE.MERCHANT_PROFILE.CREATE.CREATE_ACCOUNT' | translate
              }}</ct-button>
            </div>
          </div>
        </form>
      </ct-card>
    </div>
  </div>
</ct-layout>
