<ct-dialog-content [titleKey]="$any(config?.titleKey)" [buttons]="buttons" [alignButtons]="'end'">
  <div class="file-upload">
    <mat-tab-group [(selectedIndex)]="tabIndex">
      <mat-tab [label]="$any(config.firstTabKey) | translate">
        <ng-container *ngTemplateOutlet="videosListTemplate"></ng-container>
      </mat-tab>
      <mat-tab [label]="$any(config.secondTabKey) | translate">
        <div class="row">
          <p class="col-12 loading" *ngIf="loadingImages">{{ 'MY_ACCOUNT.LOADING_PHOTOS' | translate }}</p>
          <ng-container *ngIf="!loadingImages">
            <ct-image-upload-progress
              *ngFor="let image of uploadedImages; let index = index"
              class="col-12 col-md-2 image"
              [done]="image?.done"
              [error]="image?.error"
              [imageUrl]="getCroppedThumbPublicUrl(image?.data?.publicUrl)"
              [imageName]="image?.file?.name"
              [value]="image?.progress"
              [selected]="image?.selected"
              [attr.selectable]="config?.selectable"
              (click)="onSelectImage(image)"
            >
            </ct-image-upload-progress>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ct-dialog-content>

<ng-template #videosListTemplate>
  <div class="row">
    <p class="col-12 loading" *ngIf="loadingVideos">{{ 'MY_ACCOUNT.LOADING_VIDEOS' | translate }}</p>
  </div>
  <ng-container *ngIf="!loadingVideos">
    <div class="px-4 pt-2">
      <ct-video-item
        *ngFor="let video of videos"
        [video]="video"
        (selectVideo)="onSelectVideo(video)"
        [class.selected]="video.selected"
      >
      </ct-video-item>
    </div>
  </ng-container>
</ng-template>
