import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-sidebar-section',
  templateUrl: './sidebar-section.component.html',
  styleUrls: ['./sidebar-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSectionComponent {
  @Input() titleKey: string;
  @Input() extraActionKey: string;
  @Input() extraActionLink: string[];
}
