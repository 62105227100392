import { NgModule } from '@angular/core';

import { CamelToTitleCasePipe } from './camel-to-title-case.pipe';
import { DatePipe, DateTimePipe, TimeAgoPipe } from './date';
import { FilterShouldShowPipe } from './filter-should-show.pipe';
import { SafePipe } from './safe.pipe';
import { StripHtmlPipe } from './strip-html.pipe';

const PIPES: any[] = [
  DatePipe,
  DateTimePipe,
  StripHtmlPipe,
  SafePipe,
  TimeAgoPipe,
  FilterShouldShowPipe,
  CamelToTitleCasePipe
];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES],
  providers: [...PIPES]
})
export class PipesModule {}
