import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { ImageBannerModule } from '@ct/components';
import { FileUploadSharedModule } from '@ct/components/file-upload/modules/file-upload-shared';
import { TranslateModule } from '@ngx-translate/core';

import { EditVideoComponent } from './edit-video/edit-video.component';
import { MyVideoPlayerComponent } from './my-video-player/my-video-player.component';
import { VideoPocComponent } from './video-poc.component';
import { VideoPocRoutingModule } from './video-poc-routing.module';

@NgModule({
  declarations: [VideoPocComponent, MyVideoPlayerComponent, EditVideoComponent],
  imports: [
    CommonModule,
    VideoPocRoutingModule,
    TranslateModule,
    ImageBannerModule,
    FileUploadSharedModule,
    MatSliderModule
  ]
})
export class VideoPocModule {}
