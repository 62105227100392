<ct-image-banner img="register" [title]="'AUTH.REGISTER.IMG_TITLE' | translate"></ct-image-banner>
<div class="container register">
  <h1 class="title text-center">{{ 'AUTH.REGISTER.TITLE' | translate }}</h1>
  <h4 class="text-center pl-5 pr-5 pb-5">
    {{ 'AUTH.REGISTER.SUB_TITLE' | translate }}
  </h4>
  <ct-card>
    <form class="mt-3 form" [formGroup]="form" (ngSubmit)="onRegister()">
      <ct-card-section [title]="'AUTH.REGISTER.ACCOUNT_PROFILE' | translate">
        <div class="row">
          <ct-input
            [label]="'AUTH.REGISTER.FIRST_NAME' | translate"
            [placeholder]="'AUTH.REGISTER.FIRST_NAME_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="firstName"
          ></ct-input>
        </div>
        <div class="row">
          <ct-input
            [label]="'AUTH.REGISTER.LAST_NAME' | translate"
            [placeholder]="'AUTH.REGISTER.LAST_NAME_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="lastName"
          ></ct-input>
        </div>
        <div class="row">
          <ct-input
            type="email"
            [label]="'AUTH.REGISTER.EMAIL_ADDRESS' | translate"
            [placeholder]="'AUTH.REGISTER.EMAIL_ADDRESS_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="emailAddress"
          ></ct-input>
        </div>
        <div class="row">
          <ct-input
            [label]="'AUTH.REGISTER.PROFILE_NAME' | translate"
            [placeholder]="'AUTH.REGISTER.PROFILE_NAME_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="profileName"
          ></ct-input>
        </div>
        <div class="row">
          <ct-textarea
            [label]="'AUTH.REGISTER.PROFILE_DESCRIPTION' | translate"
            [placeholder]="'AUTH.REGISTER.PROFILE_DESCRIPTION_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="profileDescription"
          ></ct-textarea>
        </div>
      </ct-card-section>
      <ct-card-section [title]="'AUTH.REGISTER.ACCOUNT_SETTINGS' | translate">
        <div class="row">
          <div class="col-12 col-md-6">
            <ct-passwords formControlName="passwords"></ct-passwords>
          </div>
          <ct-checkbox class="col-12" formControlName="acceptToC"
            ><span [innerHTML]="'COMMON.LEGAL.TERMS_AND_CONDITIONS.AGREE_CHECKBOX_LABEL' | translate"></span
          ></ct-checkbox>
        </div>
      </ct-card-section>
      <div class="row">
        <ct-re-captcha class="col-12" formControlName="captcha"></ct-re-captcha>
        <div class="col-12">
          <ct-button variant="flat" color="accent" [disabled]="disableActions">{{
            'AUTH.REGISTER.CREATE_ACCOUNT' | translate
          }}</ct-button>
        </div>
      </div>
    </form>
  </ct-card>
</div>
