<div class="trip">
  <ct-link [link]="link" class="d-flex">
    <div class="trip__title mb-1">{{ trip?.title }}</div>
    <div *ngIf="showAuthor" class="trip__author">{{ trip?.author?.firstName }} {{ trip?.author?.lastName }}</div>
    <div class="trip__date">
      {{ trip?.startDate | date }} - {{ (trip?.endDate | date) || ('COMMON.DATE.NOW' | translate) }}
    </div>
  </ct-link>
  <div class="d-flex align-items-center flex-wrap mb-2">
    <ct-tag class="mr-1 mt-1" *ngFor="let tag of trip?.tags" [name]="tag?.name"></ct-tag>
  </div>
  <ct-link *ngIf="extraLink" [link]="link">
    <div class="trip__extra-link">{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.VIEW_TRIP' | translate }}</div>
  </ct-link>
</div>
