<ct-dialog-content [titleKey]="'MY_ACCOUNT.MY_PHOTOS_FEATURE.EDIT_PHOTO'" [buttons]="buttons" [alignButtons]="'end'">
  <ct-progressive-image
    [height]="'200px'"
    [width]="'300px'"
    [publicUrl]="photo?.publicUrl"
    [thumbnail]="photo?.xsPreview"
  >
  </ct-progressive-image>
  <p class="delete-link my-2" (click)="onDelete()">
    {{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE_PERMANENTLY' | translate }}
  </p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <ct-input
        [label]="'MY_ACCOUNT.MY_PHOTOS_FEATURE.CAPTION' | translate"
        class="col-12"
        formControlName="description"
      ></ct-input>
    </div>
    <div class="row">
      <ct-tag-select
        class="col-12"
        [label]="'MY_ACCOUNT.MY_PHOTOS_FEATURE.TAG' | translate"
        [placeholder]="'MY_ACCOUNT.MY_PHOTOS_FEATURE.TAGS_PLACEHOLDER' | translate"
        [optionsFn]="optionsFn"
        [createFn]="createFn"
        [checkFn]="checkFn"
        [labelFn]="labelFn"
        formControlName="tags"
      ></ct-tag-select>
    </div>
    <div class="row mb-3">
      <ct-checkbox class="col-12" formControlName="isPrivate">
        <ng-container>
          {{ 'MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE' | translate }}
        </ng-container>
      </ct-checkbox>
    </div>
  </form>
</ct-dialog-content>
