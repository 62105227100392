import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthorModule } from '@ct/components/author';
import { ButtonModule } from '@ct/components/button';
import { CardWrapperModule } from '@ct/components/card-wrapper';
import { DialogModule } from '@ct/components/dialog';
import { GalleryModule } from '@ct/components/gallery';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { LinkModule } from '@ct/components/link';
import { TagModule } from '@ct/components/tag';
import { TextEditorModule } from '@ct/components/text-editor';
import { UserAvatarModule } from '@ct/components/user-avatar';
import { PipesModule } from '@ct/core';
import { CardItemModule } from '@ct/shared/modules/card-item/card-item.module';
import { TranslateModule } from '@ngx-translate/core';

import { GroupCardComponent } from './group-card';

@NgModule({
  declarations: [GroupCardComponent],
  imports: [
    CommonModule,
    ImageModule,
    LinkModule,
    TextEditorModule,
    TranslateModule,
    AuthorModule,
    PipesModule,
    UserAvatarModule,
    ButtonModule,
    DialogModule,
    TagModule,
    GalleryModule,
    ReactiveFormsModule,
    CardWrapperModule,
    IconModule,
    CardItemModule
  ],
  exports: [GroupCardComponent]
})
export class GroupSharedModule {}
