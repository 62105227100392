import { FileUploadConfig } from '../interfaces';

export const FILE_UPLOAD_DEFAULT_CONFIG: FileUploadConfig = {
  titleKey: 'COMPONENTS.UPLOAD',
  multiple: false,
  disabled: false,
  accept: '*',
  firstTabKey: 'COMPONENTS.FILE_UPLOAD.UPLOAD',
  secondTabKey: 'COMPONENTS.FILE_UPLOAD.FILES',
  showExisting: true
};
