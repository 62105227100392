import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import {
  DeletionDialogComponent,
  DialogService,
  FilterItem,
  GalleryItem,
  GalleryService,
  ImageItem,
  ImageSize,
  ImageUploadService,
  InformationDialogComponent
} from '@ct/components';
import { DestroyableFeature, Features, TitleConfig, TitleFeature, UploadedImage, UserProfile } from '@ct/core';
import { getBiggerPublicUrl, getCroppedThumbPublicUrl, MyAccountPhotoApiService, SortOrder } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { MyAccountPhotosOperation, PhotoSelectionStatus, PhotoStatus } from '../../enums';
import { MyAccountPhotosEditDialogComponent } from '../my-account-photos-edit-dialog';
import { MY_ACCOUNT_PHOTOS_FEATURE_LIMIT } from './../../../../constants/';

interface PhotoQueryParams {
  offset: number;
  filter: PhotoStatus;
  sortOrder: SortOrder;
}

const DEFAULT_SORT = SortOrder.Desc;
const DEFAULT_OFFSET = 0;
const DEFAULT_FILTER = PhotoStatus.All;

@Component({
  selector: 'ct-my-account-photos',
  templateUrl: './my-account-photos.component.html',
  styleUrls: ['./my-account-photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature()])
export class MyAccountPhotosComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_PHOTOS'
  };
  public readonly limit = MY_ACCOUNT_PHOTOS_FEATURE_LIMIT;
  public readonly sortOptions = [
    {
      value: SortOrder.Asc,
      label: this.translateService.instant('COMMON.SORT.OLDEST')
    },
    {
      value: SortOrder.Desc,
      label: this.translateService.instant('COMMON.SORT.NEWEST')
    }
  ];

  public filters: FilterItem[] = [
    { name: PhotoStatus.All, labelKey: 'MY_ACCOUNT.MY_PHOTOS_FEATURE.VIEW_ALL' },
    { name: PhotoStatus.Public, labelKey: 'MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC' },
    { name: PhotoStatus.Private, labelKey: 'MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE' }
  ];

  public selectionFilters: FilterItem[] = [
    { name: PhotoSelectionStatus.All, labelKey: 'MY_ACCOUNT.MY_PHOTOS_FEATURE.SELECT_ALL' },
    { name: PhotoSelectionStatus.None, labelKey: 'MY_ACCOUNT.MY_PHOTOS_FEATURE.SELECT_NONE' }
  ];

  public photos: UploadedImage[] = [];
  public sortControl: UntypedFormControl = new UntypedFormControl();
  public loading = false;
  public showLoadButton = true;
  public multiselect = false;
  public toggleSelectionControl = new UntypedFormControl(this.multiselect);

  public queryParams: PhotoQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER,
    sortOrder: DEFAULT_SORT
  };

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public get someSelected(): boolean {
    return this.photos?.some(({ selected }) => selected);
  }

  public get selected(): UploadedImage[] {
    return this.photos?.filter(({ selected }) => selected);
  }

  private galleryId = 'myLightbox';
  private items: GalleryItem[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private galleryService: GalleryService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ photos }) => {
      this.photos = photos;
      this.items = this.photos.map(
        (photo) =>
          new ImageItem({
            src: getBiggerPublicUrl(photo.publicUrl) ?? photo.path,
            thumb: getCroppedThumbPublicUrl(photo.publicUrl),
            data: photo
          })
      );
      const galleryRef = this.galleryService.ref(this.galleryId, { imageSize: ImageSize.Contain });
      galleryRef.load(this.items);
    });
    this.route.queryParams.pipe(take(1)).subscribe(({ offset, sortOrder, filter }) => {
      const sort = sortOrder || this.queryParams.sortOrder;
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset,
        sortOrder: sort,
        filter: filter || this.queryParams.filter
      };
      this.showLoadButton = !(this.photos.length === 0 || this.photos.length < this.limit);
      this.sortControl.patchValue(sort);

      this.refreshUrlParams(this.queryParams);
    });

    this.onSortOrderChanged();

    this.toggleSelectionControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((selected) => {
      this.multiselect = selected;
    });
  }

  onAddPhoto() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: false,
        showExisting: false,
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        this.photos = [...photos, ...this.photos];
        this.changeDetectorRef.detectChanges();
      });
  }

  onEditPhoto(photo: UploadedImage) {
    this.dialogService
      .open(MyAccountPhotosEditDialogComponent, { width: '50vw', data: { photo } })
      .afterClosed()
      .pipe(
        take(1),
        filter((item) => !!item?.photo),
        map(({ operation, photo }) => {
          const photoId = photo?.id;
          if (operation === MyAccountPhotosOperation.Delete) {
            return this.photos.filter(({ id }) => id !== photoId);
          }
          if (operation === MyAccountPhotosOperation.Update) {
            const photoIndex = this.photos.findIndex((photo) => photo.id === photoId);
            this.photos[photoIndex] = { ...this.photos[photoIndex], ...photo };
            return [...this.photos];
          }
          return [];
        })
      )
      .subscribe((photos) => {
        this.photos = photos;
        this.changeDetectorRef.detectChanges();
      });
  }

  onDeletePhotos() {
    const selectedIds = this.selected.map(({ id }) => id);
    const title =
      selectedIds.length > 1 ? this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE.TITLE') : null;
    const confirm =
      selectedIds.length > 1 ? this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE.YES') : null;
    const cancel =
      selectedIds.length > 1 ? this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE.NO') : null;
    const message =
      selectedIds.length > 1
        ? this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE.MESSAGE')
        : this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.DELETE.SINGLE_MESSAGE', {
            blogPost: this.selected[0].blogPostIds?.length || 0,
            journalEntry: this.selected[0].journalEntryIds?.length || 0,
            trip: this.selected[0].tripIds?.length || 0
          });
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title,
          message,
          confirm,
          cancel
        },
        width: '50vw'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          forkJoin(selectedIds.map((id) => this.myAccountPhotoApiService.remove(id).pipe(catchError((err) => of(err)))))
        ),
        map(() => selectedIds)
      )
      .subscribe((removedIds) => {
        const idsToRemoveSet = new Set<string>(removedIds);
        this.photos = this.photos.filter(({ id }) => !idsToRemoveSet.has(id));
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  onSelectionChanged(photo: UploadedImage) {
    const photoId = photo?.id;
    const photoIndex = this.photos.findIndex((item) => item.id === photoId);
    this.photos[photoIndex] = { ...photo, selected: !photo.selected };
    this.photos = [...this.photos];
  }

  onBulkAccessChanged(makePrivate: boolean) {
    const cancel = this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.NO');
    const confirmPrivate = this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.YES');
    const confirmPublic = this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.YES');
    const privateSingle = {
      title: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.TITLE'),
      message: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.MESSAGE'),
      confirm: confirmPrivate,
      cancel
    };
    const publicSingle = {
      title: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.TITLE'),
      message: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.MESSAGE', {
        blogPost: this.selected[0].blogPostIds?.length || 0,
        journalEntry: this.selected[0].journalEntryIds?.length || 0,
        trip: this.selected[0].tripIds?.length || 0
      }),
      confirm: confirmPublic,
      cancel
    };
    const privateMultiple = {
      title: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.TITLE_PLURAL'),
      message: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.MESSAGE'),
      confirm: confirmPrivate,
      cancel
    };
    const publicMultiple = {
      title: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.TITLE_PLURAL'),
      message: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.MESSAGE_PLURAL'),
      confirm: confirmPublic,
      cancel
    };

    const data =
      this.selected.length > 1
        ? makePrivate
          ? publicMultiple
          : privateMultiple
        : makePrivate
        ? publicSingle
        : privateSingle;

    this.dialogService
      .open(InformationDialogComponent, {
        data,
        width: '50vw'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          forkJoin(
            this.selected.map(({ id }) =>
              this.myAccountPhotoApiService.update(id, { isPrivate: makePrivate }).pipe(catchError((err) => of(err)))
            )
          )
        ),
        switchMap(() => this.loadPhotos(this.queryParams))
      )
      .subscribe((photos) => this.refreshPhotos(photos));
  }

  onAccessChanged(photo: UploadedImage) {
    const photoId = photo?.id;
    const isPrivate = photo?.isPrivate;

    const message = isPrivate
      ? {
          title: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.MESSAGE'),
          confirm: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.YES'),
          cancel: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PRIVATE_TO_PUBLIC_DIALOG.NO')
        }
      : {
          title: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.TITLE'),
          // TODO: add pluralization
          message: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.MESSAGE', {
            blogPost: photo.blogPostIds?.length || 0,
            journalEntry: photo.journalEntryIds?.length || 0,
            trip: photo.tripIds?.length || 0
          }),
          confirm: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.YES'),
          cancel: this.translateService.instant('MY_ACCOUNT.MY_PHOTOS_FEATURE.PUBLIC_TO_PRIVATE_DIALOG.NO')
        };
    this.dialogService
      .open(InformationDialogComponent, { data: message, width: '450px' })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.myAccountPhotoApiService.update(photoId, { isPrivate: !photo.isPrivate }))
      )
      .subscribe((updatedPhoto) => {
        const photoIndex = this.photos.findIndex((item) => item.id === photoId);
        this.photos[photoIndex] = { ...this.photos[photoIndex], ...updatedPhoto };
        this.photos = [...this.photos];
        this.changeDetectorRef.detectChanges();
      });
  }

  onSortOrderChanged() {
    this.sortControl.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((sortOrder: SortOrder) => {
          this.queryParams.sortOrder = sortOrder;
          return this.loadPhotos(this.queryParams);
        })
      )
      .subscribe((photos) => this.refreshPhotos(photos));
  }

  onSelectionFilterChanged({ name }: FilterItem) {
    this.photos = this.photos.map((photo) => ({ ...photo, selected: name === PhotoSelectionStatus.All }));
  }

  onFilterChanged({ name }: FilterItem) {
    if (name === this.queryParams.filter) {
      return;
    }
    this.queryParams = {
      ...this.queryParams,
      offset: DEFAULT_OFFSET,
      filter: name,
      sortOrder: DEFAULT_SORT
    };
    return this.loadPhotos(this.queryParams).subscribe((photos) => this.refreshPhotos(photos));
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadPhotos(this.queryParams).subscribe((photos) => this.refreshPhotos(photos));
  }

  loadPhotos({ offset, sortOrder, filter }: PhotoQueryParams) {
    this.loading = true;
    const isPrivate = filter === PhotoStatus.All ? undefined : filter === PhotoStatus.Private ? true : false;
    return this.myAccountPhotoApiService
      .getAllMyPhotos({ range: { limit: this.limit, offset }, sortOrder, isPrivate })
      .pipe(
        tap(() => (this.loading = false)),
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        })
      );
  }

  refreshPhotos(photos: UploadedImage[]) {
    if (this.queryParams.offset === 0) {
      this.photos = [...photos];
    } else {
      this.photos = [...this.photos, ...photos];
    }
    this.showLoadButton = !(photos.length === 0 || photos.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  refreshUrlParams({ offset, sortOrder, filter }: PhotoQueryParams) {
    this.router.navigate([], {
      queryParams: { sortOrder, offset, filter },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
}
