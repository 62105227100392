import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@ct/core';
import { environment } from '@ct/environment';

const endpoint = environment.searchApiBaseUrl;

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  search(query: string) {
    return this.get(``, { query });
  }

  autocomplete(query: string) {
    return this.get(`autocomplete`, { query, limit: 10, offset: 0 });
  }
}
