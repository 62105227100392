<div class="my-account-header">
  <div class="row">
    <div class="col-12">
      <div class="user">
        <div class="user__photo-wrap">
          <ct-user-avatar class="photo" [src]="user?.photo?.path"></ct-user-avatar>
          <div class="photo-replace" (click)="onChangePhoto()">
            {{ 'MY_ACCOUNT.CHANGE_PHOTO' | translate }}
          </div>
        </div>
        <span class="user__name text-truncate">{{ user?.username }}</span>
        <span class="user__join"> {{ 'MY_ACCOUNT.JOINED' | translate : { date: user?.createdOn | date } }}</span>
      </div>
    </div>
  </div>
</div>
