import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BlogPost } from '@ct/core';

import { MyAccountBlogPostApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MyAccountTripsLastEditedResolver implements Resolve<BlogPost> {
  constructor(private myAccountBlogPostApiService: MyAccountBlogPostApiService) {}

  resolve() {
    return this.myAccountBlogPostApiService.getMyLastEdited();
  }
}
