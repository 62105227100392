const MINIMUM_IMAGE_SIZE = 300;

export class ImageParamsOptions {
  readonly size?: number | null;
  readonly crop?: boolean;

  constructor(args: Partial<ImageParamsOptions>) {
    this.size = args.size;
    this.crop = args.crop ?? false;
  }
}

export class ImageSizesParams {
  readonly width?: string | number | null;
  readonly height?: string | number | null;

  constructor(args: Partial<ImageSizesParams>) {
    this.width = ImageSizesParams.parseSize(args.width);
    this.height = ImageSizesParams.parseSize(args.height);

    if (this.width && !this.height) {
      this.height = this.width <= MINIMUM_IMAGE_SIZE ? MINIMUM_IMAGE_SIZE : this.width * 2;
    } else if (this.height && !this.width) {
      this.width = this.height <= MINIMUM_IMAGE_SIZE ? MINIMUM_IMAGE_SIZE : this.height * 2;
    }
  }

  static parseSize(size?: number | string | null): number | null {
    return typeof size === 'number' || size?.toString().includes('px') ? Number.parseInt(size.toString()) : null;
  }

  hasParsedValue(): boolean {
    return typeof this.width === 'number' || typeof this.height === 'number';
  }

  getBiggerSize(): number | null {
    return this.hasParsedValue()
      ? (this.width as number) > (this.height as number)
        ? (this.width as number)
        : (this.height as number)
      : null;
  }
}
export function imagePublicUrlParamsHelper(publicUrl: string | undefined, options?: ImageParamsOptions): string {
  if (!options || !publicUrl?.trim()) {
    return publicUrl as string;
  }

  const { size, crop } = options;

  let transformedUrl = publicUrl;

  if (size && crop) {
    transformedUrl += `=s${size}-c`;
  } else if (size) {
    transformedUrl += `=s${size}`;
  } else if (crop) {
    transformedUrl += `=c`;
  }

  return transformedUrl;
}

export function getBiggerPublicUrl(
  publicUrl: string | undefined,
  sizes: Pick<ImageSizesParams, 'width' | 'height'> = {}
): string {
  const imageSize = new ImageSizesParams(sizes);

  let windowSize: ImageSizesParams;

  try {
    windowSize = new ImageSizesParams({
      width: globalThis?.innerWidth ?? 400,
      height: globalThis?.innerHeight ?? 400
    });
  } catch (e) {
    windowSize = new ImageSizesParams({
      width: 400,
      height: 400
    });
    console.error(e);
  }

  return imagePublicUrlParamsHelper(publicUrl, {
    size: imageSize.getBiggerSize() ?? windowSize.getBiggerSize(),
    crop: false
  });
}

export function getBiggerCroppedPublicUrl(
  publicUrl: string | undefined,
  sizes: Pick<ImageSizesParams, 'width' | 'height'> = {}
): string {
  const imageSize = new ImageSizesParams(sizes);

  let windowSize: ImageSizesParams;

  try {
    windowSize = new ImageSizesParams({
      width: globalThis?.innerWidth ?? 400,
      height: globalThis?.innerHeight ?? 400
    });
  } catch (e) {
    windowSize = new ImageSizesParams({
      width: 400,
      height: 400
    });
    console.error(e);
  }

  return imagePublicUrlParamsHelper(publicUrl, {
    size: imageSize.getBiggerSize() ?? windowSize.getBiggerSize(),
    crop: true
  });
}

export function getCroppedThumbPublicUrl(publicUrl: string | undefined, size: string | number | null = 120): string {
  const imageSize = ImageSizesParams.parseSize(size);

  return imagePublicUrlParamsHelper(publicUrl, {
    size: imageSize,
    crop: true
  });
}
