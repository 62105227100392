import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { DialogService, ShareDialogComponent } from '@ct/components';
import { BlogPost, UserProfile } from '@ct/core';
import { environment } from '@ct/environment';
import { BlogPostPhotoViewType, entitySlugUrl } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'ct-admin-story-view',
  templateUrl: './my-account-post-view.component.html',
  styleUrls: ['./my-account-post-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountPostViewComponent {
  public currentUser$: Observable<UserProfile | null> = this.authService
    .getUserProfile()
    .pipe(catchError(() => of(null)));
  public post$: Observable<BlogPost> = this.route.data.pipe(map(({ post }) => post));
  public photoViewType = BlogPostPhotoViewType.Grid;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private dialogService: DialogService
  ) {}

  shareStory(item: BlogPost) {
    const url: string = this.getSharingUrl(item);

    this.dialogService.open(ShareDialogComponent, {
      data: {
        url: url || globalThis.location.href,
        text: item?.title,
        disabled: false
      },
      width: '300px'
    });
  }

  private getSharingUrl(item: BlogPost): string {
    // TODO: window fix for SSR, should be replaced with APP level injection.
    let location = undefined;
    if (typeof globalThis !== 'undefined' && globalThis?.location) {
      location = globalThis.location.origin;
    }
    const baseUrl = location || environment.webUrl;

    return entitySlugUrl(`${baseUrl}/stories/`, item);
  }
}
