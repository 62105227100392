import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UploadedImage, VideoUploadEntity } from '@ct/core';
import { getCroppedThumbPublicUrl } from '@ct/shared/helpers';

import { DialogButton } from '../../../../../dialog';
import { FILE_UPLOAD_DEFAULT_CONFIG } from '../../../file-upload/constants';
import { ImageUpload } from '../../classes';
import { ImageUploadConfig } from '../../interfaces';

interface SelectableVideoUploadEntity extends VideoUploadEntity {
  selected?: boolean;
}

@Component({
  selector: 'ct-media-select-dialog',
  templateUrl: './media-select-dialog.component.html',
  styleUrls: ['./media-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSelectDialogComponent implements OnInit {
  static dialogConfig: MatDialogConfig = {
    minWidth: '360px',
    width: '80%'
  };
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  public tabIndex = 0;
  public loadingImages = false;
  public loadingVideos = false;
  public uploadedImages: ImageUpload[] = [];
  public videos: SelectableVideoUploadEntity[] = [];
  public config: ImageUploadConfig = FILE_UPLOAD_DEFAULT_CONFIG;

  public selectButton: DialogButton = {
    labelKey: 'COMPONENTS.FILE_UPLOAD.SELECT',
    clicked: () => this.dialogRef.close({ images: this.selectedImages, video: this.selectedVideo })
  };

  get selectedImages() {
    return this.uploadedImages.filter((i) => i.selected).map((i) => i.data);
  }

  get selectedVideo() {
    return this.videos.find((v) => v.selected);
  }

  get isOneImageSelected() {
    return this.uploadedImages.some((image) => image.selected);
  }

  get buttons() {
    return [this.selectButton];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: ImageUploadConfig },
    private dialogRef: MatDialogRef<MediaSelectDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.config = { ...this.config, ...this.data?.config };
  }

  ngOnInit() {
    if (this.config.getAllImagesFn) {
      this.loadingImages = true;
      this.config.getAllImagesFn().subscribe((images) => {
        this.uploadedImages = [
          ...this.uploadedImages,
          ...images.map((image: UploadedImage) => new ImageUpload({} as File, false, true, false, 100, false, image))
        ];
        this.loadingImages = false;
        this.changeDetectorRef.detectChanges();
      });
    }
    if (this.config.getAllVideosFn) {
      this.loadingVideos = true;
      this.config.getAllVideosFn().subscribe((videos) => {
        this.videos = [...this.videos, ...videos];
        this.loadingVideos = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  onSelectImage(image: ImageUpload) {
    if (!this.config?.selectable) {
      return;
    }
    if (!this.config?.multiple && this.isOneImageSelected) {
      this.deselectAll();
    }
    image.selected = !image.selected;
    this.changeDetectorRef.detectChanges();
  }

  onSelectVideo(video: SelectableVideoUploadEntity) {
    this.videos.forEach((v) => (v.selected = false));

    video.selected = !video.selected;
    this.changeDetectorRef.detectChanges();
  }

  deselectAll() {
    this.uploadedImages.forEach((image) => (image.selected = false));
  }
}
