<ct-autocomplete
  [label]="label || ('MY_ACCOUNT.MY_POSTS_FEATURE.TAGS' | translate)"
  [placeholder]="placeholder || ('MY_ACCOUNT.MY_POSTS_FEATURE.TAGS_PLACEHOLDER' | translate)"
  [optionsFn]="optionsFn"
  [maxlength]="maxlength"
  [formControl]="autocompleteControl"
  [focusAfterSubmit]="true"
  [hint]="false"
  [noMatchOption]="noMatchOption"
  [attr.compact]="true"
  (changed)="changed($event)"
></ct-autocomplete>
<div class="tags">
  <ct-tag
    class="mr-1 mb-1"
    *ngFor="let keyword of control.value"
    [removable]="true"
    [name]="keyword"
    (removed)="removed(keyword)"
  ></ct-tag>
</div>
