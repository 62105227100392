<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header
    ctHeader
    icon="book"
    title="Writings"
    description="Write private memories or publish stories to the community"
  ></ct-extended-header>

  <div ctContent class="row">
    <ng-container *ngIf="post$ | async as post">
      <ct-writing-card
        [story]="post"
        [showLastEdit]="false"
        [canShare]="false"
        [canOpen]="false"
        (share)="shareStory(post)"
        class="col-12"
      >
      </ct-writing-card>
      <div class="col-12 col-md-9 pt-4">
        <ct-blog-post
          [currentUser]="currentUser$ | async"
          [post]="post$ | async"
          [photoViewType]="photoViewType"
          [customSharingUrl]="true"
        >
        </ct-blog-post>
      </div>
    </ng-container>
    <div class="col-12 col-md-3 pt-2 pt-md-0">
      <ct-blog-sidebar></ct-blog-sidebar>
    </div>
  </div>
</ct-layout>
