import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, GroupWithStories, RequestRange, UserProfile } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

import { EntityType, SortOrder } from '../enums';

const endpoint = environment.shareApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class ShareApiService<T = unknown> extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  shareEntity(userId: string, entityId: string | number, entityType: EntityType) {
    const body = {
      userId,
      entityType,
      entityId: String(entityId)
    };
    return this.post('', body);
  }

  removeEntity(userId: string, entityId: string | number) {
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.delete(`entity/${entityId}`, params);
  }

  getAllEntities({
    authorId,
    userId,
    entityType,
    range,
    sortOrder
  }: {
    authorId?: string;
    userId?: string;
    entityType: EntityType;
    range?: RequestRange;
    sortOrder?: SortOrder;
  }): Observable<T[]> {
    let params = new HttpParams();
    params = params.append('entityType', entityType);
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (userId !== undefined && userId !== null) {
      params = params.append('userId', userId);
    }
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    return this.get('', params);
  }

  getAllFriendsWithAccess({
    entityType,
    entityId
  }: {
    entityId?: string;
    entityType: EntityType;
  }): Observable<UserProfile[]> {
    let params = new HttpParams();
    params = params.append('entityType', entityType);
    if (entityId !== undefined && entityId !== null) {
      params = params.append('entityId', entityId);
    }
    return this.get('friends-list', params);
  }

  getGroupsWithStories(): Observable<GroupWithStories[]> {
    return this.get('groups-list/detailed/stories');
  }
}
