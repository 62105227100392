export enum UserRole {
  Admin = 'admin',
  Moderator = 'moderator'
}

export interface UserRolesRelationsEntity {
  readonly id?: string;
  readonly userId?: string;
  readonly createdOn?: Date;
  readonly modifiedOn?: Date;
  readonly roles: UserRole[];
}
