import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AddListDialogComponent } from '@ct/app-content/my-account/modules/my-account-trips/components';
import { AuthQuery } from '@ct/auth';
import { DialogService, SpinnerService } from '@ct/components';
import { ListsApiService, TitleService, TripList, TripListItem } from '@ct/core';
import { ListItemsApiService } from '@ct/core/services/list-items-api.service';
import { EntityType, trackById, Trip } from '@ct/shared';
import { Subject } from 'rxjs';
import { delay, filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

const LIMIT = 5;
const DEFAULT_OFFSET = 0;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-my-account-trip-lists',
  templateUrl: './my-account-trip-lists.component.html',
  styleUrls: ['./my-account-trip-lists.component.scss']
})
export class MyAccountTripListsComponent implements OnInit, OnDestroy {
  readonly arrayControl: FormArray = new FormArray<any>([]);
  elements: TripList[] = [];

  public loading = false;
  public showLoadButton = false;
  public offset = 0;
  public trip: Trip;
  public queryParams = {
    offset: DEFAULT_OFFSET,
    filter: 'timeline'
  };

  get isAuthor(): boolean {
    return this.authQuery.getValue().profile?.userId === this.trip?.authorId;
  }

  get form(): FormGroup {
    return this.arrayControl as unknown as FormGroup;
  }

  public trackByFn = trackById;

  private destroyed$ = new Subject<void>();
  private editingListId: string | null;

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private listApiService: ListsApiService,
    private spinnerService: SpinnerService,
    private listItemsApiService: ListItemsApiService,
    private authQuery: AuthQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.route.parent?.data
      .pipe(
        map(({ timeline }) => timeline),
        takeUntil(this.destroyed$)
      )
      .subscribe((timelineData) => {
        this.elements = timelineData?.lists || [];
        this.trip = timelineData?.trip;

        if (this.elements.length === 0 || this.elements.length < LIMIT) {
          this.showLoadButton = false;
        }

        this.titleService.setTitle(this.trip?.title);
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  isEditing(list: TripList): boolean {
    return Boolean(this.editingListId) && String(list.id) === this.editingListId;
  }

  onStartEdit(event: Event, list: TripList): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.editingListId = String(list.id);
    this.arrayControl.clear();
    list.items?.forEach((item) => {
      const group = Object.keys(item).reduce((acc: Record<string, FormControl>, name) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[name] = new FormControl(item[name]);
        if (name === 'amount') {
          acc[name].setValidators(Validators.min(1));
        }
        if (name === 'name') {
          acc[name].setValidators(Validators.required);
        }
        return acc;
      }, {});
      this.arrayControl.push(new FormGroup(group));
    });
  }

  addNewItem() {
    this.arrayControl.push(
      new FormGroup({
        name: new FormControl('', Validators.required),
        amount: new FormControl(1, Validators.min(1)),
        checked: new FormControl(false)
      })
    );
  }

  saveEdits(): void {
    const listId = this.editingListId as string;
    const items = this.arrayControl.value as TripListItem[];
    const listIndex = this.elements.findIndex(({ id }) => id === this.editingListId);
    const upsertItems = items.map((item) => ({ ...item, listId }));

    if (upsertItems.length) {
      this.spinnerService.show({ instant: true });
      this.listItemsApiService
        .bulkUpdate(upsertItems)
        .pipe(
          takeUntil(this.destroyed$),
          switchMap(() => this.listApiService.getById(listId).pipe(delay(1000))),
          finalize(() => {
            this.spinnerService.hide();
            this.changeDetectorRef.markForCheck();
          })
        )
        .subscribe((updatedList) => {
          const elements = [...this.elements];
          elements[listIndex] = updatedList;
          this.elements = elements;
          this.editingListId = null;
          this.arrayControl.clear();
        });
    } else {
      this.editingListId = null;
      this.arrayControl.clear();
    }
  }

  createList() {
    this.dialogService
      .open(AddListDialogComponent)
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap((title) => {
          this.spinnerService.show({ instant: true });
          return this.listApiService.create({
            title: title as string,
            entityId: this.trip.id,
            entityType: EntityType.Trip
          });
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((list) => {
        this.elements = [...this.elements, list];
      });
  }

  cancelEdit(): void {
    this.editingListId = null;
    this.arrayControl.clear();
  }

  onDeleteItem(index: number): void {
    this.arrayControl.removeAt(index);
  }
}
