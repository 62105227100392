<ct-autocomplete
  [placeholder]="placeholder || ('SITE_SEARCH.HEADER_TITLE' | translate)"
  [optionsFn]="optionsFn"
  [maxlength]="maxlength"
  [focusAfterSubmit]="true"
  [hint]="false"
  [attr.compact]="true"
  [customOptionsTemplate]="optionRef"
  (changed)="changed($event)"
  [formControl]="autocompleteControl"
  [prefixIcon]="prefixIcon"
  (prefixIconClicked)="onSearchIconClick()"
>
  <ng-template #optionRef let-option="option">
    <ng-container [ngSwitch]="option.type">
      <span *ngSwitchCase="'user'">Stories by {{ option.username }}</span>
      <span *ngSwitchCase="'tag'">Stories for tag - {{ option.name }}</span>
      <span *ngSwitchCase="'story'">Story: {{ option.title }}</span>
      <span *ngSwitchCase="'trip'">Your trip: {{ option.title }}</span>
      ({{ option.score | number }})
    </ng-container>
  </ng-template>
</ct-autocomplete>
