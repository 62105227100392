import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const PREFIX = 'TIME_AGO_PIPE';
const JUST_NOW = 'JUST_NOW';
const MINUTE = 'MINUTE';
const HOUR = 'HOUR';
const DAY = 'DAY';
const WEEK = 'WEEK';
const MONTH = 'MONTH';
const YEAR = 'YEAR';

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const MIN_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;
const WEEKS_IN_MONTH = 4;
const MONTHS_IN_YEAR = 12;

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  constructor(readonly translator: TranslateService) {}

  public transform(value?: number | string | Date): string {
    if (!value) {
      return '';
    }
    const milliseconds: number = Date.now() - new Date(value).getTime();
    const seconds: number = Math.round(milliseconds / MS_IN_SECOND);
    const minutes: number = Math.round(seconds / SEC_IN_MINUTE);
    const hours: number = Math.round(minutes / MIN_IN_HOUR);
    const days: number = Math.round(hours / HOURS_IN_DAY);
    const weeks: number = Math.round(days / DAYS_IN_WEEK);
    const months: number = Math.round(weeks / WEEKS_IN_MONTH);
    const years: number = Math.round(months / MONTHS_IN_YEAR);
    let params: [string, number?];

    switch (true) {
      case years >= 1:
        params = this.getParams(YEAR, years);
        break;
      case months >= 1:
        params = this.getParams(MONTH, months);
        break;
      case weeks >= 1:
        params = this.getParams(WEEK, weeks);
        break;
      case days >= 1:
        params = this.getParams(DAY, days);
        break;
      case hours >= 1:
        params = this.getParams(HOUR, hours);
        break;
      case minutes >= 1:
        params = this.getParams(MINUTE, minutes);
        break;
      default:
        params = [JUST_NOW, seconds];
        break;
    }

    return this.getTranslation(...params);
  }

  private getParams(key: string, time: number): [string, number?] {
    return [time > 1 ? `${key}S` : key, time];
  }

  private getTranslation(key: string, time?: number): string {
    return this.translator.instant(`${PREFIX}.${key}`, {
      n: time?.toString()
    });
  }
}
