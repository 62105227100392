import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { FileUploadConfig } from '../../file-upload';
import { ImageUpload } from '../classes';

const UPLOAD_QUEUE_LIMIT = 3;

@Injectable({ providedIn: 'root' })
export class ImageUploadQueueService {
  public uploadedImages: ImageUpload[] = [];

  protected finishedUpload: boolean;

  private uploadImagesQueue: ImageUpload[] = [];
  protected loading = new BehaviorSubject<boolean>(false);

  get loading$(): Observable<boolean> {
    return this.loading.asObservable();
  }

  onStartUpload(imageFiles: File[], config?: FileUploadConfig) {
    this.uploadedImages = [...imageFiles.map((file: File) => new ImageUpload(file, true))];
    this.uploadedImages.forEach((image: ImageUpload) => this.addToQueue(image, config));
    this.uploadFromQueue(config);
  }

  protected addToQueue(image: ImageUpload, config?: FileUploadConfig) {
    if ((image.done && image.data) || !config?.uploadFn) {
      return;
    }
    this.uploadImagesQueue.push(image);
  }

  protected uploadFromQueue(config?: FileUploadConfig) {
    const queueLimit = config?.queueLimit || UPLOAD_QUEUE_LIMIT;
    this.uploadImagesQueue.splice(0, queueLimit).forEach((image) => this.uploadImage(image, config));
    this.uploadImagesQueue.forEach((image) => image.setProgress(1, 100));
  }

  protected uploadFirstFromQueue(config?: FileUploadConfig) {
    if (this.uploadImagesQueue.length) {
      this.uploadImage(this.uploadImagesQueue.shift() as ImageUpload, config);
    } else if (this.uploadedImages.every((image) => image.done)) {
      this.finishedUpload = true;
      this.loading.next(false);
    }
  }

  protected uploadImage(image: ImageUpload, config?: FileUploadConfig) {
    if ((image.done && image.data) || !config?.uploadFn) {
      this.uploadFirstFromQueue(config);
      return;
    }

    this.loading.next(true);

    return config
      .uploadFn(image?.file)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event?.type !== HttpEventType.UploadProgress) {
            return event;
          }
          image.setProgress(event.loaded, event.total as number);
        }),
        finalize(() => {
          this.uploadFirstFromQueue(config);
        })
      )
      .subscribe(
        (event: any) => {
          if (event?.type !== HttpEventType.Response || !event?.body?.data) {
            return;
          }
          image.setSuccess(event?.body?.data);
        },
        () => {
          image.setError();
        }
      );
  }
}
