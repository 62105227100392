import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlogPost, BlogPostStatus } from '@ct/core';

const ADMIN_STATUSES = new Set([BlogPostStatus.WaitingApproval, BlogPostStatus.Suspended]);

@Component({
  selector: 'ct-blog-post-card',
  templateUrl: './blog-post-card.component.html',
  styleUrls: ['./blog-post-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostCardComponent {
  @Input() showLink = false;
  @Input() showStatus = false;
  @Input() showAuthor = true;
  @Input() showTags = true;
  @Input() imgHeight: string;
  @Input() imgWidth: string;
  @Input() objectFit = 'cover';
  @Input() post: BlogPost | undefined;
  @Input() link: string;
  @Input() imgCols = 3;
  @Input() contentCols = 9;
  @Input() contentMargin: boolean;
  @Input() linkFullWidth: boolean;

  get status(): BlogPostStatus {
    const isInReview = !ADMIN_STATUSES.has(this.post?.status as BlogPostStatus) && (this.post?.flags as number) > 0;
    return isInReview ? BlogPostStatus.FlaggedForReview : (this.post?.status as BlogPostStatus);
  }
}
