<ct-dialog-content titleKey="MY_ACCOUNT.MY_TRIPS_FEATURE.SHARE.TITLE" [closeIcon]="true">
  <ct-filter-pane
    class="my-4"
    [filters]="filters"
    [activeFilter]="activeFilter"
    (filterChanged)="onFilterChanged($event)"
  ></ct-filter-pane>
  <ng-container *ngIf="activeFilter === friendsStatus.AvailableFriends">
    <ng-container *ngFor="let user of availableFriends">
      <div class="row">
        <div class="user col-12">
          <!--          TODO move to common component?-->
          <ct-user-avatar
            class="user__avatar"
            [src]="getCroppedThumbPublicUrl(user?.photo?.publicUrl)"
            width="32px"
            height="32px"
          ></ct-user-avatar>
          <div>
            <p class="user__name">{{ user.firstName }} {{ user.lastName }}</p>
            <p class="user__name user__name--light">{{ user.username }}</p>
          </div>
          <span class="user__action ml-auto" (click)="onShare(user)">{{
            'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARE.START_SHARING' | translate
          }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!availableFriends?.length">
      <p>{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARE.ADD_FRIENDS' | translate }}</p>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="activeFilter === friendsStatus.FriendsWithAccess">
    <ng-container *ngFor="let user of friendsWithAccess">
      <div class="row">
        <div class="user col-12">
          <!--          TODO move to common component?-->
          <ct-user-avatar
            class="user__avatar"
            [src]="getCroppedThumbPublicUrl(user?.photo?.publicUrl)"
            width="32px"
            height="32px"
          ></ct-user-avatar>
          <div>
            <p class="user__name">{{ user.firstName }} {{ user.lastName }}</p>
            <p class="user__name user__name--light">{{ user.username }}</p>
          </div>
          <span class="user__action ml-auto" (click)="onRemove(user)">{{
            'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARE.STOP_SHARING' | translate
          }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!friendsWithAccess?.length">
      <p>{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARE.NOT_SHARED' | translate }}</p>
    </ng-container>
  </ng-container>
</ct-dialog-content>
