import { GalleryAction } from '../enums';
import { GalleryState } from '../interfaces';

export const defaultState: GalleryState = {
  action: GalleryAction.INITIALIZED,
  isPlaying: false,
  hasNext: false,
  hasPrev: false,
  currIndex: 0,
  items: []
};
