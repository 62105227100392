import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Hammer from '@egjs/hammerjs';
import { fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/**
 * This directive uses tap event if HammerJS is loaded, otherwise it falls back to normal click event
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tapClick]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TapClick implements OnInit, OnDestroy {
  private hammer: any;
  clickListener = Subscription.EMPTY;
  @Input() tapClickDisabled: boolean | undefined;
  @Output() tapClick = new EventEmitter();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.activateClickEvent();
  }

  activateClickEvent() {
    if (typeof Hammer !== 'undefined') {
      // Use Hammer.js tap event
      this.hammer = new Hammer(this.el.nativeElement);
      this.hammer.on('tap', () => {
        if (!this.tapClickDisabled) {
          this.tapClick.emit(null);
        }
      });
    } else {
      // Use normal click event
      this.clickListener = fromEvent(this.el.nativeElement, 'click')
        .pipe(
          filter(() => !this.tapClickDisabled),
          tap(() => this.tapClick.emit(null))
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
    this.clickListener.unsubscribe();
  }
}
