<section class="user" *ngIf="user$ | async as user">
  <div
    class="d-flex flex-nowrap align-items-center cursor--pointer"
    [routerLink]="['/user-profile']"
    [queryParams]="{ userId: user.userId }"
  >
    <ct-user-avatar class="user__photo" [src]="user?.photo?.path"></ct-user-avatar>
    <span class="user__name text-truncate">{{ user?.username }}</span>
    <span class="user__join"> {{ 'MY_ACCOUNT.JOINED' | translate: { date: user?.createdOn | date } }}</span>
  </div>
  <div class="user__description">
    {{ user.description }}
  </div>
</section>
<div class="line"></div>
<router-outlet></router-outlet>
