import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CanonicalConfig,
  CanonicalFeature,
  CustomValidators,
  DestroyableFeature,
  EMAIL_MAX_LENGTH,
  Features,
  FormStateDispatcher,
  TitleConfig,
  TitleFeature
} from '@ct/core';
import { Observable } from 'rxjs';

import { AuthApiService } from '../../services';

@Component({
  selector: 'ct-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature(), CanonicalFeature()])
export class ForgotPasswordComponent {
  public readonly destroyed$: Observable<void>;

  public readonly titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.FORGOT_PASSWORD'
  };
  public readonly canonicalConfig: CanonicalConfig = {
    canonicalUrl: '/forgot-password'
  };

  public verification: boolean;

  public readonly form = new UntypedFormGroup({
    emailAddress: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(EMAIL_MAX_LENGTH)
    ]),
    captcha: new UntypedFormControl(null, [Validators.required])
  });

  get emailAddress() {
    return this.form.controls.emailAddress?.value;
  }

  constructor(
    private router: Router,
    private formState: FormStateDispatcher,
    private authApiService: AuthApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onResetPassword() {
    this.formState.onSubmit.notify();

    if (this.form.invalid || this.verification) {
      return;
    }

    const { captcha, emailAddress } = this.form.value;

    this.authApiService.forgotPassword(emailAddress, captcha).subscribe(
      () => {
        this.verification = true;
        this.form.controls.emailAddress.disable();
        this.form.reset({ emailAddress });
        this.form.removeControl('captcha');
        this.form.addControl(
          'verificationCode',
          new UntypedFormControl('', [Validators.required, CustomValidators.verificationCode])
        );
        this.form.addControl('passwords', new UntypedFormControl());
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        if (error?.status === 401) {
          this.form.controls.emailAddress.setErrors({ emailIsNotValid: true });
          this.form.get('captcha')?.patchValue(null);
        }
      }
    );
  }

  onVerify() {
    this.formState.onSubmit.notify();

    if (this.form.invalid || !this.verification) {
      return;
    }

    const { verificationCode, passwords } = this.form.value;

    this.authApiService
      .forgotPasswordVerification({
        emailAddress: this.emailAddress,
        verificationCode,
        newPassword: passwords.password
      })
      .subscribe(
        () => this.router.navigate(['/login']),
        (error) =>
          error?.status === 401 ? this.form.controls.verificationCode.setErrors({ codeHasExpiried: true }) : null
      );
  }
}
