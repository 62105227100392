import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ct-media-select',
  templateUrl: './media-select.component.html',
  styleUrls: ['./media-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSelectComponent {
  @ViewChild('fileUpload', { static: false }) fileUploadRef: ElementRef;

  @Input() multiple: boolean | undefined = true;
  @Input() disabled: boolean | undefined = false;
  @Input() accept: string | undefined = 'video/*, image/*';
  @Input() height: string | undefined = '150px';
  @Input() width: string | undefined = '100%';
  @Input() title: string | undefined;

  @Output() selectFiles = new EventEmitter<File[]>();

  onFilesSelected() {
    if (!this.fileUploadRef) {
      return;
    }
    const fileUploadElement = this.fileUploadRef.nativeElement;
    fileUploadElement.click();
    fileUploadElement.onchange = () => {
      this.selectFiles.emit([...fileUploadElement.files]);
      fileUploadElement.value = '';
    };
  }

  onFilesDropped(files: FileList) {
    this.selectFiles.emit([...files]);
  }
}
