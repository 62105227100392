import { Injectable } from '@angular/core';

import { FileUploadConfig } from '../interfaces';

@Injectable()
export class FileUploadService {
  showUploadDialog(config?: FileUploadConfig) {
    // TODO: implement file upload
  }
}
