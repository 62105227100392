import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ct-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent {
  @Input() src: string | undefined;
  @Input() poster: string | undefined;
  @Input() playOnClick: boolean | undefined = true;

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  @Output() public playClicked = new EventEmitter<MouseEvent>();

  public showPlayButton = true;

  click($event: MouseEvent) {
    if (this.playOnClick) {
      this.showPlayButton = !this.showPlayButton;
      this.videoplayer.nativeElement?.paused
        ? this.videoplayer.nativeElement.play()
        : this.videoplayer.nativeElement.pause();
    }
    this.playClicked.emit($event);
  }
}
