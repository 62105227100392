import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogService, ImageUploadService, SelectOption, SpinnerService } from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import { DestroyableFeature, Features, FormStateDispatcher, LocalStorageService, Tag, UploadedImage } from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { QuillModules } from 'ngx-quill';
import { Observable } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';

import { BaseAutosaveFormComponent } from '../../../../../../classes';
import { ImageUploadApiService } from '../../../../../../services/image-upload-api.service';
import { MyAccountPhotoApiService } from '../../../../../../services/my-account-photos-api.service';
import { TagApiService } from '../../../../../../services/tag-api.service';
import { Trip } from '../../../../interfaces';
import { TripCampsiteApiService, TripJournalEntryApiService } from '../../../../services';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

const TRIP_JOURNAL_ENTRY_AUTOSAVE_CONFIG = {
  autosaveKey: 'TRIP_JOURNAL_ENTRY_CREATE_AUTOSAVE',
  excludeFormFields: [],
  dialogConfig: {
    titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.TITLE',
    messageKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.MESSAGE',
    confirmKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.CONFIRM',
    cancelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.CANCEL'
  }
};

@Component({
  selector: 'ct-journal-add-dialog',
  templateUrl: './journal-add-dialog.component.html',
  styleUrls: ['./journal-add-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class JournalAddDialogComponent extends BaseAutosaveFormComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly modules: QuillModules = QUILL_MODULES_CONFIG;

  public readonly form: UntypedFormGroup;

  public isLoading = false;

  public campsites$: Observable<SelectOption[]> = this.tripCampsiteApiService
    .getAllByTripId(this.tripId as string)
    .pipe(
      map((campsites) => campsites.map((campsite) => ({ value: campsite.id, label: campsite.title } as SelectOption)))
    );

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onSave();
      }
    }
  ];

  get tripId(): string {
    return this.trip.id as string;
  }

  get photos() {
    return this.form.controls.photos?.value;
  }

  private campsiteId?: string;

  public readonly optionsFn = (match: string) => this.tagApiService.getAll(match);

  public readonly createFn = (name: string) => this.tagApiService.create(name);

  public readonly checkFn = (name: string) => this.tagApiService.getByName(name);

  public readonly labelFn = ({ name }: Tag) => name as string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly trip: Trip,
    protected localStorageService: LocalStorageService,
    protected translateService: TranslateService,
    protected dialogService: DialogService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<JournalAddDialogComponent>,
    private formState: FormStateDispatcher,
    private spinnerService: SpinnerService,
    private imageUploadApiService: ImageUploadApiService,
    private tripJournalEntryApiService: TripJournalEntryApiService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private tagApiService: TagApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(localStorageService, dialogService, translateService, TRIP_JOURNAL_ENTRY_AUTOSAVE_CONFIG);
    this.campsiteId = this.route.snapshot.queryParams.campsiteId;

    this.form = new UntypedFormGroup({
      tags: new UntypedFormControl([]),
      body: new UntypedFormControl('', [Validators.required]),
      campsiteId: new UntypedFormControl(this.campsiteId),
      photos: new UntypedFormControl([]),
      videos: new UntypedFormControl([])
    });
  }

  ngOnInit() {
    this.setForm(this.form);
    this.getAutosavedItem()
      .pipe(take(1))
      .subscribe((autosavedPost) => {
        if (!autosavedPost) {
          this.stopAutosave();
          this.startAutosave();
          return;
        }
        this.form.patchValue(autosavedPost);
        this.startAutosave();
      });
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    return this.tripJournalEntryApiService
      .create(this.tripId, this.form.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.stopAutosave();
        this.dialogRef.close({ reload: true });
      });
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTOS',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.imageUploadApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
        this.changeDetectorRef.detectChanges();
      });
  }

  onRemovePhoto({ id }: UploadedImage) {
    const photos = this.photos.filter((photo: UploadedImage) => photo.id !== id);
    this.form.controls.photos.patchValue(photos);
    this.changeDetectorRef.detectChanges();
  }

  onCancel() {
    this.stopAutosave();
    this.dialogRef.close({ reload: false });
  }

  onBack() {
    this.stopAutosave();
    this.dialogRef.close();
  }
}
