<ct-card-item
  [item]="itemWithPhoto"
  [showLink]="showLink"
  [creationDate]="item?.createdOn"
  [description]="item?.description"
  [showAuthor]="false"
  [showTags]="false"
  [imgHeight]="'180px'"
  [imgCols]="imgCols"
  [imgWidth]="imgWidth"
  [objectFit]="objectFit"
  [link]="link"
  [contentCols]="contentCols"
>
</ct-card-item>
<div *ngIf="item?.sold" class="sold-overlay">
  <p>SOLD</p>
</div>
