<!--TO_DO remove after add virtual-scroll-->
<ng-container #dataListContentWrapper>
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl" cdkScrollable class="ct-data-list">
    <cdk-tree-node
      *cdkTreeNodeDef="let listItem"
      cdkTreeNodePadding
      class="ct-data-list__row"
      [cdkTreeNodePaddingIndent]="leftTreePadding"
      (click)="selectNode(listItem)"
      [ngClass]="{ 'ct-data-list__row-selected': getAllSelectedDescendants(listItem) }"
    >
      <ct-data-list-row
        [listItem]="listItem"
        [column]="column"
        [isSelected]="getAllSelectedDescendants(listItem)"
      ></ct-data-list-row>
    </cdk-tree-node>
    <cdk-tree-node
      class="ct-data-list__row"
      *cdkTreeNodeDef="let listItem; when: hasChildren"
      cdkTreeNodePadding
      [cdkTreeNodePaddingIndent]="leftTreePadding"
      (click)="selectNode(listItem)"
      [ngClass]="{ 'ct-data-list__row-selected': getAllSelectedDescendants(listItem) }"
    >
      <ct-icon
        class="ct-data-list__row-icon-wrapper"
        cdkTreeNodeToggle
        aria-label="toggle tree node"
        [attr.aria-label]="'toggle ' + listItem.filename"
        [cdkTreeNodeToggleRecursive]="listItem.collapsed"
        [icon]="treeControl.isExpanded(listItem) ? 'down' : 'right'"
      >
      </ct-icon>
      <ct-data-list-row
        [listItem]="listItem"
        [column]="column"
        [isSelected]="getAllSelectedDescendants(listItem)"
      ></ct-data-list-row>
    </cdk-tree-node>
  </cdk-tree>
</ng-container>
