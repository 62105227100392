import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { IconSetComponent } from '../components/icon-set';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  static readonly FAMILY: string = 'ct-icons';
  private iconList: string[];
  private readonly svgSprite: string;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    const componentFactory: ComponentFactory<IconSetComponent> =
      this.componentFactoryResolver.resolveComponentFactory(IconSetComponent);
    const component: ComponentRef<IconSetComponent> = componentFactory.create(Injector.create({ providers: [] }));
    const element: HTMLElement = component.location.nativeElement as HTMLElement;
    const componentTemplate: string = element.innerHTML;

    const svgs = element.querySelectorAll('defs > svg');

    svgs.forEach(({ id, outerHTML }) => this.addSvgIconFromText(id, outerHTML));

    this.svgSprite = componentTemplate;
  }

  getAvailableIconList(): string[] {
    if (!this.iconList) {
      this.iconList = [];

      const shapeIdRegexp = /<svg[^>]*id="([\w-]+)"[^>]*>/g;
      let match: RegExpExecArray = shapeIdRegexp.exec(this.svgSprite) as RegExpExecArray;

      while (match !== null) {
        this.iconList.push(match[1]);
        match = shapeIdRegexp.exec(this.svgSprite) as RegExpExecArray;
      }
    }

    return this.iconList;
  }

  getSvgIcon(name: string): Observable<SVGElement> {
    return this.matIconRegistry.getNamedSvgIcon(name);
  }

  addSvgIcon(name: string, url: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
  }

  addSvgIconFromText(name: string, text: string): void {
    this.matIconRegistry.addSvgIconLiteral(name, this.domSanitizer.bypassSecurityTrustHtml(text));
  }
}
