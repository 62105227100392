import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Trip } from '@ct/shared';

@Component({
  selector: 'ct-my-account-overview-trips',
  templateUrl: './my-account-overview-trips.component.html',
  styleUrls: ['./my-account-overview-trips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountOverviewTripsComponent {
  @Input() trips: Trip[] | undefined;

  getLink(trip: Trip) {
    return `../trips/${trip.id}/${trip.slug}`;
  }
}
