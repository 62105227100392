import { Pipe, PipeTransform } from '@angular/core';

import { getYoutubeId } from '../../../helpers';
import { YoutubeVideo } from '../interfaces';

function findId(value: string | YoutubeVideo | undefined) {
  if (typeof value === 'object' && value?.youtubeId) {
    return getYoutubeId(value.youtubeId);
  }
  if (typeof value === 'string') {
    return getYoutubeId(value);
  }
  return '';
}

@Pipe({
  name: 'youtubeId'
})
export class YoutubeIdPipe implements PipeTransform {
  transform(value: string | undefined | YoutubeVideo): string {
    if (!value) {
      return '';
    }
    return findId(value);
  }
}

@Pipe({
  name: 'youtubeIdArray'
})
export class YoutubeIdArrayPipe implements PipeTransform {
  transform(value: string[] | YoutubeVideo[]): string[] {
    if (Array.isArray(value)) {
      return value.map(findId);
    }
    return [];
  }
}
