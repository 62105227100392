import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageItem, ImageSize } from '@ct/components/gallery';
import { UploadedImage } from '@ct/core';
import { SortableOptions } from 'sortablejs';

import { getBiggerPublicUrl, getCroppedThumbPublicUrl, trackById } from './../../../..//helpers';

@Component({
  selector: 'ct-photo-grid',
  templateUrl: './photo-grid.component.html',
  styleUrls: ['./photo-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoGridComponent implements OnInit {
  @Input() col = 3;
  @Input() imageHeight = '150px';
  @Input() imageWidth = '100%';
  @Input() limit: number | undefined;
  @Input() set photos(photos: UploadedImage[] | null | undefined) {
    this.#photos = photos;
  }

  get photos(): UploadedImage[] | null | undefined {
    return this.#photos;
  }
  @Input() showPlaceholder: boolean | undefined = false;
  @Input() removable: boolean | undefined = false;
  @Input() editable: boolean | undefined = false;
  @Input() emptyMessage: boolean | undefined = true;
  @Input() fullScreen: boolean | undefined = true;
  @Input() draggable: boolean | undefined = false;
  @Input() link: string;
  @Input() showAccess: boolean | undefined = false;
  @Input() multiselect: boolean | undefined = false;
  @Input() imageDescriptionPrefix?: string;

  @Output() placeholderSelected = new EventEmitter<MouseEvent>();
  @Output() removed = new EventEmitter<UploadedImage>();
  @Output() edit = new EventEmitter<UploadedImage>();
  @Output() dragged = new EventEmitter<{ $event?: DragEvent; photos: UploadedImage[] }>();
  @Output() accessChanged = new EventEmitter<UploadedImage>();
  @Output() selectionChanged = new EventEmitter<UploadedImage>();

  public galleryConfig = { imageSize: ImageSize.Contain };
  public trackByFn = trackById;

  sortOptions: SortableOptions;

  #photos: UploadedImage[] | null | undefined;

  constructor() {
    this.sortOptions = {
      onUpdate: (event) => {
        if (this.photos && event.newIndex !== undefined && event.oldIndex !== undefined) {
          const element = this.photos[event.oldIndex];
          this.photos.splice(event.oldIndex, 1);
          this.photos.splice(event.newIndex, 0, element);
          this.dragged.emit({ photos: this.photos as UploadedImage[] });
        }
      }
    };
  }

  ngOnInit() {
    this.sortOptions.disabled = !this.draggable;
  }

  get _photos() {
    return this.photos?.slice(0, this.limit);
  }

  get galleryItems() {
    return this._photos?.map(
      (photo) =>
        new ImageItem({
          original: photo.path,
          src: getBiggerPublicUrl(photo.publicUrl),
          thumb: getCroppedThumbPublicUrl(photo.publicUrl),
          data: photo
        })
    );
  }

  onEdit({ data }: any) {
    if (!this.editable) {
      return;
    }
    const photo = data?.data;
    this.edit.emit(photo);
  }

  onSelectPlaceholder($event: MouseEvent) {
    this.placeholderSelected.emit($event);
  }

  getLink(id: string | undefined) {
    return this.link + `/${id}`;
  }
}
