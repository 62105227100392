import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { GalleryRef, ImageItem } from '../../../gallery';
import { LightboxAction } from '../../enums';

@Component({
  selector: 'ct-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightboxComponent {
  public id: string; // Gallery ref id
  public overlayRef: OverlayRef; // Overlay ref to close the lightbox
  public galleryRef: GalleryRef; // Gallery ref
  public state: 'void' | 'enter' | 'exit' = 'enter'; // State of the lightbox animation
  public editable: boolean; // is item editable
  public role: string; // The ARIA role of the lightbox element.
  public ariaLabel: string; // Aria label to assign to the lightbox element
  public ariaLabelledBy: string; // ID of the element that should be considered as the lightbox's label.
  public ariaDescribedBy: string; // ID of the element that describes the lightbox
  public startAnimationTime: number | undefined = 1000; // The lightbox start animation time in ms
  public exitAnimationTime: number | undefined = 3000; // The lightbox exit animation time in ms

  private action$ = new Subject<{ action: LightboxAction; data?: any }>();

  public action = this.action$.asObservable();

  @HostBinding('[attr.id]') get attrId() {
    return 'lightbox-' + this.id;
  }

  @HostBinding('[attr.role]') get attrRole() {
    return this.role;
  }

  @HostBinding('[attr.aria-modal]') get attrAriaModal() {
    return true;
  }

  @HostBinding('[attr.tabindex]') get attrTabindex() {
    return -1;
  }

  @HostBinding('[attr.aria-labelledby]') get attrAriaLabelledBy() {
    return this.ariaLabel ? null : this.ariaLabelledBy;
  }

  @HostBinding('[attr.aria-label]') get attrAriaLabel() {
    return this.ariaLabel;
  }

  @HostBinding('[attr.aria-describedby]') get attrAriaDescribedBy() {
    return this.ariaDescribedBy || null;
  }

  onEdit() {
    this.galleryRef.state.pipe(take(1)).subscribe((state) => {
      const imageItems: ImageItem[] = state?.items as ImageItem[];
      const currentImageItem = imageItems[state.currIndex as number];
      this.overlayRef.detach();
      this.action$.next({ action: LightboxAction.Edit, data: currentImageItem });
    });
  }

  onClose() {
    this.overlayRef.detach();
    this.action$.next({ action: LightboxAction.Close });
  }
}
