import { LightboxConfig } from '../interfaces';

export const defaultConfig: LightboxConfig = {
  backdropClass: 'g-backdrop',
  panelClass: 'g-overlay',
  hasBackdrop: true,
  keyboardShortcuts: true,
  role: 'lightbox',
  startAnimationTime: 150,
  exitAnimationTime: 75,
  editable: false
};
