import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TripCampsite, TripCampsiteApiService } from '@ct/shared';

@Injectable({ providedIn: 'root' })
export class MyAccountTripCampsitesDataResolverService implements Resolve<TripCampsite[]> {
  constructor(private tripCampsiteApiService: TripCampsiteApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const tripId = activatedRouteSnapshot.params?.id as string;
    return this.tripCampsiteApiService.getAllByTripId(tripId);
  }
}
