import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DialogService } from '@ct/components/dialog/services';
import { ChannelVideoUploadApiService, TranscodingState, UploadedImage, VideoUploadEntity } from '@ct/core';
import { forkJoin, Observable, of } from 'rxjs';

import { ImageUploadApiService } from '../../../../services';
import { VideoPlayerDialogComponent } from '../../../video-player-dialog';
import { TripJournalEntry } from '../../interfaces';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-journal-entry',
  templateUrl: './journal-entry.component.html',
  styleUrls: ['./journal-entry.component.scss']
})
export class JournalEntryComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input()
  journalEntry: TripJournalEntry;

  @Input()
  showMedia = true;

  @Input()
  canEdit: boolean;

  @Output()
  edit: EventEmitter<TripJournalEntry> = new EventEmitter<TripJournalEntry>();

  photos: UploadedImage[] = [];
  videos: VideoUploadEntity[] = [];

  private get photos$(): Observable<UploadedImage[]> {
    return (
      this.journalEntry?.photoIds?.length
        ? forkJoin(this.journalEntry?.photoIds?.map((photoId: string) => this.imageUploadApiService.getById(photoId)))
        : of([])
    ) as Observable<UploadedImage[]>;
  }

  private get videos$(): Observable<VideoUploadEntity[]> {
    return (
      this.journalEntry?.videoIds?.length
        ? forkJoin(this.journalEntry?.videoIds?.map((videoId: string) => this.videoUploadApiService.getById(videoId)))
        : of([])
    ) as Observable<VideoUploadEntity[]>;
  }

  private interval: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private imageUploadApiService: ImageUploadApiService,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.startVideosStatusListener();
  }

  ngOnDestroy(): void {
    this.stopVideosStatusListener();
  }

  ngAfterViewInit() {
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.journalEntry || changes.showMedia) && this.showMedia) {
      this.photos$.subscribe((photos) => {
        this.photos = photos;
        this.cdr.markForCheck();
      });
      this.videos$.subscribe((videos) => {
        this.videos = videos;
        this.cdr.markForCheck();
      });
    }
  }

  onEdit() {
    this.edit.emit(this.journalEntry);
  }

  onPlayVideo(video: VideoUploadEntity): void {
    this.dialogService.open(VideoPlayerDialogComponent, { data: video });
  }

  private startVideosStatusListener() {
    this.interval = setInterval(() => {
      const processingVideos = this.videos.filter(
        ({ transcodingJobStatus }) => transcodingJobStatus !== TranscodingState.SUCCEEDED
      );

      if (processingVideos.length > 0) {
        this.videoUploadApiService
          .getVideosStatus(processingVideos.map(({ id }) => id as string))
          .subscribe((videos) => {
            const videosMap: Map<string, TranscodingState> = new Map(
              videos.map((video) => [video.id as string, video.transcodingJobStatus])
            );
            this.videos = this.videos.map((video) => {
              if (videosMap.has(video.id as string)) {
                return {
                  ...video,
                  transcodingJobStatus: videosMap.get(video.id as string) as TranscodingState
                };
              }

              return video;
            });

            this.cdr.markForCheck();
          });
      }
    }, 30000) as unknown as number;
  }

  private stopVideosStatusListener() {
    clearInterval(this.interval);
  }
}
