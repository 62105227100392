import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { JournalEntryApiService, TripJournalEntry } from '@ct/shared';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../constants';

@Injectable({ providedIn: 'root' })
export class MyAccountTripJournalEntriesDataResolverService implements Resolve<TripJournalEntry[]> {
  constructor(private journalEntryApiService: JournalEntryApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    const campsiteId = activatedRouteSnapshot.queryParams?.campsiteId as string;
    const tripId = activatedRouteSnapshot.params?.id as string;
    return this.journalEntryApiService.getAll({
      tripId,
      range: { limit: MY_ACCOUNT_POSTS_FEATURE_LIMIT, offset },
      campsiteId
    });
  }
}
