import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { IconModule } from '@ct/components/icon';
import { VideoItemModule } from '@ct/shared/modules/video-item';
import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from '../../../dialog';
import { ImageModule } from '../../../image';
import { ProgressSpinnerModule } from '../../../progress-spinner';
import { FileUploadSharedModule } from '../file-upload-shared';
import { ImageUploadDialogComponent, ImageUploadProgressComponent, MediaSelectDialogComponent } from './components';
import { ImageUploadService } from './services';

@NgModule({
  declarations: [ImageUploadDialogComponent, ImageUploadProgressComponent, MediaSelectDialogComponent],
  providers: [ImageUploadService],
  imports: [
    CommonModule,
    TranslateModule,
    DialogModule,
    MatTabsModule,
    ImageModule,
    ProgressSpinnerModule,
    FileUploadSharedModule,
    IconModule,
    VideoItemModule
  ],
  exports: [MediaSelectDialogComponent]
})
export class ImageUploadModule {}
