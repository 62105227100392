import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageItem, ImageSize } from '@ct/components/gallery';
import { UploadedImage } from '@ct/core';

import { getBiggerPublicUrl, getCroppedThumbPublicUrl, trackById } from './../../../..//helpers';

@Component({
  selector: 'ct-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoGalleryComponent {
  @Input() set photos(photos: UploadedImage[] | null | undefined) {
    this.#photos = photos;
  }

  get photos(): UploadedImage[] {
    return this.#photos ?? [];
  }
  @Input() imageDescriptionPrefix?: string;

  public galleryConfig = { imageSize: ImageSize.Contain };
  public trackByFn = trackById;

  #photos: UploadedImage[] | null | undefined = [];

  get galleryItems() {
    return this.photos?.map(
      (photo) =>
        new ImageItem({
          original: photo.path,
          src: getBiggerPublicUrl(photo.publicUrl),
          thumb: getCroppedThumbPublicUrl(photo.publicUrl),
          data: photo
        })
    );
  }
}
