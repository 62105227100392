import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BlogPostWithFeaturedImage } from '@ct/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { toSlug } from '../../../helpers';
import { ImageUploadApiService } from '../../../services';
import { BlogPostApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class BlogPostDataResolver implements Resolve<BlogPostWithFeaturedImage | undefined> {
  constructor(
    private blogPostApiService: BlogPostApiService,
    private imageUploadApiService: ImageUploadApiService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO: move number exstraction from ID to API services to reduce copypasting
    const numberPattern = /\d+/g;
    let id = route.params?.id?.match(numberPattern)[0];
    const slug = route.params?.slug;

    if (slug === 'undefined') {
      // fix for some strange navigation in logs to `stories/:slug/undefined` causing errors
      const slugArray = id.split('-');
      id = slugArray[slugArray.length - 1];
    }

    // TODO remove once compatibility with /:id/:slug is not needed anymore
    let shouldRedirect = true;
    if (id && slug.includes('index.html')) {
      // hotfix for navigation from Facebook to prerendered file on Google Storage
      shouldRedirect = false;
      const slugArray = id.split('-');
      id = slugArray[slugArray.length - 1]?.match(numberPattern)[0];
    }

    if (slug && !id) {
      shouldRedirect = false;
      const slugArray = slug.split('-');
      id = slugArray[slugArray.length - 1]?.match(numberPattern)[0];
    }
    return this.blogPostApiService.getById(id).pipe(
      switchMap((post) => {
        if (shouldRedirect) {
          const parentUrl = state.url.slice(0, state.url.indexOf(route.url[route.url.length - 2].path));
          this.router.navigate([parentUrl, `${toSlug(post?.title as string)}-${id}`]);
          return of(post);
        }
        if (post?.featuredPhotoId) {
          return this.imageUploadApiService.getMetadata(post.featuredPhotoId).pipe(
            map((metadata) => ({
              ...post,
              featuredPhotoTitle: metadata?.photoTitle,
              featuredPhotoKeywords: metadata?.keywords
            })),
            catchError((err) => {
              console.error(err);
              return of(post);
            })
          );
        }
        return of(post);
      })
    );
  }
}
