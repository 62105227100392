<div class="toggle-container">
  <mat-slide-toggle
    [formControl]="control"
    [color]="color"
    (focus)="onFocus()"
    [disableRipple]="true"
    [checked]="value"
    [disabled]="disabled"
    (change)="onValueChange($event)"
    (focusout)="onFocusOut($event)"
  >
    <ng-content></ng-content>
  </mat-slide-toggle>
  <mat-error class="error" *ngIf="control.hasError('required')">
    {{ 'COMMON.VALIDATIONS.CHECKBOX' | translate }}</mat-error
  >
</div>
