import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class BaseHttpService {
  constructor(protected httpClient: HttpClient, protected URL: string) {}

  get<TResponse>(method: string, params?: any, options?: any) {
    return this.httpClient
      .get<TResponse>(this.URL + `/${method}`, {
        reportProgress: false,
        ...options,
        params
      })
      .pipe(
        map((response: any) => response?.data),
        catchError((err) => throwError(err))
      );
  }

  post<TRequest, TResponse = TRequest>(method: string, body: TRequest, params?: any, options?: any) {
    return this.httpClient
      .post<TResponse>(this.URL + `/${method}`, body, {
        ...options,
        params
      })
      .pipe(
        map((response: any) => response?.data),
        catchError((err) => throwError(err))
      );
  }

  put<TRequest, TResponse = TRequest>(method: string, body: TRequest, params?: any, options?: any) {
    return this.httpClient
      .put<TResponse>(this.URL + `/${method}`, body, {
        ...options,
        params
      })
      .pipe(
        map((response: any) => response?.data),
        catchError((err) => throwError(err))
      );
  }

  patch<TRequest, TResponse = TRequest>(method: string, body: TRequest, params?: any, options?: any) {
    return this.httpClient
      .patch<TResponse>(this.URL + `/${method}`, body, {
        ...options,
        params
      })
      .pipe(
        map((response: any) => response?.data),
        catchError((err) => throwError(err))
      );
  }

  delete<TRequest, TResponse = TRequest>(method: string, params?: any, options?: any) {
    return this.httpClient
      .delete<TResponse>(this.URL + `/${method}`, {
        reportProgress: false,
        ...options,
        params
      })
      .pipe(
        map((response: any) => response?.data),
        catchError((err) => throwError(err))
      );
  }
}
