import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService implements Storage {
  private inMemoryStorage: { [key: string]: string } = {};

  constructor(@Inject(PLATFORM_ID) private platformId: any, private errorHandler: ErrorHandler) {}

  get length() {
    if (isPlatformBrowser(this.platformId) && isSupported(() => localStorage)) {
      return localStorage.length;
    }
    return Object.keys(this.inMemoryStorage).length;
  }

  getItem(key: string): string | null {
    if (isPlatformBrowser(this.platformId) && isSupported(() => localStorage)) {
      return localStorage.getItem(key);
    }
    if (key in this.inMemoryStorage) {
      return this.inMemoryStorage[key];
    }
    return null;
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId) && isSupported(() => localStorage)) {
      localStorage.removeItem(key);
    } else {
      delete this.inMemoryStorage[key];
    }
  }

  setItem(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId) && isSupported(() => localStorage)) {
      try {
        localStorage.setItem(key, value);
      } catch (e) {
        this.errorHandler.handleError(e);
      }
    } else {
      this.inMemoryStorage[key] = String(value);
    }
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId) && isSupported(() => localStorage)) {
      localStorage.clear();
    } else {
      this.inMemoryStorage = {};
    }
  }

  key(index: number): string | null {
    if (isPlatformBrowser(this.platformId) && isSupported(() => localStorage)) {
      return localStorage.key(index);
    } else {
      return Object.keys(this.inMemoryStorage)[index] || null;
    }
  }
}

function isSupported(getStorage: () => Storage): boolean {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    getStorage().setItem(key, key);
    getStorage().removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}
