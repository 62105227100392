import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ct/components/button';
import { DateModule } from '@ct/components/date';
import { DialogModule } from '@ct/components/dialog';
import { InputModule } from '@ct/components/input';
import { SelectModule } from '@ct/components/select';
import { TranslateModule } from '@ngx-translate/core';

import { MediaSharedModule } from '../../media-shared';
import { CreateGroupDialogComponent } from './create-group-dialog.component';

@NgModule({
  declarations: [CreateGroupDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    DateModule,
    InputModule,
    SelectModule,
    MediaSharedModule
  ],
  exports: [CreateGroupDialogComponent]
})
export class CreateGroupDialogModule {}
