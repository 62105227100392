import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  IconModule,
  ImageBannerModule,
  InputModule,
  ReCaptchaModule,
  TextareaModule
} from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { TranslateModule } from '@ngx-translate/core';

import { ContactUsComponent, ContactUsGratitudeComponent } from './components';
import { ContactUsRoutingModule } from './contact-us-routing.module';

@NgModule({
  declarations: [ContactUsComponent, ContactUsGratitudeComponent],
  imports: [
    CommonModule,
    ContactUsRoutingModule,
    ImageBannerModule,
    TranslateModule,
    ReactiveFormsModule,
    TextareaModule,
    InputModule,
    ButtonModule,
    IconModule,
    LayoutModule,
    ReCaptchaModule.forFeature()
  ]
})
export class ContactUsModule {}
