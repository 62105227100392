import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { BlogPost } from '@ct/core';
import { EMPTY } from 'rxjs';

import { BLOG_POSTS_FEATURE_LIMIT } from '../constants';
import { BlogPostApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class BlogPostsSearchResolver implements Resolve<BlogPost[]> {
  constructor(private blogPostApiService: BlogPostApiService, private router: Router) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const search = activatedRouteSnapshot.queryParams?.search;
    const offset = (activatedRouteSnapshot.queryParams?.offset as number) || 0;
    if (!search) {
      this.router.navigate(['/stories']);
      return EMPTY;
    }
    return this.blogPostApiService.search(search, { limit: BLOG_POSTS_FEATURE_LIMIT, offset });
  }
}
