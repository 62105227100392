import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Mode } from '@ct/shared';

import {
  CreateMarketplaceItemComponent,
  CreateMerchantProfileComponent,
  MarketplaceCommunityComponent,
  MarketplaceComponent,
  MarketplaceHomeComponent,
  MarketplaceItemExtendedComponent,
  MarketplaceItemsAllComponent,
  MarketplaceItemsByAuthorComponent,
  MarketplaceItemsComponent,
  MarketplaceItemsWrapperComponent,
  MarketplaceSellingComponent
} from './components';
import { CanCreateMerchantProfileGuard, CanEditGuard, CanSellGuard, CanViewSellingGuard } from './guards';
import {
  CategoryDataResolver,
  MarketplaceItemDataResolver,
  MarketplaceItemsDataResolver,
  MarketplaceMyItemsDataResolver
} from './resolvers';

const routes: Routes = [
  {
    path: '',
    component: MarketplaceHomeComponent
  },
  {
    path: 'create-merchant',
    component: CreateMerchantProfileComponent,
    canActivate: [CanCreateMerchantProfileGuard]
  },
  {
    path: 'selling',
    component: MarketplaceItemsWrapperComponent,
    resolve: {
      categories: CategoryDataResolver,
      items: MarketplaceMyItemsDataResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [CanViewSellingGuard],
    children: [
      {
        path: '',
        component: MarketplaceSellingComponent
      }
    ]
  },
  {
    path: 'community',
    component: MarketplaceItemsWrapperComponent,
    resolve: {
      categories: CategoryDataResolver,
      items: MarketplaceItemsDataResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: [
      {
        path: '',
        component: MarketplaceCommunityComponent
      }
    ]
  },
  {
    path: '',
    component: MarketplaceComponent,
    resolve: {
      categories: CategoryDataResolver
    },
    children: [
      {
        path: 'sell',
        component: CreateMarketplaceItemComponent,
        data: { mode: Mode.Create },
        canActivate: [CanSellGuard]
      },
      {
        path: 'my-profile',
        component: CreateMerchantProfileComponent,
        canActivate: [CanSellGuard]
      },
      {
        path: '',
        component: MarketplaceItemsAllComponent,
        children: [
          {
            path: '',
            component: MarketplaceItemsComponent,
            resolve: {
              items: MarketplaceItemsDataResolver
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange'
          },
          {
            path: 'by-merchant',
            component: MarketplaceItemsByAuthorComponent,
            children: [
              {
                path: '',
                component: MarketplaceItemsComponent,
                resolve: {
                  items: MarketplaceItemsDataResolver
                },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
              }
            ]
          },
          {
            path: ':slugWithId',
            component: MarketplaceItemExtendedComponent,
            data: {
              baseRoute: '/marketplace/'
            },
            resolve: {
              item: MarketplaceItemDataResolver
            }
          },
          {
            path: ':slugWithId/edit',
            component: CreateMarketplaceItemComponent,
            data: { mode: Mode.Edit },
            resolve: {
              item: MarketplaceItemDataResolver
            },
            canActivate: [CanEditGuard]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketplaceRoutingModule {}
