<input type="file" accept="video/mp4" (change)="handleFileInput($event)" />
<br />
<p *ngIf="loading">Processing video....</p>
<br />
<div class="grid">
  <div>
    <p>Start frame:</p>
    <canvas #canvasPreviewStart></canvas>
  </div>

  <div>
    <p>End frame:</p>
    <canvas #canvasPreviewEnd></canvas>
  </div>

  <div>
    <button (click)="onPreview()">Preview</button>
    <button (click)="onStopPreview()">Stop</button>
    <canvas #canvasFinalPreview style="display: none"></canvas>
    <video #videoFinalPreview style="display: block"></video>
  </div>
</div>
<br />

<p *ngIf="!loading && videoEnd">Total video length: {{ videoEnd }}</p>
<p *ngIf="isLongerThanMinute">Warning: Video is longer than minute and should be trimmed</p>
<mat-slider *ngIf="!loading && videoEnd" [min]="videoStart" [max]="videoEnd" discrete>
  <input [disabled]="loading" [value]="trimStart" (valueChange)="onTrimStart($event)" matSliderStartThumb />
  <input [disabled]="loading" [value]="trimEnd" (valueChange)="onTrimEnd($event)" matSliderEndThumb />
</mat-slider>
<p *ngIf="trimEnd - trimStart > 60">Warning: video is too long and should be trimmed at 60 sec max</p>

<button (click)="onStartCropping()">Crop Video</button>
<button (click)="onRotate()">Rotate Video</button>

<div class="crop-canvas-container">
  <canvas id="c1" #canvasCrop></canvas>
  <canvas
    id="c2"
    [height]="canvasCrop.height"
    [width]="canvasCrop.width"
    #canvasCropOverlay
    (mousedown)="selectCropArea($event)"
    (mousemove)="onMoveCropSelection($event)"
    (mouseup)="selectCropAreaDone()"
  ></canvas>
</div>
<br />
