import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TripCampsite } from '../../interfaces';

@Component({
  selector: 'ct-campsite-entry',
  templateUrl: './campsite-entry.component.html',
  styleUrls: ['./campsite-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampsiteEntryComponent {
  @Input() entry: TripCampsite;
  @Input() link: string;
}
