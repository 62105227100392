<ct-dialog-content titleKey="MY_ACCOUNT.ADD_MEDIA" [closeIcon]="true" headerIcon="add-circle">
  <section class="options-grid">
    <article *ngFor="let option of addOptions" class="option" (click)="onOptionSelect(option)">
      <ct-icon [icon]="option.icon" size="fit"></ct-icon>
      <p class="mb-0">{{ option.titleKey | translate }}</p>
    </article>
  </section>
  <ct-media-select
    [style.display]="'none'"
    (selectFiles)="onAddVideo($event)"
    accept="video/*"
    [multiple]="false"
  ></ct-media-select>
</ct-dialog-content>
