<div
  class="row wrap"
  *ngIf="campsites.length > 0; else noTimeline"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <ng-container *ngFor="let element of campsites; let i = index">
    <div class="element">
      <div class="line"></div>
      <div class="left d-md-flex">
        <div class="date">
          <span class="day">{{ i + 1 }}</span>
        </div>
      </div>
      <div class="right">
        <ng-container *ngTemplateOutlet="campsite; context: { element: this.element }"></ng-container>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</div>

<ng-template #noTimeline>
  <ct-empty-call-to-action
    *ngIf="isAuthor"
    buttonIcon="add-circle"
    emptyMessageTextKey="MY_ACCOUNT.EMPTY_ENTITY.NO_CONTENT"
    buttonTextKey="MY_ACCOUNT.EMPTY_ENTITY.ADD_NOW"
    (buttonClick)="addDialog()"
  ></ct-empty-call-to-action>
</ng-template>

<ng-template #campsite let-element="element">
  <ng-container *ngTemplateOutlet="elementHeader; context: { element: this.element }"></ng-container>
  <div class="row body">
    <div class="col-12 col-md-3">
      <ct-progressive-image
        [noImagePlaceholder]="true"
        noImagePlaceholderPath="assets/images/map.png"
        height="220px"
        [publicUrl]="element?.featuredPhoto?.publicUrl"
        [thumbnail]="element?.featuredPhoto?.publicUrl"
        [altText]="element.title ? 'photo of ' + element.title : ''"
      >
      </ct-progressive-image>
    </div>
    <div class="col-12 col-md-9 body-content">
      <div class="info">
        <div class="title pt-2">{{ element?.title }}</div>
        <div class="address pt-2">{{ element?.formatted_address }}</div>
        <a class="phone pt-2" *ngIf="element?.phone" [href]="'tel:' + element.phone">{{ element?.phone }}</a>
      </div>
      <ng-container *ngIf="element.checkInTime; else checkinButton">
        <p class="check-in mb-4">
          Checked In at {{ element.checkInTime | date : 'HH:mm' }} on {{ element.checkInTime | date : 'mediumDate' }}
        </p>
      </ng-container>
      <ng-template #checkinButton>
        <ct-button class="mb-4" variant="flat" (click)="onCheckIn(element)" [disabled]="checkInLoading">
          <ct-icon class="pr-2" icon="cabin"></ct-icon>
          <div class="d-flex align-items-center">
            {{ 'Check In' | translate }}
          </div>
        </ct-button>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #elementHeader let-element="element" let-startDate="element?.startDate" let-endDate="element?.endDate">
  <div class="header">
    <div class="title">
      <ct-icon class="title-icon" icon="cabin"></ct-icon>
      <span>{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.WAYPOINT' | translate }}</span>
    </div>
    <span class="header-details">{{ startDate | date : 'longDate' }} - {{ endDate | date : 'longDate' }}</span>
    <div *ngIf="isAuthor" class="edit-icon">
      <ct-icon icon="edit" (click)="editCampsite(element)"></ct-icon>
    </div>
  </div>
</ng-template>
