import { Injectable } from '@angular/core';
import { NoopMeterProvider } from '@opentelemetry/api/build/src/metrics/NoopMeterProvider';
import { Meter, MeterProvider } from '@opentelemetry/api-metrics';

import { DEFAULT_METER } from '../../common';
import { IMetricService } from '../base';

@Injectable()
export class NoopMetricService implements IMetricService {
  private readonly meterProvider: MeterProvider;

  constructor() {
    this.meterProvider = new NoopMeterProvider();
  }

  getMeter(name: string = DEFAULT_METER): Meter {
    return this.meterProvider.getMeter(name);
  }

  getMeterProvider(): MeterProvider {
    return this.meterProvider;
  }
}
