import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService, SpinnerService, TabHeader } from '@ct/components';
import { Features, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { CreateTripDialogComponent, Mode, Trip, TripSharingStatus } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { MY_ACCOUNT_TRIPS_FEATURE_LIMIT } from '../../../../constants';
import { MyAccountTripApiService } from '../../services';
import { ShareTripDialogComponent } from '../share-trip-dialog';

interface TripQueryParams {
  offset: number;
  filter: TripSharingStatus;
}

const DEFAULT_OFFSET = 0;
const DEFAULT_FILTER = TripSharingStatus.MyTrips;

@Component({
  selector: 'ct-my-account-trips',
  templateUrl: './my-account-trips.component.html',
  styleUrls: ['./my-account-trips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountTripsComponent implements OnInit {
  public readonly limit = MY_ACCOUNT_TRIPS_FEATURE_LIMIT;

  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_TRIPS'
  };

  public queryParams: TripQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER
  };

  public tabs: TabHeader[] = [
    { name: TripSharingStatus.MyTrips, labelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.MY_TRIPS', selected: true },
    { name: TripSharingStatus.SharedByMe, labelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARED_BY_ME' },
    { name: TripSharingStatus.SharedWithMe, labelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.SHARED_WITH_ME' }
  ];

  public trips: Trip[] = [];
  public readonly hasContent: boolean;
  public readonly lastEdited: Trip;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public loading = false;
  public showLoadButton = true;

  get isMyTripsTab(): boolean {
    return this.queryParams.filter === TripSharingStatus.MyTrips;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountTripApiService: MyAccountTripApiService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {
    this.hasContent = this.route.snapshot.data.hasContent as boolean;
    this.lastEdited = this.route.snapshot.data.lastEdited as Trip;
  }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ trips }) => {
      this.trips = trips;
      this.showLoadButton = !(this.trips.length === 0 || this.trips.length < this.limit);
      this.changeDetectorRef.detectChanges();
    });
    this.route.queryParams.pipe(take(1)).subscribe(({ offset, filter }) => {
      this.queryParams = {
        ...this.queryParams,
        filter: filter ?? this.queryParams.filter,
        offset: +offset || this.queryParams.offset
      };
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        selected: tab.name === this.queryParams.filter
      }));
      this.refreshUrlQueryParams(this.queryParams);
    });
  }

  addTrip() {
    this.dialogService.open(CreateTripDialogComponent);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadData(this.queryParams);
  }

  onFilterChanged({ name }: TabHeader) {
    if (name === this.queryParams.filter) {
      return;
    }
    this.queryParams = {
      ...this.queryParams,
      offset: DEFAULT_OFFSET,
      filter: name as TripSharingStatus
    };
    this.tabs = this.tabs.map((tab) => ({
      ...tab,
      selected: tab.name === name
    }));
    this.loadData(this.queryParams);
  }

  loadData(params: TripQueryParams) {
    return this.loadTrips(params).subscribe((trips) => this.refreshTrips(trips));
  }

  loadTrips({ offset, filter }: TripQueryParams) {
    this.spinnerService.show();
    this.loading = true;
    return this.myAccountTripApiService.getAllMyTrips({ limit: this.limit, offset }, filter).pipe(
      finalize(() => {
        this.loading = false;
        this.spinnerService.hide();
      })
    );
  }

  refreshTrips(trips: Trip[]) {
    if (this.queryParams.offset === 0) {
      this.trips = [...trips];
    } else {
      this.trips.push(...trips);
    }
    this.showLoadButton = !(trips.length === 0 || trips.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
    this.refreshUrlQueryParams(this.queryParams);
  }

  refreshUrlQueryParams({ offset, filter }: TripQueryParams) {
    this.router
      .navigate([], {
        queryParams: { offset, filter },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
      .then(() => this.changeDetectorRef.detectChanges());
  }

  getLink(trip: Trip) {
    return `./${trip.id}/${trip.slug}/timeline`;
  }

  editTrip(trip: Trip) {
    this.dialogService.open(CreateTripDialogComponent, {
      data: {
        mode: Mode.Edit,
        trip
      }
    });
  }

  shareTrip(trip: Trip) {
    this.dialogService.open(ShareTripDialogComponent, {
      data: {
        trip
      },
      width: '380px'
    });
  }

  openTrip(trip: Trip) {
    this.router.navigate([this.getLink(trip)], {
      relativeTo: this.route
    });
  }
}
