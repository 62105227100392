import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@ct/components/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../../../button';
import { FileUploadPlaceholderComponent, MediaSelectComponent } from './components';
import { FileDndDirective } from './directives';

const COMPONENTS = [FileUploadPlaceholderComponent, MediaSelectComponent];

const DIRECTIVES = [FileDndDirective];

@NgModule({
  declarations: [...COMPONENTS, DIRECTIVES],
  imports: [CommonModule, ButtonModule, TranslateModule, IconModule],
  exports: [...COMPONENTS, DIRECTIVES]
})
export class FileUploadSharedModule {}
