import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { SpinnerModule } from '@ct/components/spinner';
import { CoreModule, PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { MyVideoPlayerModule } from '../my-video-player';
import { VideoItemComponent, VideoItemPreviewComponent } from './components';

@NgModule({
  declarations: [VideoItemComponent, VideoItemPreviewComponent],
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule,
    IconModule,
    SpinnerModule,
    MyVideoPlayerModule,
    PipesModule,
    NgOptimizedImage,
    ImageModule,
    CoreModule
  ],
  exports: [VideoItemComponent, VideoItemPreviewComponent]
})
export class VideoItemModule {}
