<div class="my-account-sidebar">
  <div class="container">
    <div *ngIf="data$ | async as data">
      <!-- TODO: uncomnent it, once Search API works -->
      <!-- <ct-input
        [formControl]="searchControl"
        [placeholder]="'MY_ACCOUNT.SEARCH_PLACEHOLDER' | translate"
        [prefixIcon]="'search'"
        (prefixIconClicked)="onSearch()"
      ></ct-input> -->
      <ct-sidebar-section
        class="mb-4"
        [titleKey]="'MY_ACCOUNT.MY_TRIPS'"
        [extraActionKey]="'MY_ACCOUNT.VIEW_ALL_TRIPS'"
        [extraActionLink]="getLink('trips/list')"
      >
        <ng-container *ngIf="data?.trips?.length">
          <ct-link
            *ngFor="let trip of data?.trips | slice: 0:tripsLimit"
            [link]="getLink('trips', trip?.id, trip?.slug)"
          >
            <ct-sidebar-section-item [title]="trip?.title" [date]="trip?.createdOn"></ct-sidebar-section-item>
          </ct-link>
        </ng-container>
        <ng-container *ngIf="!data?.trips?.length">
          <p class="flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
      <ct-sidebar-section
        class="mb-4"
        [titleKey]="'MY_ACCOUNT.MY_POSTS'"
        [extraActionKey]="'MY_ACCOUNT.VIEW_ALL_POSTS'"
        [extraActionLink]="getLink('posts')"
      >
        <ng-container *ngIf="data?.posts?.length">
          <ct-link *ngFor="let post of data?.posts" [link]="getLink('posts/view', post?.id, post?.slug)">
            <ct-sidebar-section-item [title]="post?.title" [date]="post?.createdOn"></ct-sidebar-section-item>
          </ct-link>
        </ng-container>
        <ng-container *ngIf="!data?.posts?.length">
          <p class="flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
      <ct-sidebar-section
        class="mb-4"
        [titleKey]="'MY_ACCOUNT.MY_PHOTOS'"
        [extraActionKey]="'MY_ACCOUNT.VIEW_ALL_PHOTOS'"
        [extraActionLink]="getLink('media', 'photos')"
      >
        <ct-photo-grid [photos]="data?.photos" [col]="4" [imageHeight]="'100px'" [limit]="photosLimit"></ct-photo-grid>
      </ct-sidebar-section>
      <!-- TODO: intergate with API  -->
      <!--<ct-sidebar-section class="mb-4" [titleKey]="'MY_ACCOUNT.MY_VIDEOS'">
        &lt;!&ndash;         [extraActionKey]="'MY_ACCOUNT.VIEW_ALL_VIDEOS'"
        [extraActionLink]="getLink('videos')" &ndash;&gt;
        <ct-coming-soon [showDescription]="false" [fontSize]="'1rem'" font="semi-bold" mode="light"></ct-coming-soon>
        &lt;!&ndash; <ct-video-grid [videos]="data?.videos" [col]="4" [videoHeight]="'100px'" [limit]="videosLimit"></ct-video-grid> &ndash;&gt;
      </ct-sidebar-section>-->
      <!-- TODO: intergate with API  -->
      <ct-sidebar-section
        class="mb-4"
        [titleKey]="'MY_ACCOUNT.MY_MARKETPLACE'"
        [extraActionKey]="'MY_ACCOUNT.VIEW_ALL_LISTINGS'"
        [extraActionLink]="getLink('marketplace')"
      >
        <ng-container *ngIf="data?.marketplace?.length">
          <ct-link *ngFor="let item of data?.marketplace" [link]="getLink('marketplace/view', item?.id)">
            <ct-sidebar-section-item [title]="item?.title" [date]="item?.createdOn"></ct-sidebar-section-item>
          </ct-link>
        </ng-container>
        <ng-container *ngIf="!data?.marketplace?.length">
          <p class="flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
      <ct-sidebar-section class="mb-4" [titleKey]="'MY_ACCOUNT.MY_ACCOUNT'">
        <ct-link [link]="getLink('overview')">
          <ct-sidebar-section-item [title]="'MY_ACCOUNT.ACCOUNT_OVERVIEW' | translate"></ct-sidebar-section-item>
        </ct-link>

        <ct-link [link]="getLink('profile')">
          <ct-sidebar-section-item [title]="'MY_ACCOUNT.EDIT_PROFILE' | translate"></ct-sidebar-section-item>
        </ct-link>

        <ct-sidebar-section-item
          [title]="'MY_ACCOUNT.LOGOUT' | translate"
          (click)="onLogout()"
        ></ct-sidebar-section-item>
      </ct-sidebar-section>
    </div>
  </div>
</div>
