import { Injectable } from '@angular/core';
import { UploadedImage } from '@ct/core';

import { TripJournalEntry, TripJournalEntryResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class TripJournalEntryFactory {
  toRequest(post: Partial<TripJournalEntry>): Partial<TripJournalEntryResponse> {
    const { id, createdOn, modifiedOn, photos, videos, groups, users, ...value } = post;
    return {
      ...value,
      photoIds: photos?.map((photo: UploadedImage) => photo.id) as string[],
      videoIds: videos?.map((video: any) => video.id) as string[], // Video interface
      groupIds: groups?.map((group: any) => group.id) as string[],
      userIds: users?.map((user: any) => user.userId) as string[]
    };
  }
}
