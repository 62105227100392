import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterItem } from './filter-pane.interface';

@Component({
  selector: 'ct-filter-pane',
  templateUrl: './filter-pane.component.html',
  styleUrls: ['./filter-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPaneComponent {
  @Input() filters: FilterItem[] = [];
  @Input() activeFilter: string;

  @Output() filterChanged = new EventEmitter<FilterItem>();
}
