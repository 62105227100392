import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ct/components/button';
import { DialogModule } from '@ct/components/dialog';
import { LinkModule } from '@ct/components/link';
import { UserRowModule } from '@ct/components/user-row';
import { TranslateModule } from '@ngx-translate/core';

import { IconModule } from '../icon';
import { NotificationInAppComponent } from './notification-in-app';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  declarations: [NotificationsComponent, NotificationInAppComponent],
  imports: [CommonModule, IconModule, UserRowModule, LinkModule, ButtonModule, TranslateModule, DialogModule],
  exports: [NotificationsComponent, NotificationInAppComponent]
})
export class NotificationsModule {}
