import { ɵDirectiveDef } from '@angular/core';

import { Writable } from '../../models';
import { AppInjector, MetaTagService } from '../../services';

export function RemoveSocialMediaMetaTagsFeature() {
  return <T>(directiveDef: Writable<ɵDirectiveDef<T>>) => {
    const { factory, type } = directiveDef;

    directiveDef.factory = () => {
      const instance = factory?.(type) as T;
      const injector = AppInjector.getInjector();
      if (!injector) {
        throw new Error(
          `AppInjector not found! Please, provide it or remove RemoveSocialMediaMetaTagsFeature() decorator`
        );
      }
      const metaTagService = injector.get(MetaTagService);

      metaTagService.removeSocialMediaTags();

      return instance;
    };
  };
}
