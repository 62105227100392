import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseControlComponent, BlogPost, FormStateDispatcher, Tag, UserProfile } from '@ct/core';
// TODO get rid of circular dependencies
import { entitySlugUrl } from '@ct/shared/helpers/entity-slug-url.helper';
import { Trip } from '@ct/shared/modules/trip-shared/interfaces/trip.interface';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchService } from '../../services';

@Component({
  selector: 'ct-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchAutocompleteComponent extends BaseControlComponent<string> implements OnInit, OnDestroy {
  public readonly destroyed$ = new Subject<void>();
  public readonly control = new UntypedFormControl(null);
  public readonly autocompleteControl = new UntypedFormControl(null);

  public readonly placeholder = '';
  public readonly label = '';
  public readonly maxlength = 200;
  public searchControl: UntypedFormControl = new UntypedFormControl();

  public prefixIcon = 'search';

  public get noMatchOption() {
    return {
      label: this.autocompleteControl.value,
      value: this.autocompleteControl.value,
      disabled: false
    };
  }

  constructor(
    @Optional() @Inject(NgControl) public readonly ctrl: FormControlName,
    @Optional() public readonly formState: FormStateDispatcher | null,
    public readonly changeDetector: ChangeDetectorRef,
    public searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
    this.route.queryParams.subscribe(({ query }) => {
      this.autocompleteControl.setValue(query);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changed(value: any) {
    if (typeof value === 'string') {
      this.router.navigateByUrl(`/site-search?query=${value}`);
      return;
    }

    this.clearInput();

    if (!value) {
      return;
    }

    if (value.type === 'story') {
      this.router.navigateByUrl(entitySlugUrl('/stories/', value));
    }
    if (value.type === 'tag') {
      // TODO: implement tag name
      this.router.navigateByUrl(`/stories/by-tag?tagId=${value.id}`);
    }
    if (value.type === 'user') {
      // TODO: implement username
      this.router.navigateByUrl(`/stories/by-author?authorId=${value.userId}`);
    }
    if (value.type === 'trip') {
      this.router.navigateByUrl(`/my-account/trips/${value.id}/${value.slug}`);
    }
  }

  clearInput() {
    this.autocompleteControl.patchValue(undefined);
    this.changeDetector.detectChanges();
  }

  public optionsFn = (match: string) => this.getAutocomplete(match);

  private getAutocomplete(match: string) {
    return this.searchService.autocomplete(match).pipe(
      map((res) => {
        return [
          ...res.users.map((user: UserProfile) => ({ ...user, type: 'user' })),
          ...res.stories.map((story: BlogPost) => ({ ...story, type: 'story' })),
          ...res.tags.map((tag: Tag) => ({ ...tag, type: 'tag' })),
          ...res.trips.map((trip: Trip) => ({ ...trip, type: 'trip' }))
        ].sort((a, b) => (a.score > b.score ? -1 : 1));
      })
    );
  }

  onSearchIconClick() {
    this.changed(this.autocompleteControl.value);
  }
}
