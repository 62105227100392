<ct-card-item
  [item]="groupWithImage"
  [showLink]="false"
  [creationDate]="group?.createdOn"
  [description]="group?.visibility"
  [showAuthor]="false"
  [showTags]="false"
  [imgHeight]="imgHeight"
  [imgCols]="imgCols"
  [imgWidth]="imgWidth"
  [objectFit]="objectFit"
  [link]="link"
  [contentMargin]="contentMargin"
  [contentCols]="contentCols"
>
  <div *ngIf="user && !isAdmin" class="d-flex justify-content-end">
    <ct-button color="primary" *ngIf="canJoin" (click)="onJoin()">Join</ct-button>
    <ct-button color="primary" *ngIf="canAccept" (click)="onJoin()">Accept</ct-button>
    <ct-button color="warn" *ngIf="requestSent" (click)="onCancel()">Cancel Request</ct-button>
  </div>
  <div *ngIf="isAdmin" class="d-flex justify-content-end">
    <ct-button color="warn" (click)="onDelete()">Delete</ct-button>
  </div>
</ct-card-item>
