import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormControlName, ValidationErrors, Validator } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IDestroyable } from '../lifecycle';
import { BaseControl } from './base-control.class';
import { FormStateDispatcher } from './form-state.dispatcher';

export abstract class BaseArrayComponent<T, R = T> extends BaseControl<T> implements Validator, IDestroyable {
  readonly destroyed$: Observable<void>;
  abstract form: FormArray;
  protected onValidatorChange?: () => void;
  protected onChanged?: (value: T[] | unknown) => void;
  protected onTouched?: () => void;

  protected readonly viewToModelParser?: (value: T[]) => T[] = identity;
  protected readonly modelToViewFormatter?: (value: T[]) => T[] = identity;

  get value(): T {
    return this.form.getRawValue() as unknown as T;
  }

  writeValue(value: T): void {
    if (value) {
      this.form.clear();
      (this.modelToViewFormatter?.(value as unknown as T[]) as T[]).forEach((el) =>
        this.form.push(el as unknown as AbstractControl)
      );
    }
  }

  registerOnChange(fn: (value: T) => void) {
    super.registerOnChange(fn);

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.onChanged?.((this.viewToModelParser?.(value) as T[]) || value);
      this.onTouched?.();
    });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      return this.form.disable();
    }
    this.form.enable();
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : { invalid: true };
  }

  initFormControlValidations(
    ctrl: FormControlName,
    formState: FormStateDispatcher,
    changeDetector: ChangeDetectorRef
  ): void {
    if (ctrl?.control?.validator) {
      this.form.setValidators(ctrl.control.validator);
    }
    if (ctrl?.control?.asyncValidator) {
      this.form.setAsyncValidators(ctrl.control.asyncValidator);
    }
    this.onValidatorChange?.();

    ctrl?.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.form.setErrors(this.form?.errors);
      changeDetector.markForCheck();
    });

    formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.form.markAsTouched({ onlySelf: true });
      this.form.updateValueAndValidity();
      changeDetector.markForCheck();
    });
  }
}
