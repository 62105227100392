import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';

import { TripEntryTabComponent } from '../trip-entry-tab';

@Component({
  selector: 'ct-trip-entry-tabs',
  templateUrl: './trip-entry-tabs.component.html',
  styleUrls: ['./trip-entry-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripEntryTabsComponent implements AfterContentInit {
  @Input() selectedTabIndex: number;

  @ContentChildren(TripEntryTabComponent) tabs: QueryList<TripEntryTabComponent>;

  ngAfterContentInit() {
    if (this.selectedTabIndex && this.selectedTabIndex !== -1) {
      const tab = this.tabs.get(this.selectedTabIndex) as TripEntryTabComponent;
      return this.onSelectTab(tab);
    }
    const isSomeTabActive = this.tabs.some((tab) => tab.active);
    if (!isSomeTabActive) {
      return this.onSelectTab(this.tabs.first);
    }
  }

  onSelectTab(tab?: TripEntryTabComponent) {
    if (!tab) {
      return;
    }
    this.deactivateTabs();
    tab.active = true;
    tab.changeDetectorRef.detectChanges();
  }

  deactivateTabs() {
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
      tab.changeDetectorRef.detectChanges();
    });
  }
}
