<ct-entity-item
  [body]="thread?.body"
  [userPhoto]="getCroppedThumbPublicUrl(thread?.author?.photo?.publicUrl)"
  [username]="thread?.author?.username"
  [createdOn]="thread?.createdOn"
  [modifiedOn]="thread?.modifiedOn"
  [reply]="isLoggedIn"
  [editable]="isInteractable"
  [deletable]="isInteractable"
  (replied)="onReply()"
  (edited)="editedThread.emit(thread)"
  (deleted)="deletedThread.emit(thread)"
>
  <div #containerWrapper>
    <ng-container #vc></ng-container>
  </div>
  <ct-entity-comment
    *ngFor="let comment of thread?.comments; trackBy: trackByFn"
    [comment]="comment"
    [user]="user"
    (edited)="onEditComment(comment)"
    (deleted)="onDeleteComment(comment)"
  ></ct-entity-comment>
</ct-entity-item>
