import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { TripJournalEntry } from '../interfaces';
import { TripJournalEntryApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class TripJournalEntryDataResolver implements Resolve<TripJournalEntry | undefined> {
  constructor(private tripJournalEntryApiService: TripJournalEntryApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const id = activatedRouteSnapshot.params?.id;
    const entryId = activatedRouteSnapshot.params?.entryId;
    return this.tripJournalEntryApiService.getById(id, entryId);
  }
}
