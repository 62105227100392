<div class="row" *ngIf="posts?.length">
  <ct-blog-post-card
    class="col-12 col-md-4"
    imgHeight="300px"
    imgWidth="100%"
    *ngFor="let post of posts"
    [post]="post"
    [link]="getLink(post)"
    [imgCols]="12"
    [contentCols]="12"
  ></ct-blog-post-card>
</div>
<p *ngIf="!posts?.length" class="flex-center">
  <span>{{ 'COMMON.EMPTY' | translate }}</span>
</p>
