import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from '@ct/auth';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { MerchantProfile } from '../../../../marketplace/interfaces';
import { MerchantProfileApiService } from '../../../../marketplace/services';

@Injectable({ providedIn: 'root' })
export class MerchantProfileResolver implements Resolve<MerchantProfile | null> {
  constructor(private merchantProfileApiService: MerchantProfileApiService, private authService: AuthService) {}

  resolve() {
    return this.getMyMerchantProfile();
  }

  private getMyMerchantProfile(): Observable<MerchantProfile | null> {
    return this.authService.getUserProfile().pipe(
      take(1),
      switchMap(() => this.merchantProfileApiService.getMyProfile()),
      catchError(() => of(null))
    );
  }
}
