import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from '@ct/core';

import { IconModule } from '../icon';
import { GalleryComponent } from './components/gallery';
import { GalleryCoreComponent } from './components/gallery-core';
import { GalleryItemComponent } from './components/gallery-item';
import { GalleryIframeComponent } from './components/gallery-item-types/gallery-iframe';
import { GalleryImageComponent } from './components/gallery-item-types/gallery-image';
import { GalleryVideoComponent } from './components/gallery-item-types/gallery-video';
import { GalleryNavComponent } from './components/gallery-nav';
import { GallerySliderComponent } from './components/gallery-slider';
import { GalleryThumbComponent } from './components/gallery-thumb';
import { GalleryThumbsComponent } from './components/gallery-thumbs';
import { LazyImage } from './directives/lazy-image';
import { TapClick } from './directives/tap-click';
import { GalleryConfig } from './interfaces';
import { GALLERY_CONFIG } from './tokens';

@NgModule({
  imports: [CommonModule, IconModule, PipesModule],
  declarations: [
    GalleryComponent,
    GalleryNavComponent,
    GalleryCoreComponent,
    GallerySliderComponent,
    GalleryThumbsComponent,
    GalleryThumbComponent,
    GalleryItemComponent,
    GalleryImageComponent,
    GalleryVideoComponent,
    GalleryIframeComponent,
    LazyImage,
    TapClick
  ],
  exports: [GalleryComponent, LazyImage, TapClick]
})
export class GalleryModule {
  static withConfig(config: GalleryConfig): ModuleWithProviders<GalleryModule> {
    return {
      ngModule: GalleryModule,
      providers: [
        {
          provide: GALLERY_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
