<div class="notifications">
  <div class="notifications-icon" (click)="toggleNotifications($event)">
    <ct-icon class="icon" size="fit" title="notification" icon="notification"></ct-icon>
    <span *ngIf="unreadCount() > 0" class="badge"></span>
  </div>

  <ul *ngIf="showNotifications" class="notification-list">
    <li
      *ngFor="let notification of notifications"
      class="notification-list-item"
      (click)="markAsRead($event, notification)"
      [class.read]="notification.status === NotificationStatus.Seen"
    >
      <ng-container *ngIf="buildNotification(notification) as display">
        <h4 class="mb-0">{{ display.title }}</h4>
        <p class="mb-0">{{ display.message }}</p>
      </ng-container>
    </li>
    <li *ngIf="notifications?.length; else noNotifications" class="notification-list-item read">
      <ct-link (click)="$event.stopPropagation()" [link]="['/notifications']"> Show all </ct-link>
    </li>
  </ul>
</div>

<ng-template #noNotifications>
  <li class="notification-list-item read"><p class="mb-0 d-flex flex-center">No notifications</p></li>
</ng-template>
