<div class="row drag-boundary" [sortablejs]="_photos" [sortablejsOptions]="sortOptions">
  <div class="col-6 col-md-{{ col }} photo" *ngFor="let photo of _photos; let index = index; trackBy: trackByFn">
    <ng-container
      *ngTemplateOutlet="
        fullScreen ? lightboxTmpl : link ? linkTmpl : defaultTmpl;
        context: { photo: photo, index: index }
      "
    ></ng-container>
  </div>
  <ct-new-item-placeholder
    *ngIf="showPlaceholder"
    class="placeholder col-6 col-md-{{ col }}"
    [textKey]="'MY_ACCOUNT.MY_PHOTOS_FEATURE.ADD_NEW_PHOTO'"
    [height]="imageHeight"
    [width]="imageWidth"
    (click)="onSelectPlaceholder($event)"
  ></ct-new-item-placeholder>
</div>
<p *ngIf="!_photos?.length && !showPlaceholder && emptyMessage" class="flex-center">
  <span>{{ 'COMMON.EMPTY' | translate }}</span>
</p>

<ng-template #lightboxTmpl let-photo="photo" let-index="index">
  <ct-photo
    [height]="imageHeight"
    [width]="imageWidth"
    [photo]="photo"
    [removable]="removable"
    [showAccess]="showAccess"
    [multiselect]="multiselect"
    (removed)="removed.emit(photo)"
    (accessChanged)="accessChanged.emit(photo)"
    (selectionChanged)="selectionChanged.emit(photo)"
    lightbox
    [lightboxConfig]="{ editable: editable }"
    [galleryItems]="galleryItems"
    [galleryItemIndex]="index"
    [galleryConfig]="galleryConfig"
    (galleryItemEdit)="onEdit($event)"
    [altText]="imageDescriptionPrefix + ' image ' + (index + 1)"
  >
  </ct-photo>
</ng-template>

<ng-template #linkTmpl let-photo="photo" let-index="index">
  <ct-link [link]="getLink(photo?.id)" queryParamsHandling="merge">
    <ct-photo
      [height]="imageHeight"
      [width]="imageWidth"
      [photo]="photo"
      [removable]="removable"
      [showAccess]="showAccess"
      [multiselect]="multiselect"
      [attr.draggable]="draggable"
      (removed)="removed.emit(photo)"
      [altText]="imageDescriptionPrefix + ' image ' + (index + 1)"
    >
    </ct-photo>
  </ct-link>
</ng-template>

<ng-template #defaultTmpl let-photo="photo" let-index="index">
  <ct-photo
    [height]="imageHeight"
    [width]="imageWidth"
    [photo]="photo"
    [removable]="removable"
    [showAccess]="showAccess"
    [multiselect]="multiselect"
    (removed)="removed.emit(photo)"
    [altText]="imageDescriptionPrefix + ' image ' + (index + 1)"
  >
  </ct-photo>
</ng-template>
