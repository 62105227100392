import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { getYoutubeId } from '../../../../helpers';
import { YoutubeVideo } from '../../interfaces';

@Component({
  selector: 'ct-youtube-video-grid',
  templateUrl: './youtube-video-grid.component.html',
  styleUrls: ['./youtube-video-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeVideoGridComponent {
  @Input() videos: YoutubeVideo[] | undefined;

  @Input() col = 2;
  @Input() videoHeight = '150px';
  @Input() limit: number | undefined;
  @Input() showPlaceholder: boolean | undefined = false;
  @Input() selectable: boolean | undefined = false;
  @Input() multiselect: boolean | undefined = false;
  @Input() removable: boolean | undefined = false;

  @Output() removed = new EventEmitter<YoutubeVideo>();
  @Output() selectionChanged = new EventEmitter<YoutubeVideo>();
  @Output() played = new EventEmitter<string>();
  @Output() addVideo = new EventEmitter<string>();
  get _videos() {
    return this.videos?.slice(0, this.limit);
  }

  onVideoPlay(youtubeId: string | undefined) {
    this.played.emit(getYoutubeId(youtubeId));
  }

  onAddVideo(event: any) {
    this.addVideo.emit();
  }
}
