import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Tag } from '@ct/core';

import { TagApiService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class BlogPostsTagResolver implements Resolve<Tag> {
  constructor(private tagApiService: TagApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const tagId = activatedRouteSnapshot.queryParams?.tagId as string;
    return this.tagApiService.getById(tagId);
  }
}
