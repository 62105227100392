import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlogPost, SeriesWithStories } from '@ct/core';
import { entitySlugUrl } from '@ct/shared/helpers';

@Component({
  selector: 'ct-blog-post-series',
  templateUrl: './blog-post-series.component.html',
  styleUrls: ['./blog-post-series.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostSeriesComponent {
  @Input() series: SeriesWithStories;
  @Input() showSeries = true;
  @Input() activeStoryId: string;

  get stories(): BlogPost[] {
    return this.series?.stories ?? [];
  }

  getLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }
}
