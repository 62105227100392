<mat-label *ngIf="showLabel && label">
  <span>{{ label }}</span>
  <span *ngIf="required"> * </span>
</mat-label>
<mat-form-field appearance="outline">
  <mat-select [formControl]="control" [placeholder]="(!showLabel && label) || placeholder" [compareWith]="compareFn">
    <mat-select-trigger *ngIf="displayFn">{{ displayFn(options, control.value) }}</mat-select-trigger>
    <ng-container *ngIf="optional">
      <mat-option [value]="null"> &mdash; </mat-option>
    </ng-container>
    <ng-container *ngFor="let option of options; trackBy: trackByFn">
      <mat-option [value]="valueFn(option)"> {{ labelFn(option) }}</mat-option>
    </ng-container>
  </mat-select>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'COMMON.VALIDATIONS.REQUIRED' | translate }}
  </mat-error>
</mat-form-field>
