<ct-dialog-content
  [titleKey]="isEditMode ? 'MY_ACCOUNT.MY_TRIPS_FEATURE.EDIT_WAYPOINT' : 'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_WAYPOINT'"
  headerIcon="add-circle"
  [closeIconParams]="{ reload: false }"
  [backIcon]="true"
  [buttons]="buttons"
  [alignButtons]="'end'"
  (back)="onBack()"
>
  <div class="waypoint">
    <form class="form" [formGroup]="form">
      <div class="row">
        <ct-input
          class="col-12"
          formControlName="title"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.WAYPOINT_TITLE' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TITLE_PLACEHOLDER' | translate"
        ></ct-input>
      </div>
      <div class="row">
        <ct-google-places
          formControlName="formatted_address"
          class="col-12"
          [options]="placesOptions"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.LOCATION_PLACEHOLDER' | translate"
          (locationChanged)="onLocation($event)"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.LOCATION_PLACEHOLDER' | translate"
          (setCurrentLocation)="onCurrentLocation()"
        ></ct-google-places>
      </div>
      <div class="row">
        <ct-date-picker
          class="col-12 col-md-6"
          formControlName="startDate"
          [label]="'COMMON.DATE.START' | translate"
          [placeholder]="'COMMON.DATE.START_PLACEHOLDER' | translate"
          [startDate]="trip.startDate"
          [min]="trip.startDate"
          [max]="form.controls?.endDate?.value || trip.endDate"
        ></ct-date-picker>
        <ct-date-picker
          class="col-12 col-md-6"
          formControlName="endDate"
          [label]="'COMMON.DATE.END' | translate"
          [placeholder]="'COMMON.DATE.END_PLACEHOLDER' | translate"
          [min]="form.controls?.startDate?.value || trip.startDate"
          [startDate]="form.controls?.endDate?.value || form.controls?.startDate?.value || trip.startDate"
          [max]="trip.endDate"
        ></ct-date-picker>
      </div>
      <div class="row mb-3">
        <h2 class="item-title col-12">{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.FEATURED_PHOTO' | translate }}</h2>
        <ct-new-item-placeholder
          class="placeholder col-12 col-md-3"
          [img]="form.controls.featuredPhoto?.value?.publicUrl"
          [textKey]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_NEW_PHOTO'"
          [replaceKey]="
            form.controls.featuredPhoto?.value?.publicUrl ? 'MY_ACCOUNT.MY_TRIPS_FEATURE.REPLACE_PHOTO' : ''
          "
          (placeholderClicked)="onSelectFeaturedPhotoPlaceholder()"
          (replaced)="onReplaceFeaturedPhoto()"
          (removed)="onRemoveFeaturedPhoto()"
        ></ct-new-item-placeholder>
      </div>
    </form>
  </div>
</ct-dialog-content>
