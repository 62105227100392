import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CanonicalFeature,
  DestroyableFeature,
  FacebookCardType,
  Features,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType
} from '@ct/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ct-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature(), DestroyableFeature()])
export class NotFoundComponent {
  private destroyed$ = new Subject<void>();
  previousUrl: string;
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.NOT_FOUND'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.NOT_FOUND',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/not_found.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/404'
    }
  };

  constructor(router: Router) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((event) => {
        this.previousUrl = (event as NavigationEnd).url;
      });
  }
}
