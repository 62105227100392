import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService, TabHeader } from '@ct/components';
import { TitleService, UserProfile } from '@ct/core';
import {
  AddToTripDialogComponent,
  CreateTripDialogComponent,
  Mode,
  Trip,
  TripApiService,
  TripEntityType
} from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { ShareTripDialogComponent } from '../share-trip-dialog';

const LIMIT = 5;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-my-account-trip-tabs',
  templateUrl: './my-account-trip-tabs.component.html',
  styleUrls: ['./my-account-trip-tabs.component.scss']
})
export class MyAccountTripTabsComponent implements OnInit, OnDestroy {
  public trip: Trip;
  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public tabs: TabHeader[] = [
    { name: 'timeline', labelKey: 'Timeline', selected: true },
    { name: 'campsites', labelKey: 'Itinerary' },
    { name: 'journals', labelKey: 'Memories' },
    { name: 'lists', labelKey: 'Lists', shouldShow: () => this.canViewList() }
  ];

  private readonly destroyed$ = new Subject();

  get isAuthor(): boolean {
    return this.authQuery.getValue().profile?.userId === this.trip?.authorId;
  }

  canViewList(): boolean {
    return this.isAuthor;
  }

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private tripApiService: TripApiService,
    private router: Router,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.url
      .pipe(
        takeUntil(this.destroyed$),
        map((urls) => urls[urls.length - 1].path)
      )
      .subscribe((path) => {
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          selected: tab.name === path
        }));
        this.changeDetectorRef.detectChanges();
      });

    const timelineData = this.route.snapshot.data?.timeline;
    const trip = this.route.snapshot.data?.trip;

    this.trip = timelineData?.trip ?? trip;
    this.titleService.setTitle(this.trip?.title);
    this.changeDetectorRef.markForCheck();

    this.loggedInUser$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.tabs = [...this.tabs];
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  editTrip() {
    const dialog = this.dialogService.open(CreateTripDialogComponent, {
      data: {
        mode: Mode.Edit,
        trip: this.trip
      }
    });

    dialog
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((trip) => {
        this.trip = trip as Trip;
        this.changeDetectorRef.detectChanges();
      });
  }

  shareTrip() {
    this.dialogService.open(ShareTripDialogComponent, {
      data: {
        trip: this.trip
      },
      width: '380px'
    });
  }

  onFilterChanged({ name }: TabHeader) {
    this.router.navigate(['..', name], {
      relativeTo: this.route,
      queryParams: {
        campsiteId: null,
        offset: null
      },
      queryParamsHandling: 'merge'
    });
  }

  addDialog() {
    this.dialogService
      .open(AddToTripDialogComponent, { data: this.trip })
      .afterClosed()
      .pipe(
        take(1),
        filter((options) => options && Boolean(options.reload)),
        switchMap((options) => {
          if (options.navigateTo) {
            switch (options.navigateTo) {
              case TripEntityType.Video:
                this.onFilterChanged(this.tabs[3]);
                break;
              case TripEntityType.Campsite:
                this.onFilterChanged(this.tabs[1]);
                break;
              case TripEntityType.Journal:
                this.onFilterChanged(this.tabs[2]);
                break;
            }
            return of({ trip: this.trip });
          }
          return forkJoin({
            timeline: this.tripApiService.getTimeline(this.trip.id as string, {
              offset: 0,
              limit: LIMIT
            }),
            trip: this.tripApiService.getById(this.trip.id as string)
          });
        })
      )
      .subscribe((timelineData) => {
        this.trip = timelineData?.trip;
      });
  }
}
