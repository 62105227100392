<ng-container
  [lazyImage]="src"
  (progress)="onProgress($event)"
  (loaded)="onLoaded($event)"
  (errorItem)="onError($event)"
  [ngSwitch]="state | async"
>
  <div
    *ngSwitchCase="'success'"
    @fadeIn
    class="g-image-item"
    role="img"
    [attr.aria-label]="imageDescription"
    [style.backgroundImage]="imageUrl | safe: 'style'"
  >
    <a *ngIf="original" class="g-image-original-link" [href]="original | safe: 'url'" target="_blank"
      >Show original image</a
    >
  </div>

  <div *ngSwitchCase="'failed'" class="g-image-error-message">
    <ng-container *ngIf="isThumbnail; else isLarge">
      <h4>⚠</h4>
    </ng-container>
    <ng-template #isLarge>
      <h2>⚠</h2>
      <p>Unable to load the image!</p>
    </ng-template>
  </div>
  <ng-container *ngSwitchCase="'loading'">
    <ct-icon class="g-loading" icon="loading"></ct-icon>
  </ng-container>
</ng-container>
