<div class="header" #headerElement>
  <div class="container">
    <ct-link *ngIf="!isProd" [link]="['/']" (click)="closeMenu()" class="logo-container">
      <img
        class="logo"
        alt="logo"
        src="assets/logos/main-logo-light.png"
        srcset="assets/logos/main-logo-light@2x.png 2x, assets/logos/main-logo-light@3x.png 3x"
      />
    </ct-link>
    <a *ngIf="isProd" [href]="externalUrl" class="logo-container">
      <img
        class="logo"
        alt="logo"
        src="assets/logos/main-logo-light.png"
        srcset="assets/logos/main-logo-light@2x.png 2x, assets/logos/main-logo-light@3x.png 3x"
      />
    </a>
    <ct-icon class="menu-icon" icon="menu" (click)="collapsed = !collapsed"></ct-icon>
    <nav class="navbar" [ngClass]="collapsed ? 'collapsed' : 'expanded'">
      <div class="navbar-menu">
        <ul>
          <li *ngFor="let menuItem of menuItems">
            <a routerLinkActive="active" [routerLink]="menuItem.link" (click)="closeMenu()">{{
              menuItem.title$ | async
            }}</a>
          </li>
          <li class="border-left">
            <a routerLinkActive="active" [routerLink]="'/register'" (click)="closeMenu()">{{
              'COMMON.REGISTER' | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="navbar-buttons">
        <ct-link
          class="d-none d-md-flex"
          [link]="['/login']"
          [queryParams]="{ returnUrl: router.url }"
          [queryParamsHandling]="'preserve'"
        >
          <ct-button variant="stroked" (click)="closeMenu()" color="simple">
            {{ 'HEADER.BUTTONS.LOGIN' | translate }}</ct-button
          >
        </ct-link>
        <ct-link
          class="d-flex d-md-none"
          [link]="['/login']"
          [queryParams]="{ returnUrl: router.url }"
          [queryParamsHandling]="'preserve'"
        >
          <ct-button variant="stroked" (click)="closeMenu()"> {{ 'HEADER.BUTTONS.LOGIN' | translate }}</ct-button>
        </ct-link>
      </div>
    </nav>
  </div>
</div>
