import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ct/components/button';
import { CheckboxModule } from '@ct/components/checkbox';
import { DateModule } from '@ct/components/date';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { InputModule } from '@ct/components/input';
import { KeywordInputModule } from '@ct/components/keyword-input';
import { SelectModule } from '@ct/components/select';
import { TagSelectModule } from '@ct/components/tag-select';
import { TextEditorModule } from '@ct/components/text-editor';
import { TranslateModule } from '@ngx-translate/core';

import { ReportInappropriateDialogComponent } from './report-inappropriate-dialog.component';

@NgModule({
  declarations: [ReportInappropriateDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    DateModule,
    InputModule,
    IconModule,
    TagSelectModule,
    TextEditorModule,
    SelectModule,
    CheckboxModule,
    KeywordInputModule
  ],
  exports: [ReportInappropriateDialogComponent]
})
export class ReportInappropriateDialogModule {}
