import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { ImageModule } from '../image';
import { LinkModule } from '../link';
import { EmptyCallToActionComponent } from './empty-call-to-action.component';

@NgModule({
  declarations: [EmptyCallToActionComponent],
  imports: [CommonModule, LinkModule, ImageModule, IconModule, TranslateModule, ButtonModule, RouterModule],
  exports: [EmptyCallToActionComponent]
})
export class EmptyCallToActionModule {}
