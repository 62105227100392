import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-text-editor-view',
  templateUrl: './text-editor-view.component.html',
  styleUrls: ['./text-editor-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorViewComponent {
  @Input() public content: undefined | string = '';
  @Input() public theme = 'snow';
}
