<ct-dialog-content
  [titleKey]="$any(config?.titleKey)"
  [buttons]="buttons"
  [alignButtons]="'end'"
  [closeIconParams]="closeIconParams"
>
  <div class="file-upload">
    <mat-tab-group [(selectedIndex)]="tabIndex" *ngIf="config.showExisting; else simpleUpload">
      <mat-tab [label]="$any(config.firstTabKey) | translate">
        <ng-container *ngTemplateOutlet="uploadTemplate"></ng-container>
      </mat-tab>
      <mat-tab [label]="$any(config.secondTabKey) | translate">
        <div class="row">
          <p class="col-12 loading" *ngIf="loading">{{ 'MY_ACCOUNT.LOADING_PHOTOS' | translate }}</p>
          <ng-container *ngIf="!loading">
            <ct-image-upload-progress
              *ngFor="let image of uploadedImages; let index = index"
              class="col-12 col-md-2 image"
              [done]="image?.done"
              [error]="image?.error"
              [imageUrl]="getCroppedThumbPublicUrl(image?.data?.publicUrl)"
              [imageName]="image?.file?.name"
              [value]="image?.progress"
              [selected]="image?.selected"
              [attr.selectable]="config?.selectable"
              (click)="onSelectImage(image)"
            >
            </ct-image-upload-progress>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ct-dialog-content>

<ng-template #simpleUpload>
  <ng-container *ngIf="!finishedUpload">
    <ng-container *ngTemplateOutlet="uploadTemplate"></ng-container>
  </ng-container>
  <ng-container *ngIf="finishedUpload">
    <div *ngIf="!errorImages?.length" class="file-upload__message file-upload__success">
      <ct-icon icon="success" variant="large" color="primary"></ct-icon>
      <p>{{ 'Images uploaded successfully!' }}</p>
    </div>
    <div *ngIf="errorImages?.length" class="file-upload__message file-upload__error">
      <ct-icon icon="close" variant="large" color="primary"></ct-icon>
      <p>{{ 'Some images failed to upload' }}</p>
    </div>
  </ng-container>
</ng-template>

<ng-template #uploadTemplate>
  <ct-file-upload-placeholder
    [multiple]="config?.multiple"
    [disabled]="config?.disabled"
    [accept]="config?.accept"
    (selectFiles)="onSelectFiles($event)"
  ></ct-file-upload-placeholder>
</ng-template>
