<div class="tab-header--wrapper">
  <div class="tab-header--prefix">
    <ng-content select="[ctTabPrefix]"></ng-content>
  </div>
  <span
    *ngFor="let tab of tabs | filterShouldShow"
    class="tab-header"
    [class.tab-header--active]="tab.selected"
    [ngStyle]="{ 'background-color': tab.selected && backgroundColor }"
    (click)="onTabClicked(tab)"
  >
    {{ tab.labelKey | translate }}
  </span>
  <div class="tab-header--suffix">
    <ng-content select="[ctTabSuffix]"></ng-content>
  </div>
</div>
