import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { CanonicalFeature, Features, JsonLdService, SeoConfig, TitleFeature, UserProfile } from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature(), CanonicalFeature()])
export class FaqComponent implements OnDestroy {
  public readonly seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'COMMON.FAQ.TITLE'
    },
    canonicalConfig: {
      canonicalUrl: '/faq'
    }
  };

  public readonly videos = [
    {
      titleKey: 'COMMON.FAQ.WELCOME.HEADER',
      videoLinkKey: 'COMMON.FAQ.WELCOME.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.OVERVIEW.HEADER',
      videoLinkKey: 'COMMON.FAQ.OVERVIEW.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.DASHBOARD.HEADER',
      videoLinkKey: 'COMMON.FAQ.DASHBOARD.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.PROFILE.HEADER',
      videoLinkKey: 'COMMON.FAQ.PROFILE.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.NAVIGATION.HEADER',
      videoLinkKey: 'COMMON.FAQ.NAVIGATION.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.TRIP_SETTINGS.HEADER',
      videoLinkKey: 'COMMON.FAQ.TRIP_SETTINGS.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.WRITINGS.HEADER',
      videoLinkKey: 'COMMON.FAQ.WRITINGS.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.TRIP_MEDIA.HEADER',
      videoLinkKey: 'COMMON.FAQ.TRIP_MEDIA.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.FRIENDS.HEADER',
      videoLinkKey: 'COMMON.FAQ.FRIENDS.VIDEO'
    },
    {
      titleKey: 'COMMON.FAQ.MARKETPLACE.HEADER',
      videoLinkKey: 'COMMON.FAQ.MARKETPLACE.VIDEO'
    }
  ];

  public readonly loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public readonly simple = this.route.snapshot.data.simple;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private jsonLdService: JsonLdService,
    private route: ActivatedRoute
  ) {
    this.jsonLdService.insertSchema(this.generateJsonLdSchema(), 'structured-data-faq');
  }

  onCollapse(iframe: HTMLIFrameElement) {
    // eslint-disable-next-line no-self-assign
    iframe.src = iframe.src;
    this.cdr.markForCheck();
  }

  private generateJsonLdSchema() {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.videos.map(({ titleKey, videoLinkKey }) => ({
        '@type': 'Question',
        name: this.translateService.instant(titleKey),
        acceptedAnswer: {
          '@type': 'Answer',
          text: this.translateService.instant(videoLinkKey)
        }
      }))
    };
  }

  ngOnDestroy(): void {
    this.jsonLdService.removeStructuredData('structured-data-faq');
  }
}
