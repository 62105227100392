import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService, ShareDialogComponent, TabHeader } from '@ct/components';
import {
  BlogPost,
  Features,
  FriendStatus,
  RelationUserProfile,
  TitleConfig,
  TitleFeature,
  UserProfile
} from '@ct/core';
import { environment } from '@ct/environment';
import { entitySlugUrl, JournalEntryApiService, Mode, trackById, TripJournalEntry } from '@ct/shared';
import {
  AddToWritingsDialogComponent,
  WritingJournalAddDialogComponent
} from '@ct/shared/modules/add-to-writings-dialog';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, take } from 'rxjs/operators';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../../constants';
import { FriendsQuery, UserRelationsApiService } from '../../../../services';
import { MyAccountBlogPostApiService } from '../../services';

enum Writing {
  Stories = 'stories',
  Journal = 'journal-entries'
}

interface WritingQueryParams {
  offset: number;
  filter: Writing;
}

const DEFAULT_FILTER = Writing.Stories;
const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-my-account-writings',
  templateUrl: './my-account-writings.component.html',
  styleUrls: ['./my-account-writings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountWritingsComponent implements OnInit {
  public readonly limit = MY_ACCOUNT_POSTS_FEATURE_LIMIT;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_POSTS'
  };
  public tabs: TabHeader[] = [
    { name: Writing.Stories, labelKey: 'MAIN.FEATURES.MY_ACCOUNT_POSTS', selected: true },
    { name: Writing.Journal, labelKey: 'MAIN.FEATURES.MY_ACCOUNT_JOURNALS' }
  ];

  public loading = false;
  public posts: BlogPost[] = [];
  public journalEntries: TripJournalEntry[] = [];
  public showLoadButton = true;

  public lastEdited: BlogPost;

  public queryParams: WritingQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER
  };

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  private friends$: Observable<RelationUserProfile[]> = this.friendsQuery.selectAll({
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });

  get shareAllUrl(): string {
    let location = undefined;
    if (typeof globalThis !== 'undefined' && globalThis?.location) {
      location = globalThis.location.origin;
    }
    const baseUrl = location || environment.webUrl;

    return `${baseUrl}/stories/by-author?authorId=${this.userId}`;
  }

  get isStoriesTab(): boolean {
    return this.queryParams.filter === Writing.Stories;
  }

  get isJournalsTab(): boolean {
    return this.queryParams.filter === Writing.Journal;
  }
  public trackByFn = trackById;

  private userId: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountBlogPostApiService: MyAccountBlogPostApiService,
    private journalEntryApiService: JournalEntryApiService,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private friendsQuery: FriendsQuery,
    private userRelationsApiService: UserRelationsApiService
  ) {
    this.userRelationsApiService.getAllMyFriends().subscribe();
    this.loggedInUser$.pipe(take(1)).subscribe((user) => (this.userId = user.userId));
  }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ stories }) => (this.posts = stories));
    this.route.data.pipe(take(1)).subscribe(({ journalEntries }) => (this.journalEntries = journalEntries));
    this.route.data.pipe(take(1)).subscribe(({ lastEdited }) => (this.lastEdited = lastEdited));
    this.route.queryParams.pipe(take(1)).subscribe(({ offset, filter }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset,
        filter: filter || this.queryParams.filter
      };
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        selected: tab.name === this.queryParams.filter
      }));

      if (this.queryParams.filter === Writing.Stories) {
        this.showLoadButton = !(this.posts.length === 0 || this.posts.length < this.limit);
      } else if (this.queryParams.filter === Writing.Journal) {
        this.showLoadButton = !(this.journalEntries.length === 0 || this.journalEntries.length < this.limit);
      }

      this.refreshUrlQueryParams(this.queryParams);
    });
  }

  getLink(post: BlogPost) {
    return entitySlugUrl('view/', post);
  }

  getEditLink(post: BlogPost) {
    return entitySlugUrl('edit/', post);
  }

  onFilterChanged({ name }: TabHeader) {
    if (name === this.queryParams.filter) {
      return;
    }
    this.queryParams = {
      ...this.queryParams,
      offset: DEFAULT_OFFSET,
      filter: name as Writing
    };
    this.tabs = this.tabs.map((tab) => ({
      ...tab,
      selected: tab.name === name
    }));
    return this.loadData(this.queryParams);
  }

  loadData(params: WritingQueryParams, shouldRefreshUrl = true) {
    switch (params.filter) {
      case Writing.Journal:
        return this.loadJournals(this.queryParams).subscribe((journalEntries) =>
          this.refreshJournals(journalEntries, shouldRefreshUrl)
        );
      case Writing.Stories:
        return this.loadPosts(this.queryParams).subscribe((posts) => this.refreshPosts(posts, shouldRefreshUrl));
    }
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadData(this.queryParams, false);
  }

  loadPosts({ offset }: WritingQueryParams) {
    this.loading = true;
    return this.myAccountBlogPostApiService.getAllByAuthorId({ limit: this.limit, offset }).pipe(
      catchError((err) => {
        this.loading = false;
        return throwError(err);
      })
    );
  }

  loadJournals({ offset }: WritingQueryParams) {
    this.loading = true;
    return this.journalEntryApiService
      .getAll({ range: { limit: this.limit, offset } })
      .pipe(finalize(() => (this.loading = false)));
  }

  refreshPosts(posts: BlogPost[], shouldRefreshUrl: boolean) {
    if (this.queryParams.offset === 0) {
      this.posts = [...(posts ?? [])];
    } else {
      this.posts.push(...(posts ?? []));
    }
    this.showLoadButton = !(posts?.length === 0 || posts?.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
    if (shouldRefreshUrl) {
      this.refreshUrlQueryParams(this.queryParams);
    }
  }

  refreshJournals(journalEntries: TripJournalEntry[], shouldRefreshUrl: boolean) {
    if (this.queryParams.offset === 0) {
      this.journalEntries = [...journalEntries];
    } else {
      this.journalEntries.push(...journalEntries);
    }
    this.showLoadButton = !(journalEntries.length === 0 || journalEntries.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
    if (shouldRefreshUrl) {
      this.refreshUrlQueryParams(this.queryParams);
    }
  }

  refreshUrlQueryParams({ offset, filter }: WritingQueryParams) {
    this.router
      .navigate([], {
        queryParams: { filter, offset },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
      .then(() => this.changeDetectorRef.detectChanges());
  }

  editStory(item: BlogPost) {
    this.router.navigate([this.getEditLink(item)], { relativeTo: this.route });
  }

  shareStory(item: BlogPost) {
    const url: string = this.getSharingUrl(item);

    this.dialogService.open(ShareDialogComponent, {
      data: {
        url: url || globalThis.location.href,
        text: item?.title,
        disabled: false
      },
      width: '300px'
    });
  }

  openStory(item: BlogPost) {
    this.router.navigate([this.getLink(item)], { relativeTo: this.route });
  }

  editJournal(item: TripJournalEntry) {
    this.dialogService
      .open(WritingJournalAddDialogComponent, {
        data: {
          journalEntry: item,
          mode: Mode.Edit,
          friends$: this.friends$
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.queryParams = {
          ...this.queryParams,
          offset: DEFAULT_OFFSET
        };
        this.loadData(this.queryParams);
      });
  }

  addWriting() {
    this.dialogService
      .open(AddToWritingsDialogComponent, { data: { friends$: this.friends$ } })
      .afterClosed()
      .pipe(
        take(1),
        filter((data) => Boolean(data?.reload))
      )
      .subscribe(() => {
        this.queryParams = {
          ...this.queryParams,
          offset: DEFAULT_OFFSET,
          // only memory has `reload` property - story is created on new page
          filter: Writing.Journal
        };
        this.loadData(this.queryParams);
      });
  }

  private getSharingUrl(item: BlogPost): string {
    // TODO: window fix for SSR, should be replaced with APP level injection.
    let location = undefined;
    if (typeof globalThis !== 'undefined' && globalThis?.location) {
      location = globalThis.location.origin;
    }
    const baseUrl = location || environment.webUrl;

    return entitySlugUrl(`${baseUrl}/stories/`, item);
  }
}
