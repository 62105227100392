import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ct-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareDialogComponent {
  public isDisabled: boolean;
  public linkControl: UntypedFormControl;

  public facebookSharingUrl: string;
  public twitterSharingUrl: string;
  public url: string;
  public text: string | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private elementRef: ElementRef) {
    this.text = this.data.text;
    this.url = this.data.url;
    this.isDisabled = this.data.disabled;
    this.linkControl = new UntypedFormControl(this.url);
    // TODO: create FB app & use https://developers.facebook.com/docs/sharing/reference/share-dialog instead
    this.facebookSharingUrl = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${this.url}`;
    this.twitterSharingUrl = `https://twitter.com/intent/tweet?${this.text ? 'text=' + this.text + '&' : ''}url=${
      this.url
    }`;
  }

  public openWindow(url: string): void {
    if (this.isDisabled) {
      return;
    }

    globalThis.open(url, '_blank', 'toolbar=0,status=0,resizable=1,width=626,height=436');
  }

  async copyLink() {
    if (this.isDisabled) {
      return;
    }
    try {
      await this.copyTextToClipboard(this.url);
    } catch (e) {
      console.error(e);
    }
  }

  selectAll() {
    this.elementRef.nativeElement.querySelector('input')?.setSelectionRange(0, this.url.length);
  }

  private copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      return this.fallbackCopyTextToClipboard(text);
    }
    return navigator.clipboard.writeText(text);
  }

  private fallbackCopyTextToClipboard(text: string) {
    return new Promise((resolve, reject) => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        resolve('Fallback: Copying text command was ' + msg);
      } catch (err) {
        reject('Fallback: Oops, unable to copy' + err);
      }

      document.body.removeChild(textArea);
    });
  }
}
