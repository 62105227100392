import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaTagService, Tag } from '@ct/core';
import { TagApiService, TagsQuery } from '@ct/shared';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ct-blog-posts-by-tag',
  templateUrl: './blog-posts-by-tag.component.html',
  styleUrls: ['./blog-posts-by-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostsByTagComponent implements OnInit, OnDestroy {
  public tag$ = this.query.selectActive() as Observable<Tag>;

  private destroyed$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private tagApiService: TagApiService,
    private query: TagsQuery,
    private metaTagService: MetaTagService
  ) {
    this.metaTagService.addTag({
      name: 'robots',
      content: 'noindex'
    });
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        takeUntil(this.destroyed$),
        map(({ tagId }) => tagId),
        filter(Boolean)
      )
      .subscribe((tagId) => {
        if (!this.query.getEntity(tagId)) {
          this.tagApiService.getById(tagId as string).subscribe();
        } else {
          this.tagApiService.setActive(tagId as string);
        }
      });
  }

  ngOnDestroy() {
    this.metaTagService.removeTag('name="robots"');
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
