import { DataListItem } from '@ct/components';

export interface SubCategory {
  id?: string;
  title?: string;
  slug?: string;
  description?: string;
  categoryId?: string;
  order?: number;
  createdOn?: Date;
  authorId?: string;
}

export class TreeSubCategory extends DataListItem implements SubCategory {
  id: string;
  title: string;
  description: string;
  createdOn: Date;
  authorId: string;
  categoryId: string;

  constructor(category: SubCategory) {
    super();
    this.id = category.id ?? '';
    this.title = category.title ?? '';
    this.description = category.description ?? '';
    this.createdOn = category.createdOn ?? new Date();
    this.authorId = category.authorId ?? '';
    this.categoryId = category.categoryId ?? '';
  }
}
