import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UserProfileApiService } from '@ct/auth';
import { BlogPostApiService, TagApiService, TripApiService, TripCampsiteApiService } from '@ct/shared';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarketplaceApiService } from '../../marketplace';
import { SearchResults } from '../interfaces';

const TAGS_LIMIT = 10;
const USER_LIMIT = 10;
const BLOG_LIMIT = 10;
const TRIPS_LIMIT = 10;
const CAMPSITES_LIMIT = 10;
const MARKETPLACE_LIMIT = 10;

@Injectable({ providedIn: 'root' })
export class SearchDataResolver implements Resolve<SearchResults> {
  constructor(
    private blogPostApiService: BlogPostApiService,
    private tagApiService: TagApiService,
    private userProfileApiService: UserProfileApiService,
    private tripApiService: TripApiService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private marketplaceApiService: MarketplaceApiService
  ) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const query = activatedRouteSnapshot.queryParams?.query;
    return this.getSidebarData(query);
  }

  private getSidebarData(query: string): Observable<SearchResults> {
    return forkJoin([
      this.blogPostApiService.search(query, { limit: BLOG_LIMIT, offset: 0 }, 'search'),
      this.tagApiService.search(query, { limit: TAGS_LIMIT, offset: 0 }),
      this.userProfileApiService.search(query, { limit: USER_LIMIT, offset: 0 }),
      this.tripApiService.search(query, { limit: TRIPS_LIMIT, offset: 0 }),
      this.tripCampsiteApiService.search(query, { limit: CAMPSITES_LIMIT, offset: 0 }),
      this.marketplaceApiService.search({
        search: query,
        type: 'search',
        range: { limit: MARKETPLACE_LIMIT, offset: 0 }
      })
    ]).pipe(
      map(([posts, tags, users, trips, campsites, items]) => {
        return {
          tags: tags,
          posts: posts,
          users: users,
          trips: trips,
          campsites: campsites,
          marketplaceItems: items.results
        };
      })
    );
  }
}
