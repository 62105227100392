<ng-container *ngIf="channels?.length; else noUsers">
  <div
    class="px-4 px-md-5 py-4 users-list"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    <h4>Channels List</h4>
    <ng-container *ngFor="let entity of channels; trackBy: trackByFn">
      <ct-channel-row class="user col-12" [channel]="entity" [clickable]="true" (channelClick)="onChannelClick(entity)">
      </ct-channel-row>
    </ng-container>
    <ng-container *ngIf="!channels?.length">
      <ng-container *ngTemplateOutlet="noUsers"></ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</ng-container>

<ng-template #noUsers>
  <ct-empty-call-to-action [hasButton]="false" emptyMessageTextKey="No channels available"></ct-empty-call-to-action>
</ng-template>
