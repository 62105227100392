import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconType } from '../icon';

@Component({
  selector: 'ct-empty-call-to-action',
  templateUrl: './empty-call-to-action.component.html',
  styleUrls: ['./empty-call-to-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyCallToActionComponent {
  @Input() readonly hasButton: boolean = true;
  @Input() readonly buttonIcon: IconType;
  @Input() readonly buttonTextKey: string;
  @Input() readonly emptyMessageTextKey: string;
  @Output() readonly buttonClick: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClick.emit();
  }
}
