import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UserIdentity } from '@ct/auth';
import { AuthService } from '@ct/auth/services/auth.service';
import { ButtonBase } from '@ct/components/button';
import { DialogService } from '@ct/components/dialog';
import { NotificationWithAuthor, UserProfile } from '@ct/core/interfaces';
import { AddToTripDialogComponent, Trip, TripApiService, TripEntityType, TripQuery } from '@ct/shared';
import { of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { SidebarItem } from './interfaces';

@Component({
  selector: 'ct-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements AfterViewInit, OnDestroy {
  static visibleFullList = true;
  visibleFullList = LayoutComponent.visibleFullList;

  @ViewChild(MatSidenavContainer, { static: false }) sidenavContainer: MatSidenavContainer;

  @ViewChild(MatSidenav, { static: false }) set sidenav(item: MatSidenav) {
    this.#sidenav = item;
    if (this.mobileQuery.matches) {
      this.#sidenav?.close();
    } else {
      this.#sidenav?.open();
    }
  }

  @Input()
  user: UserProfile | null;

  @Input() notifications: NotificationWithAuthor[] | null;
  @Input() protected showSearch = true;

  @Input()
  accountMenuItems: SidebarItem[] = [
    {
      icon: 'explore',
      route: ['/my-account/explore'],
      titleKey: 'Explore'
    },
    {
      icon: 'dashboard',
      route: ['/my-account/dashboard'],
      titleKey: 'Dashboard'
    },
    {
      icon: 'cabin',
      route: ['/my-account/trips'],
      titleKey: 'Trips'
    },
    {
      icon: 'book',
      route: ['/my-account/writings'],
      titleKey: 'Writings'
    },
    {
      icon: 'article',
      route: ['/my-account/groups'],
      titleKey: 'Groups'
    },
    {
      icon: 'collections',
      route: ['/my-account/media/photos'],
      titleKey: 'Media'
    }
  ];

  @Input()
  generalMenuItems: SidebarItem[] = [
    {
      icon: 'article',
      route: ['/stories'],
      titleKey: 'Stories'
    },
    {
      icon: 'shopping-cart',
      route: ['/marketplace/community'],
      titleKey: 'Marketplace'
    },
    // {
    //   icon: 'video-camera',
    //   route: ['/channels'],
    //   titleKey: 'Channels'
    // }
  ];

  userButtons: ButtonBase[] = [
    {
      labelKey: 'MY_ACCOUNT.MY_PROFILE',
      variant: 'flat',
      clicked: () => this.router.navigate(['/my-account/profile'])
    },
    {
      labelKey: 'MY_ACCOUNT.MY_MERCHANT_PROFILE',
      variant: 'flat',
      clicked: () => this.router.navigate(['/my-account/marketplace/profile'])
    }
  ];

  #sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  headerMenuCollapsed = true;
  trip: Trip;
  readonly headerHeight = 100;

  private readonly destroyed$ = new Subject<void>();
  private _mobileQueryListener: () => void;

  constructor(
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private tripQuery: TripQuery,
    private dialogService: DialogService,
    private authService: AuthService,
    private tripApiService: TripApiService
  ) {
    this.getTrip()
      .pipe(filter(Boolean))
      .subscribe((trip) => {
        this.trip = trip as Trip;
        this.changeDetectorRef.markForCheck();
      });
    this.mobileQuery = this.media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => {
      if (!this.mobileQuery.matches) {
        this.#sidenav?.open();
      }
      this.changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addEventListener?.('change', this._mobileQueryListener);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        if (this.mobileQuery.matches) {
          this.#sidenav?.close();
        }
        this.closeHeaderMenu();
      });
  }

  ngAfterViewInit() {
    if (this.mobileQuery.matches) {
      this.#sidenav?.close();
    } else {
      this.#sidenav?.open();
      // FIXME: layout flickering when navigate from one page to another
      setTimeout(() => {
        this.sidenavContainer.updateContentMargins();

        setTimeout(() => {
          // Fix for layout shift
          this.sidenavContainer.updateContentMargins();
        }, 100);
      }, 100);
    }
  }

  addToTripDialog() {
    this.dialogService
      .open(AddToTripDialogComponent, { data: this.trip })
      .afterClosed()
      .pipe(
        take(1),
        filter((options) => options && Boolean(options.reload))
      )
      .subscribe((options) => {
        if (options.navigateTo) {
          switch (options.navigateTo) {
            case TripEntityType.Video:
              this.router.navigate(['my-account/trips', this.trip.id, 'media']);
              break;
            case TripEntityType.Campsite:
              this.router.navigate(['my-account/trips', this.trip.id, 'campsites']);
              break;
            case TripEntityType.Journal:
              this.router.navigate(['my-account/trips', this.trip.id, 'journals']);
              break;
            default:
              this.router.navigate(['my-account/trips', this.trip.id, 'timeline']);
          }
        } else {
          this.router.navigate(['my-account/trips', this.trip.id, 'timeline']);
        }
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleList() {
    this.visibleFullList = !this.visibleFullList;
    LayoutComponent.visibleFullList = this.visibleFullList;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.sidenavContainer.updateContentMargins();
    }, 100);
  }

  onLogout() {
    this.authService.logout().subscribe(() => {
      this.closeHeaderMenu();
      this.authService.redirectToLogin();
    });
  }

  closeHeaderMenu() {
    if (!this.headerMenuCollapsed) {
      this.headerMenuCollapsed = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  toggleHeaderMenu(event: MouseEvent) {
    event.stopPropagation();
    this.headerMenuCollapsed = !this.headerMenuCollapsed;
    this.changeDetectorRef.markForCheck();
  }

  private getTrip() {
    return this.tripQuery.selectActive().pipe(
      switchMap((trip) => {
        if (trip) {
          return of(trip);
        }

        return this.authService.getIdentity().pipe(
          filter((identity) => Boolean(identity)),
          switchMap((identity: UserIdentity | null) =>
            this.tripApiService.getAllByAuthorId(identity?.guuid as string, {
              range: {
                limit: 1,
                offset: 0
              }
            })
          ),
          map((trips) => trips[0])
        );
      }),
      takeUntil(this.destroyed$)
    );
  }
}
