import { Injectable } from '@angular/core';
import { BlogPost } from '@ct/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface PostsState {
  posts: BlogPost[];
}

export function createInitialState(): PostsState {
  return {
    posts: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'posts' })
export class PostsStore extends Store<PostsState> {
  constructor() {
    super(createInitialState());
  }
}
