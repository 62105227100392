<ng-container *ngIf="!simple && (isLoggedIn$ | async); else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div [class.container]="!simple">
    <div class="row">
      <div class="col-12">
        <h1 *ngIf="!simple" class="text-center">{{ 'COMMON.LEGAL.LEGAL_NOTICES.TITLE' | translate }}</h1>
        <div [innerHTML]="'COMMON.LEGAL.LEGAL_NOTICES.MAIN_TEXT' | translate"></div>
      </div>
    </div>
  </div>
</ng-template>
