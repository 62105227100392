import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@ct/core/pipes';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { TextEditorModule } from '../text-editor';
import { UserAvatarModule } from '../user-avatar';
import { EntityCommentComponent } from './entity-comment';
import { EntityCommentsComponent } from './entity-comments';
import { EntityItemComponent } from './entity-item';
import { EntityItemNewComponent } from './entity-item-new';
import { EntityThreadComponent } from './entity-thread';

const COMPONENTS = [
  EntityCommentsComponent,
  EntityCommentComponent,
  EntityItemComponent,
  EntityItemNewComponent,
  EntityThreadComponent
];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    IconModule,
    UserAvatarModule,
    TextEditorModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [...COMPONENTS]
})
export class EntityCommentsModule {}
