import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { CanonicalFeature, Features, SeoConfig, TitleFeature, UserProfile } from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature(), CanonicalFeature()])
export class TermsAndConditionsComponent {
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'COMMON.LEGAL.TERMS_AND_CONDITIONS.TITLE'
    },
    canonicalConfig: {
      canonicalUrl: '/terms-and-conditions'
    }
  };

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public readonly simple = this.route.snapshot.data.simple;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private route: ActivatedRoute,
    private notificationQuery: NotificationQuery
  ) {}
}
