<div
  class="placeholder"
  [class.disabled]="disabled"
  ctFileDnd
  [style.height]="height"
  [style.width]="width"
  (fileDropped)="onFilesDropped($event)"
  (click)="onFilesSelected()"
>
  <p class="placeholder__text">
    {{ title || ('MY_ACCOUNT.MY_PHOTOS_FEATURE.ADD_MEDIA' | translate) }}
  </p>
  <input
    [hidden]="true"
    style="display: none"
    type="file"
    name="file-upload"
    [multiple]="multiple"
    [accept]="accept"
    #fileUpload
  />
</div>
