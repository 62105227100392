import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MARKETPLACE_ITEMS_LIMIT } from '../../../../marketplace/constants';
import { MarketplaceItem } from '../../../../marketplace/interfaces';
import { MarketplaceApiService } from '../../../../marketplace/services';

@Injectable({ providedIn: 'root' })
export class MyAccountMarketplaceItemsDataResolver implements Resolve<MarketplaceItem[]> {
  constructor(private marketplaceApiService: MarketplaceApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.marketplaceApiService.getAllMyItems({
      range: { limit: MARKETPLACE_ITEMS_LIMIT, offset }
    });
  }
}
