import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@ct/core';
import { MyAccountPhotoApiService, YoutubeVideoApiService } from '@ct/shared';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarketplaceApiService } from '../../marketplace';
import {
  MY_ACCOUNT_PHOTOS_OVERVIEW_LIMIT,
  MY_ACCOUNT_POSTS_SIDEBAR_LIMIT,
  MY_ACCOUNT_TRIPS_OVERVIEW_LIMIT
} from '../constants';
import { MyAccountOverviewData } from '../interfaces';
import { MyAccountBlogPostApiService } from '../modules/my-account-writings/services';
import { MyAccountTripApiService } from './../modules/my-account-trips';
import { UserRelationsApiService } from './user-relations-api.service';

const endpoint = '';

@Injectable({ providedIn: 'root' })
export class MyAccountApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private myAccountBlogPostApiService: MyAccountBlogPostApiService,
    private userRelationsApiService: UserRelationsApiService,
    private myAccountTripApiService: MyAccountTripApiService,
    private youtubeVideoApiService: YoutubeVideoApiService,
    private marketplaceApiService: MarketplaceApiService
  ) {
    super(httpClient, endpoint);
  }

  getOverviewData(): Observable<MyAccountOverviewData> {
    return forkJoin([
      this.myAccountPhotoApiService.getAllMyPhotos({ range: { limit: MY_ACCOUNT_PHOTOS_OVERVIEW_LIMIT } }),
      this.myAccountBlogPostApiService.getAllByAuthorId({ limit: MY_ACCOUNT_POSTS_SIDEBAR_LIMIT }),
      this.myAccountTripApiService.getAllMyTrips({ limit: MY_ACCOUNT_TRIPS_OVERVIEW_LIMIT }),
      this.userRelationsApiService.getAllMyFriendsApi(),
      this.youtubeVideoApiService.getAllMyVideos(),
      this.marketplaceApiService.getAllMyItems({ sold: false })
    ]).pipe(
      map(([photos, posts, trips, friends, videos, marketplace]) => ({
        photos,
        trips,
        friends,
        videos,
        marketplace,
        posts: posts.map((post) => ({ ...post, photos }))
      }))
    );
  }
}
