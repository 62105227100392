import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DataListColumn, DataListItem } from '../data-list.interfaces';

@Component({
  selector: 'ct-data-list-row',
  templateUrl: './data-list-row.component.html',
  styleUrls: ['./data-list-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataListRowComponent {
  @Input() public listItem: DataListItem;
  @Input() public column: DataListColumn;
  @Input() public isSelected: boolean;

  get displayText(): string {
    return (this.listItem as any)[this.column.field] as string;
  }
}
