import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ImageModule } from '../image';
import { LinkModule } from './../link';
import { CardWrapperComponent } from './card-wrapper.component';

@NgModule({
  declarations: [CardWrapperComponent],
  imports: [CommonModule, LinkModule, ImageModule, TranslateModule],
  exports: [CardWrapperComponent]
})
export class CardWrapperModule {}
