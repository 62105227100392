import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconType } from '@ct/components/icon';

@Component({
  selector: 'ct-extended-header',
  templateUrl: './extended-header.component.html',
  styleUrls: ['./extended-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendedHeaderComponent {
  @Input() icon: IconType = 'article';
  @Input() title: string;
  @Input() description: string;
}
