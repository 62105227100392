import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from '@ct/components';
import {
  CanonicalFeature,
  CustomValidators,
  DestroyableFeature,
  EMAIL_MAX_LENGTH,
  FacebookCardType,
  Features,
  FormStateDispatcher,
  NAME_MAX_LENGTH,
  PROFILE_DESCRIPTION_MAX_LENGTH,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  USERNAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH
} from '@ct/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { UserIdentity } from '../../interfaces';
import { AuthApiService, UserProfileApiService } from '../../services';

@Component({
  selector: 'ct-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature()])
export class RegisterComponent {
  public readonly destroyed$: Observable<void>;
  public readonly seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.REGISTER'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.REGISTER',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/register.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/register'
    }
  };

  public form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.maxLength(NAME_MAX_LENGTH)]),
    lastName: new UntypedFormControl('', [Validators.maxLength(NAME_MAX_LENGTH)]),
    emailAddress: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(EMAIL_MAX_LENGTH)
    ]),
    profileName: new UntypedFormControl(
      '',
      [
        Validators.required,
        Validators.minLength(USERNAME_MIN_LENGTH),
        Validators.maxLength(USERNAME_MAX_LENGTH),
        CustomValidators.username
      ],
      this.usernameUniqueValidator()
    ),
    profileDescription: new UntypedFormControl('', [Validators.maxLength(PROFILE_DESCRIPTION_MAX_LENGTH)]),
    passwords: new UntypedFormControl(),
    acceptToC: new UntypedFormControl(false, Validators.requiredTrue),
    captcha: new UntypedFormControl(null, [Validators.required])
  });

  disableActions = false;

  constructor(
    private router: Router,
    private formState: FormStateDispatcher,
    private authApiService: AuthApiService,
    private userProfileApiService: UserProfileApiService,
    private spinnerService: SpinnerService
  ) {}

  onRegister() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.spinnerService.show();
    this.disableActions = true;

    const { firstName, lastName, emailAddress, profileName, profileDescription, passwords, captcha } = this.form.value;

    this.authApiService
      .register(
        {
          firstName: firstName || null,
          lastName: lastName || null,
          emailAddress,
          password: passwords?.password
        },
        captcha
      )
      .pipe(
        switchMap((user: UserIdentity) => {
          return this.userProfileApiService
            .create({ userId: user.guuid, firstName, lastName, username: profileName, description: profileDescription })
            .pipe(map((profile) => [user, profile]));
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.disableActions = false;
        })
      )
      .subscribe(
        ([user]) =>
          this.router.navigate(['verify'], {
            queryParams: { userId: user?.guuid, username: user?.username }
          }),
        (error) => {
          if (error?.status === 409) {
            this.form.controls.emailAddress.setErrors({ emailAlreadyInUse: true });
          }

          this.form.get('captcha')?.patchValue(null);
        }
      );
  }

  usernameUniqueValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const value = control.value;
      const debounceTime = 1000;
      return timer(debounceTime).pipe(
        switchMap(() =>
          this.userProfileApiService.getByUsername(value).pipe(
            map(() => ({ usernameAlreadyInUse: true })),
            catchError(() => of({}))
          )
        )
      );
    };
  }
}
