import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ct/components/button';
import { CardModule } from '@ct/components/card';
import { CheckboxModule } from '@ct/components/checkbox';
import { CountDownModule } from '@ct/components/count-down';
import { ImageBannerModule } from '@ct/components/image-banner';
import { InputModule } from '@ct/components/input';
import { LinkModule } from '@ct/components/link';
import { PasswordModule } from '@ct/components/password';
import { ReCaptchaModule } from '@ct/components/re-captcha';
import { TextareaModule } from '@ct/components/textarea';
import { TranslateModule } from '@ngx-translate/core';

import { ForgotPasswordComponent, LoginComponent, RegisterComponent, VerifyComponent } from './components';
import { AccessTokenInterceptor } from './interceptors';
import { AuthService } from './services';

const INTERCEPTORS: any[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AccessTokenInterceptor,
    multi: true,
    deps: [AuthService]
  }
];

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotPasswordComponent, VerifyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    InputModule,
    CheckboxModule,
    TextareaModule,
    ButtonModule,
    RouterModule,
    ReactiveFormsModule,
    ImageBannerModule,
    CardModule,
    PasswordModule,
    CountDownModule,
    LinkModule,
    MatSnackBarModule,
    ReCaptchaModule.forFeature()
  ]
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [...INTERCEPTORS]
    };
  }
}
