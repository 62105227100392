<p *ngIf="disabled">Preparing video, please wait.</p>
<div class="video-list" [class.video-list--disabled]="disabled">
  <div class="video-list__thumbnail" (click)="onPlayVideo()">
    <ct-progressive-image
      *ngIf="video.transcodingJobStatus === transcodingState.SUCCEEDED && video.thumbnailUrl"
      [publicUrl]="video.thumbnailUrl"
      width="256px"
      height="144px"
      objectFit="contain"
    />
    <ct-icon size="fit" [icon]="'circle-play'" class="play-icon"></ct-icon>
  </div>
  <p class="video-list__name" (click)="onPlayVideo()">
    {{ video.name }}
  </p>
  <ct-icon *ngIf="removable" class="video-list__remove" icon="remove" color="warn" (click)="onDeleteVideo()"></ct-icon>
</div>
