import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components';

@Component({
  selector: 'ct-download-media-info',
  templateUrl: './download-media-info.component.html',
  styleUrls: ['./download-media-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadMediaInfoComponent {
  buttons: DialogButton[] = [
    {
      labelKey: 'Got It!',
      color: 'primary',
      clicked: () => this.dialogRef.close()
    }
  ];

  constructor(private dialogRef: MatDialogRef<DownloadMediaInfoComponent>) {}
}
