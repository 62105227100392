import { ESCAPE, LEFT_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Inject, Injectable, Optional } from '@angular/core';
import { Subject } from 'rxjs';

import { GalleryConfig, GalleryItem, GalleryService } from '../../gallery';
import { LightboxComponent } from './../components';
import { defaultConfig } from './../constants';
import { LightboxConfig } from './../interfaces';
import { LIGHTBOX_CONFIG } from './../tokens';

@Injectable()
export class LightboxService {
  public opened = new Subject<string>();
  public closed = new Subject<string>();

  private overlayRef: OverlayRef;
  private config: LightboxConfig;

  constructor(
    @Optional() @Inject(LIGHTBOX_CONFIG) config: LightboxConfig,
    private galleryService: GalleryService,
    private overlay: Overlay
  ) {
    this.config = config ? { ...defaultConfig, ...config } : defaultConfig;
  }

  setConfig(config: LightboxConfig) {
    this.config = { ...this.config, ...config };
  }

  open(i = 0, id = 'lightbox', config?: LightboxConfig, galleryConfig?: GalleryConfig, items?: GalleryItem[]) {
    const _config = config ? { ...this.config, ...config } : this.config;

    const overlayConfig: OverlayConfig = {
      backdropClass: _config.backdropClass,
      panelClass: _config.panelClass,
      hasBackdrop: _config.hasBackdrop,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      disposeOnNavigation: true
    };

    const galleryRef = this.galleryService.ref(id, galleryConfig);
    galleryRef.set(i);

    if (items?.length) {
      galleryRef.load(items);
    }

    this.overlayRef = this.overlay.create(overlayConfig);

    // overlay opened event
    this.overlayRef.attachments().subscribe(() => this.opened.next(id));

    // overlay closed event
    this.overlayRef.detachments().subscribe(() => this.closed.next(id));

    // Attach gallery to the overlay
    const galleryPortal = new ComponentPortal(LightboxComponent);
    const lightboxRef: ComponentRef<LightboxComponent> = this.overlayRef.attach(galleryPortal);

    lightboxRef.instance.id = id;
    lightboxRef.instance.overlayRef = this.overlayRef;
    lightboxRef.instance.galleryRef = galleryRef;
    lightboxRef.instance.editable = config?.editable as boolean;
    lightboxRef.instance.role = this.config.role as string;
    lightboxRef.instance.ariaLabel = this.config.ariaLabel as string;
    lightboxRef.instance.ariaLabelledBy = this.config.ariaLabelledBy as string;
    lightboxRef.instance.ariaDescribedBy = this.config.ariaDescribedBy as string;
    lightboxRef.instance.startAnimationTime = this.config.startAnimationTime as number;
    lightboxRef.instance.exitAnimationTime = this.config.exitAnimationTime as number;

    if (_config.hasBackdrop) {
      this.overlayRef.backdropClick().subscribe(() => this.close());
    }

    // Add keyboard shortcuts
    if (_config.keyboardShortcuts) {
      this.overlayRef.keydownEvents().subscribe((event: any) => {
        switch (event.keyCode) {
          case LEFT_ARROW:
            galleryRef.prev();
            break;
          case RIGHT_ARROW:
            galleryRef.next();
            break;
          case ESCAPE:
            this.close();
        }
      });
    }

    return lightboxRef.instance.action;
  }

  close() {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
