import { MatDialogConfig } from '@angular/material/dialog';

export const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  disableClose: true,
  autoFocus: false,
  panelClass: ['ct-dialog'],
  closeOnNavigation: true,
  hasBackdrop: true,
  position: {
    top: '5%'
  },
  width: '50%',
  minWidth: '300px'
};
