import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon/icon.module';
import { VideoComponent } from './video.component';

@NgModule({
  declarations: [VideoComponent],
  imports: [CommonModule, IconModule],
  exports: [VideoComponent]
})
export class VideoModule {}
