import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoChannelEntity } from '@ct/core';
import { getCroppedThumbPublicUrl } from '@ct/shared/helpers';

@Component({
  selector: 'ct-channel-row',
  templateUrl: './channel-row.component.html',
  styleUrls: ['./channel-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelRowComponent {
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  @Input() channel: VideoChannelEntity;
  @Input() clickable: boolean;
  @Output() channelClick: EventEmitter<void> = new EventEmitter<void>();
}
