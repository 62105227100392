import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BlogPost, BlogPostStatus } from '@ct/core';
import { getYoutubeId } from '@ct/shared';

const ADMIN_STATUSES = new Set([BlogPostStatus.WaitingApproval, BlogPostStatus.Suspended]);

@Component({
  selector: 'ct-blog-post-list-item',
  templateUrl: './blog-post-list-item.component.html',
  styleUrls: ['./blog-post-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostListItemComponent {
  @Input() showLink = false;
  @Input() showStatus = false;
  @Input() showFlags = false;
  @Input() showAuthor = true;
  @Input() showTags = true;
  @Input() imgHeight: string;
  @Input() imgWidth: string;
  @Input() objectFit = 'cover';
  @Input() post: BlogPost | undefined;
  @Input() link: string;
  @Input() imgCols = 3;
  @Input() contentCols = 9;
  @Input() contentMargin: boolean;
  @Input() linkFullWidth: boolean;

  @Output() clickFlag = new EventEmitter<void>();

  get status(): BlogPostStatus {
    const isInReview = !ADMIN_STATUSES.has(this.post?.status as BlogPostStatus) && (this.post?.flags as number) > 0;
    return isInReview ? BlogPostStatus.FlaggedForReview : (this.post?.status as BlogPostStatus);
  }

  get placeholderPath(): string {
    let index = 0;
    if (this.post?.id) {
      index = Number(this.post.id) % 4;
    }
    return `assets/placeholders/camping_bg_${index}.jpeg`;
  }

  get thumbnail(): string | undefined {
    return this.post?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.post?.featuredYoutubeVideo)}/0.jpg`
      : this.post?.featuredPhoto?.xsPreview;
  }

  get featured(): string | undefined {
    return this.post?.featuredPhoto?.path;
  }

  get publicUrl(): string | undefined {
    return this.post?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.post?.featuredYoutubeVideo)}/hqdefault.jpg`
      : this.post?.featuredPhoto?.publicUrl;
  }

  get isHorizontal() {
    return this.imgCols === 12;
  }

  get color(): ThemePalette {
    switch (this.post?.status) {
      case BlogPostStatus.Published:
        return 'primary';
      case BlogPostStatus.FlaggedForReview:
        return 'warn';
      case BlogPostStatus.Draft:
        return 'accent';
    }
  }
}
