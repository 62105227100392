<ct-card-section class="mb-5" [title]="'MY_ACCOUNT.MY_PROFILE' | translate">
  <div class="row">
    <ct-my-account-header class="col-12"></ct-my-account-header>
  </div>
  <div class="row">
    <ct-textarea
      class="col-12"
      [label]="'MY_ACCOUNT.MY_PROFILE_FEATURE.PROFILE_DESCRIPTION' | translate"
      [placeholder]="'MY_ACCOUNT.MY_PROFILE_FEATURE.PROFILE_DESCRIPTION_PLACEHOLDER' | translate"
      [formControl]="descriptionControl"
    ></ct-textarea>
  </div>
  <div class="row">
    <ct-button class="col-12" variant="flat" color="accent" (click)="onSaveSettings()">{{
      'MY_ACCOUNT.MY_PROFILE_FEATURE.SAVE_SETTINGS' | translate
    }}</ct-button>
  </div>
</ct-card-section>
<ct-card-section class="mb-5" [title]="'Download All Media' | translate">
  <div class="row"></div>
  <div class="row">
    <div class="col-12 d-flex">
      <ct-button class="pr-4" variant="flat" color="accent" [disabled]="archiving" (click)="onStartDownload()">{{
        'Start Download' | translate
      }}</ct-button>
      <ct-link [link]="['./download-media']">
        <ct-button variant="flat" color="primary">{{ 'View Downloads' | translate }}</ct-button>
      </ct-link>
    </div>
  </div>
</ct-card-section>
<ct-card-section [title]="'MY_ACCOUNT.MY_PROFILE_FEATURE.ACCOUNT_SETTINGS' | translate">
  <div class="row no-gutters">
    <ct-input
      type="email"
      [label]="'MY_ACCOUNT.MY_PROFILE_FEATURE.EMAIL_ADDRESS' | translate"
      class="col-12 col-md-4 pr-3"
      [formControl]="emailAddressControl"
    ></ct-input>
    <ct-link class="col-12 col-md-3" [link]="['./change-email']">
      <ct-button class="change-button" variant="flat" color="primary" [fullWidth]="true">{{
        'MY_ACCOUNT.MY_PROFILE_FEATURE.CHANGE_EMAIL' | translate
      }}</ct-button>
    </ct-link>
  </div>
  <div class="row no-gutters">
    <ct-input
      class="col-12 col-md-4 pr-3"
      [formControl]="passwordControl"
      [label]="'MY_ACCOUNT.MY_PROFILE_FEATURE.PASSWORD' | translate"
      [type]="'password'"
    ></ct-input>
    <ct-link class="col-12 col-md-3" [link]="['./change-password']">
      <ct-button class="change-button" variant="flat" color="primary" [fullWidth]="true">{{
        'MY_ACCOUNT.MY_PROFILE_FEATURE.CHANGE_PASSWORD' | translate
      }}</ct-button>
    </ct-link>
  </div>
</ct-card-section>
<ct-card-section [title]="'MY_ACCOUNT.MY_PROFILE_FEATURE.EMAIL_NOTIFICATIONS_SETTINGS' | translate">
  <div class="row no-gutters" [formGroup]="notificationsForm">
    <ct-checkbox class="col-12" formControlName="blogPostComments">{{
      'MY_ACCOUNT.MY_PROFILE_FEATURE.SEND_ON_BLOG_COMMENT' | translate
    }}</ct-checkbox>
    <ct-checkbox class="col-12" formControlName="threadReplies">{{
      'MY_ACCOUNT.MY_PROFILE_FEATURE.SEND_ON_THREAD_REPLY' | translate
    }}</ct-checkbox>
    <ct-button class="col-12 mt-4" variant="flat" color="accent" (click)="onSaveNotificationSettings()">{{
      'MY_ACCOUNT.MY_PROFILE_FEATURE.SAVE_NOTIFICATION_SETTINGS' | translate
    }}</ct-button>
  </div>
</ct-card-section>
