import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { IconService } from '../../services';
import { IconType } from './icon.types';

@Component({
  selector: 'ct-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges, OnDestroy {
  @Input() readonly icon: IconType;
  @Input() readonly variant: 'small' | 'medium' | 'large';
  @Input() readonly color: ThemePalette | string;
  @Input() readonly size: 'auto' | 'fit' | null;
  @Input() @HostBinding('attr.disabled') readonly disabled: boolean | undefined;

  private readonly iconFound$ = new Subject<boolean>();

  constructor(private readonly iconService: IconService) {}

  ngOnChanges(): void {
    if (this.icon) {
      this.iconService
        .getIcon(this.icon)
        .pipe(
          takeUntil(this.iconFound$),
          filter((e) => e.id === this.icon)
        )
        .subscribe(() => {
          this.setIconLoaded();
        });
    }
  }

  ngOnDestroy(): void {
    this.setIconLoaded();
  }

  private setIconLoaded(): void {
    this.iconFound$.next(true);
    this.iconFound$.complete();
  }
}
