<ct-progressive-image
  class="photo"
  [height]="height"
  [width]="width"
  [publicUrl]="photo?.publicUrl"
  [thumbnail]="photo?.xsPreview"
  [altText]="altText"
>
</ct-progressive-image>
<div class="action-container">
  <ct-icon
    *ngIf="multiselect"
    class="checkbox"
    [icon]="photo?.selected ? 'checkbox-checked' : 'checkbox'"
    (click)="onSelectionChanged($event)"
  ></ct-icon>
  <ct-icon
    *ngIf="showAccess && !multiselect"
    class="access"
    [icon]="photo?.isPrivate ? 'visibility-off' : 'visibility'"
    (click)="onAccessChanged($event)"
  ></ct-icon>
  <ct-icon *ngIf="removable && !multiselect" class="remove" icon="remove" (click)="removed.emit()"></ct-icon>
</div>
