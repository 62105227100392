import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ct-image-carousel-item',
  templateUrl: './image-carousel-item.component.html',
  styleUrls: ['./image-carousel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCarouselItemComponent {
  @Input() public readonly src: string | undefined;
  @ViewChild('content') public content: TemplateRef<any>;
}
