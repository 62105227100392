import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CanonicalConfig, CanonicalFeature, Features } from '@ct/core';

@Component({
  selector: 'ct-blog-posts-all',
  templateUrl: './blog-posts-all.component.html',
  styleUrls: ['./blog-posts-all.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([CanonicalFeature()])
export class BlogPostsAllComponent {
  currentDate = new Date();
  public canonicalConfig: CanonicalConfig = {
    canonicalUrl: '/stories'
  };
}
