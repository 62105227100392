import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from '@ct/auth';
import { UserProfile } from '@ct/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { UserRelationsApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class FriendsListResolver implements Resolve<UserProfile[]> {
  constructor(private userRelationsApiService: UserRelationsApiService, private authService: AuthService) {}

  resolve() {
    return this.getFriendsList();
  }

  private getFriendsList(): Observable<UserProfile[]> {
    return this.authService.getUserProfile().pipe(
      take(1),
      switchMap(() => this.userRelationsApiService.getAllMyFriendsApi())
    );
  }
}
