import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { MyAccountTripApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MyAccountTripsAvailableResolver implements Resolve<boolean> {
  constructor(private myAccountTripApiService: MyAccountTripApiService) {}

  resolve() {
    return this.myAccountTripApiService.getTripsAvailable();
  }
}
