import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CdkTreeNode, FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';

import { DataListApi, DataListColumn, DataListItem } from './data-list.interfaces';
import { TreeFlatDataSource, TreeFlattener } from './flat-data-source';

@Component({
  selector: 'ct-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public selectedItem: DataListItem | null;
  @Input() public data: DataListItem[];
  @Input() public column: DataListColumn;
  @Input() public scrollTo: number;
  @Input() public expanded: boolean;
  @Output() public selectedItemChange: EventEmitter<DataListItem | null> = new EventEmitter();
  @Output() public dataListReady: EventEmitter<DataListApi> = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport) public virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('dataListContentWrapper') public dataListWrapperTemplate: any;
  @ViewChildren(CdkTreeNode, { read: ElementRef }) public cdkTreeNodes: QueryList<ElementRef>;

  public treeControl: FlatTreeControl<DataListItem>;
  public dataSource: TreeFlatDataSource<DataListItem, DataListItem>;
  public leftTreePadding = 20;

  public fullDatasource: any;

  public dataListApi: DataListApi;

  public listSelection: SelectionModel<DataListItem | null> = new SelectionModel<DataListItem | null>(false);

  private levels: Map<DataListItem, number> = new Map<DataListItem, number>();
  private readonly treeFlattener: TreeFlattener<DataListItem, DataListItem>;
  private itemHeight = 24;

  constructor() {
    this.treeFlattener = new TreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);

    this.treeControl = new FlatTreeControl<DataListItem>(this.getLevel, this.isExpandable);

    this.dataSource = new TreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  public ngOnInit(): void {
    if (this.data && this.data.length > 0) {
      this.fullDatasource = [...this.data];
    }

    if (this.dataSource.data) {
      this.checkAndExpandNodes();
    }

    this.dataListApi = {
      treeControl: this.treeControl,
      dataSource: this.dataSource,
      treeFlattener: this.treeFlattener,
      listSelection: this.listSelection,
      findById: this.findById
    };

    this.dataListReady.emit(this.dataListApi);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue !== changes.data?.previousValue) {
      this.dataSource.data = [];
      this.dataSource.data = changes.data?.currentValue || [];
      this.checkAndExpandNodes();
    }

    if (changes.selectedItem?.previousValue !== changes.selectedItem?.currentValue) {
      this.selectNode(changes.selectedItem?.currentValue, true);
    }

    if (changes.scrollTo && Boolean(this.scrollTo)) {
      this.dataListWrapperTemplate.nativeElement.nextSibling.scrollTop = this.itemHeight * this.scrollTo;
      this.selectNode(this.dataSource.data[this.scrollTo]);
    }
  }

  public ngAfterViewInit(): void {
    if (this.virtualScroll) {
      this.virtualScroll.renderedRangeStream.subscribe((range: any) => {
        this.dataSource.data = [...this.fullDatasource.slice(range.start, range.end)];
      });
    }
  }

  public hasChildren(index: number, listItem: DataListItem): boolean {
    return Boolean(listItem.children && listItem.children.length > 0);
  }

  public onSelectionChange(listItem: DataListItem | null): void {
    this.selectedItemChange.emit(listItem);
  }

  public getAllSelectedDescendants(listItem: DataListItem): boolean {
    return (
      this.listSelection.isSelected(listItem) ||
      Boolean(listItem?.children?.some((child) => this.listSelection.isSelected(child)))
    );
  }

  public selectNode(listItem: DataListItem | null, initial = false): void {
    if (listItem?.disabled) {
      return;
    }
    if (!initial) {
      this.onSelectionChange(listItem);
    }
    if (this.selectedItem || Object.prototype.hasOwnProperty.call(this, 'selectedItem')) {
      this.listSelection.clear();
      this.listSelection.select(listItem);
    } else {
      this.listSelection.clear();
    }
  }

  private getLevel = (listItem: DataListItem): number => {
    return this.levels.get(listItem) ? (this.levels.get(listItem) as number) : 0;
  };

  private transformer = (listItem: DataListItem, level: number): DataListItem => {
    this.levels.set(listItem, level);
    return listItem;
  };

  private isExpandable(listItem: DataListItem): boolean {
    return Boolean(listItem.children && listItem.children.length > 0);
  }

  private getChildren(listItem: DataListItem): DataListItem[] {
    return listItem.children ? listItem.children : [];
  }

  private findById(id: string): DataListItem {
    return this.treeControl.dataNodes?.find((node) => node?.id === id) as DataListItem;
  }

  private checkAndExpandNodes() {
    if (this.expanded) {
      return this.dataSource.data.forEach((listItem: DataListItem) => this.treeControl.expandDescendants(listItem));
    }
    this.dataSource.data.forEach((listItem: DataListItem) => this.walk(listItem));
  }

  private walk(listItem: DataListItem) {
    if (listItem.collapsed === false) {
      this.treeControl.expand(listItem);
    }
    listItem.children?.forEach((child) => this.walk(child));
  }
}
