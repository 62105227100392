import { ɵDirectiveDef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';

import { SeoConfig, SocialMediaConfig } from '../../interfaces';
import { Writable } from '../../models';
import { AppInjector, LocationService, MetaTagService } from '../../services';

export function SocialMediaMetaTagFeature() {
  return <T extends { seoConfig?: SeoConfig; socialMediaConfig?: SocialMediaConfig }>(
    directiveDef: Writable<ɵDirectiveDef<T>>
  ) => {
    const { factory, type } = directiveDef;

    directiveDef.factory = () => {
      const instance = factory?.(type) as T;
      const injector = AppInjector.getInjector();
      if (!injector) {
        throw new Error(`AppInjector not found! Please, provide it or remove SocialMediaMetaTagFeature() decorator`);
      }
      if (!instance.seoConfig && !instance.socialMediaConfig) {
        throw new Error(
          `'seoConfig' or 'socialMediaConfig' are not defined in the ${type?.name}! Please, provide it or remove SocialMediaMetaTagFeature() decorator`
        );
      }
      const translate = injector.get(TranslateService);
      const metaTagService = injector.get(MetaTagService);
      const locationService = injector.get(LocationService);

      const config: SocialMediaConfig = (instance.seoConfig?.socialMediaConfig ||
        instance.socialMediaConfig) as SocialMediaConfig;
      const { titleKey, descriptionKey, facebookCardType, twitterCardType, titleSuffixKey = '' } = config;
      const image = locationService.getOrigin() + '/' + config.image;

      translate
        .get([titleKey, descriptionKey, titleSuffixKey])
        .pipe(
          take(1),
          map((value) => ({
            title: value[titleKey],
            description: value[descriptionKey],
            titleSuffix: value[titleSuffixKey]
          }))
        )
        .subscribe(
          ({ title, description, titleSuffix }: { title: string; description: string; titleSuffix: string }) => {
            titleSuffix = titleSuffix && ` | ${titleSuffix}`;
            metaTagService.setFacebook({
              title,
              titleSuffix,
              image,
              description,
              type: facebookCardType
            });
            metaTagService.setTwitter({
              title,
              titleSuffix,
              image,
              description,
              card: twitterCardType
            });
          }
        );
      return instance;
    };
  };
}
