import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EnrichedExploreTimeline } from '@ct/core';
import { ExploreApiService } from '@ct/shared';

@Injectable({ providedIn: 'root' })
export class TimelineEntryDataResolver implements Resolve<EnrichedExploreTimeline | undefined> {
  constructor(private exploreApiService: ExploreApiService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params?.id;

    return this.exploreApiService.getById(id);
  }
}
