import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subscription, SubscriptionLike } from 'rxjs';

import { ImageSize, LoadingStrategy, SlidingDirection, ThumbnailsMode, ThumbnailsPosition } from '../../enums';
import { GalleryError, GalleryItem, GalleryState } from '../../interfaces';
import { GalleryService } from '../../services/gallery.service';
import { GalleryRef } from '../../services/gallery-ref';
import {
  IframeItem,
  IframeItemData,
  ImageItem,
  ImageItemData,
  VideoItem,
  VideoItemData,
  YoutubeItem,
  YoutubeItemData
} from './../../models';

@Component({
  selector: 'ct-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit, OnChanges, OnDestroy {
  public galleryRef: GalleryRef;

  @Input() id?: string;
  @Input() items: GalleryItem[];
  @Input() nav: boolean;
  @Input() thumb: boolean;
  @Input() autoPlay: boolean;
  @Input() gestures: boolean;
  @Input() thumbWidth: number;
  @Input() thumbHeight: number;
  @Input() disableThumb: boolean;
  @Input() panSensitivity: number;
  @Input() playerInterval: number;
  @Input() imageDescriptionPrefix?: string;
  @Input() thumbMode: ThumbnailsMode;
  @Input() imageSize: ImageSize;
  @Input() slidingDirection: SlidingDirection;
  @Input() loadingStrategy: LoadingStrategy;
  @Input() thumbPosition: ThumbnailsPosition;

  // Inputs used by the lightbox
  @Input() destroyRef = true; // Destroy gallery ref on component destroy event
  @Input() skipInitConfig = false; // Skip initializing the config with components inputs (Lightbox mode)

  @Output() itemClick = new EventEmitter<number>();
  @Output() thumbClick = new EventEmitter<number>();
  @Output() playingChange = new EventEmitter<GalleryState>();
  @Output() indexChange = new EventEmitter<GalleryState>();
  @Output() itemsChange = new EventEmitter<GalleryState>();
  @Output() errorItem = new EventEmitter<GalleryError>();

  private itemClick$: SubscriptionLike = Subscription.EMPTY;
  private thumbClick$: SubscriptionLike = Subscription.EMPTY;
  private itemChange$: SubscriptionLike = Subscription.EMPTY;
  private indexChange$: SubscriptionLike = Subscription.EMPTY;
  private playingChange$: SubscriptionLike = Subscription.EMPTY;
  private playerListener$: SubscriptionLike = Subscription.EMPTY;

  constructor(private galleryService: GalleryService) {}

  onAction(i: string | number) {
    switch (i) {
      case 'next':
        this.galleryRef.next();
        break;
      case 'prev':
        this.galleryRef.prev();
        break;
      default:
        this.galleryRef.set(i as number);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.galleryRef) {
      this.galleryRef.setConfig(this.getConfig());

      if (changes.items && changes.items.currentValue !== changes.items.previousValue) {
        this.load(this.items);
      }
    }
  }

  ngOnInit() {
    // Get gallery instance by id
    if (this.skipInitConfig) {
      this.galleryRef = this.galleryService.ref(this.id);
    } else {
      this.galleryRef = this.galleryService.ref(this.id, this.getConfig());
    }

    // Load gallery items
    this.load(this.items);

    // Activate player listener
    this.playerListener$ = this.galleryRef.activatePlayer().subscribe();

    // Subscribes to events on demand
    if (this.indexChange.observers.length) {
      this.indexChange$ = this.galleryRef.indexChanged.subscribe((state: GalleryState) => this.indexChange.emit(state));
    }
    if (this.itemsChange.observers.length) {
      this.itemChange$ = this.galleryRef.itemsChanged.subscribe((state: GalleryState) => this.itemsChange.emit(state));
    }
    if (this.playingChange.observers.length) {
      this.playingChange$ = this.galleryRef.playingChanged.subscribe((state: GalleryState) =>
        this.playingChange.emit(state)
      );
    }

    // Start playing if auto-play is set to true
    if (this.autoPlay) {
      this.play();
    }
  }

  ngOnDestroy() {
    this.itemClick$.unsubscribe();
    this.thumbClick$.unsubscribe();
    this.itemChange$.unsubscribe();
    this.indexChange$.unsubscribe();
    this.playingChange$.unsubscribe();
    this.playerListener$.unsubscribe();
    if (this.destroyRef) {
      this.galleryRef.destroy();
    }
  }

  onItemClick(i: number) {
    this.itemClick.emit(i);
    this.galleryRef.itemClick.next(i);
  }

  onThumbClick(i: number) {
    this.galleryRef.set(i);
    this.thumbClick.emit(i);
    this.galleryRef.thumbClick.next(i);
  }

  onError(err: GalleryError) {
    this.errorItem.emit(err);
    this.galleryRef.error.next(err);
  }

  load(items: GalleryItem[]) {
    this.galleryRef.load(items);
  }

  add(item: GalleryItem, active?: boolean) {
    this.galleryRef.add(item, active);
  }

  addImage(data: ImageItemData, active?: boolean) {
    this.add(new ImageItem(data), active);
  }

  addVideo(data: VideoItemData, active?: boolean) {
    this.add(new VideoItem(data), active);
  }

  addIframe(data: IframeItemData, active?: boolean) {
    this.add(new IframeItem(data), active);
  }

  addYoutube(data: YoutubeItemData, active?: boolean) {
    this.add(new YoutubeItem(data), active);
  }

  remove(i: number) {
    this.galleryRef.remove(i);
  }

  next() {
    this.galleryRef.next();
  }

  prev() {
    this.galleryRef.prev();
  }

  set(i: number) {
    this.galleryRef.set(i);
  }

  reset() {
    this.galleryRef.reset();
  }

  play(interval?: number) {
    this.galleryRef.play(interval);
  }

  stop() {
    this.galleryRef.stop();
  }

  private getConfig() {
    return {
      nav: this.nav ?? this.galleryService.config.nav,
      thumb: this.thumb ?? this.galleryService.config.thumb,
      autoPlay: this.autoPlay ?? this.galleryService.config.autoPlay,
      gestures: this.gestures ?? this.galleryService.config.gestures,
      imageSize: this.imageSize ?? this.galleryService.config.imageSize,
      thumbMode: this.thumbMode ?? this.galleryService.config.thumbMode,
      thumbWidth: this.thumbWidth ?? this.galleryService.config.thumbWidth,
      thumbHeight: this.thumbHeight ?? this.galleryService.config.thumbHeight,
      disableThumb: this.disableThumb ?? this.galleryService.config.disableThumb,
      thumbPosition: this.thumbPosition ?? this.galleryService.config.thumbPosition,
      panSensitivity: this.panSensitivity ?? this.galleryService.config.panSensitivity,
      playerInterval: this.playerInterval ?? this.galleryService.config.playerInterval,
      loadingStrategy: this.loadingStrategy ?? this.galleryService.config.loadingStrategy,
      slidingDirection: this.slidingDirection ?? this.galleryService.config.slidingDirection,
      imageDescriptionPrefix: this.imageDescriptionPrefix || this.galleryService.config.imageDescriptionPrefix
    };
  }
}
