import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin } from 'rxjs';

import { TripApiService } from '../services';

const LIMIT = 5;

@Injectable({ providedIn: 'root' })
export class TripTimelineResolver implements Resolve<unknown | undefined> {
  constructor(private tripApiService: TripApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const id = activatedRouteSnapshot.params?.id;
    return this.getData(id);
  }

  private getData(id: string) {
    return forkJoin({
      timeline: this.tripApiService.getTimeline(id, {
        offset: 0,
        limit: LIMIT
      }),
      trip: this.tripApiService.getById(id)
    });
  }
}
