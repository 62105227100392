import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ComingSoonComponent } from './coming-soon';

@NgModule({
  declarations: [ComingSoonComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ComingSoonComponent]
})
export class StaticPagesModule {}
