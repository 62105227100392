import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ToggleComponent extends BaseControlComponent<boolean> implements OnInit {
  public readonly destroyed$: Observable<void>;
  @Input() readonly color: ThemePalette = 'primary';

  @Input() set value(checked: boolean) {
    this._value = checked;
    this.control.setValue(this._value);
    this.changeDetector.detectChanges();
  }

  get value(): boolean {
    return this._value;
  }

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Output() readonly valueChange = new EventEmitter<boolean>();

  public readonly control = new UntypedFormControl(null);

  onChange: (value: boolean) => void;

  onTouched: () => void;

  touched = false;
  private _value: boolean;
  private _disabled = false;

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  onFocus() {
    this.markAsTouched();
  }

  writeValue(value: boolean): void {
    this._value = value;
    this.changeDetector.markForCheck();
    super.writeValue(value);
  }

  setDisabledState(disabled: boolean) {
    this._disabled = disabled;
    super.setDisabledState(disabled);
  }

  onValueChange(event: MatSlideToggleChange): void {
    if (!this.disabled) {
      this._value = event.checked;
      this.valueChange.emit(this.value);

      this.onChange?.(this.value);
      this.control.setValue(this._value);
    }
  }

  onFocusOut(e: FocusEvent): void {
    this.markAsTouched();
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
    }
  }
}
