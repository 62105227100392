import { UploadedImage } from '@ct/core';

import { Currency, MarketplaceItemCondition } from '../enums';
import { Category, SubCategory } from '../interfaces';
import { Address } from './address.interface';
import { MerchantProfile } from './merchant-profile.interface';

export interface MarketplaceItem {
  id?: string;
  merchantId?: string;
  title?: string;
  description?: string;
  price?: number;
  address?: Address;
  photoIds?: string[];
  condition?: MarketplaceItemCondition;
  brand?: string;
  modelTitle?: string;
  photos?: UploadedImage[];
  merchant?: MerchantProfile;
  createdOn?: Date;
  modifiedOn?: Date;
  sold?: boolean;
  category?: Category;
  subCategory?: SubCategory;
  categoryId?: string;
  subCategoryId?: string;
  addressRef?: string;
  slug?: string;
  amazonAffiliateUrl?: string;
  currency?: Currency;
  alternateContact?: AlternateItemContact;
}

export interface AlternateItemContact {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
  readonly phone?: string;
}

export class MarketplaceItemSearchResults {
  results: MarketplaceItem[];
  otherResults?: MarketplaceItem[];
}
