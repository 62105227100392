<ct-icon
  icon="nav"
  *ngIf="state?.hasPrev"
  class="g-nav-prev"
  aria-label="Previous"
  (tapClick)="action.emit('prev')"
></ct-icon>

<ct-icon
  icon="nav"
  *ngIf="state?.hasNext"
  class="g-nav-next"
  aria-label="Next"
  (tapClick)="action.emit('next')"
></ct-icon>
