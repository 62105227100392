<div
  class="placeholder"
  [class.disabled]="disabled"
  ctFileDnd
  (fileDropped)="onFilesDropped($event)"
  (click)="onFilesSelected()"
>
  <ct-icon class="placeholder__icon" icon="attach-file-add"></ct-icon>
  <p class="placeholder__text">
    {{
      (multiple
        ? 'MY_ACCOUNT.MY_PHOTOS_FEATURE.DROP_FILES_TO_UPLOAD'
        : 'MY_ACCOUNT.MY_PHOTOS_FEATURE.DROP_FILE_TO_UPLOAD'
      ) | translate
    }}
  </p>
  <input [hidden]="true" type="file" name="file-upload" [multiple]="multiple" [accept]="accept" #fileUpload />
</div>
