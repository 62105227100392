<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'flat'">
    <button [type]="type" [color]="color" [disabled]="disabled" [class.full-width]="fullWidth" mat-flat-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'raised'">
    <button [type]="type" [color]="color" [disabled]="disabled" [class.full-width]="fullWidth" mat-raised-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <button [type]="type" [color]="color" [disabled]="disabled" [class.full-width]="fullWidth" mat-icon-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'stroked'">
    <button [type]="type" [color]="color" [disabled]="disabled" [class.full-width]="fullWidth" mat-stroked-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <button [type]="type" [color]="color" [disabled]="disabled" [class.full-width]="fullWidth" mat-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
