<div
  class="image-gallery"
  [class.one-col-gallery]="photos.length === 1"
  [class.two-cols-gallery]="photos.length === 2"
  [class.four-cols-gallery]="photos.length === 3"
  [class.three-cols-gallery]="photos.length > 3"
>
  <div
    *ngFor="let photo of photos; let index = index; trackBy: trackByFn"
    [class.has-more]="photos.length > 4 && index === 3"
    [attr.data-see-more]="index === 3 ? '+' + (photos.length - 4) + ' See More' : null"
    class="gallery-item"
  >
    <ng-container *ngTemplateOutlet="lightboxTmpl; context: { photo: photo, index: index }"></ng-container>
  </div>
</div>

<ng-template #lightboxTmpl let-photo="photo" let-index="index">
  <ct-photo
    [photo]="photo"
    lightbox
    [lightboxConfig]="{ editable: false }"
    [galleryItems]="galleryItems"
    [galleryItemIndex]="index"
    [galleryConfig]="galleryConfig"
    [altText]="imageDescriptionPrefix + ' image ' + (index + 1)"
  >
  </ct-photo>
</ng-template>
