import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'ct-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
  @Input() link: string[] | string;
  @Input() queryParams: Params;
  @Input() queryParamsHandling: 'merge' | 'preserve' | '' = '';

  get url() {
    if (Array.isArray(this.link)) {
      return this.link;
    }
    return [`${this.link}`];
  }

  get isExternalLink() {
    return typeof this.link === 'string' && this.link.startsWith('http');
  }
}
