import { Injectable } from '@angular/core';
import { UploadedImage } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { YoutubeVideo } from '../../../youtube/interfaces';
import { TripCampsite } from '../../interfaces';

export interface CampsiteState extends EntityState<TripCampsite> {
  id?: string;
  title: string;
  location: string;
  tripId: string;
  photoIds: string[];
  photos?: UploadedImage[];
  videoIds: string[];
  entryIds: string[];
  startDate?: Date;
  endDate?: Date;
  featuredPhotoId: string;
  featuredPhoto?: UploadedImage;
  youtubeVideos: YoutubeVideo[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'campsite',
  cache: {
    ttl: 3600
  },
  idKey: 'id'
})
export class CampsiteStore extends EntityStore<CampsiteState> {
  constructor() {
    super();
  }
}
