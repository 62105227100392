import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountDownComponent } from './count-down.component';
import { CountDownTimePipe } from './count-down-time.pipe';

@NgModule({
  declarations: [CountDownComponent, CountDownTimePipe],
  imports: [CommonModule],
  exports: [CountDownComponent]
})
export class CountDownModule {}
