<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

  <div ctContent>
    <ng-container>
      <div class="row px-4 px-md-5 mb-4 pt-4 with-background">
        <a routerLink=".." class="pb-2 back-link col-12">< back to the list</a>
        <div class="mb-4 timeline-entity col-12 col-md-9">
          <ng-container *ngTemplateOutlet="elementHeader; context: { entity: timeline }"></ng-container>

          <p *ngIf="timeline.body" class="timeline-entity__message">{{ timeline.body }}</p>
          <ng-container *ngIf="timeline.type === timelineType.Journal">
            <ct-journal-entry
              *ngIf="timeline.journal; else noEntity"
              [journalEntry]="toJournalEntry(timeline.journal)"
              [canEdit]="false"
            ></ct-journal-entry>
          </ng-container>
          <ng-container *ngIf="timeline.type === timelineType.BlogPost">
            <ct-blog-post-card
              *ngIf="timeline.story; else noEntity"
              [post]="timeline.story"
              [showLink]="true"
              [showStatus]="true"
              imgHeight="220px"
              imgWidth="100%"
              [link]="getStoryLink(timeline)"
            ></ct-blog-post-card>
          </ng-container>
          <ng-container *ngIf="timeline.type === timelineType.Trip">
            <ct-trip-card
              *ngIf="timeline.trip; else noEntity"
              class="cursor--pointer"
              [trip]="timeline.trip"
              [link]="getTripLink(timeline)"
            ></ct-trip-card>
          </ng-container>
          <ng-container *ngTemplateOutlet="elementFooter; context: { entity: timeline }"></ng-container>
        </div>
        <div class="col-12 col-md-3 pt-2 pt-md-0">
          <ct-blog-sidebar></ct-blog-sidebar>
        </div>
      </div>
    </ng-container>

    <ng-template
      #elementHeader
      let-entity="entity"
      let-author="entity?.author"
      let-type="entity?.type"
      let-createdOn="entity?.createdOn"
    >
      <div class="header row">
        <div class="user col-12">
          <ct-user-avatar
            class="user__avatar"
            [src]="getCroppedThumbPublicUrl(author?.photo?.publicUrl)"
            width="32px"
            height="32px"
          ></ct-user-avatar>
          <p class="user__link">
            <span
              *ngIf="author; else deletedUser"
              class="user__name"
              [routerLink]="['/user-profile']"
              [queryParams]="{ userId: author.userId }"
              >{{ author.username }}</span
            >
            <ng-template #deletedUser>
              <span class="user__name">Deleted User</span>
            </ng-template>
            <ng-container *ngIf="type === timelineType.Trip">
              <span class="user__subtitle">
                shared the trip on {{ createdOn ?? entity.date | date : 'MMM d yyyy, HH:mm' }}
              </span>
            </ng-container>
            <ng-container *ngIf="type === timelineType.Journal">
              <span class="user__subtitle">
                shared the memory on {{ createdOn ?? entity.date | date : 'MMM d yyyy, HH:mm' }}
              </span>
            </ng-container>
            <ng-container *ngIf="type === timelineType.BlogPost">
              <span class="user__subtitle">
                shared the story on {{ createdOn ?? entity.date | date : 'MMM d yyyy, HH:mm' }}
              </span>
            </ng-container>
            <ng-container *ngIf="entity?.metadata?.trip">
              <span class="user__subtitle">
                on <span class="highlight">{{ entity.metadata.trip.title }}</span> trip</span
              >
            </ng-container>
            <ng-container *ngIf="entity?.metadata?.waypoint">
              <span class="user__subtitle">
                at <span class="highlight">{{ entity.metadata.waypoint.title }}</span></span
              >
            </ng-container>
          </p>
        </div>
      </div>
    </ng-template>

    <ng-template #elementFooter let-entity="entity">
      <div class="row">
        <div class="col-12 extra-features">
          <div class="extra-features__action">
            <ct-icon icon="chat"></ct-icon>
            {{ entity.metadata?.threads?.length }}
          </div>
          <ct-like
            class="extra-features__action"
            [likes]="entity.metadata?.likes"
            [user]="loggedInUser$ | async"
            (like)="onLike(entity)"
            (dislike)="onDislike(entity)"
          ></ct-like>
        </div>
      </div>
      <ct-entity-comments
        class="mt-4"
        [entityId]="entity?.metadataId"
        [entityType]="getEntityType(entity)"
        [disableComments]="false"
        [fetchOnInit]="false"
        [threads]="entity.metadata.threads"
        [user]="loggedInUser$ | async"
      ></ct-entity-comments>
    </ng-template>

    <ng-template #noEntity>
      <div class="no-entity">
        {{ 'Shared entity is not available anymore' | translate }}
      </div>
    </ng-template>
  </div>
</ct-layout>
