export const MY_ACCOUNT_PHOTOS_SIDEBAR_LIMIT = 9;
export const MY_ACCOUNT_PHOTOS_OVERVIEW_LIMIT = 12;
export const MY_ACCOUNT_PHOTOS_FEATURE_LIMIT = 29;

export const MY_ACCOUNT_VIDEOS_SIDEBAR_LIMIT = 9;
export const MY_ACCOUNT_VIDEOS_FEATURE_LIMIT = 6;

export const MY_ACCOUNT_POSTS_SIDEBAR_LIMIT = 3;
export const MY_ACCOUNT_POSTS_FEATURE_LIMIT = 10;

export const MY_ACCOUNT_TRIPS_SIDEBAR_LIMIT = 3;
export const MY_ACCOUNT_TRIPS_OVERVIEW_LIMIT = 9;
export const MY_ACCOUNT_TRIPS_FEATURE_LIMIT = 10;

export const MY_ACCOUNT_GROUPS_FEATURE_LIMIT = 10;

export const MY_ACCOUNT_EXPLORE_FEATURE_LIMIT = 10;
