import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() src: string | undefined;
  @Input() width: string;
  @Input() height: string;
  @Input() round = true;
}
