<div class="container forgot-password">
  <form class="form" [formGroup]="form">
    <h1 class="title">{{ 'AUTH.FORGOT_PASSWORD.TITLE' | translate }}</h1>
    <div class="row">
      <ct-input
        type="email"
        [label]="'AUTH.FORGOT_PASSWORD.EMAIL_ADDRESS' | translate"
        [placeholder]="'AUTH.FORGOT_PASSWORD.EMAIL_ADDRESS_PLACEHOLDER' | translate"
        lightLabel="true"
        class="col-12 col-md-4"
        formControlName="emailAddress"
      ></ct-input>
    </div>
    <div class="row" *ngIf="!verification">
      <ct-re-captcha class="col-12 col-md-4" formControlName="captcha"></ct-re-captcha>
    </div>

    <ng-container *ngIf="verification">
      <div class="row mb-3">
        <div class="col-12 col-md-4">
          {{ 'AUTH.FORGOT_PASSWORD.WE_JUST_SEND_VERIFICATION_CODE' | translate }}
          <strong>{{ emailAddress }}</strong
          >. {{ 'AUTH.FORGOT_PASSWORD.ENTER_CODE_AND_PASSWORD_TO_VERIFY' | translate }}
          <br />
          {{ 'AUTH.FORGOT_PASSWORD.EMAIL_DELIVERY_CAN_TAKE_MINUTE' | translate }}
        </div>
      </div>
      <div class="row">
        <ct-input
          [label]="'AUTH.FORGOT_PASSWORD.CODE' | translate"
          [placeholder]="'AUTH.FORGOT_PASSWORD.CODE_PLACEHOLDER' | translate"
          [mask]="'000-000'"
          class="col-12 col-md-4"
          lightLabel="true"
          formControlName="verificationCode"
        ></ct-input>
      </div>
      <div class="row">
        <ct-passwords formControlName="passwords" class="col-12 col-md-4" [lightLabel]="true"></ct-passwords>
      </div>
    </ng-container>

    <div class="row pt-2">
      <div class="col-12 col-md-4">
        <ct-button
          *ngIf="!verification"
          type="button"
          variant="flat"
          color="accent"
          [fullWidth]="true"
          (click)="onResetPassword()"
        >
          {{ 'AUTH.FORGOT_PASSWORD.RESET_PASSWORD' | translate }}</ct-button
        >
        <ct-button
          *ngIf="verification"
          type="button"
          variant="flat"
          color="accent"
          [fullWidth]="true"
          (click)="onVerify()"
        >
          {{ 'AUTH.FORGOT_PASSWORD.VERIFY' | translate }}
        </ct-button>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col-12 col-md-4 d-flex justify-content-center">
        <ct-link [link]="['/login']" underline="true">{{ 'AUTH.FORGOT_PASSWORD.BACK_TO_LOGIN' | translate }}</ct-link>
      </div>
    </div>
  </form>
</div>
