import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Trip } from '@ct/shared';

import { MyAccountTripApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MyAccountTripsLastEditedResolver implements Resolve<Trip> {
  constructor(private myAccountTripApiService: MyAccountTripApiService) {}

  resolve() {
    return this.myAccountTripApiService.getMyLastEdited();
  }
}
