import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import Hammer from '@egjs/hammerjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ThumbnailsMode, ThumbnailsPosition } from '../../enums';
import { GalleryConfig, GalleryError, GalleryState, SliderState, WorkerState } from '../../interfaces';

@Component({
  selector: 'ct-gallery-thumbs',
  templateUrl: './gallery-thumbs.component.html',
  styleUrls: ['./gallery-thumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryThumbsComponent implements OnInit, OnChanges, OnDestroy {
  public sliderState$: Observable<SliderState>;

  @Input() public state: GalleryState | null;
  @Input() public config: GalleryConfig | null;

  @Output() public action = new EventEmitter<string | number>();
  @Output() public thumbClick = new EventEmitter<number>();
  @Output() public errorItem = new EventEmitter<GalleryError>();

  @HostBinding('style.height') public height: string;
  @HostBinding('style.width') public width: string;

  private readonly slidingWorker$ = new BehaviorSubject<WorkerState>({ value: 0, active: false });
  private hammer: any;
  private freeModeCurrentOffset = 0;

  constructor(private el: ElementRef, private zone: NgZone) {
    // Activate sliding worker
    this.sliderState$ = this.slidingWorker$.pipe(
      map((state: WorkerState) => ({
        style: this.getSliderStyles(state),
        active: state.active
      }))
    );
  }

  ngOnChanges() {
    // Refresh the slider
    this.updateSlider({ value: 0, active: false });
    this.freeModeCurrentOffset = 0;
  }

  ngOnInit() {
    if (this.config?.gestures && !this.config.disableThumb && typeof Hammer !== 'undefined') {
      let direction: number = Hammer.DIRECTION_HORIZONTAL;

      switch (this.config.thumbPosition) {
        case ThumbnailsPosition.Right:
        case ThumbnailsPosition.Left:
          direction = Hammer.DIRECTION_VERTICAL;
          break;
        case ThumbnailsPosition.Top:
        case ThumbnailsPosition.Bottom:
          direction = Hammer.DIRECTION_HORIZONTAL;
          break;
      }

      // Activate gestures
      this.hammer = new Hammer(this.el.nativeElement);
      this.hammer.get('pan').set({ direction });

      this.zone.runOutsideAngular(() => {
        // Move the slider
        switch (this.config?.thumbMode) {
          case ThumbnailsMode.Strict:
            this.hammer.on('pan', (e: any) => this.strictMode(e));
            break;
          case ThumbnailsMode.Free:
            this.hammer.on('pan', (e: any) => this.freeMode(e));
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
  }

  /**
   * Sliding strict mode
   */
  private strictMode(e: any) {
    switch (this.config?.thumbPosition) {
      case ThumbnailsPosition.Right:
      case ThumbnailsPosition.Left:
        this.updateSlider({ value: e.deltaY, active: true });
        if (e.isFinal) {
          this.updateSlider({ value: 0, active: false });
          this.verticalPan(e);
        }
        break;
      case ThumbnailsPosition.Top:
      case ThumbnailsPosition.Bottom:
        this.updateSlider({ value: e.deltaX, active: true });
        if (e.isFinal) {
          this.updateSlider({ value: 0, active: false });
          this.horizontalPan(e);
        }
    }
  }

  /**
   * Sliding free mode
   */
  private freeMode(e: any) {
    const thumbHeight = this.config?.thumbHeight as number;
    const itemsLength = this.state?.items?.length as number;
    const thumbWidth = this.config?.thumbWidth as number;
    const currIndex = this.state?.currIndex as number;

    switch (this.config?.thumbPosition) {
      case ThumbnailsPosition.Right:
      case ThumbnailsPosition.Left:
        this.updateSlider({ value: this.freeModeCurrentOffset + e.deltaY, active: true });
        if (e.isFinal) {
          if (this.minFreeScrollExceeded(e.deltaY, thumbWidth, thumbHeight)) {
            this.freeModeCurrentOffset = -(itemsLength - 1 - currIndex) * thumbHeight;
          } else if (this.maxFreeScrollExceeded(e.deltaY, thumbHeight, thumbWidth)) {
            this.freeModeCurrentOffset = currIndex * thumbHeight;
          } else {
            this.freeModeCurrentOffset += e.deltaY;
          }
          this.updateSlider({ value: this.freeModeCurrentOffset, active: false });
        }
        break;
      case ThumbnailsPosition.Top:
      case ThumbnailsPosition.Bottom:
        this.updateSlider({ value: this.freeModeCurrentOffset + e.deltaX, active: true });
        if (e.isFinal) {
          if (this.minFreeScrollExceeded(e.deltaX, thumbHeight, thumbWidth)) {
            this.freeModeCurrentOffset = -(itemsLength - 1 - currIndex) * thumbWidth;
          } else if (this.maxFreeScrollExceeded(e.deltaX, thumbWidth, thumbHeight)) {
            this.freeModeCurrentOffset = currIndex * thumbWidth;
          } else {
            this.freeModeCurrentOffset += e.deltaX;
          }
          this.updateSlider({ value: this.freeModeCurrentOffset, active: false });
        }
    }
  }

  /**
   * Check if the minimum free scroll is exceeded (used in Bottom, Left directions)
   */
  private minFreeScrollExceeded(delta: number, width: number, height: number): boolean {
    const itemsLength = this.state?.items?.length as number;
    const currIndex = this.state?.currIndex as number;

    return -(this.freeModeCurrentOffset + delta - width / 2) > (itemsLength - currIndex) * height;
  }

  /**
   * Check if the maximum free scroll is exceeded (used in Top, Right directions)
   */
  private maxFreeScrollExceeded(delta: number, width: number, height: number): boolean {
    const currIndex = this.state?.currIndex as number;
    return this.freeModeCurrentOffset + delta > currIndex * width + height / 2;
  }

  /**
   * Convert sliding state to styles
   */
  private getSliderStyles(state: WorkerState): any {
    const thumbHeight = this.config?.thumbHeight as number;
    const itemsLength = this.state?.items?.length as number;
    const thumbWidth = this.config?.thumbWidth as number;
    const currIndex = this.state?.currIndex as number;

    let value: number;
    switch (this.config?.thumbPosition) {
      case ThumbnailsPosition.Top:
      case ThumbnailsPosition.Bottom:
        this.width = '100%';
        this.height = thumbHeight + 'px';
        value = -(currIndex * thumbWidth) - (thumbWidth / 2 - state.value);
        return {
          transform: `translate3d(${value}px, 0, 0)`,
          width: itemsLength * thumbWidth + 'px',
          height: '100%'
        };
      case ThumbnailsPosition.Left:
      case ThumbnailsPosition.Right:
        this.width = this.config.thumbWidth + 'px';
        this.height = '100%';
        value = -(currIndex * thumbHeight) - (thumbHeight / 2 - state.value);
        return {
          transform: `translate3d(0, ${value}px, 0)`,
          width: '100%',
          height: itemsLength * thumbHeight + 'px'
        };
    }
  }

  private verticalPan(e: any) {
    const thumbHeight = this.config?.thumbHeight as number;
    const itemsLength = this.state?.items?.length as number;
    const panSensitivity = this.config?.panSensitivity as number;

    if (!(e.direction & Hammer.DIRECTION_UP && e.offsetDirection & Hammer.DIRECTION_VERTICAL)) {
      return;
    }
    if (e.velocityY > 0.3) {
      this.prev();
    } else if (e.velocityY < -0.3) {
      this.next();
    } else {
      if (e.deltaY / 2 <= (-thumbHeight * itemsLength) / panSensitivity) {
        this.next();
      } else if (e.deltaY / 2 >= (thumbHeight * itemsLength) / panSensitivity) {
        this.prev();
      } else {
        this.action.emit(this.state?.currIndex);
      }
    }
  }

  private horizontalPan(e: any) {
    const thumbWidth = this.config?.thumbWidth as number;
    const itemsLength = this.state?.items?.length as number;
    const panSensitivity = this.config?.panSensitivity as number;

    if (!(e.direction & Hammer.DIRECTION_HORIZONTAL && e.offsetDirection & Hammer.DIRECTION_HORIZONTAL)) {
      return;
    }
    if (e.velocityX > 0.3) {
      this.prev();
    } else if (e.velocityX < -0.3) {
      this.next();
    } else {
      if (e.deltaX / 2 <= (-thumbWidth * itemsLength) / panSensitivity) {
        this.next();
      } else if (e.deltaX / 2 >= (thumbWidth * itemsLength) / panSensitivity) {
        this.prev();
      } else {
        this.action.emit(this.state?.currIndex);
      }
    }
  }

  private next() {
    this.action.emit('next');
  }

  private prev() {
    this.action.emit('prev');
  }

  private updateSlider(state: WorkerState) {
    const newState: WorkerState = { ...this.slidingWorker$.value, ...state };
    this.slidingWorker$.next(newState);
  }
}
