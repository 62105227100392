<ct-dialog-content [titleKey]="$any(config?.titleKey)" [buttons]="buttons" [alignButtons]="'end'">
  <div class="file-upload">
    <mat-tab-group [(selectedIndex)]="tabIndex" [ngClass]="{ 'hide-tabs': !selectable }">
      <mat-tab [label]="$any(config.firstTabKey) | translate">
        <form class="form" [formGroup]="form">
          <!-- TODO: Add validation -->
          <ct-input
            class="col-12"
            [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_NEW_VIDEO' | translate"
            [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.YOUTUBE_VIDEO_PLACEHOLDER' | translate"
            formControlName="youtubeVideo"
          ></ct-input>
        </form>
      </mat-tab>
      <mat-tab [label]="$any(config.secondTabKey) | translate" *ngIf="selectable">
        <div class="row">
          <div *ngIf="youtubeVideos" class="col-12">
            <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.YOUTUBE_VIDEOS' | translate }}</h2>
            <ct-youtube-video-grid
              [multiselect]="true"
              [videos]="youtubeVideos"
              (selectionChanged)="onSelectVideo($event)"
              [col]="3"
            ></ct-youtube-video-grid>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ct-dialog-content>
