import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@ct/components';
import { BaseHttpService, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { SortOrder } from '@ct/shared';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { MarketplaceMessage } from '../interfaces/marketplace-message.interface';

const endpoint = environment.marketplaceApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class MarketplaceMessagingApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient, private spinnerService: SpinnerService) {
    super(httpClient, endpoint);
  }

  getMy({
    range,
    itemId
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    itemId?: string;
    type?: string;
  }): Observable<MarketplaceMessage[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    this.spinnerService.show();
    let params = new HttpParams();

    params = params.append('itemId', itemId as string);

    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    return this.get(`messaging/my-messages`, params, { headers, withCredentials: true }).pipe(
      finalize(() => this.spinnerService.hide())
    );
  }

  sendMessage(message: MarketplaceMessage, captcha: string) {
    const headers = {
      [HeaderType.ContentType]: 'application/json',
      [HeaderType.Accept]: 'application/json',
      [HeaderType.GoogleRecaptcha]: captcha
    };
    return this.post('messaging/new', message, {}, { headers });
  }
}
