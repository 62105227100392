<ng-container *ngIf="!simple && (isLoggedIn$ | async); else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="text-center">
          {{ 'Notifications List' | translate }} <span class="notification-list__beta">BETA</span>
        </h1>
        <ng-container *ngIf="notifications$ | async as notifications">
          <ul class="notification-list">
            <ng-container *ngFor="let notification of notifications">
              <li
                class="notification-list-item"
                (click)="markAsSeen(notification)"
                [class.read]="notification.status === NotificationStatus.Seen"
              >
                <ng-container *ngIf="buildNotification(notification) as display">
                  <h4 class="mb-0">{{ display.title }}</h4>
                  <p class="mb-0">{{ display.message }}</p>
                </ng-container>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
