import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthenticatedGuard,
  ForgotPasswordComponent,
  LoginComponent,
  NotAuthenticatedGuard,
  RegisterComponent,
  VerifyComponent
} from '@ct/auth';

import {
  DmcaComponent,
  FaqComponent,
  LegalNoticeComponent,
  NotificationsListComponent,
  PrivacyPolicyComponent,
  TermsAndConditionsComponent
} from './components';
import { CustomPreloadingStrategyService } from './services';

const blogLegacyRoutes: Routes = [
  {
    path: 'posts',
    redirectTo: '/stories',
    pathMatch: 'full'
  },
  {
    path: 'posts/by-author',
    redirectTo: '/stories/by-author',
    pathMatch: 'full'
  },
  {
    path: 'posts/by-tag',
    redirectTo: '/stories/by-tag',
    pathMatch: 'full'
  },
  {
    path: 'post/:id/:slug',
    redirectTo: '/stories/:id/:slug',
    pathMatch: 'full'
  },
  {
    path: 'post/:slug',
    redirectTo: '/stories/:slug',
    pathMatch: 'full'
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: '/stories',
    pathMatch: 'full'
  },
  {
    path: 'home',
    redirectTo: '/stories',
    pathMatch: 'full'
  },
  {
    path: 'notifications',
    component: NotificationsListComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'faq/simple',
    component: FaqComponent,
    data: {
      simple: true
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'privacy-policy/simple',
    component: PrivacyPolicyComponent,
    data: {
      simple: true
    }
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'terms-and-conditions/simple',
    component: TermsAndConditionsComponent,
    data: {
      simple: true
    }
  },
  {
    path: 'dmca',
    component: DmcaComponent
  },
  {
    path: 'dmca/simple',
    component: DmcaComponent,
    data: {
      simple: true
    }
  },
  {
    path: 'legal',
    component: LegalNoticeComponent
  },
  {
    path: 'legal/simple',
    component: LegalNoticeComponent,
    data: {
      simple: true
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'verify',
    component: VerifyComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: '404',
    loadChildren: () => import('@ct/app-content').then((m) => m.NotFoundModule)
  },
  {
    // left for backward compatibility
    path: 'blog',
    children: [...blogLegacyRoutes]
  },
  {
    path: 'stories',
    data: { preload: true },
    loadChildren: () => import('@ct/app-content').then((m) => m.BlogModule)
  },
  {
    path: 'series',
    loadChildren: () => import('@ct/app-content').then((m) => m.BlogModule)
  },
  {
    path: 'marketplace',
    loadChildren: () => import('@ct/app-content').then((m) => m.MarketplaceModule)
  },
  {
    path: 'site-search',
    loadChildren: () => import('@ct/app-content').then((m) => m.SiteSearchModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('@ct/app-content').then((m) => m.ContactUsModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('@ct/app-content').then((m) => m.MyAccountModule),
    canLoad: [AuthenticatedGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('@ct/app-content').then((m) => m.UserProfileModule)
  },
  {
    path: 'channels',
    loadChildren: () => import('@ct/app-content').then((m) => m.ChannelModule)
  }
];

routes.push({ path: '**', redirectTo: '/404', pathMatch: 'full' });

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking'
    })
  ]
})
export class AppRoutingModule {}
