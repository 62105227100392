import { ɵComponentDef, ɵComponentType, Type } from '@angular/core';

import { Writable } from '../../models';
import { ComponentFeature } from './component-feature.interface';

export function ComponentFeatures<T>(
  componentType: Type<T>,
  features: ComponentFeature[],
  factory: () => T
) {
  const componentDef: Writable<
    ɵComponentDef<T>
  > = (componentType as ɵComponentType<T>).ɵcmp as ɵComponentDef<T>;

  Object.assign(componentDef, {
    features: [...(componentDef.features || []), ...features]
  } as ɵComponentDef<T>);

  componentDef.factory = componentDef.factory ?? factory;

  for (const feature of componentDef.features as ComponentFeature[]) {
    feature(componentDef);
  }
}
