<ct-dialog-content
  [titleKey]="'MY_ACCOUNT.MY_TRIPS_FEATURE.' + (isEditMode ? 'EDIT_JOURNAL_ENTRY' : 'ADD_JOURNAL_ENTRY')"
  headerIcon="add-circle"
  [buttons]="buttons"
  [alignButtons]="'end'"
>
  <div class="journal-entry">
    <form class="form" [formGroup]="form">
      <div class="row">
        <ct-text-editor class="col-12" formControlName="body" [modules]="modules"></ct-text-editor>
      </div>
      <div class="mb-3">
        <h1 class="item-title col-12">{{ 'MY_ACCOUNT.MY_TRIPS_FEATURE.MEDIA' | translate }}</h1>
        <div class="photo-grid-container">
          <ng-container
            *ngIf="
              !form.controls.photos?.value?.length &&
              !form.controls.videos?.value?.length &&
              (uploadingMedia$ | async) === false
            "
          >
            <p *ngIf="mediaSelectError$ | async" class="col-12 validation-error">
              You can upload one video or multiple photos. Uploading both media types for single memory is not allowed
            </p>
            <div class="media-select-wrapper">
              <ct-file-upload-placeholder
                [multiple]="true"
                accept="video/*, image/*"
                (selectFiles)="onAddMedia($event)"
              ></ct-file-upload-placeholder>
              OR
              <ct-button (click)="onSelectExisting()">Select existing</ct-button>
            </div>
          </ng-container>
          <ct-spinner-simple class="uploading-media-spinner" *ngIf="uploadingMedia$ | async"></ct-spinner-simple>
          <ct-photo-grid
            *ngIf="form.controls.photos?.value?.length"
            [photos]="form.controls.photos?.value"
            [showPlaceholder]="true"
            [removable]="true"
            [imageHeight]="'140px'"
            (removed)="onRemovePhoto($event)"
            (placeholderSelected)="onAddPhotos()"
            [draggable]="true"
          >
          </ct-photo-grid>
          <div *ngIf="form.controls.videos?.value?.length" class="video-list__entity row">
            <ct-video-item
              *ngFor="let video of form.controls.videos?.value"
              class="col-12"
              [video]="video"
              (selectVideo)="onPlayVideo()"
              (removeVideo)="onRemoveVideo()"
              [removable]="true"
            >
            </ct-video-item>
          </div>
        </div>
      </div>
      <div class="row">
        <ct-date-picker
          class="col-12"
          formControlName="date"
          [label]="'COMMON.DATE.SELECT' | translate"
          [placeholder]="'COMMON.DATE.SELECT_PLACEHOLDER' | translate"
          [startDate]="journalEntryDate"
        ></ct-date-picker>
      </div>
      <div class="row">
        <ct-tag-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TAGS' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TAGS_PLACEHOLDER' | translate"
          [optionsFn]="optionsFn"
          [createFn]="createFn"
          [checkFn]="checkFn"
          [labelFn]="labelFn"
          formControlName="tags"
        ></ct-tag-select>
      </div>
      <div class="row">
        <ct-tag-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.MENTIONS' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.MENTIONS_PLACEHOLDER' | translate"
          [optionsFn]="optionsFriendFn"
          [labelFn]="labelFriendFn"
          [searchOnInit]="true"
          [minlength]="0"
          formControlName="users"
        ></ct-tag-select>
      </div>
      <div class="row">
        <ct-tag-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.GROUPS' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.GROUPS_PLACEHOLDER' | translate"
          [optionsFn]="optionsGroupFn"
          [labelFn]="labelGroupFn"
          [searchOnInit]="true"
          [minlength]="0"
          formControlName="groups"
        ></ct-tag-select>
      </div>
      <div class="row">
        <ct-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.TRIPS' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_TO_TRIP' | translate"
          formControlName="tripId"
          [options]="trips$ | async"
        ></ct-select>
      </div>
      <div class="row">
        <ct-select
          class="col-12"
          [label]="'MY_ACCOUNT.MY_TRIPS_FEATURE.WAYPOINT' | translate"
          [placeholder]="'MY_ACCOUNT.MY_TRIPS_FEATURE.ASSIGN_WAYPOINT' | translate"
          formControlName="campsiteId"
          [options]="waypoints$ | async"
        ></ct-select>
      </div>
      <div class="row">
        <ct-toggle class="col-12" formControlName="isPublic">Public </ct-toggle>
      </div>
    </form>
  </div>
  <ct-button
    *ngIf="isEditMode && journalEntry?.id"
    class="mr-2"
    variant="stroked"
    color="warn"
    (click)="onDeleteMemory()"
    >{{ 'COMMON.DELETE' | translate }}
  </ct-button>
</ct-dialog-content>
