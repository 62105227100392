import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MerchantProfileApiService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class CanViewSellingGuard implements CanActivate {
  constructor(private merchantProfileApiService: MerchantProfileApiService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.merchantProfileApiService.getMyProfile().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404 || error.status === 400) {
          this.router.navigate(['/marketplace/community']);
        }
        return of(null);
      }),
      map((merchantProfile) => {
        return !!merchantProfile;
      })
    );
  }
}
