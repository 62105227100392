import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { ToggleComponent } from './toggle.component';
@NgModule({
  declarations: [ToggleComponent],
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, TranslateModule, MatSlideToggleModule],
  exports: [ToggleComponent]
})
export class ToggleModule {}
