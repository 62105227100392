import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, AuthService, UserProfileApiService, UserProfileQuery } from '@ct/auth';
import { FilterItem, SpinnerService } from '@ct/components';
import { BlogPost, FormStateDispatcher, FriendStatus, RelationUserProfile, UserProfile } from '@ct/core';
import { entitySlugUrl, ProfileFilter, Trip } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, of } from 'rxjs';
import { finalize, map, shareReplay, switchMap } from 'rxjs/operators';

import { MarketplaceItem } from '../../../marketplace/interfaces';
import { FriendsQuery, UserRelationsApiService } from '../../../my-account/services';
import { UserProfileData } from '../../interfaces';

@Component({
  selector: 'ct-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
  public readonly profileFilter = ProfileFilter;
  public readonly friendStatus = FriendStatus;
  public user$ = this.userQuery.selectActive() as Observable<UserProfile>;
  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public friends$: Observable<RelationUserProfile[]> = this.friendsQuery.selectAll({
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });

  get user(): UserProfile | undefined {
    return this.userQuery.getActive();
  }

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  get userFriend$(): Observable<RelationUserProfile | undefined> {
    return this.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(undefined);
        }
        return this.friendsQuery
          .selectAll({
            filterBy: ({ userId }) => userId === user.userId
          })
          .pipe(map(([friend]) => friend));
      })
    );
  }

  get profileData$(): Observable<UserProfileData> {
    return this.route.data.pipe(
      map(({ userProfileData }) => userProfileData),
      shareReplay(1)
    );
  }

  get trips$(): Observable<Trip[]> {
    return this.profileData$.pipe(
      map(({ trips }) => trips),
      shareReplay(1)
    );
  }

  get marketplaceItems$(): Observable<MarketplaceItem[]> {
    return this.profileData$.pipe(
      map(({ marketplaceItems }) => marketplaceItems),
      shareReplay(1)
    );
  }

  get posts$(): Observable<BlogPost[]> {
    return this.profileData$.pipe(
      map(({ posts }) => posts),
      shareReplay(1)
    );
  }

  get showAuthor(): boolean {
    return this.user?.userId !== this.authQuery.profile?.userId;
  }

  public publicFilters: FilterItem[] = [
    { name: ProfileFilter.BlogPosts, labelKey: 'MAIN.FEATURES.BLOG' },
    { name: ProfileFilter.Marketplace, labelKey: 'MAIN.FEATURES.MARKETPLACE' }
  ];

  public filters: FilterItem[] = [
    { name: ProfileFilter.BlogPosts, labelKey: 'MAIN.FEATURES.BLOG' },
    { name: ProfileFilter.Trips, labelKey: 'MAIN.FEATURES.TRIPS' },
    { name: ProfileFilter.Marketplace, labelKey: 'MAIN.FEATURES.MARKETPLACE' }
  ];
  public activeFilter: string = this.filters[0].name;

  constructor(
    private route: ActivatedRoute,
    private userProfileApiService: UserProfileApiService,
    private userQuery: UserProfileQuery,
    private friendsQuery: FriendsQuery,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private userRelationsApiService: UserRelationsApiService,
    private authService: AuthService,
    private readonly spinnerService: SpinnerService,
  ) {}

  ngOnInit() {
    this.userRelationsApiService.getAllMyFriendsApi().subscribe();

    this.route.queryParams.pipe().subscribe(({ userId }) => {
      if (!this.userQuery.getEntity(userId)) {
        this.userProfileApiService.getByUserId(userId).subscribe();
      } else {
        this.userProfileApiService.setActive(userId);
      }
    });
  }

  onAddFriend() {
    this.spinnerService.show({instant: true});
    this.user$
      .pipe(
        switchMap(({ userId }) => this.userRelationsApiService.create({ userId })),
        switchMap(() => this.userRelationsApiService.getAllMyFriendsApi()),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  onFilterChanged({ name }: FilterItem) {
    if (name === this.activeFilter) {
      return;
    }
    this.activeFilter = name;
  }

  getBlogLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }

  getTripLink(trip: Trip) {
    return `/my-account/trips/${trip.id}/${trip.slug}/timeline`;
  }

  getMarketplaceItemLink(item: MarketplaceItem) {
    return entitySlugUrl('/marketplace/', item);
  }
}
