import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ct-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditVideoComponent {
  videoStart = 0;
  videoEnd = 0;
  trimStart = 0;
  trimEnd = this.videoEnd;
  isLongerThanMinute = false;
  loading = false;
  protected frames: ImageBitmap[] = [];
  @ViewChild('canvasPreviewStart') protected canvasPreviewStart!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasPreviewEnd') protected canvasPreviewEnd!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasFinalPreview') protected canvasFinalPreview!: ElementRef<HTMLCanvasElement>;
  @ViewChild('videoFinalPreview') protected videoFinalPreview!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasCrop') protected canvasCrop!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasCropOverlay') protected canvasCropOverlay!: ElementRef<HTMLCanvasElement>;
  protected previewStartCtx!: CanvasRenderingContext2D;
  protected previewEndCtx!: CanvasRenderingContext2D;
  protected finalPreviewCtx!: CanvasRenderingContext2D;
  protected stopped = true;
  private videoFile!: Blob;
  private degrees = 0;
  private cropArea = {
    xStart: 0,
    yStart: 0,
    xEnd: 0,
    yEnd: 0
  };

  constructor(private cdr: ChangeDetectorRef) {}

  handleFileInput(event: Event) {
    this.loading = true;
    this.cdr.markForCheck();
    const files = (event.target as any)?.files;
    console.log(files?.item(0));
    console.log(files);
    console.log(event);

    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      this.isLongerThanMinute = duration > 60;
      this.videoEnd = duration;
      this.trimEnd = duration > 60 ? 60 : duration;
      console.log(duration);
      this.cdr.markForCheck();
      this.onStartClick(files[0]);
    };

    video.src = URL.createObjectURL(files[0]);
  }

  onRotate() {
    this.degrees += 90;
    const canvas = this.canvasCrop.nativeElement;
    const ctx = this.canvasCrop.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    const frameRate = this.frames.length / this.videoEnd;
    const frame = this.frames[Number((this.trimStart * frameRate).toFixed(0))];
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(getRadianAngle(this.degrees));
    ctx.drawImage(frame, -frame.width / 2, -frame.height / 2);
    ctx.restore();
  }

  onStartCropping() {
    const ctx1 = this.canvasCrop.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    const ctx2 = this.canvasCropOverlay.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    const frameRate = this.frames.length / this.videoEnd;
    const frame = this.frames[Number((this.trimStart * frameRate).toFixed(0))];
    this.canvasCrop.nativeElement.width = frame.width;
    this.canvasCrop.nativeElement.height = frame.height;
    this.canvasCropOverlay.nativeElement.width = frame.width;
    this.canvasCropOverlay.nativeElement.height = frame.height;
    ctx1.drawImage(frame, 0, 0);
    ctx2.setLineDash([5, 5]);
  }

  selectCropArea(event: MouseEvent) {
    this.cropArea.xStart = event.offsetX;
    this.cropArea.yStart = event.offsetY;
    console.log(this.cropArea);
    this.cdr.markForCheck();
  }

  selectCropAreaDone() {
    console.log(this.cropArea);
    this.cropArea = {
      xStart: 0,
      xEnd: 0,
      yStart: 0,
      yEnd: 0
    };
    this.cdr.markForCheck();
  }

  onMoveCropSelection(event: MouseEvent) {
    const ctx2 = this.canvasCropOverlay.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    ctx2.setLineDash([5, 5]);
    const selectionStarted = Object.values(this.cropArea).some((value) => value > 0);
    if (selectionStarted) {
      this.cropArea.xEnd = event.offsetX;
      this.cropArea.yEnd = event.offsetY;
      ctx2.strokeStyle = '#ff0000';
      ctx2.clearRect(0, 0, this.canvasCropOverlay.nativeElement.width, this.canvasCropOverlay.nativeElement.height);
      ctx2.beginPath();
      ctx2.rect(
        this.cropArea.xStart,
        this.cropArea.yStart,
        this.cropArea.xEnd - this.cropArea.xStart,
        this.cropArea.yEnd - this.cropArea.yStart
      );
      ctx2.stroke();
      this.cdr.markForCheck();
    }
  }

  onTrimStart(event: number) {
    if (!this.previewStartCtx) {
      this.previewStartCtx = this.canvasPreviewStart.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    }

    this.trimStart = event;
    const frameRate = this.frames.length / this.videoEnd;
    const frame = this.frames[Number((event * frameRate).toFixed(0))];
    this.canvasPreviewStart.nativeElement.width = frame.width / 5;
    this.canvasPreviewStart.nativeElement.height = frame.height / 5;
    this.previewStartCtx.drawImage(frame, 0, 0, frame.width / 5, frame.height / 5);
  }

  onTrimEnd(event: number) {
    if (!this.previewEndCtx) {
      this.previewEndCtx = this.canvasPreviewEnd.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    }

    this.trimEnd = event;
    const frameRate = this.frames.length / this.videoEnd;
    const frame = this.frames[Number((event * frameRate).toFixed(0))];
    this.canvasPreviewEnd.nativeElement.width = frame.width / 5;
    this.canvasPreviewEnd.nativeElement.height = frame.height / 5;
    this.previewEndCtx.drawImage(frame, 0, 0, frame.width / 5, frame.height / 5);
  }

  async onPreview() {
    const video = this.videoFinalPreview.nativeElement;
    if (video.src) {
      video.pause();
      video.currentTime = 0;
      window.URL.revokeObjectURL(video.src);
    }
    const videoUrl = URL.createObjectURL(this.videoFile);
    video.src = `${videoUrl}#t=${this.trimStart},${this.trimEnd}`;
    video.play();
  }

  async onStopPreview() {
    const video = this.videoFinalPreview.nativeElement;
    if (video.src) {
      video.pause();
      video.currentTime = 0;
      window.URL.revokeObjectURL(video.src);
      video.src = '';
      this.cdr.markForCheck();
    }
  }

  async onStartClick(videoFile: Blob) {
    if (!this.stopped) {
      this.stopped = true;
      this.loading = false;
      this.cdr.markForCheck();
      return;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (HTMLVideoElement.prototype.hasOwnProperty('requestVideoFrameCallback')) {
      this.frames = [];
      this.stopped = false;
      const video = await this.getVideoElement(videoFile);
      video.muted = true;
      video.playbackRate = 16;
      const { videoWidth, videoHeight } = video;
      console.log({ videoWidth, videoHeight });
      const drawingLoop = async () => {
        const bitmap = await createImageBitmap(video);
        this.frames.push(bitmap);

        if (!video.ended && !this.stopped) {
          video.requestVideoFrameCallback(drawingLoop);
        } else {
          this.stopped = true;
          this.loading = false;
          window.URL.revokeObjectURL(video.src);
        }
        this.cdr.markForCheck();
      };
      // the last call to rVFC may happen before .ended is set but never resolve
      video.onended = (evt) => {
        this.stopped = true;
        this.loading = false;
        this.onTrimStart(this.trimStart);
        this.onTrimEnd(this.trimEnd);
        window.URL.revokeObjectURL(video.src);
        this.cdr.markForCheck();
      };
      video.requestVideoFrameCallback(drawingLoop);
    } else {
      console.error("your browser doesn't support this API yet");
    }
  }

  private async getVideoElement(videoFile: Blob) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    this.videoFile = videoFile;
    video.src = URL.createObjectURL(videoFile);
    document.body.append(video);
    await video.play();
    return video;
  }
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}
