import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Mode } from '@ct/shared';

import { CreateMarketplaceItemComponent, MarketplaceItemExtendedComponent } from '../../../marketplace/components';
import { CanSellGuard } from '../../../marketplace/guards';
import { CategoryDataResolver, MarketplaceItemDataResolver } from '../../../marketplace/resolvers';
import {
  EditMerchantProfileComponent,
  MyAccountMarketplaceComponent,
  MyAccountMarketplaceWrapperComponent
} from './components';
import { MerchantProfileResolver, MyAccountMarketplaceItemsDataResolver } from './resolvers';

const routes: Routes = [
  {
    path: 'profile',
    component: EditMerchantProfileComponent,
    resolve: {
      merchantProfile: MerchantProfileResolver
    },
    canActivate: [CanSellGuard]
  },
  {
    path: '',
    component: MyAccountMarketplaceWrapperComponent,
    children: [
      {
        path: '',
        component: MyAccountMarketplaceComponent,
        resolve: {
          items: MyAccountMarketplaceItemsDataResolver,
          categories: CategoryDataResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: ':slugWithId',
        component: MarketplaceItemExtendedComponent,
        data: {
          baseRoute: '../'
        },
        resolve: {
          item: MarketplaceItemDataResolver,
          categories: CategoryDataResolver
        }
      },
      {
        path: ':slugWithId/edit',
        component: CreateMarketplaceItemComponent,
        data: { mode: Mode.Edit },
        resolve: {
          item: MarketplaceItemDataResolver,
          categories: CategoryDataResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountMarketplaceRoutingModule {}
