import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@ct/components/select';
import { TranslateModule } from '@ngx-translate/core';

import { AutocompleteModule } from '../autocomplete';
import { TagModule } from './../tag';
import { TagSelectComponent } from './tag-select.component';

@NgModule({
  declarations: [TagSelectComponent],
  imports: [CommonModule, AutocompleteModule, ReactiveFormsModule, TranslateModule, TagModule, SelectModule],
  exports: [TagSelectComponent]
})
export class TagSelectModule {}
