import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';

export function registerLocales() {
  return [localeEn].forEach((locale) => registerLocaleData(locale));
}

export const localePipeConfig: { [key: string]: string } = {
  en: 'en-US'
};
