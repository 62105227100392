import { Injectable } from '@angular/core';
import { Tracer, TracerProvider } from '@opentelemetry/api';
import { BasicTracerProvider } from '@opentelemetry/sdk-trace-base';

import { DEFAULT_TRACER } from '../../common';
import { ITraceService } from '../base';

@Injectable()
export class NoopTraceService implements ITraceService {
  private readonly tracerProvider = new BasicTracerProvider();

  getTracer(name: string = DEFAULT_TRACER): Tracer {
    return this.tracerProvider.getTracer(name);
  }

  getTracerProvider(): TracerProvider {
    return this.tracerProvider;
  }
}
