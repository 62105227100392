import { Injectable } from '@angular/core';

import { DialogService } from '../../../../dialog';
import { ImageUploadDialogComponent } from '../components';
import { ImageUploadConfig } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ImageUploadService {
  constructor(private dialogService: DialogService) {}

  showUploadDialog(config?: ImageUploadConfig) {
    return this.dialogService.open(ImageUploadDialogComponent, {
      width: '50vw',
      data: { config }
    });
  }
}
