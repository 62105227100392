import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from '@ct/components/spinner/services';
import { BaseHttpService, EnrichedExploreTimeline, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const endpoint = environment.exploreApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class ExploreApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient, protected spinnerService: SpinnerService) {
    super(httpClient, endpoint);
  }

  getAll(range?: RequestRange): Observable<EnrichedExploreTimeline[]> {
    this.spinnerService.show();
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }

    return this.get('all', params, { headers, withCredentials: true }).pipe(finalize(() => this.spinnerService.hide()));
  }

  getByEntityId(id: string): Observable<EnrichedExploreTimeline> {
    this.spinnerService.show();
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };

    return this.get(`entity/${id}`, null, { headers, withCredentials: true }).pipe(
      finalize(() => this.spinnerService.hide())
    );
  }

  getById(id: string): Observable<EnrichedExploreTimeline> {
    this.spinnerService.show();
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };

    return this.get(id, null, { headers, withCredentials: true }).pipe(finalize(() => this.spinnerService.hide()));
  }

  getTripsTimeline(range?: RequestRange): Observable<EnrichedExploreTimeline[]> {
    this.spinnerService.show();
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }

    return this.get('trips', params, { headers, withCredentials: true }).pipe(
      finalize(() => this.spinnerService.hide())
    );
  }

  getGroupsTimeline(range?: RequestRange): Observable<EnrichedExploreTimeline[]> {
    this.spinnerService.show();
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }

    return this.get('groups', params, { headers, withCredentials: true }).pipe(
      finalize(() => this.spinnerService.hide())
    );
  }
}
