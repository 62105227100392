<ct-dialog-content [closeIcon]="false">
  <div class="notification-in-app__wrapper">
    <div class="notification-in-app__icon-wrapper">
      <ct-icon class="close-icon" size="fit" icon="close" (click)="onClose()"></ct-icon>
    </div>
    <div class="notification-in-app__content">
      <div class="notification-in-app__text">
        <h1>Unfortunately we don't support this type of notifications yet.</h1>
        <p>
          This notification was generated from the Mobile app, and does not currently support navigation to the mention
          at this time. We are working to incorporate this feature.
        </p>
      </div>
      <div class="buttons">
        <a href="https://apps.apple.com/us/app/camping-tools-travel-companion/id1672658613" target="_blank">
          <img
            src="assets/mobile/appstore.png"
            loading="lazy"
            alt="download iOS application"
            class="downloadbtn apple"
          />
        </a>

        <a href="https://play.google.com/store/apps/details?id=tools.camping.charlotte" target="_blank">
          <img
            src="assets/mobile/playstore.png"
            loading="lazy"
            alt="download Android application"
            class="downloadbtn apple"
          />
        </a>
      </div>
    </div>
  </div>
</ct-dialog-content>
