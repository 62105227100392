<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

  <div ctContent>
    <div class="my-account-overview">
      <div class="container">
        <ct-card *ngIf="data$ | async as data">
          <ct-card-section
            class="mb-3"
            [title]="'MY_ACCOUNT.MY_TRIPS' | translate"
            [extraActionLabel]="('MY_ACCOUNT.VIEW_ALL_TRIPS' | translate) + ' >'"
            [extraActionLink]="['../trips/list']"
          >
            <ct-my-account-overview-trips [trips]="data?.trips"></ct-my-account-overview-trips>
          </ct-card-section>
          <ct-card-section
            class="mb-3"
            [title]="'MY_ACCOUNT.MY_POSTS' | translate"
            [extraActionLabel]="('MY_ACCOUNT.VIEW_ALL_POSTS' | translate) + ' >'"
            [extraActionLink]="['../posts']"
          >
            <ct-my-account-overview-posts [posts]="data?.posts"></ct-my-account-overview-posts>
          </ct-card-section>
          <ct-card-section
            class="mb-3"
            [title]="'MY_ACCOUNT.MY_PHOTOS' | translate"
            [extraActionLabel]="('MY_ACCOUNT.VIEW_ALL_PHOTOS' | translate) + ' >'"
            [extraActionLink]="['../media/photos']"
          >
            <ct-photo-grid [photos]="data?.photos" [limit]="photosLimit"></ct-photo-grid>
          </ct-card-section>
          <ct-card-section
            class="mb-3"
            [title]="'MY_ACCOUNT.MY_VIDEOS' | translate"
            [extraActionLabel]="('MY_ACCOUNT.VIEW_ALL_VIDEOS' | translate) + ' >'"
            [extraActionLink]="['../media/videos']"
          >
            <ct-youtube-video-grid [videos]="data?.videos" [limit]="10"></ct-youtube-video-grid>
          </ct-card-section>

          <ct-card-section
            class="mb-3"
            [title]="'MY_ACCOUNT.MY_MARKETPLACE' | translate"
            [extraActionLabel]="('MY_ACCOUNT.VIEW_ALL_LISTINGS' | translate) + ' >'"
            [extraActionLink]="['../marketplace']"
          >
            <ct-my-account-overview-marketplace [items]="data?.marketplace"></ct-my-account-overview-marketplace>
          </ct-card-section>
        </ct-card>
      </div>
    </div>
  </div>
</ct-layout>
