import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { MarketplaceState, MarketplaceStore } from './marketplace.store';

@Injectable({ providedIn: 'root' })
export class MarketplaceQuery extends Query<MarketplaceState> {
  selectItems$ = this.select('items');
  selectOtherItems$ = this.select('otherItems');
  selectCategories$ = this.select('categories');
  selectSubCategories$ = this.select('subCategories');

  constructor(protected store: MarketplaceStore) {
    super(store);
  }
}
