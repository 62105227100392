import { Injectable } from '@angular/core';
import { UploadedImage, UserProfile } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface UserProfileState extends EntityState<UserProfile> {
  id?: string;
  userId: string;
  firstName: string;
  lastName: string;
  username: string;
  description?: string;
  createdOn?: Date | string;
  modifiedOn?: Date | string;
  active?: boolean;
  photoId?: string;
  photo?: UploadedImage;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'user-profile',
  cache: {
    ttl: 3600
  },
  idKey: 'userId'
})
export class UserProfileStore extends EntityStore<UserProfileState> {
  constructor() {
    super();
  }
}
