import { Injectable } from '@angular/core';
import { Tag, UploadedImage, UserProfile } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { TripJournalEntry } from '../../interfaces';

export interface JournalEntryState extends EntityState<TripJournalEntry> {
  id?: string;
  tripId: string;
  authorId: string;
  campsiteId?: string | null;
  videoIds: string[];
  tags: Tag[];
  isPrivate: boolean;
  photoIds: string[];
  createdOn: string | Date;
  modifiedOn: string;
  body: string;
  author?: UserProfile;
  photos?: UploadedImage[];
  videos?: any[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'journalEntry',
  cache: {
    ttl: 3600
  },
  idKey: 'id'
})
export class JournalEntryStore extends EntityStore<JournalEntryState> {
  constructor() {
    super();
  }
}
