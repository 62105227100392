import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tag } from '@ct/core';

@Component({
  selector: 'ct-image-with-tags',
  templateUrl: './image-with-tags.component.html',
  styleUrls: ['./image-with-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageWithTagsComponent {
  @Input() tags: Tag[] | undefined = [];
  @Input() src: string | undefined;
  @Input() publicUrl: string | undefined;
  @Input() thumbnail: string | undefined;
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() noImagePlaceholderPath: string;
  @Input() objectFit = 'cover';
  @Input() altText: string | undefined;
  @Input() tagLocation: 'top' | 'bottom' = 'top';
}
