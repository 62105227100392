import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-trip-entry-tab',
  templateUrl: './trip-entry-tab.component.html',
  styleUrls: ['./trip-entry-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripEntryTabComponent {
  @Input() title: string;
  @Input() active = false;

  constructor(public changeDetectorRef: ChangeDetectorRef) {}
}
