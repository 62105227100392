<ct-gallery-thumbs
  *ngIf="config?.thumb"
  [state]="state"
  [config]="config"
  (action)="action.emit($event)"
  (thumbClick)="thumbClick.emit($event)"
>
</ct-gallery-thumbs>
<div class="g-box">
  <ct-gallery-slider
    [state]="state"
    [config]="config"
    (action)="action.emit($event)"
    (itemClick)="itemClick.emit($event)"
    (errorItem)="errorItem.emit($event)"
  >
    <ct-gallery-nav
      *ngIf="config?.nav && itemsLength > 1"
      [state]="state"
      [config]="config"
      (action)="action.emit($event)"
    >
    </ct-gallery-nav>
  </ct-gallery-slider>
</div>
