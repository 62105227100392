import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum MyAccountTripAction {
  CampsiteChanged = 'campsiteChanged',
  JournalEntriesChanged = 'journalEntriesChanged',
  PhotosChanged = 'photosChanged'
}

export interface MyAccountTripState {
  action?: MyAccountTripAction | null;
}

export const defaultState: MyAccountTripState = {
  action: null
};

const filterActions = (actions: string[]) => {
  return filter((state: MyAccountTripState) => actions.indexOf(state.action as string) > -1);
};

@Injectable({ providedIn: 'root' })
export class MyAccountTripStateService {
  private readonly state$: BehaviorSubject<MyAccountTripState>;

  get campsiteChanged(): Observable<MyAccountTripState> {
    return this.state$.asObservable().pipe(filterActions([MyAccountTripAction.CampsiteChanged]));
  }

  get journalEntriesChanged(): Observable<MyAccountTripState> {
    return this.state$.asObservable().pipe(filterActions([MyAccountTripAction.JournalEntriesChanged]));
  }

  get photosChanged(): Observable<MyAccountTripState> {
    return this.state$.asObservable().pipe(filterActions([MyAccountTripAction.PhotosChanged]));
  }

  constructor() {
    this.state$ = new BehaviorSubject<MyAccountTripState>(defaultState);
  }

  setState(state: MyAccountTripState) {
    this.state$.next(state);
  }

  reset() {
    this.state$.next(defaultState);
  }

  destroy() {
    this.state$.complete();
  }
}
