import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import {
  CustomValidators,
  DestroyableFeature,
  EMAIL_MAX_LENGTH,
  Features,
  FormStateDispatcher,
  NAME_MAX_LENGTH,
  UserProfile
} from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { default as PhoneNumber } from 'awesome-phonenumber';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { MerchantProfileApiService } from '../../services';

@Component({
  selector: 'ct-create-merchant-profile',
  templateUrl: './create-merchant-profile.component.html',
  styleUrls: ['./create-merchant-profile.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class CreateMerchantProfileComponent {
  public readonly destroyed$: Observable<void>;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public form;

  private returnUrl = '/';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formState: FormStateDispatcher,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private merchantProfileApiService: MerchantProfileApiService
  ) {
    const params = this.route.snapshot.queryParams;
    if (params['returnUrl']) {
      this.returnUrl = params['returnUrl'];
    }
    const { firstName, lastName, email } = (this.authQuery.profile ?? {}) as UserProfile;
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(firstName, [Validators.required, Validators.maxLength(NAME_MAX_LENGTH)]),
      lastName: new UntypedFormControl(lastName, [Validators.required, Validators.maxLength(NAME_MAX_LENGTH)]),
      email: new UntypedFormControl(email, [
        Validators.required,
        Validators.email,
        Validators.maxLength(EMAIL_MAX_LENGTH)
      ]),
      phone: new UntypedFormControl('', [Validators.required, CustomValidators.phoneNumberExtended('US')]),
      address: new UntypedFormGroup({
        street: new UntypedFormControl('', [Validators.required]),
        street2: new UntypedFormControl(''),
        city: new UntypedFormControl('', [Validators.required]),
        state: new UntypedFormControl('', [Validators.required]),
        zip: new UntypedFormControl('', [Validators.required])
      }),
      acceptToC: new UntypedFormControl(false, Validators.requiredTrue)
    });
  }

  onRegister() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    const { acceptToC, phone: phoneNumber, ...merchantProfile } = this.form.value;

    if (!acceptToC) {
      return;
    }

    const phone = new PhoneNumber(phoneNumber, 'US').getNumber('international');

    this.merchantProfileApiService.create({ ...merchantProfile, phone }).subscribe(
      () => {
        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl).catch(() => this.router.navigate(['/home']));
        } else {
          this.router.navigate(['/home']);
        }
      },
      (error) => {
        if (error.status === 400) {
          if (error.error.field === 'address') {
            const addressGroup = this.form.get('address') as UntypedFormGroup;
            Object.values(addressGroup.controls).forEach((control) => {
              control.setErrors({ invalid: true });
            });

            addressGroup.valueChanges.pipe(take(1)).subscribe(() => {
              Object.values(addressGroup.controls).forEach((control) => {
                control.setErrors(null);
              });
            });
          }
        }
      }
    );
  }
}
