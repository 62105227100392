import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { DataListApi, DataListColumn, DataListItem, TabHeader } from '@ct/components';
import { DestroyableFeature, Features, UserProfile } from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Category, TreeCategory, TreeSubCategory } from '../../interfaces';

interface MarketplaceQueryParams {
  offset: number;
  merchantId?: string;
  categoryId?: string;
  subCategoryId?: string;
  search?: string;
  location?: string;
  lat?: number;
  lon?: number;
}

enum Marketplace {
  Selling = 'selling',
  Community = 'community'
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-marketplace-items-wrapper',
  templateUrl: './marketplace-items-wrapper.component.html',
  styleUrls: ['./marketplace-items-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class MarketplaceItemsWrapperComponent implements AfterViewInit {
  public readonly destroyed$: Observable<void>;

  public tabs: TabHeader[] = [
    { name: Marketplace.Community, labelKey: 'MARKETPLACE.ALL_ITEMS_LIST', selected: true },
    { name: Marketplace.Selling, labelKey: 'MARKETPLACE.OWN_ITEMS' }
  ];
  public loading = false;

  public queryParams: MarketplaceQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public listColumn: DataListColumn = {
    field: 'title',
    align: 'left'
  };
  public categories: TreeCategory[];
  public selectedEntity: TreeCategory | TreeSubCategory | null;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  private dataListApi: DataListApi;
  private nodesFromRoot: TreeCategory[] = [];
  private previousSelectedEntity: TreeCategory | TreeSubCategory | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.route.data.pipe(take(1)).subscribe(({ categories }) => {
      this.categories = categories?.map((category: Category) => new TreeCategory(category)) ?? [];
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          while (route.firstChild && route.firstChild.snapshot.url?.length) {
            route = route.firstChild;
          }

          return route;
        })
      )
      .subscribe((route: ActivatedRoute) => {
        const path = route.snapshot.url[0]?.path;
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          selected: tab.name === path
        }));
      });
  }

  ngAfterViewInit() {
    this.route.queryParams.pipe(take(1)).subscribe(({ categoryId, subCategoryId }) => {
      const category = (this.categories ?? []).find(({ id }) => id === categoryId);
      if (category) {
        if (subCategoryId) {
          this.previousSelectedEntity = category.subCategories.find(
            ({ id }) => id === subCategoryId
          ) as TreeSubCategory;
        } else {
          this.previousSelectedEntity = category;
        }
        this.selectedEntity = this.previousSelectedEntity;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.expandFromRootToCurrentEntity(this.previousSelectedEntity as TreeCategory);
  }

  onFilterChanged(tab: TabHeader) {
    this.router.navigate(['marketplace', tab.name]);
  }

  addItems() {
    // TODO create popup fir new item?
    this.router.navigate(['marketplace/sell']);
  }

  clearSelection(event: MouseEvent) {
    event.preventDefault();
    this.previousSelectedEntity = null;
    this.dataListApi?.listSelection.clear();
    this.dataListApi?.treeControl.collapseAll();
    this.changeDetectorRef.detectChanges();
    this.refreshUrlQueryParams({ offset: DEFAULT_OFFSET });
  }

  onEntitySelected(entity: DataListItem | null) {
    if (this.previousSelectedEntity && this.dataListApi?.listSelection.isSelected(entity)) {
      this.previousSelectedEntity = null;
      this.dataListApi?.listSelection.clear();
      this.changeDetectorRef.detectChanges();
      return this.refreshUrlQueryParams({ offset: DEFAULT_OFFSET });
    }
    if (entity === this.previousSelectedEntity) {
      return;
    }

    this.previousSelectedEntity = entity as TreeSubCategory;
    if (this.previousSelectedEntity.categoryId) {
      this.refreshUrlQueryParams({
        categoryId: this.previousSelectedEntity.categoryId,
        subCategoryId: this.previousSelectedEntity.id,
        offset: DEFAULT_OFFSET
      });
    } else {
      this.refreshUrlQueryParams({ categoryId: this.previousSelectedEntity.id, offset: DEFAULT_OFFSET });
    }
    this.expandFromRootToCurrentEntity(entity as TreeCategory);
  }

  expandFromRootToCurrentEntity(entity: TreeCategory | null) {
    if (!entity) {
      return;
    }
    this.nodesFromRoot = [];
    this.walkFromCurrentNodeToRoot(entity);
    this.nodesFromRoot.forEach(({ id }) => {
      const node = this.dataListApi?.findById(id);
      this.dataListApi?.treeControl?.expand(node);
    });
  }

  walkFromCurrentNodeToRoot(entity: TreeCategory | TreeSubCategory) {
    if ((entity as TreeSubCategory)?.categoryId) {
      const category = (this.categories ?? []).find(({ id }) => id === (entity as TreeSubCategory).categoryId);
      this.nodesFromRoot.push(category as TreeCategory);
    }
  }

  onDataListReady(dataListApi: DataListApi) {
    this.dataListApi = dataListApi;
  }

  refreshUrlQueryParams({
    offset,
    merchantId,
    categoryId,
    subCategoryId,
    search,
    location,
    lat,
    lon
  }: MarketplaceQueryParams) {
    this.loading = true;

    let route = this.route;

    while (route.firstChild && route.firstChild !== route) {
      route = route.firstChild;
    }

    this.router.navigate(['/marketplace/community'], {
      queryParams: { offset, merchantId, categoryId, subCategoryId, search, location, lat, lon },
      queryParamsHandling: 'merge',
      relativeTo: route
    });
  }
}
