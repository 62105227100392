import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { LikeComponent } from './like.component';

@NgModule({
  declarations: [LikeComponent],
  imports: [CommonModule, IconModule],
  exports: [LikeComponent]
})
export class LikeModule {}
