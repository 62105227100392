import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Destroyable } from './abstracts/lifecycle';
import { CTContextDirective } from './directives';

@NgModule({
  declarations: [Destroyable, CTContextDirective],
  exports: [CTContextDirective],
  imports: [CommonModule]
})
export class CoreModule {}
