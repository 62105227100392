import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MyVideoPlayerComponent } from './my-video-player.component';

@NgModule({
  declarations: [MyVideoPlayerComponent],
  imports: [CommonModule],
  exports: [MyVideoPlayerComponent]
})
export class MyVideoPlayerModule {}
