<ng-container *ngIf="!simple && (isLoggedIn$ | async); else defaultContent">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
    <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

    <div ctContent>
      <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultContent>
  <div [class.container]="!simple">
    <div class="row">
      <div class="col-12">
        <h1 *ngIf="!simple" class="text-center">{{ 'COMMON.LEGAL.DMCA.TITLE' | translate }}</h1>
        <div [innerHTML]="'COMMON.LEGAL.DMCA.TEXT_PART_1' | translate"></div>
        <div class="contact-address ml-5">
          <div [innerHTML]="'COMMON.LEGAL.DMCA.NOTIFICATION_SUBJECT' | translate"></div>
          <div [innerHTML]="'COMMON.LEGAL.CONTACT_ADDRESS' | translate"></div>
          <div [innerHTML]="'COMMON.LEGAL.CONTACT_EMAIL' | translate"></div>
        </div>
        <div [innerHTML]="'COMMON.LEGAL.DMCA.TEXT_PART_2' | translate"></div>
      </div>
    </div>
  </div>
</ng-template>
