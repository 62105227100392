import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeletionDialogComponent, DialogService, SpinnerService } from '@ct/components';
import { DestroyableFeature, Features, FormStateDispatcher, TitleService } from '@ct/core';
import { ItineraryApiService, Mode, toSlug, Trip, TripApiService } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-trip-edit',
  templateUrl: './my-account-trip-edit.component.html',
  styleUrls: ['./my-account-trip-edit.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class MyAccountTripEditComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public readonly form = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    slug: new UntypedFormControl(''),
    startDate: new UntypedFormControl(null, [Validators.required]),
    endDate: new UntypedFormControl(null)
  });
  public isLoading = false;

  get isEditMode() {
    return this.route.snapshot.data?.mode === Mode.Edit;
  }

  get trip(): Trip {
    return this.route.snapshot.data.trip;
  }

  get tripId() {
    return this.route.snapshot.data.trip?.id;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formState: FormStateDispatcher,
    private titleService: TitleService,
    private translateService: TranslateService,
    private tripApiService: TripApiService,
    private itineraryApiService: ItineraryApiService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.titleService.setTitle(this.translateService.instant('MY_ACCOUNT.NEW_TRIP'));
  }

  ngOnInit() {
    if (this.isEditMode && this.trip) {
      this.form.patchValue(this.trip);
    }

    this.form.controls.title.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((title) => this.form.controls.slug.patchValue(toSlug(title)));
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    (!this.isEditMode
      ? this.tripApiService
          .create({ ...this.form.value })
          .pipe(
            switchMap((trip) =>
              this.itineraryApiService
                .create({ tripId: trip.id })
                .pipe(map((itinerary) => ({ ...trip, itineraryId: itinerary.id })))
            )
          )
      : this.tripApiService.update(this.tripId, { ...this.form.value })
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((trip) =>
        this.router.navigate(
          [this.isEditMode ? `../../../${trip?.id}/${trip?.slug}` : `../${trip?.id}/${trip?.slug}`],
          {
            relativeTo: this.route
          }
        )
      );
  }

  onCancel() {
    this.router.navigate([this.isEditMode ? '..' : '../list'], { relativeTo: this.route });
  }

  onDelete() {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_TRIP_TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_TRIP_MESSAGE')
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.tripApiService.delete(this.trip.id as string))
      )
      .subscribe(() => this.router.navigate(['../../../list'], { relativeTo: this.route }));
  }
}
