import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { DialogService, NotificationInAppComponent } from '@ct/components';
import { BlogPost, Group, GroupTimeline, NotificationStatus, NotificationWithAuthor, UserProfile } from '@ct/core';
import {
  EntityType,
  ExploreApiService,
  NotificationApiService,
  Trip,
  TripCampsite,
  TripJournalEntry
} from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ct-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent {
  protected readonly NotificationStatus = NotificationStatus;

  public readonly loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public readonly simple = this.route.snapshot.data.simple;

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  private readonly debouncer$ = new Subject<void>();
  private notificationIds: string[] = [];
  private latency = 1000;
  private dialogRef: MatDialogRef<NotificationInAppComponent> | null;
  private timer: number | null;
  private closeSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private notificationService: NotificationApiService,
    private exploreApiService: ExploreApiService
  ) {
    this.debouncer$
      .pipe(
        debounceTime(this.latency),
        switchMap(() => this.notificationService.updateStatus(this.notificationIds))
      )
      .subscribe(() => {
        this.notificationIds = [];
      });
  }
  buildNotification(notification: NotificationWithAuthor): { title: string; message: string } {
    const title = this.getActionByEntity(notification, this.getTitleByEntityType(notification));
    const message = this.getMessageByEntity(notification, this.getTitleByEntityType(notification), notification.author);
    return { title, message };
  }

  markAsSeen(notification: NotificationWithAuthor) {
    if (notification.status !== NotificationStatus.Seen) {
      this.notificationIds = [...new Set([...this.notificationIds, notification.id as string])];
      this.debouncer$.next();
    }
    this.navigateToEntity(notification);
  }

  showMobileAppPopup() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    if (!this.dialogRef) {
      this.dialogRef = this.dialogService.open(NotificationInAppComponent);
      this.closeSubscription = this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
      });
    }
    setTimeout(() => {
      this.closeSubscription?.unsubscribe();
      this.dialogRef?.close();
      this.dialogRef = null;
      this.timer = null;
    }, 15000);
  }

  private getTitleByEntityType(notification: NotificationWithAuthor): string {
    switch (notification.notificationObject.entityType) {
      case EntityType.Trip:
        return `"${(notification.entity as Trip).title}" trip`;
      case EntityType.JournalEntry:
        return 'a memory';
      case EntityType.BlogPost:
        return `story "${(notification.entity as BlogPost).title}"`;
      case EntityType.List:
        return `"${(notification.entity as any).title}" list` ?? 'list';
      case EntityType.ListItem:
        return `list item ${(notification.entity as any).name}`;
      case EntityType.Group:
        return `"${(notification.entity as Group).title}" group`;
      case EntityType.Itinerary:
        return `"${(notification.entity as any).title}" itinerary`;
      case EntityType.Campsite:
        return `"${(notification.entity as unknown as TripCampsite).title}" waypoint`;
      case EntityType.Like:
        return `Your ${this.relatedEntity(notification)} got ${(notification.entity as BlogPost).likesCount}`;
      case EntityType.Photo:
      case EntityType.Thread:
      case EntityType.Comment:
        return notification.notificationObject.entityType;
      default:
        return '';
    }
  }

  private getMessageByEntity(notification: NotificationWithAuthor, title: string, author: UserProfile): string {
    switch (notification.notificationObject.notificationType) {
      case 'shared':
        return `${author?.username ?? 'Someone'} shared ${title} with you`;
      case 'comment':
        return `${author?.username ?? 'Someone'} commented on ${title}: '${notification.entity.threadBody}'`;
      case 'thread_reply':
        return `${author?.username ?? 'Someone'} replied to comment on ${title}: '${notification.entity.commentBody}'`;
      case 'list_item_check':
        return `${author?.username ?? 'Someone'} marked as done ${title}`;
      case 'list_item_uncheck':
        return `${author?.username ?? 'Someone'} unchecked ${title}`;
      case 'entity_tag':
        return `${author?.username ?? 'Someone'} mentioned you in ${title}`;
      case 'item_changed':
        return `${author?.username ?? 'Someone'} updated ${title}`;
      case 'friend_request':
        return `${author?.username ?? 'Someone'} wants to be friends`;
      case 'liked':
        return Number(notification.entity.likesCount) > 1
          ? `${author?.username ?? 'Someone'} + ${
              Number(notification.entity.likesCount) - 1
            } others liked your ${this.relatedEntity(notification)}`
          : `${author?.username ?? 'Someone'} liked your ${this.relatedEntity(notification)}`;
      case 'group_invite':
        return `${author?.username ?? 'Someone'} invited you to join ${title}`;
      case 'group_request':
        return `${author?.username ?? 'Someone'} wants to join your ${title}`;
      case 'group_ownership_change':
        return `${author?.username ?? 'Deleted user'} made you admin in ${title}`;
      case 'share_location':
        return `${author?.username ?? 'Someone'} started broadcasting own location in ${title}`;
      case 'waypoint_checkin':
        return `${author?.username ?? 'Someone'} just checked in at ${title}: ${
          (notification.entity as unknown as TripCampsite).formatted_address
        }`;
      case 'trip_add_memory':
        return `${author.username} added new memory to ${title}`;
      default:
        return '';
    }
  }
  private relatedEntity(notification: NotificationWithAuthor): string {
    switch (notification.relatedEntityType) {
      case EntityType.JournalEntry:
        return 'memory';
      case EntityType.Campsite:
        return `waypoint`;
      case EntityType.BlogPost:
        return `story`;
      case EntityType.GroupTimeline:
        return `timeline entry`;
      case EntityType.List:
      case EntityType.ListItem:
      case EntityType.Group:
      case EntityType.Itinerary:
      case EntityType.Trip:
      case EntityType.Photo:
      case EntityType.Thread:
      case EntityType.Comment:
      case EntityType.Video:
        return notification.relatedEntityType;
      default:
        return '';
    }
  }

  private getActionByEntity(notification: NotificationWithAuthor, entityTitle: string): string {
    const title = toTitleCase(entityTitle);
    switch (notification.notificationObject.notificationType) {
      case 'shared':
        return `${title} has been shared with you`;
      case 'comment':
        return `New comment received`;
      case 'thread_reply':
        return 'New reply to your thread';
      case 'list_item_check':
        return `${title} has been marked as done`;
      case 'list_item_uncheck':
        return `${title} has been unchecked`;
      case 'entity_tag':
        return `You have been tagged at ${title}`;
      case 'item_changed':
        return `${title} has been updated`;
      case 'friend_request':
        return `New friend request`;
      case 'liked':
        return `${title} like(s)`;
      case 'group_invite':
        return `New group invitation`;
      case 'group_request':
        return `New group request`;
      case 'share_location':
        return `User Location Sharing`;
      case 'waypoint_checkin':
        return `Someone just checked in!`;
      case 'trip_add_memory':
        return `New memory was added to shared trip`;
      default:
        return '';
    }
  }
  private async navigateToEntity(notification: NotificationWithAuthor) {
    const url = await this.getRoute(notification);
    if (url) {
      this.router.navigateByUrl(url);
    } else {
      this.showMobileAppPopup();
    }
  }

  async getRoute(notification: NotificationWithAuthor): Promise<string> {
    const entityId = notification.entity.id;
    switch (notification.notificationObject.notificationType) {
      case 'shared':
        switch (notification.relatedEntityType) {
          case EntityType.Trip:
            return `/my-account/trips/${(notification.entity as Trip).id}/t/timeline`;
          case EntityType.GroupTimeline:
            return `/my-account/explore/${(notification.entity as GroupTimeline).id}`;
          case EntityType.JournalEntry:
          case EntityType.Campsite:
            return this.getExploreUrl((notification.entity as TripJournalEntry).id as string);
        }
        break;
      case 'comment':
        switch (notification.relatedEntityType) {
          case EntityType.GroupTimeline:
            return `/my-account/explore/${(notification.entity as GroupTimeline).id}`;
          case EntityType.JournalEntry:
          case EntityType.Campsite:
            return this.getExploreUrl((notification.entity as TripJournalEntry).id as string);
          case EntityType.BlogPost:
            return `/my-account/writings/view/${(notification.entity as BlogPost).slug}`;
        }
        break;
      case 'thread_reply':
        switch (notification.relatedEntityType) {
          case EntityType.GroupTimeline:
            return `/my-account/explore/${(notification.entity as GroupTimeline).id}`;
          case EntityType.JournalEntry:
          case EntityType.Campsite:
            return this.getExploreUrl((notification.entity as TripJournalEntry).id as string);
          case EntityType.BlogPost:
            return `/my-account/writings/view/${(notification.entity as BlogPost).slug}`;
        }
        break;
      case 'list_item_check':
        return '';
      case 'list_item_uncheck':
        return '';
      case 'entity_tag':
        switch (notification.relatedEntityType) {
          case EntityType.GroupTimeline:
            return `/my-account/explore/${(notification.entity as GroupTimeline).id}`;
          case EntityType.JournalEntry:
          case EntityType.Campsite:
            return this.getExploreUrl((notification.entity as TripJournalEntry).id as string);
        }
        break;
      case 'item_changed':
        return '';
      case 'friend_request':
        return '/my-account/friends';
      case 'liked':
        switch (notification.relatedEntityType) {
          case EntityType.GroupTimeline:
            return `/my-account/explore/${(notification.entity as GroupTimeline).id}`;
          case EntityType.JournalEntry:
          case EntityType.Campsite:
            return this.getExploreUrl((notification.entity as TripJournalEntry).id as string);
          case EntityType.BlogPost:
            return `/my-account/writings/view/${(notification.entity as BlogPost).slug}`;
        }
        break;
      case 'group_invite':
        return `/my-account/groups/${entityId}/timeline`;
      case 'group_request':
        return `/my-account/groups/${entityId}/requests`;
      case 'group_ownership_change':
        return `/my-account/groups/${entityId}/members`;
      case 'share_location':
        return `/my-account/groups/${entityId}/timeline`;
      case 'waypoint_checkin':
        return `/my-account/trips/${(notification.entity as TripCampsite).tripId}/t/timeline`;
      case 'trip_add_memory':
        switch (notification.relatedEntityType) {
          case EntityType.Trip:
            return `/my-account/trips/${(notification.entity as Trip).id}/t/timeline`;
          case EntityType.JournalEntry:
          case EntityType.Campsite:
            return this.getExploreUrl((notification.entity as TripJournalEntry).id as string);
        }
        break;
      default:
        return '';
    }

    return '';
  }

  private async getExploreUrl(entityId: string): Promise<string> {
    const entity = await this.exploreApiService.getByEntityId(entityId).toPromise();
    return `/my-account/explore/${entity.id}`;
  }
}

function toTitleCase(str: string) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
