import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { GalleryConfig } from '../../interfaces';

@Component({
  selector: 'ct-gallery-thumb',
  templateUrl: './gallery-thumb.component.html',
  styleUrls: ['./gallery-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryThumbComponent {
  @Input() config: GalleryConfig | null;

  @Input() index: number | undefined;
  @Input() currIndex: number | undefined;
  @Input() type: string | undefined;
  @Input() data: any;

  @Output() errorItem = new EventEmitter<Error>();

  @HostBinding('class.g-active-thumb') get isActive() {
    return this.index === this.currIndex;
  }
}
