import { Injectable } from '@angular/core';
import { BlogPost, BlogPostResponse, UploadedImage } from '@ct/core';

@Injectable({
  providedIn: 'root'
})
export class BlogPostFactory {
  toRequest(post: Partial<BlogPost>): BlogPostResponse {
    const { id, photos, videos, featuredPhoto, ...value } = post as BlogPost;
    return {
      ...value,
      photoIds: photos?.map((photo: UploadedImage) => photo.id) as string[],
      videoIds: videos?.map((video: any) => video.id) as string[], // Video interface
      featuredPhotoId: featuredPhoto?.id as string
    };
  }
}
