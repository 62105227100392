<div class="ct-my-account-marketplace-item">
  <div class="image">
    <ct-image-with-tags
      class="image"
      [src]="featured"
      [publicUrl]="publicUrl"
      [thumbnail]="thumbnail"
      height="160px"
      width="100%"
      objectFit="cover"
      [altText]="item?.title + ' featured photo'"
    ></ct-image-with-tags>
    <ct-tag class="price mr-1" [name]="tag?.name"></ct-tag>
  </div>
  <div class="title mb-2 mt-2" (click)="clicked.emit($event)">{{ item?.title }}</div>
  <div class="address mb-2 mt-2">{{ item?.createdOn | date }}</div>
  <div class="address mb-2 mt-2">
    <p class="short-description" [innerHTML]="$any(item?.description) | striphtml | safe: 'html'"></p>
  </div>
</div>
