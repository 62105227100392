import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'ct-gallery-video',
  templateUrl: './gallery-video.component.html',
  styleUrls: ['./gallery-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryVideoComponent implements OnInit {
  videoSources: { url: string; type?: string }[];
  controls: boolean;

  @Input() src: string | { url: string; type?: string }[];
  @Input() poster: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() controlsEnabled: boolean;

  @Input() set pause(shouldPause: boolean) {
    if (this.video.nativeElement) {
      const video: HTMLVideoElement = this.video.nativeElement;
      if (shouldPause && !video.paused) {
        video.pause();
      }
    }
  }

  @Input() set play(shouldPlay: boolean) {
    if (this.video.nativeElement) {
      const video: HTMLVideoElement = this.video.nativeElement;
      if (shouldPlay) {
        video.play();
      }
    }
  }

  @Output() errorItem = new EventEmitter<Error | any>();

  @ViewChild('video', { static: true }) video: ElementRef;

  ngOnInit() {
    if (this.src instanceof Array) {
      this.videoSources = [...this.src];
    } else {
      this.videoSources = [{ url: this.src }];
    }
    this.controls = typeof this.controlsEnabled === 'boolean' ? this.controlsEnabled : true;
  }
}
