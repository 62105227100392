import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogPost, DestroyableFeature, Features, Tag, UserProfile } from '@ct/core';
import { entitySlugUrl, getCroppedThumbPublicUrl, Trip, TripCampsite } from '@ct/shared';
import { Observable } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { MarketplaceItem } from '../../marketplace/interfaces';

@Component({
  selector: 'ct-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SearchResultsComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  get posts$(): Observable<BlogPost[]> {
    return this.route.data.pipe(
      map(({ search }) => search?.posts),
      shareReplay(1)
    );
  }

  get tags$(): Observable<Tag[]> {
    return this.route.data.pipe(
      map(({ search }) => search?.tags),
      shareReplay(1)
    );
  }

  get users$(): Observable<UserProfile[]> {
    return this.route.data.pipe(
      map(({ search }) => search?.users),
      shareReplay(1)
    );
  }

  get trips$(): Observable<Trip[]> {
    return this.route.data.pipe(
      map(({ search }) => search?.trips),
      shareReplay(1)
    );
  }

  get campsites$(): Observable<TripCampsite[]> {
    return this.route.data.pipe(
      map(({ search }) => search?.campsites),
      shareReplay(1)
    );
  }

  get marketplaceItems$(): Observable<MarketplaceItem[]> {
    return this.route.data.pipe(
      map(({ search }) => search?.marketplaceItems),
      shareReplay(1)
    );
  }

  constructor(private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {}

  query: string;

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(({ query }) => {
      this.query = query;
      this.changeDetectorRef.detectChanges();
    });
  }

  getStoriesLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }

  getMarketplaceLink(item: MarketplaceItem) {
    return entitySlugUrl('/marketplace/', item);
  }

  getCampsiteLink(campsite: TripCampsite) {
    // TODO: need trip slug here.
    return `/my-account/trips/${campsite.tripId}/trip/campsite/${campsite.id}`;
  }

  getTripLink(trip: Trip) {
    return `/my-account/trips/${trip.id}/${trip.slug}`;
  }
}
