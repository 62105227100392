import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconModule } from '../icon';
import { UserAvatarModule } from '../user-avatar';
import { UserRowComponent } from './user-row.component';

@NgModule({
  declarations: [UserRowComponent],
  imports: [CommonModule, IconModule, UserAvatarModule, RouterModule],
  exports: [UserRowComponent]
})
export class UserRowModule {}
