import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControlName, NgControl, UntypedFormControl } from '@angular/forms';
import { BaseControlComponent, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ct-re-captcha',
  templateUrl: './re-captcha.component.html',
  styleUrls: ['./re-captcha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ReCaptchaComponent extends BaseControlComponent<string> implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly control = new UntypedFormControl(null);

  constructor(
    @Optional() @Inject(NgControl) readonly ctrl: FormControlName,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() readonly formState: FormStateDispatcher | null
  ) {
    super();
    if (this.ctrl && !this.ctrl.valueAccessor) {
      this.ctrl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initFormControlValidations(this.ctrl, this.formState, this.changeDetector);
  }

  onFocus() {
    this.onTouched?.();
  }
}
