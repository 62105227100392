<ct-card-section class="mb-5" [title]="'MY_ACCOUNT.MY_PROFILE_FEATURE.CHANGE_EMAIL_ADDRESS' | translate">
  <mat-stepper orientation="vertical" [disableRipple]="true" #stepper>
    <mat-step [completed]="false" [editable]="false">
      <ng-template matStepLabel> {{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.START' | translate }}</ng-template>
      <ng-template matStepContent>
        <p>{{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.BEFORE_YOU_START' | translate }}</p>
        <ul>
          <li>{{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.YOUR_NEW_EMAIL' | translate }}</li>
        </ul>
        <div class="row no-gutters mt-1">
          <ct-button class="col-6 col-md-2" variant="flat" color="accent" [fullWidth]="true" (click)="start()">{{
            'MY_ACCOUNT.MY_PROFILE_FEATURE.START' | translate
          }}</ct-button>
        </div>
      </ng-template>
    </mat-step>
    <mat-step [completed]="false" [editable]="false">
      <ng-template matStepLabel>{{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.CONFIRM_CURRENT_EMAIL' | translate }}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="confirmCurrentEmailForm" (ngSubmit)="onConfirmCurrentEmailAddress()">
          <div class="row">
            <ct-input
              class="col-12 col-md-6"
              [label]="'MY_ACCOUNT.MY_PROFILE_FEATURE.NEW_EMAIL_ADDRESS' | translate"
              [placeholder]="'MY_ACCOUNT.MY_PROFILE_FEATURE.NEW_EMAIL_ADDRESS_PLACEHOLDER' | translate"
              formControlName="newEmailAddress"
            ></ct-input>
          </div>
          <div class="row no-gutters mt-1">
            <ct-button class="col-6 col-md-2" type="submit" variant="flat" color="accent" [fullWidth]="true">{{
              'MY_ACCOUNT.MY_PROFILE_FEATURE.CONFIRM' | translate
            }}</ct-button>
          </div>
        </form>
      </ng-template>
    </mat-step>
    <mat-step [completed]="false" [editable]="false">
      <ng-template matStepLabel>{{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.CONFIRM_NEW_EMAIL' | translate }}</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="confirmNewEmailForm" (ngSubmit)="onConfirmNewEmailAddress()">
          <div class="row mb-3">
            <div class="col-12">
              <div>
                {{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.WE_JUST_SENT_CODE_NEW_EMAIL' | translate }}
                <strong> {{ newEmailAddress }} </strong>.
                {{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.IN_ORDER_TO_CONFIRM_EMAIL_ADDRESS' | translate }}
              </div>
              <div>
                {{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.EMAIL_DELIVERY_CAN_TAKE_MINUTE' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <ct-input
              class="col-12 col-md-6"
              [label]="'MY_ACCOUNT.MY_PROFILE_FEATURE.CODE' | translate"
              [placeholder]="'MY_ACCOUNT.MY_PROFILE_FEATURE.CODE_PLACEHOLDER' | translate"
              [mask]="'000-000'"
              formControlName="code"
            ></ct-input>
          </div>
          <div class="row no-gutters mt-1">
            <ct-button class="col-6 col-md-2" type="submit" variant="flat" color="accent" [fullWidth]="true">{{
              'MY_ACCOUNT.MY_PROFILE_FEATURE.CONFIRM' | translate
            }}</ct-button>
          </div>
        </form>
      </ng-template>
    </mat-step>
    <mat-step [completed]="false" [editable]="false">
      <ng-template matStepLabel>{{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.FINISH' | translate }}</ng-template>
      <ng-template matStepContent>
        <div>
          <p>
            {{ 'MY_ACCOUNT.MY_PROFILE_FEATURE.YOU_SUCCESSFULLY_CHANGED_YOUR_EMAIL' | translate }}
            <strong>{{ newEmailAddress }}</strong>
          </p>
          <div class="row no-gutters mt-1">
            <ct-link [link]="['..']">
              <ct-button variant="flat" color="accent" [fullWidth]="true">{{
                'MY_ACCOUNT.MY_PROFILE_FEATURE.GO_TO_PROFILE' | translate
              }}</ct-button>
            </ct-link>
          </div>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</ct-card-section>
