import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { GalleryModule } from '../gallery';
import { IconModule } from '../icon';
import { LightboxComponent } from './components';
import { LightboxDirective } from './directives';
import { LightboxConfig } from './interfaces';
import { LightboxService } from './services';
import { LIGHTBOX_CONFIG } from './tokens';

@NgModule({
  imports: [CommonModule, OverlayModule, GalleryModule, A11yModule, IconModule],
  declarations: [LightboxComponent, LightboxDirective],
  providers: [LightboxService],
  exports: [LightboxDirective]
})
export class LightboxModule {
  static withConfig(config: LightboxConfig): ModuleWithProviders<LightboxModule> {
    return {
      ngModule: LightboxModule,
      providers: [
        {
          provide: LIGHTBOX_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
