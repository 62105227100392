<ng-container *ngIf="isApiLoaded$ | async">
  <mat-label *ngIf="label">
    <span>{{ label }}</span>
    <a (click)="currentLocation()" class="location-link">Set Current</a>
  </mat-label>
  <mat-form-field appearance="outline">
    <input
      [formControl]="control"
      matInput
      ngx-google-places-autocomplete
      [options]="options"
      #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)"
      [placeholder]="placeholder | translate"
    />
  </mat-form-field>
</ng-container>
