import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MerchantProfileApiService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class CanCreateMerchantProfileGuard implements CanActivate {
  constructor(private merchantProfileApiService: MerchantProfileApiService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.merchantProfileApiService.getMyProfile().pipe(
      map((merchantProfile) => {
        return !merchantProfile;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of(true);
        } else if (error.status === 400) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
        return of(false);
      })
    );
  }
}
