import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ImageLoading } from '../../enums';

@Component({
  selector: 'ct-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent {
  @Input() src: string | undefined;
  @Input() alt: string | undefined = '';
  @Input() removable: boolean | undefined = false;
  @Input() loading: ImageLoading = ImageLoading.Auto;

  @Output() removed = new EventEmitter<void>();
}
