<section class="thread-new">
  <form [formGroup]="form">
    <div class="d-flex">
      <ct-user-avatar class="photo" height="40px" width="40px" [src]="user?.photo?.xsPreview"></ct-user-avatar>
      <ct-text-editor class="text-editor" formControlName="body" [modules]="modules"></ct-text-editor>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <ct-button *ngIf="cancelable" class="mr-2" variant="flat" color="primary" (click)="onCancel()">{{
        cancelButtonKey | translate
      }}</ct-button>
      <ct-button variant="flat" color="accent" (click)="onSubmit()">{{ submitButtonKey | translate }}</ct-button>
    </div>
  </form>
</section>
