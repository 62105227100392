import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SpinnerService } from '@ct/components';
import { FormStateDispatcher, MediaArchiveLinks } from '@ct/core';
import { MyAccountPhotoApiService } from '@ct/shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-profile-download-media',
  templateUrl: './my-account-profile-download-media.component.html',
  styleUrls: ['./my-account-profile-download-media.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountProfileDownloadMediaComponent {
  protected archive: MediaArchiveLinks | undefined;

  get links(): string[] {
    return this.archive?.links ?? [];
  }

  get modifiedOn(): Date {
    return this.archive?.modifiedOn as Date;
  }

  constructor(
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {
    this.loadArchives();
  }

  loadArchives() {
    this.spinnerService.show({ instant: true });
    this.myAccountPhotoApiService
      .getArchives()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe((archive) => {
        this.archive = archive;
        this.cdr.markForCheck();
      });
  }
}
