<section>
  <ng-container *ngIf="!loggedIn">
    <ct-image-carousel [auto]="true">
      <ct-image-carousel-item *ngFor="let bannerImage of unregisteredBannerImages" [src]="bannerImage">
        <div class="content">
          <h2 class="title">{{ 'HOME.BANNER.TITLE' | translate }}</h2>
          <h3 class="sub-title">{{ 'HOME.BANNER.SUB_TITLE' | translate }}</h3>
          <ct-link [link]="['/register']">
            <ct-button variant="flat" color="accent">{{ 'HOME.BANNER.REGISTER_NOW_BUTTON' | translate }}</ct-button>
          </ct-link>
        </div>
      </ct-image-carousel-item>
    </ct-image-carousel>
    <ct-why-register></ct-why-register>
  </ng-container>
  <ng-container *ngIf="loggedIn">
    <ct-image-carousel *ngIf="posts?.length" [auto]="true">
      <ct-image-carousel-item *ngFor="let post of posts" [src]="post.featuredPhoto?.path">
        <ct-link [link]="getLink(post)">
          <div class="content">
            <div class="author">
              <ct-author
                class="mr-3"
                [username]="post?.author?.username"
                [userId]="post?.author?.userId"
                [photo]="post?.author?.photo?.xsPreview"
                [date]="post?.createdOn"
                light="true"
              ></ct-author>
              <div>
                <ct-tag class="mr-1 mb-1" *ngFor="let tag of post?.tags" [name]="tag?.name"></ct-tag>
              </div>
            </div>
            <h2 class="title">{{ post.title }}</h2>
            <h3 class="description">{{ post.body | striphtml }}</h3>
          </div></ct-link
        >
      </ct-image-carousel-item>
    </ct-image-carousel>
  </ng-container>
</section>
