<ct-dialog-content
  titleKey="MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISH_PRIVATELY"
  [closeIcon]="true"
  [buttons]="buttons"
  alignButtons="end"
>
  <div class="row">
    <div class="group col-12">
      <div>
        <p class="group__name">Publish on Stories page</p>
      </div>
      <ct-toggle [value]="isPublic" (valueChange)="onTogglePublic($event)"></ct-toggle>
    </div>
  </div>
  <ng-container *ngFor="let group of groups">
    <div class="row">
      <div class="group col-12">
        <div>
          <p class="group__name">{{ group.title }}</p>
        </div>
        <ct-toggle [value]="sharedGroupIds.has(group.id)" (valueChange)="onShare($event, group)"></ct-toggle>
      </div>
    </div>
  </ng-container>
</ct-dialog-content>
