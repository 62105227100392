import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconType, ImageUploadService, MediaSelectComponent, SpinnerService } from '@ct/components';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  VideoOrientation
} from '@ct/core';
import { MyAccountPhotoApiService } from '@ct/shared';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

enum MediaEntityType {
  Photos = 'photos',
  Video = 'video'
}

interface AddOption {
  icon: IconType;
  titleKey: string;
  type: MediaEntityType;
}

@Component({
  selector: 'ct-add-media-dialog',
  templateUrl: './add-media-dialog.component.html',
  styleUrls: ['./add-media-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class AddMediaDialogComponent {
  public readonly destroyed$: Observable<void>;
  public readonly addOptions: AddOption[] = [
    {
      icon: 'photo',
      titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.PHOTOS',
      type: MediaEntityType.Photos
    },
    {
      icon: 'video-camera',
      titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.VIDEOS',
      type: MediaEntityType.Video
    }
  ];

  @ViewChild(MediaSelectComponent)
  private readonly mediaSelectComponent: MediaSelectComponent;

  constructor(
    private dialogRef: MatDialogRef<AddMediaDialogComponent>,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private spinnerService: SpinnerService,
    private readonly snackBar: MatSnackBar
  ) {}

  onOptionSelect(option: AddOption) {
    switch (option.type) {
      case MediaEntityType.Photos:
        return this.onAddPhotos();
      case MediaEntityType.Video:
        return this.mediaSelectComponent.onFilesSelected();
    }
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: false,
        showExisting: false,
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close({ reload: true });
      });
  }

  async onAddVideo(files: File[]) {
    const file = files[0];
    if (file) {
      let orientation = VideoOrientation.Landscape;
      let width = 0;
      let height = 0;
      const duration = await this.onGetDuration(file);
      try {
        const result = (await this.onGetOrientation(file)) ?? { width, height, orientation };
        orientation = result.orientation;
        width = result.width;
        height = result.height;
      } catch (e) {
        return this.snackBar.open(`Error parsing video "${file.name}". Please, select another video.`, 'close', {
          duration: 10000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom'
        });
      }
      this.spinnerService.show({ instant: true });
      this.videoUploadApiService
        .getUploadLink({ orientation, duration, width, height, fileName: file.name })
        .pipe(
          switchMap(({ uploadLink, ...video }) => {
            return this.videoUploadApiService.useUploadLink(file, uploadLink).pipe(map(() => video));
          }),
          catchError((err) => {
            this.spinnerService.hide();
            if (err.error.error && err.error.message) {
              this.snackBar.open(`${err.error.error}: ${err.error.message}`, 'close', {
                duration: 10000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom'
              });
            }
            return of(null);
          }),
          filter(Boolean)
        )
        .subscribe(() => {
          this.spinnerService.hide();
          this.dialogRef.close({ reload: true });
        });
    }
  }

  async onGetOrientation(videoFile: Blob) {
    // eslint-disable-next-line no-prototype-builtins
    if (HTMLVideoElement.prototype.hasOwnProperty('requestVideoFrameCallback')) {
      const video = await this.getVideoElement(videoFile);
      video.muted = true;
      video.playbackRate = 16;
      const { videoWidth, videoHeight } = video;
      video.pause();
      window.URL.revokeObjectURL(video.src);
      video.remove();
      const orientation = videoWidth < videoHeight ? VideoOrientation.Portrait : VideoOrientation.Landscape;
      return { width: videoWidth, height: videoHeight, orientation };
    } else {
      console.error("your browser doesn't support this API yet");
    }
  }

  async onGetDuration(videoFile: Blob) {
    return new Promise<number>((resolve) => {
      // eslint-disable-next-line no-prototype-builtins
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        video.remove();
        resolve(duration);
      };

      video.src = URL.createObjectURL(videoFile);
    });
  }

  private async getVideoElement(videoFile: Blob) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    video.src = URL.createObjectURL(videoFile);
    document.body.append(video);
    await video.play();
    return video;
  }
}
