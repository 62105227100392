<ng-container *ngIf="isLoggedIn$ | async; else defaultTemplate">
  <ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async" [showSearch]="false">
    <ct-extended-header
      ctHeader
      icon="shopping-cart"
      description="Buy and Sell Used Camping Equipment"
      title="Marketplace"
    ></ct-extended-header>

    <div ctContent>
      <ct-tab-header
        class="d-none d-md-flex"
        [tabs]="tabs"
        style="margin-bottom: -1px"
        (tabChange)="onFilterChanged($event)"
      >
        <ct-icon ctTabPrefix class="add__icon" title="Add New Item" icon="add-circle" (click)="addItems()"></ct-icon>
      </ct-tab-header>
      <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)">
        <ct-icon ctTabPrefix class="add__icon" icon="add-circle" (click)="addItems()"></ct-icon>
      </ct-tabs-mobile>
      <div class="with-background row">
        <div class="col-12">
          <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
        </div>
      </div>
    </div>
  </ct-layout>
</ng-container>

<ng-template #defaultTemplate>
  <div class="container">
    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
  </div>
</ng-template>

<ng-template #defaultContent>
  <div class="row flex-md-row flex-column-reverse">
    <div class="col-12 col-md-3 pt-2 pt-md-4 categories-filter">
      <h2>Categories</h2>
      <p class="mb-1">
        <a (click)="clearSelection($event)" class="clear-filter">X {{ 'MARKETPLACE.CLEAR_FILTER' | translate }}</a>
      </p>
      <ct-data-list
        class="mb-4"
        [column]="listColumn"
        [data]="categories"
        [selectedItem]="selectedEntity"
        (selectedItemChange)="onEntitySelected($event)"
        (dataListReady)="onDataListReady($event)"
      ></ct-data-list>
    </div>
    <div class="col-12 col-md-9 mt-4 marketplace-items">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
