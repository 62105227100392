import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tag, UploadedImage, UserProfile } from '@ct/core';

import { getYoutubeId } from '../../helpers';

export interface CardItem {
  id?: string;
  featuredYoutubeVideo?: string;
  featuredPhoto?: UploadedImage;
  tags?: Tag[];
  title?: string;
  author?: UserProfile;
}

@Component({
  selector: 'ct-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardItemComponent {
  @Input() showLink = false;
  @Input() showAuthor = true;
  @Input() showTags = true;
  @Input() imgHeight: string;
  @Input() imgWidth: string;
  @Input() objectFit = 'cover';
  @Input() item: CardItem | undefined;
  @Input() creationDate: string | Date | undefined;
  @Input() description: string | undefined;
  @Input() link: string;
  @Input() imgCols = 3;
  @Input() contentCols = 9;
  @Input() contentMargin: boolean;
  @Input() linkFullWidth: boolean;

  get placeholderPath(): string {
    let index = 0;
    if (this.item?.id) {
      index = Number(this.item.id) % 4;
    }
    return `assets/placeholders/camping_bg_${index}.jpeg`;
  }

  get thumbnail(): string | undefined {
    return this.item?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.item?.featuredYoutubeVideo)}/default.jpg`
      : this.item?.featuredPhoto?.xsPreview;
  }

  get publicUrl(): string | undefined {
    return this.item?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.item?.featuredYoutubeVideo)}/hqdefault.jpg`
      : this.item?.featuredPhoto?.publicUrl;
  }

  get featured(): string | undefined {
    return this.item?.featuredPhoto?.path;
  }

  get isHorizontal() {
    return this.imgCols === 12;
  }
}
