<ct-dialog-content
  titleKey="COMMON.FLAG_INAPPROPRIATE"
  [closeIcon]="true"
  headerIcon="flag"
  [buttons]="buttons"
  [alignButtons]="'end'"
>
  <form class="form" [formGroup]="form">
    <div class="row">
      <h2>Reason</h2>
      <ct-text-editor class="col-12" formControlName="reason" [modules]="modules"></ct-text-editor>
    </div>
  </form>
</ct-dialog-content>
