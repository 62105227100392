<img
  *ngIf="src"
  [src]="src"
  alt="user-avatar"
  [ngStyle]="{ width: width, height: height }"
  [class.round]="round"
  loading="lazy"
/>
<ct-icon
  *ngIf="!src"
  [ngStyle]="{ width: width, height: height }"
  [class.round]="round"
  size="fit"
  icon="no-avatar"
></ct-icon>
