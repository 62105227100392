import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { TripState, TripStore } from './trip.store';

@Injectable({ providedIn: 'root' })
export class TripQuery extends QueryEntity<TripState> {
  constructor(protected store: TripStore) {
    super(store);
  }

  // selectUpdatedEntityIds
}
