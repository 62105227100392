import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  AuthorModule,
  ButtonModule,
  CardModule,
  CheckboxModule,
  DialogModule,
  EmptyCallToActionModule,
  FilterPaneModule,
  IconModule,
  ImageModule,
  InputModule,
  KeywordInputModule,
  LikeModule,
  LinkModule,
  NavigationModule,
  SelectModule,
  SocialSharingModule,
  TabHeaderModule,
  TabsMobileModule,
  TagModule,
  TagSelectModule,
  TextEditorModule,
  ToggleModule
} from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule, MediaSharedModule, TripSharedModule, YoutubeModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { BlogModule } from '../../../blog';
import { MyAccountSharedModule } from '../my-account-shared';
import {
  MyAccountPostCreateComponent,
  MyAccountPostViewComponent,
  MyAccountWritingsComponent,
  PublishStoryDialogComponent
} from './components';
import { MyAccountWritingsRoutingModule } from './my-account-writings-routing.module';

@NgModule({
  declarations: [
    MyAccountWritingsComponent,
    MyAccountPostCreateComponent,
    MyAccountPostViewComponent,
    PublishStoryDialogComponent
  ],
  imports: [
    CommonModule,
    MyAccountWritingsRoutingModule,
    CardModule,
    CheckboxModule,
    TranslateModule,
    MyAccountSharedModule,
    AuthorModule,
    TagModule,
    ImageModule,
    ButtonModule,
    IconModule,
    LikeModule,
    InputModule,
    ReactiveFormsModule,
    TextEditorModule,
    TagSelectModule,
    LinkModule,
    BlogSharedModule,
    NavigationModule,
    MediaSharedModule,
    MatSnackBarModule,
    FilterPaneModule,
    KeywordInputModule,
    YoutubeModule,
    LayoutModule,
    SocialSharingModule,
    TabHeaderModule,
    TabsMobileModule,
    TripSharedModule,
    EmptyCallToActionModule,
    BlogModule,
    InfiniteScrollModule,
    DialogModule,
    ToggleModule,
    SelectModule
  ]
})
export class MyAccountWritingsModule {}
