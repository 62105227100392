<div class="row">
  <ct-video
    class="video col-6 col-md-{{ col }} mb-2"
    [class.cursor-pointer]="selectable"
    *ngFor="let video of _videos; let index = index; trackBy: trackByFn"
    [ngStyle]="{ height: videoHeight }"
    [src]="video?.source"
    [poster]="video?.poster"
    [playOnClick]="false"
    (playClicked)="onPlay(video?.id)"
  ></ct-video>
  <ct-new-item-placeholder
    *ngIf="showPlaceholder"
    [textKey]="'MY_ACCOUNT.MY_VIDEOS_FEATURE.ADD_NEW_VIDEO'"
    class="placeholder col-6 col-md-{{ col }} mb-2"
  ></ct-new-item-placeholder>
</div>
<p *ngIf="!_videos?.length && !showPlaceholder" class="flex-center">
  <span>{{ 'COMMON.EMPTY' | translate }}</span>
</p>
