<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header
    ctHeader
    icon="book"
    title="Writings"
    description="Write private memories or publish stories to the community"
  ></ct-extended-header>

  <div ctContent>
    <ct-card>
      <section class="post">
        <section class="d-flex justify-content-end align-items-center mb-3">
          <ct-blog-post-status class="mr-2" [status]="status"></ct-blog-post-status>
          <ng-container *ngTemplateOutlet="isEditMode ? edit : create"></ng-container>
        </section>
        <form class="form" [formGroup]="form">
          <div class="row">
            <ct-input
              class="col-12"
              [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE' | translate"
              [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
              formControlName="title"
            ></ct-input>
          </div>
          <div class="row">
            <ct-tag-select
              class="col-12"
              [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TAGS_PLACEHOLDER' | translate"
              [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.STORY_TAGS' | translate"
              [optionsFn]="optionsFn"
              [createFn]="createFn"
              [checkFn]="checkFn"
              [labelFn]="labelFn"
              formControlName="tags"
            ></ct-tag-select>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-md-3">
              <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.FEATURED_PHOTO' | translate }}</h2>
              <ct-new-item-placeholder
                class="placeholder"
                [img]="form.controls.featuredPhoto?.value?.publicUrl"
                [textKey]="'MY_ACCOUNT.MY_POSTS_FEATURE.ADD_NEW_PHOTO'"
                [replaceKey]="
                  form.controls.featuredPhoto?.value?.publicUrl ? 'MY_ACCOUNT.MY_POSTS_FEATURE.REPLACE_PHOTO' : ''
                "
                (placeholderClicked)="onSelectFeaturedPhotoPlaceholder()"
                (replaced)="onReplaceFeaturedPhoto()"
                (removed)="onRemoveFeaturedPhoto()"
              ></ct-new-item-placeholder>
            </div>
            <div class="col-12 col-md-9">
              <ct-input
                [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.PHOTO_TITLE' | translate"
                [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
                formControlName="featuredPhotoTitle"
              ></ct-input>
              <ct-keyword-input
                [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.KEYWORDS_PLACEHOLDER' | translate"
                [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.PHOTO_KEYWORDS' | translate"
                formControlName="featuredPhotoKeywords"
              ></ct-keyword-input>
            </div>
          </div>
          <div class="row">
            <ct-input
              class="col-12"
              [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.FEATURED_YOUTUBE_VIDEO' | translate"
              [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.YOUTUBE_VIDEO_PLACEHOLDER' | translate"
              formControlName="featuredYoutubeVideo"
            ></ct-input>
          </div>
          <div class="row">
            <ct-text-editor class="col-12" formControlName="body" [modules]="modules"></ct-text-editor>
          </div>
          <div class="mb-3">
            <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.PHOTOS' | translate }}</h2>
            <ct-photo-grid
              [photos]="form.controls.photos?.value"
              [showPlaceholder]="true"
              [removable]="true"
              (removed)="onRemovePhoto($event)"
              (placeholderSelected)="onAddPhotos()"
              [draggable]="true"
            ></ct-photo-grid>
          </div>
          <div class="mb-3">
            <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.YOUTUBE_VIDEOS' | translate }}</h2>
            <ct-youtube-video-grid
              [videos]="youtubeVideos.value | youtubeObjectArray"
              [showPlaceholder]="true"
              (addVideo)="onAddVideos()"
              [removable]="true"
              (removed)="removeVideo($event)"
            ></ct-youtube-video-grid>
          </div>
          <div class="row">
            <ct-input
              class="col-12"
              [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.NEW_SERIES' | translate"
              [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.NEW_SERIES_PLACEHOLDER' | translate"
              formControlName="seriesTitle"
            ></ct-input>
          </div>
          <div *ngIf="seriesList?.length" class="row">
            <ct-select
              class="col-12"
              [options]="seriesList"
              [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.EXISTING_SERIES' | translate"
              [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.EXISTING_SERIES_PLACEHOLDER' | translate"
              formControlName="seriesId"
            ></ct-select>
          </div>
          <!-- TODO: not implemented
          <div class="mb-3">
            <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.VIDEOS' | translate }}</h2>
            <ct-video-grid [videos]="form.controls.videos?.value" [showPlaceholder]="true"></ct-video-grid>
          </div>
          -->
          <div class="row mb-3">
            <ct-checkbox class="col-12" formControlName="allowComments">
              <ng-container>
                {{ 'MY_ACCOUNT.MY_POSTS_FEATURE.ALLOW_COMMENTS' | translate }}
              </ng-container>
            </ct-checkbox>
          </div>
          <div class="d-flex">
            <ng-container *ngTemplateOutlet="isEditMode ? edit : create"></ng-container>
          </div>
        </form>
      </section>
    </ct-card>
  </div>
</ct-layout>

<ng-template #edit>
  <ct-button *ngIf="isPublished" [disabled]="isLoading" variant="flat" color="primary" (click)="onSwitchDraft()">{{
    'MY_ACCOUNT.MY_POSTS_FEATURE.SWITCH_TO_DRAFT' | translate
  }}</ct-button>
  <ct-button
    *ngIf="isPublished || isDraft"
    class="ml-2"
    variant="flat"
    [color]="!isDraft ? 'accent' : 'primary'"
    (click)="onUpdate()"
    >{{ 'COMMON.UPDATE' | translate }}</ct-button
  >
  <ct-button *ngIf="requiresAction" class="ml-2" variant="flat" color="accent" (click)="submitForApproval()">{{
    'MY_ACCOUNT.MY_POSTS_FEATURE.SUBMIT_APPROVAL' | translate
  }}</ct-button>
  <ct-button [disabled]="isLoading" class="ml-2" variant="flat" color="accent" (click)="onPublish()">{{
    'MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISH' | translate
  }}</ct-button>
  <ct-button class="ml-2" variant="flat" color="warn" (click)="onDelete()">{{ 'COMMON.DELETE' | translate }}</ct-button>
</ng-template>

<ng-template #create>
  <ct-button [disabled]="isLoading" class="mr-2" variant="flat" color="primary" (click)="onSaveDraft()">{{
    'MY_ACCOUNT.MY_POSTS_FEATURE.SAVE_DRAFT' | translate
  }}</ct-button>
  <ct-button [disabled]="isLoading" variant="flat" color="accent" (click)="onPublish()">{{
    'MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISH' | translate
  }}</ct-button>
</ng-template>
