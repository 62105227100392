import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { SortOrder } from '@ct/shared/enums';
import { SetActiveOptions } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TripCampsiteFactory } from '../factories';
import { TripCampsite } from '../interfaces';
import { CampsiteStore } from './state';

const endpoint = environment.tripApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class TripCampsiteApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    public tripCampsiteFactory: TripCampsiteFactory,
    private store: CampsiteStore
  ) {
    super(httpClient, endpoint);
  }

  getAllByTripId(
    tripId: string,
    options?: {
      photoId?: string;
      range?: RequestRange;
      sortOrder?: SortOrder;
    }
  ): Observable<TripCampsite[]> {
    const sortOrder = options?.sortOrder ?? SortOrder.Asc;
    let params = new HttpParams();
    if (options?.photoId !== undefined && options.photoId !== null) {
      params = params.append('photoId', options.photoId);
    }
    if (options?.range?.limit !== undefined && options.range.limit !== null) {
      params = params.append('limit', options.range.limit as number);
    }
    if (options?.range?.offset !== undefined && options.range.offset !== null) {
      params = params.append('offset', options.range.offset as number);
    }

    params = params.append('sortOrder', sortOrder);
    this.store.setLoading(true);

    return this.get(`${tripId}/campsites`, params).pipe(
      tap((campsites) => {
        if (campsites?.length) {
          this.store.upsertMany(campsites);
        }
        this.store.setLoading(false);
      })
    );
  }

  getById(tripId: string, campsiteId: string): Observable<TripCampsite> {
    this.store.setLoading(true);
    return this.get(`${tripId}/campsites/${campsiteId}`).pipe(
      tap((campsite) => {
        this.store.upsert(campsiteId, campsite);
        this.store.setLoading(false);
        this.setActive(campsiteId);
      })
    );
  }

  create(tripId: string, campsite: TripCampsite): Observable<TripCampsite> {
    this.store.setLoading(true);
    const request = this.tripCampsiteFactory.toRequest(campsite);
    return this.post(`${tripId}/campsites`, request).pipe(
      tap((updatedCampsite) => {
        this.store.upsert(updatedCampsite.id, updatedCampsite);
        this.store.setLoading(false);
        this.setActive(updatedCampsite.id as string);
      })
    );
  }

  update(tripId: string, entryId: string, campsite: Partial<TripCampsite>): Observable<TripCampsite> {
    this.store.setLoading(true);
    const request = this.tripCampsiteFactory.toRequest(campsite);
    return this.patch(`${tripId}/campsites/${entryId}`, request).pipe(
      tap((updatedCampsite) => {
        this.store.upsert(entryId, updatedCampsite);
        this.store.setLoading(false);
        this.setActive(entryId);
      })
    );
  }

  remove(tripId: string, entryId: string) {
    this.store.setLoading(true);
    return this.delete(`${tripId}/campsites/${entryId}`).pipe(
      tap(() => {
        this.store.remove(entryId);
        this.store.setLoading(false);
      })
    );
  }

  getAll(range?: RequestRange): Observable<TripCampsite[]> {
    this.store.setLoading(true);

    return this.get(`any/campsites/all`, range).pipe(
      tap((campsites) => {
        if (campsites?.length) {
          this.store.upsertMany(campsites);
        }
        this.store.setLoading(false);
      })
    );
  }

  search(search: string, range?: RequestRange): Observable<TripCampsite[]> {
    this.store.setLoading(true);

    return this.get(`any/campsites/search`, { query: search, ...range }).pipe(
      tap((campsites) => {
        if (campsites?.length) {
          this.store.upsertMany(campsites);
        }
        this.store.setLoading(false);
      })
    );
  }

  setActive(id: string) {
    this.store.setActive(id as SetActiveOptions); // exactly same code doesn't require type cast in tag-api.service...
  }
}
