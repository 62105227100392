import { Injectable } from '@angular/core';
import { Tag } from '@ct/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TagsState extends EntityState<Tag> {
  id?: string;
  reserved?: boolean;
  authorId?: string;
  createdOn?: string;
  name: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tags' })
export class TagsStore extends EntityStore<TagsState> {
  constructor() {
    super();
  }
}
