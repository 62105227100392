import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProfileQuery } from '@ct/auth';
import {
  BlogPost,
  DestroyableFeature,
  FacebookCardType,
  Features,
  MetaTagService,
  SeoConfig,
  SocialMediaMetaTagFeature,
  Tag,
  TitleService,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import {
  BLOG_POSTS_FEATURE_LIMIT,
  BlogPostApiService,
  entitySlugUrl,
  PostsQuery,
  TagsQuery,
  trackById
} from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

interface PostQueryParams {
  offset: number;
  authorId?: string;
  tagId?: string;
  search?: string;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), DestroyableFeature()])
export class BlogPostsComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly limit = BLOG_POSTS_FEATURE_LIMIT;
  public seoConfig: SeoConfig = {
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.BLOG',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/blog.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/stories'
    }
  };

  public loading = false;
  public showLoadButton = true;
  public posts: BlogPost[] = [];
  public user: UserProfile;
  public tag: Tag;

  public queryParams: PostQueryParams = {
    offset: DEFAULT_OFFSET
  };
  public trackByFn = trackById;

  constructor(
    private route: ActivatedRoute,
    private blogPostApiService: BlogPostApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: TitleService,
    private metaTagService: MetaTagService,
    private translateService: TranslateService,
    public postsQuery: PostsQuery,
    public userProfileQuery: UserProfileQuery,
    public tagQuery: TagsQuery
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(({ offset, authorId, tagId, search }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || DEFAULT_OFFSET,
        authorId: authorId,
        tagId: tagId,
        search: search
      };
      this.changeDetectorRef.detectChanges();
    });

    this.postsQuery.selectPosts$.pipe(takeUntil(this.destroyed$)).subscribe((posts) => {
      this.refreshPosts(posts);
      this.initSeo();
    });
  }

  initSeo() {
    if (this.queryParams.authorId) {
      this.userProfileQuery
        .selectEntity(this.queryParams.authorId)
        .pipe(debounceTime(100), takeUntil(this.destroyed$))
        .subscribe((user) => {
          if (user && this.user !== user) {
            this.user = user as UserProfile;
            this.titleService.setTitle(`Stories of ${this.user.username}`);
            this.metaTagService.setMetaDescription(`Stories by ${this.user.username} - ${this.user.description}`);
          }
        });
    } else if (this.queryParams.tagId) {
      this.tagQuery
        .selectEntity(this.queryParams.tagId)
        .pipe(debounceTime(100), takeUntil(this.destroyed$))
        .subscribe((tag) => {
          if (tag) {
            this.tag = tag as Tag;
            this.titleService.setTitle(`Stories tagged ${this.tag.name}`);
            this.metaTagService.setMetaDescription(`Stories tagged with ${this.tag.name}`);
          }
        });
    } else {
      this.titleService.setTitleWithSuffix(this.translateService.instant('MAIN.FEATURES.BLOG'), 'All Stories');
      this.metaTagService.setMetaDescription('All latest stories');
    }
  }

  getLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadPosts(this.queryParams);
  }

  refreshPosts(posts: BlogPost[]) {
    if (this.queryParams.offset === 0) {
      this.posts = [...(posts ?? [])];
    } else {
      this.posts.push(...(posts ?? []));
    }
    this.showLoadButton = !(posts?.length === 0 || posts?.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  loadPosts({ offset, authorId, tagId, search }: PostQueryParams) {
    this.loading = true;

    let action$: Observable<BlogPost[]>;

    if (!search) {
      action$ = this.blogPostApiService.getAll({
        range: { limit: BLOG_POSTS_FEATURE_LIMIT, offset },
        authorId,
        tagId,
        type: 'list'
      });
    } else {
      action$ = this.blogPostApiService.search(search, { limit: BLOG_POSTS_FEATURE_LIMIT, offset });
    }

    action$.subscribe();
  }
}
