import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectOption } from '@ct/components';
import {
  DestroyableFeature,
  FacebookCardType,
  Features,
  FormStateDispatcher,
  MetaTagService,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleService,
  TwitterCardType
} from '@ct/core';
import { entitySlugUrl } from '@ct/shared';
import { GeocoderService } from '@ct/shared/modules/google-maps/services/geocoder.service';
import { GeolocationService } from '@ct/shared/modules/google-maps/services/geolocation.service';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MARKETPLACE_ITEMS_LIMIT } from '../../constants';
import { MarketplaceItem, SubCategory } from '../../interfaces';
import { MarketplaceQuery } from '../../services/state';
import GeocoderResult = google.maps.GeocoderResult;

interface MarketplaceQueryParams {
  offset: number;
  merchantId?: string;
  categoryId?: string;
  subCategoryId?: string;
  search?: string;
  location?: string;
  lat?: number;
  lon?: number;
}

interface Location {
  city?: string;
  state?: string;
  zip?: string;
  lat?: number;
  lng?: number;
}
const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-marketplace-items',
  templateUrl: './marketplace-items.component.html',
  styleUrls: ['./marketplace-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
@Features([SocialMediaMetaTagFeature(), DestroyableFeature()])
export class MarketplaceItemsComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public readonly limit = MARKETPLACE_ITEMS_LIMIT;
  public seoConfig: SeoConfig = {
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.MARKETPLACE',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/marketplace.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/marketplace/community'
    }
  };

  public form: UntypedFormGroup;
  public categoriesList: SelectOption[] = [];
  public subCategoriesMap = new Map<string, SubCategory[]>();
  public subCategoriesList: SelectOption[] = [];
  public readonly categories$ = this.marketplaceQuery.selectCategories$;
  public isLoading = false;

  public loading = false;
  public items: MarketplaceItem[] = [];
  public otherItems?: MarketplaceItem[] = [];

  public queryParams: MarketplaceQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public location: Location | null;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: TitleService,
    private metaTagService: MetaTagService,
    private translateService: TranslateService,
    public marketplaceQuery: MarketplaceQuery,
    private formState: FormStateDispatcher,
    private geocoderService: GeocoderService,
    private geolocationService: GeolocationService
  ) {
    this.form = new UntypedFormGroup({
      search: new UntypedFormControl('', []),
      categoryId: new UntypedFormControl('', []),
      subCategoryId: new UntypedFormControl({ value: '', disabled: true }),
      location: new UntypedFormControl('', [])
    });
  }

  ngOnInit() {
    this.marketplaceQuery.selectOtherItems$.pipe(takeUntil(this.destroyed$)).subscribe((items) => {
      this.otherItems = items;
      this.changeDetectorRef.markForCheck();
    });
    this.marketplaceQuery.selectItems$.pipe(takeUntil(this.destroyed$)).subscribe((items) => {
      this.refreshItems(items);
      this.initSeo();
    });

    const categoryControl = this.form.get('categoryId') as UntypedFormControl;
    categoryControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.populateSubCategories(value);
    });

    const locationControl = this.form.get('location') as UntypedFormControl;
    locationControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (!value) {
        this.location = null;
      }
    });

    combineLatest([this.route.queryParams, this.categories$.pipe(takeUntil(this.destroyed$))]).subscribe(
      ([queryParams, categories]) => {
        const { offset, merchantId, search, categoryId, subCategoryId, location } = queryParams;
        this.queryParams = {
          ...this.queryParams,
          offset: +offset || DEFAULT_OFFSET,
          merchantId,
          categoryId,
          subCategoryId,
          search,
          location
        };

        if (this.categoriesList.length === 0) {
          this.categoriesList = (categories ?? []).reduce((acc, { id, title, subCategories }) => {
            this.subCategoriesMap.set(id as string, subCategories as SubCategory[]);
            acc.push({ value: id as string, label: title as string });
            return acc;
          }, [] as SelectOption[]);
        }

        if (this.form.value.search !== search) {
          this.form.get('search')?.setValue(search);
        }
        if (this.form.value.categoryId !== categoryId) {
          this.form.get('categoryId')?.setValue(categoryId);
        }
        if (this.form.value.subCategoryId !== subCategoryId) {
          this.form.get('subCategoryId')?.setValue(subCategoryId);
        }
        if (this.form.value.location !== location) {
          this.form.get('location')?.setValue(location);
        }

        this.changeDetectorRef.detectChanges();
      }
    );
  }

  populateSubCategories(categoryId: string) {
    const subCategories = this.subCategoriesMap.get(categoryId) ?? [];
    this.subCategoriesList = subCategories.map(({ id, title }) => ({
      value: id as string,
      label: title as string
    }));

    const subCategoryControl = this.form.get('subCategoryId') as UntypedFormControl;
    if (this.subCategoriesList.length === 0) {
      subCategoryControl.disable();
    } else {
      subCategoryControl.enable();
    }
    if (!this.subCategoriesList.some(({ value }) => value === subCategoryControl.value)) {
      subCategoryControl.setValue('');
    }

    this.changeDetectorRef.detectChanges();
  }

  initSeo() {
    this.titleService.setTitleWithSuffix(this.translateService.instant('MAIN.FEATURES.MARKETPLACE'), 'All Items');
    this.metaTagService.setMetaDescription('All latest items for sale');
  }

  getLink(item: MarketplaceItem) {
    return entitySlugUrl('/marketplace/', item);
  }

  onPrev() {
    const nextOffset = this.queryParams.offset - this.limit;
    if (nextOffset < 0) {
      return;
    }
    this.queryParams.offset = nextOffset;
    this.refreshUrlQueryParams(this.queryParams);
  }

  onNext() {
    if (this.items?.length < this.limit) {
      return;
    }
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.refreshUrlQueryParams(this.queryParams);
  }

  refreshItems(items: MarketplaceItem[]) {
    this.loading = false;
    this.items = items;
    this.changeDetectorRef.markForCheck();
  }

  refreshUrlQueryParams({
    offset,
    merchantId,
    categoryId,
    subCategoryId,
    search,
    location,
    lat,
    lon
  }: MarketplaceQueryParams) {
    this.loading = true;

    this.router.navigate([], {
      queryParams: { offset, merchantId, categoryId, subCategoryId, search, location, lat, lon },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  onSearch() {
    this.formState.onSubmit.notify();
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.changeDetectorRef.markForCheck();

    const { ...postForm } = this.form.value;

    this.queryParams.subCategoryId = postForm.subCategoryId;
    this.queryParams.categoryId = postForm.categoryId;
    this.queryParams.search = postForm.search;

    if (this.location) {
      this.queryParams.location = `${this.location.city}, ${this.location.state}`;
      this.queryParams.lat = this.location.lat;
      this.queryParams.lon = this.location.lng;
    } else if (!postForm.location) {
      delete this.queryParams.location;
      delete this.queryParams.lat;
      delete this.queryParams.lon;
    }

    this.refreshUrlQueryParams(this.queryParams);
    this.isLoading = false;
  }

  onClearSearch() {
    this.form.get('search')?.setValue('');
  }

  onClearAll() {
    this.form.reset();
    this.onSearch();
  }

  onLocation(data: Address) {
    this.location = this.extractCity(data);
    this.form.get('location')?.setValue(`${this.location.city}, ${this.location.state}`);
  }

  onCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.setLocationFromCoords(position.coords.latitude, position.coords.longitude);
          }
        },
        () => {
          return this.geolocationService.getGeolocation().subscribe((geo) => {
            if (geo.location) {
              this.setLocationFromCoords(geo.location.lat, geo.location.lng);
            }
          });
        },
        { timeout: 10000 }
      );
    }
  }

  setLocationFromCoords(lat: number, lon: number) {
    this.geocoderService.geocode(lat, lon).subscribe((results) => {
      const found = this.extractCity(results[0]);
      this.location = found;
      this.form.get('location')?.setValue(`${found.city}, ${found.state}`);
      this.changeDetectorRef.detectChanges();
    });
  }

  extractCity(place: GeocoderResult | Address) {
    const address = place.address_components;
    let city, state, zip;
    address.forEach((component) => {
      const types = component.types;
      if (types.indexOf('locality') > -1) {
        city = component.long_name;
      }

      if (types.indexOf('administrative_area_level_1') > -1) {
        state = component.short_name;
      }

      if (types.indexOf('postal_code') > -1) {
        zip = component.long_name;
      }
    });
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    return { city, state, zip, lat, lng };
  }
}
