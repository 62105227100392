import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from '@ct/core/interfaces';
import { LocalStorageService } from '@ct/core/services';
import { Span } from '@ct/opentelemetry';
import { FirebaseCloudMessagingService } from '@ct/shared/services/firebase-cloud-messaging.service';
import { NotificationApiService } from '@ct/shared/services/notification-api.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { AuthTokens, UserIdentity } from '../interfaces';
import { AuthApiService } from './auth-api.service';
import { AuthQuery, AuthStore } from './state';
import { UserProfileApiService } from './user-profile-api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public readonly accessTokenKey: string = 'ACCESS_TOKEN';
  public readonly refreshTokenKey: string = 'REFRESH_TOKEN';

  private identity$: Observable<UserIdentity | null> = this.authQuery.select('identity');
  private profile$: Observable<UserProfile | null> = this.authQuery.select('profile');

  constructor(
    private authApiService: AuthApiService,
    private notificationsApiService: NotificationApiService,
    private firebaseCloudMessagingService: FirebaseCloudMessagingService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userProfileApiService: UserProfileApiService,
    private authStore: AuthStore,
    private authQuery: AuthQuery
  ) {}

  @Span()
  public login(username: string, password: string) {
    return this.authApiService
      .login(username, password)
      .pipe(tap(({ authenticationToken, refreshToken }) => this.setTokens(authenticationToken, refreshToken)));
  }

  @Span()
  public getIdentity({ force }: { force?: boolean } = {}): Observable<UserIdentity | null> {
    if (!this.getAccessToken()) {
      return of(null);
    }
    return this.identity$.pipe(
      take(1),
      switchMap((identity) => {
        if (identity && !force) {
          return of(identity);
        }
        return this.authApiService.getIdentity().pipe(
          tap((userIdentity: UserIdentity) => {
            this.authStore.update({ identity: userIdentity });
          }),
          catchError((err) => {
            this.authStore.update({ identity: null });
            // TODO: it should redirect?
            this.redirectToLogin().then();
            return throwError(err);
          })
        );
      })
    );
  }

  @Span()
  public getUserProfile(): Observable<UserProfile> {
    return this.profile$.pipe(
      take(1),
      switchMap((profile) => {
        if (profile) {
          return of(profile);
        }
        return this.getIdentity().pipe(
          switchMap((identity) => {
            if (!identity) {
              return throwError('no identity');
            }
            return forkJoin([
              this.userProfileApiService
                .getByUserId(identity?.guuid as string)
                .pipe(switchMap(({ id }) => this.userProfileApiService.getById(id))),
              this.userProfileApiService.getMyEmail().pipe(
                catchError((err) => {
                  if (err.status === 404) {
                    return of(null);
                  }
                  return throwError(err);
                })
              )
            ]).pipe(
              map(([userProfile, email]) => ({ ...userProfile, email })),
              tap((userProfile: UserProfile) => {
                this.authStore.update({ profile: userProfile });
              }),
              catchError((err) => {
                this.authStore.update({ profile: null });
                return throwError(err);
              })
            );
          })
        );
      })
    );
  }

  @Span()
  public refreshToken(): Observable<AuthTokens> {
    const token = this.getRefreshToken();
    return this.authApiService
      .refreshToken(token)
      .pipe(tap(({ newAuthToken, refreshToken }: AuthTokens) => this.setTokens(newAuthToken as string, refreshToken)));
  }

  @Span()
  public refreshFcmToken() {
    const existingToken = this.firebaseCloudMessagingService.getFcmDeviceToken();
    if (existingToken) {
      return this.notificationsApiService.removeToken(existingToken).pipe(
        switchMap(() => this.getIdentity().pipe(take(1))),
        tap((identity) => identity && this.firebaseCloudMessagingService.requestToken(identity.guuid))
      );
    }

    return this.getIdentity().pipe(
      take(1),
      tap((identity) => identity && this.firebaseCloudMessagingService.requestToken(identity.guuid))
    );
  }

  @Span()
  public logout(): Observable<null> {
    const fcmToken = this.firebaseCloudMessagingService.getFcmDeviceToken();
    return this.notificationsApiService.removeToken(fcmToken).pipe(
      switchMap(() => this.authApiService.logout()),
      finalize(() => {
        this.notificationsApiService.cleanupLocalNotifications();
        this.authStore.update({ profile: null, identity: null });
        this.firebaseCloudMessagingService.removeFcmDeviceToken();
        this.localStorageService.removeItem(this.accessTokenKey);
        this.localStorageService.removeItem(this.refreshTokenKey);
      })
    );
  }

  @Span()
  public isAuthenticated(): Observable<boolean> {
    return this.identity$.pipe(
      switchMap((identity) => {
        if (!identity && !this.getAccessToken()) {
          return of(false);
        }
        if (identity?.username) {
          return of(true);
        }
        return this.getIdentity().pipe(
          map((identity) => !!identity),
          catchError(() => of(false))
        );
      })
    );
  }

  @Span()
  public redirectToHomePage(url?: string): Promise<boolean> {
    return this.router.navigate([url ?? '/home']);
  }

  @Span()
  public redirectToLogin(): Promise<boolean> {
    return this.router.navigate(['/login']);
  }

  public getAccessToken(): string {
    return this.localStorageService.getItem(this.accessTokenKey) as string;
  }

  public setTokens(accessToken: string, refreshToken: string): void {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  public getRefreshToken(): string {
    return this.localStorageService.getItem(this.refreshTokenKey) as string;
  }

  public setAccessToken(token: string): void {
    this.localStorageService.setItem(this.accessTokenKey, token);
  }

  public setRefreshToken(token: string): void {
    this.localStorageService.setItem(this.refreshTokenKey, token);
  }

  @Span()
  public updateUserProfile(userProfile: UserProfile) {
    return this.profile$.pipe(
      take(1),
      tap((profile) => {
        this.authStore.update({ profile: { ...profile, ...userProfile } });
      })
    );
  }

  @Span()
  public updateIdentity(userIdentity: UserIdentity) {
    return this.identity$.pipe(
      take(1),
      tap((identity) => {
        this.authStore.update({ identity: { ...identity, ...userIdentity } });
      })
    );
  }
}
