import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components/dialog';

import { YoutubeDialogAddConfig, YoutubeVideo } from '../../interfaces';

@Component({
  selector: 'ct-youtube-video-upload-dialog',
  templateUrl: './youtube-video-add-dialog.component.html',
  styleUrls: ['./youtube-video-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeVideoAddDialogComponent implements OnInit {
  public selectable = true;
  public config: YoutubeDialogAddConfig;
  public tabIndex = 0;
  public loading = false;
  public youtubeVideos: YoutubeVideo[] = [];

  public readonly form = new FormGroup({
    youtubeVideo: new FormControl<string>('')
  });

  public closeButton: DialogButton = {
    labelKey: 'COMPONENTS.FILE_UPLOAD.CLOSE',
    clicked: () => this.dialogRef.close()
  };

  public selectButton: DialogButton = {
    labelKey: 'COMPONENTS.FILE_UPLOAD.SELECT',
    clicked: () => {
      this.dialogRef.close(this.selectedVideos);
    }
  };

  public addButton: DialogButton = {
    labelKey: 'Add',
    clicked: () => {
      const link = this.form.controls.youtubeVideo.value as string;
      return this.config.addVideoLinkFn(link).subscribe(
        (youtubeVideo: YoutubeVideo) => {
          youtubeVideo.selected = true;
          this.youtubeVideos.unshift(youtubeVideo);
          this.changeDetectorRef.detectChanges();
          this.dialogRef.close([youtubeVideo]);
        },
        () => {
          // TODO: handle error
        }
      );
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: YoutubeDialogAddConfig },
    private dialogRef: MatDialogRef<YoutubeVideoAddDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.config = { ...this.config, ...this.data?.config };
    this.selectable = this.config.selectable;
  }

  get selectedVideos() {
    return this.youtubeVideos.filter((i) => i.selected);
  }

  get buttons() {
    if (this.tabIndex === 0) {
      return [this.addButton];
    }
    return [this.config.selectable ? this.selectButton : this.closeButton];
  }

  ngOnInit() {
    if (this.config.getAllVideosFn) {
      this.loading = true;
      this.config.getAllVideosFn().subscribe((videos) => {
        this.youtubeVideos = [...this.youtubeVideos, ...videos];
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  onSelectVideo(video: YoutubeVideo) {
    if (!this.config?.selectable) {
      return;
    }
    video.selected = !video.selected;
    this.changeDetectorRef.detectChanges();
  }

  deselectAll() {
    this.youtubeVideos.forEach((video) => (video.selected = false));
  }
}
