import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@ct/auth';
import { BlogPost, BlogPostStatus, RequestRange } from '@ct/core';
import { BlogPostApiService, BlogPostFactory, PostsStore, SortOrder } from '@ct/shared';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MyAccountBlogPostApiService extends BlogPostApiService {
  constructor(
    public httpClient: HttpClient,
    public blogPostFactory: BlogPostFactory,
    private authService: AuthService
  ) {
    super(httpClient, blogPostFactory, new PostsStore());
  }

  getAllByAuthorId(range?: RequestRange, status?: BlogPostStatus, sortOrder: SortOrder = SortOrder.Desc) {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return super.getAll({ range, status, sortOrder, authorId: identity?.guuid });
      })
    );
  }

  getMyLastEdited(): Observable<BlogPost> {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return super.getAll({ range: { limit: 1 }, sortBy: 'modifiedOn', authorId: identity?.guuid });
      }),
      map((stories) => stories[0])
    );
  }
}
