import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { GalleryItemType, LoadingStrategy } from '../../enums';
import { GalleryConfig } from '../../interfaces';

@Component({
  selector: 'ct-gallery-item',
  templateUrl: './gallery-item.component.html',
  styleUrls: ['./gallery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryItemComponent {
  public readonly Types = GalleryItemType;

  @Input() config: GalleryConfig | null;
  @Input() index: number;
  @Input() currIndex: number | undefined;
  @Input() type: string | undefined;
  @Input() data: any; // Item's data, this object contains the data required to display the content (e.g. src path)

  @Output() errorItem = new EventEmitter<Error>();

  @HostBinding('class.g-active-item') get isActive() {
    return this.index === this.currIndex;
  }

  get isAutoPlay() {
    if (this.isActive) {
      if (this.type === GalleryItemType.Video || this.type === GalleryItemType.Youtube) {
        return this.data.autoplay;
      }
    }
  }

  get youtubeSrc() {
    let autoplay: 1 | 0 = 0;
    if (this.isActive && this.type === GalleryItemType.Youtube && this.data.autoplay) {
      autoplay = 1;
    }
    const url = new URL(this.data.src);
    url.search = new URLSearchParams({
      wmode: 'transparent',
      ...this.data.params,
      autoplay
    }).toString();
    return url.href;
  }

  get load() {
    switch (this.config?.loadingStrategy) {
      case LoadingStrategy.Preload:
        return true;
      case LoadingStrategy.Lazy:
        return this.currIndex === this.index;
      default:
        return this.currIndex === this.index || this.currIndex === this.index - 1 || this.currIndex === this.index + 1;
    }
  }
}
