import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterItem } from '@ct/components';
import { Features, TitleConfig, TitleFeature } from '@ct/core';
import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { MarketplaceApiService } from '../../../../../marketplace';
import { MarketplaceItem } from '../../../../../marketplace/interfaces';
import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../../constants';

enum ItemSoldStatus {
  All = 'all',
  Sold = 'sold',
  Available = 'available'
}
interface PostQueryParams {
  offset: number;
  filter: ItemSoldStatus;
}

const DEFAULT_FILTER = ItemSoldStatus.All;
const DEFAULT_OFFSET = 0;
@Component({
  selector: 'ct-my-account-marketplace',
  templateUrl: './my-account-marketplace.component.html',
  styleUrls: ['./my-account-marketplace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountMarketplaceComponent {
  public readonly limit = MY_ACCOUNT_POSTS_FEATURE_LIMIT;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_POSTS'
  };
  public filters: FilterItem[] = [
    { name: ItemSoldStatus.All, labelKey: 'MY_ACCOUNT.MY_MARKETPLACE_FEATURE.VIEW_ALL' },
    { name: ItemSoldStatus.Available, labelKey: 'MY_ACCOUNT.MY_MARKETPLACE_FEATURE.AVAILABLE' },
    { name: ItemSoldStatus.Sold, labelKey: 'MY_ACCOUNT.MY_MARKETPLACE_FEATURE.SOLD' }
  ];

  public items: MarketplaceItem[] = [];

  public queryParams: PostQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER
  };
  public loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private marketplaceApiService: MarketplaceApiService
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ items }) => (this.items = items));
    this.route.queryParams.pipe(take(1)).subscribe(({ offset, filter }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset,
        filter: filter || this.queryParams.filter
      };
      this.refreshUrlQueryParams(this.queryParams);
    });
  }

  getLink(item: MarketplaceItem) {
    return `${item.id}`;
  }

  onFilterChanged({ name }: FilterItem) {
    if (name === this.queryParams.filter) {
      return;
    }
    this.queryParams = {
      ...this.queryParams,
      offset: DEFAULT_OFFSET,
      filter: name
    };
    return this.loadItems(this.queryParams).subscribe((items) => this.refreshItems(items));
  }

  onPrev() {
    const nextOffset = this.queryParams.offset - this.limit;
    if (nextOffset < 0) {
      return;
    }
    this.queryParams.offset = nextOffset;
    this.loadItems(this.queryParams).subscribe((items) => this.refreshItems(items));
  }

  onNext() {
    if (this.items?.length < this.limit) {
      return;
    }
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadItems(this.queryParams).subscribe((items) => this.refreshItems(items));
  }

  loadItems({ offset, filter }: PostQueryParams) {
    this.loading = true;
    let sold;
    if (filter && filter !== ItemSoldStatus.All) {
      sold = filter === ItemSoldStatus.Sold;
    }
    return this.marketplaceApiService.getAllMyItems({ range: { limit: this.limit, offset }, sold }).pipe(
      tap(() => (this.loading = false)),
      catchError((err) => {
        this.loading = false;
        return throwError(err);
      })
    );
  }

  refreshItems(items: MarketplaceItem[]) {
    this.items = items;
    this.changeDetectorRef.detectChanges();
    this.refreshUrlQueryParams(this.queryParams);
  }

  refreshUrlQueryParams({ offset, filter }: PostQueryParams) {
    this.router
      .navigate([], {
        queryParams: { filter, offset },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
      .then(() => this.changeDetectorRef.detectChanges());
  }
}
