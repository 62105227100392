import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from '@ct/components';
import { ButtonModule } from '@ct/components/button';
import { CheckboxModule } from '@ct/components/checkbox';
import { DateModule } from '@ct/components/date';
import { DialogModule } from '@ct/components/dialog';
import { FileUploadSharedModule } from '@ct/components/file-upload/modules/file-upload-shared';
import { IconModule } from '@ct/components/icon';
import { InputModule } from '@ct/components/input';
import { KeywordInputModule } from '@ct/components/keyword-input';
import { SelectModule } from '@ct/components/select';
import { TagSelectModule } from '@ct/components/tag-select';
import { TextEditorModule } from '@ct/components/text-editor';
import { ToggleModule } from '@ct/components/toggle';
import { TranslateModule } from '@ngx-translate/core';

import { BlogSharedModule } from '../blog-shared/blog-shared.module';
import { MediaSharedModule } from '../media-shared/media-shared.module';
import { VideoItemModule } from '../video-item/video-item.module';
import { YoutubeModule } from '../youtube/youtube.module';
import { AddToWritingsDialogComponent } from './add-to-writings-dialog.component';
import { StoryAddDialogComponent, WritingJournalAddDialogComponent } from './components';

@NgModule({
  declarations: [AddToWritingsDialogComponent, StoryAddDialogComponent, WritingJournalAddDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    DateModule,
    InputModule,
    IconModule,
    MediaSharedModule,
    TagSelectModule,
    TextEditorModule,
    SelectModule,
    BlogSharedModule,
    YoutubeModule,
    CheckboxModule,
    KeywordInputModule,
    ToggleModule,
    FileUploadSharedModule,
    SpinnerModule,
    VideoItemModule
  ],
  exports: [AddToWritingsDialogComponent, StoryAddDialogComponent, WritingJournalAddDialogComponent]
})
export class AddToWritingsDialogModule {}
