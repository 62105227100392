import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components';
import { BlogPost, BlogPostStatus, GroupWithStories } from '@ct/core';
import { getCroppedThumbPublicUrl } from '@ct/shared';

@Component({
  selector: 'ct-publish-story-dialog',
  templateUrl: './publish-story-dialog.component.html',
  styleUrls: ['./publish-story-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishStoryDialogComponent implements OnInit {
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  public story: BlogPost;
  public groups: GroupWithStories[] = [];
  protected isPublic: boolean;
  protected buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'warn',
      clicked: () => this.dialogRef.close()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onConfirm();
      },
      color: 'primary'
    }
  ];
  protected readonly sharedGroupIds = new Set<string | undefined>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { story: BlogPost; groups: GroupWithStories[] },
    private dialogRef: MatDialogRef<PublishStoryDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.story = this.data.story;
    this.groups = this.data.groups;
    this.isPublic = this.story.status === BlogPostStatus.Published;
    const id = String(this.story.id);
    this.groups.forEach((group) => {
      if (group.sharedBlogPosts?.includes(id)) {
        this.sharedGroupIds.add(group.id);
      }
    });
    this.cdr.detectChanges();
  }

  onShare(share: boolean, group: GroupWithStories) {
    share ? this.sharedGroupIds.add(group.id) : this.sharedGroupIds.delete(group.id);
  }

  onTogglePublic(published: boolean) {
    this.isPublic = published;
  }

  onConfirm() {
    const toAdd: string[] = [];
    const toRemove: string[] = [];

    const id = String(this.story.id);
    this.groups.forEach((group) => {
      if (group.sharedBlogPosts.includes(id)) {
        if (!this.sharedGroupIds.has(group.id)) {
          toRemove.push(group.id as string);
        }
      } else if (this.sharedGroupIds.has(group.id)) {
        toAdd.push(group.id as string);
      }
    });

    this.dialogRef.close({ toAdd, toRemove, isPublic: this.isPublic });
  }
}
