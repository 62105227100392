import { Injectable } from '@angular/core';
import { UploadedImage } from '@ct/core';

import { MarketplaceItem } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceItemFactory {
  toRequest(marketplaceItem: MarketplaceItem): MarketplaceItem {
    const { id, photos, ...value } = marketplaceItem;
    return {
      ...value,
      photoIds: photos?.map((photo: UploadedImage) => photo.id) as string[]
    };
  }
}
