import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { MarketplaceItem } from '../interfaces';
import { MarketplaceApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class MarketplaceItemDataResolver implements Resolve<MarketplaceItem> {
  constructor(private marketplaceApiService: MarketplaceApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const slugWithId = activatedRouteSnapshot.params.slugWithId;
    const slugArray = slugWithId.split('-');
    const id = slugArray[slugArray.length - 1];
    return this.marketplaceApiService.getById(id);
  }
}
