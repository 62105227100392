import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ct-blog-posts-search',
  templateUrl: './blog-posts-search.component.html',
  styleUrls: ['./blog-posts-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPostsSearchComponent implements OnInit {
  public search: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ search }) => {
      this.search = search;
    });
  }
}
