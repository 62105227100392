import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ct-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent {
  @Input() readonly name: string | undefined;
  @Input() readonly removable: boolean | undefined;
  @Input() readonly color: ThemePalette | string;

  @Output() readonly removed = new EventEmitter<void>();
}
