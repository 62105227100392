import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import {
  AuthorModule,
  ButtonModule,
  CardModule,
  CheckboxModule,
  DataListModule,
  DialogModule,
  GalleryModule,
  IconModule,
  ImageBannerModule,
  ImageModule,
  ImageUploadModule,
  InputModule,
  LinkModule,
  NavigationModule,
  ReCaptchaModule,
  SelectModule,
  SpinnerModule,
  TabHeaderModule,
  TabsMobileModule,
  TagModule,
  TextareaModule,
  TextEditorModule,
  ToggleModule,
  UserAvatarModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { LayoutModule } from '@ct/layout';
import { CardItemModule, GoogleMapsModule, MediaSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import {
  CreateMarketplaceItemComponent,
  CreateMerchantProfileComponent,
  MarketplaceCommunityComponent,
  MarketplaceComponent,
  MarketplaceHomeComponent,
  MarketplaceItemComponent,
  MarketplaceItemExtendedComponent,
  MarketplaceItemsAllComponent,
  MarketplaceItemsByAuthorComponent,
  MarketplaceItemsComponent,
  MarketplaceItemsWrapperComponent,
  MarketplaceSellingComponent
} from './components';
import { ContactSellerDialogComponent } from './components/contact-seller-dialog/contact-seller-dialog.component';
import { MarketplaceRoutingModule } from './marketplace-routing.module';

@NgModule({
  declarations: [
    MarketplaceComponent,
    CreateMarketplaceItemComponent,
    CreateMerchantProfileComponent,
    MarketplaceItemComponent,
    MarketplaceItemExtendedComponent,
    MarketplaceItemsComponent,
    MarketplaceItemsAllComponent,
    ContactSellerDialogComponent,
    MarketplaceHomeComponent,
    MarketplaceItemsByAuthorComponent,
    MarketplaceItemsWrapperComponent,
    MarketplaceSellingComponent,
    MarketplaceCommunityComponent
  ],
  exports: [MarketplaceItemComponent],
  imports: [
    CommonModule,
    MarketplaceRoutingModule,
    TranslateModule,
    ImageBannerModule,
    CardModule,
    InputModule,
    ReactiveFormsModule,
    CheckboxModule,
    ButtonModule,
    NavigationModule,
    PipesModule,
    LinkModule,
    AuthorModule,
    ImageModule,
    TextEditorModule,
    GalleryModule,
    MediaSharedModule,
    ImageUploadModule,
    MatSnackBarModule,
    SelectModule,
    DataListModule,
    TagModule,
    ToggleModule,
    UserAvatarModule,
    DialogModule,
    TextareaModule,
    ReCaptchaModule,
    MatTabsModule,
    SpinnerModule,
    GoogleMapsModule,
    AgmCoreModule,
    LayoutModule,
    CardItemModule,
    TabHeaderModule,
    TabsMobileModule,
    IconModule,
    InfiniteScrollModule
  ]
})
export class MarketplaceModule {}
