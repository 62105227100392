import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { LikeSummary, UserProfile } from '@ct/core';

@Component({
  selector: 'ct-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikeComponent implements OnChanges {
  @Input() likes: LikeSummary;
  @Input() user: UserProfile | null;
  @Output() like: EventEmitter<string> = new EventEmitter();
  @Output() dislike: EventEmitter<string> = new EventEmitter();

  isLiked: boolean;
  count: number;
  loading = true;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges({ user, likes }: SimpleChanges) {
    // can it happen?
    if (this.likes && user?.currentValue && !user.firstChange) {
      this.loadLikes(this.likes);
    } else if (likes?.currentValue !== likes?.previousValue) {
      this.loadLikes(likes.currentValue ?? {});
    }
  }

  @HostListener('click', ['$event.target']) likePost() {
    if (this.loading || !this.user) {
      return;
    }
    this.loading = true;
    if (!this.isLiked) {
      this.like.emit();
    } else {
      this.dislike.emit();
    }
  }

  loadLikes(data: LikeSummary) {
    const { count, isLiked } = data;
    this.count = count;
    this.isLiked = isLiked;
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
}
