import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeletionDialogComponent, DialogService, SelectOption } from '@ct/components';
import { FormStateDispatcher, UploadedImage } from '@ct/core';
import { ImageUploadApiService, TripCampsiteApiService } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-trip-photo-edit',
  templateUrl: './my-account-trip-photo-edit.component.html',
  styleUrls: ['./my-account-trip-photo-edit.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountTripPhotoEditComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public readonly form = new UntypedFormGroup({
    campsiteId: new UntypedFormControl(null),
    description: new UntypedFormControl('')
  });

  public campsites: SelectOption[] = [];

  get photo(): UploadedImage {
    return this.data.photo;
  }

  get tripId() {
    return String(this.data.trip.id);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MyAccountTripPhotoEditComponent>,
    private formState: FormStateDispatcher,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private imageUploadApiService: ImageUploadApiService
  ) {
    this.tripCampsiteApiService
      .getAllByTripId(this.tripId as string)
      .pipe(
        map((campsites) => campsites.map((campsite) => ({ value: campsite.id, label: campsite.title } as SelectOption)))
      )
      .subscribe((campsites) => (this.campsites = campsites));
  }

  ngOnInit() {
    if (this.photo) {
      this.form.patchValue(this.photo);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    return this.imageUploadApiService
      .update(this.photo?.id, this.form.value)
      .subscribe(() => this.dialogRef.close(true));
  }

  onDelete() {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_PHOTO_TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_PHOTO_MESSAGE')
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        map(() => this.photo),
        switchMap(({ id, tripIds, campsiteIds }) =>
          this.imageUploadApiService.update(id, {
            tripIds: tripIds?.filter((tripId: string) => tripId !== this.tripId),
            campsiteIds: campsiteIds?.filter(
              (campsiteId: string) => !this.campsites.find(({ value }) => value === campsiteId)
            )
          })
        )
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
