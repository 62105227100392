import { AbstractControl } from '@angular/forms';

// regexp is taken from here: https://regexr.com/3cg7r
// instagram username regexp
export function UsernameValidator({ value }: AbstractControl) {
  const regexp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
  const valid = !value || regexp.test(value);

  return valid ? null : { username: true };
}
