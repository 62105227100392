<div class="my-account-sidebar">
  <div class="container">
    <div>
      <ct-sidebar-section class="mb-5">
        <ct-link
          link="https://www.ritron.com/blog/best-walkie-talkies-for-camping-radio-and-the-great-outdoors"
          class="ad-banner"
        >
          <img src="assets/ad-banners/ritron_radio.png" alt="Ritron Camping Walkie Talkies Ad" loading="lazy" />
        </ct-link>
      </ct-sidebar-section>
      <ct-sidebar-section *ngIf="(isLoggedIn$ | async) === false" class="mb-5">
        <ct-link link="https://camping.tools/app?ref=app.camping.tools" class="signup-banner">
          <img src="assets/images/sidebar-banner-mobile-app.jpg" alt="Part of Mobile App" loading="lazy" />
        </ct-link>
      </ct-sidebar-section>
      <!-- TODO: uncomment it once Search API is working -->
      <!--      <ct-input
        [formControl]="searchControl"
        [placeholder]="'BLOG.SIDEBAR.SEARCH_PLACEHOLDER' | translate"
        [prefixIcon]="'search'"
        (prefixIconClicked)="onSearch()"
        (keyup.enter)="onSearch()"
      ></ct-input>-->
      <ct-sidebar-section
        *ngIf="posts$ | async as posts"
        class="mb-4"
        [titleKey]="'BLOG.SIDEBAR.RECENT_POSTS'"
        background="white"
      >
        <ng-container *ngIf="posts?.length">
          <ct-blog-post-card
            *ngFor="let post of posts"
            [link]="getLink(post?.id, post?.slug)"
            class="mb-4"
            [post]="post"
            [showLink]="true"
            [showStatus]="false"
            [showAuthor]="false"
            [showTags]="false"
            [linkFullWidth]="true"
            imgHeight="100px"
            imgWidth="100%"
            [imgCols]="12"
            [contentCols]="12"
          ></ct-blog-post-card>
        </ng-container>
        <ng-container *ngIf="!posts?.length">
          <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
      <ct-sidebar-section
        *ngIf="tags$ | async as tags"
        class="mb-4"
        [titleKey]="'BLOG.SIDEBAR.RECENT_TAGS'"
        background="white"
      >
        <ng-container *ngIf="tags?.length">
          <ct-link
            *ngFor="let tag of tags"
            [link]="['/stories/by-tag']"
            [queryParams]="{ tagId: tag.id, authorId: null }"
            inline="true"
          >
            <ct-tag class="mr-1 mb-1" [name]="tag?.name"></ct-tag>
          </ct-link>
        </ng-container>
        <ng-container *ngIf="!tags?.length">
          <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
      <ct-sidebar-section
        *ngIf="users$ | async as users"
        class="mb-4"
        [titleKey]="'BLOG.SIDEBAR.RECENT_CONTRIBUTORS'"
        background="white"
      >
        <ng-container *ngIf="users?.length">
          <ct-link
            *ngFor="let user of users"
            [link]="['/stories/by-author']"
            [queryParams]="{ authorId: user.userId, tagId: null }"
          >
            <div class="user">
              <ct-user-avatar
                class="user__avatar"
                [src]="user?.photo?.publicUrl"
                width="32px"
                height="32px"
              ></ct-user-avatar>
              <span class="user__name">{{ user.username }}</span>
            </div>
          </ct-link>
        </ng-container>
        <ng-container *ngIf="!users?.length">
          <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
    </div>
  </div>
</div>
