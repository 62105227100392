import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ct/auth';
import { MenuItem, translateMenuItems } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { basicMenuItems, loggedInMenuItems, notLoggedInMenuItems } from '../../constants';

@Component({
  selector: 'ct-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public get menuItems$(): Observable<MenuItem[]> {
    return this.isLoggedIn$.pipe(
      map((isLoggedIn) =>
        translateMenuItems(
          [...basicMenuItems, ...(isLoggedIn ? loggedInMenuItems : notLoggedInMenuItems)],
          (key: string) => this.translateService.get(key)
        )
      )
    );
  }

  public get year() {
    return new Date().getFullYear().toString();
  }

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  public isSent = false;

  constructor(private translateService: TranslateService, private router: Router, private authService: AuthService) {}

  onMenuItem(menuItem: MenuItem) {
    if (menuItem.name === 'logout') {
      return this.authService.logout().subscribe();
    }
    if (menuItem.name === 'login' && !this.router.url.includes(menuItem.link)) {
      return this.router.navigate([menuItem.link], { queryParams: { returnUrl: this.router.url } });
    }
    return false;
  }
}
