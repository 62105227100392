import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TabHeader } from './tab-header.interface';

@Component({
  selector: 'ct-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabHeaderComponent {
  @Input() tabs: TabHeader[];
  @Input() backgroundColor: string;

  @Output() tabChange = new EventEmitter<TabHeader>();

  onTabClicked(tab: TabHeader): void {
    this.tabChange.emit(tab);
  }
}
