import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '@ct/components/dialog/services';
import { Trip } from '@ct/shared/modules/trip-shared/interfaces/trip.interface';
import { CreateTripDialogComponent } from '@ct/shared/modules/trip-shared/modules/create-trip-dialog';

import { SidebarItem } from '../../interfaces';

@Component({
  selector: 'ct-layout-sidebar',
  templateUrl: './layout-sidebar.component.html',
  styleUrls: ['./layout-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidebarComponent {
  @Input() visibleFullList: boolean;

  @Input() isMobile: boolean;

  @Input() accountMenuItems: SidebarItem[];

  @Input() generalMenuItems: SidebarItem[];

  @Input() trip: Trip;

  @Output() addToTrip: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleList: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dialogService: DialogService) {}

  onToggleList() {
    this.toggleList.emit();
  }

  openNewTripDialog() {
    this.dialogService.open(CreateTripDialogComponent);
  }

  addToTripDialog() {
    this.addToTrip.emit();
  }
}
