<div class="layout-container" (click)="closeHeaderMenu()" [class.is-mobile]="mobileQuery.matches">
  <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      [fixedTopGap]="headerHeight"
    >
      <ct-layout-sidebar
        [isMobile]="mobileQuery.matches"
        [trip]="trip"
        [visibleFullList]="visibleFullList"
        [accountMenuItems]="accountMenuItems"
        [generalMenuItems]="generalMenuItems"
        (toggleList)="toggleList()"
        (addToTrip)="addToTripDialog()"
      ></ct-layout-sidebar>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="header w-100">
        <ct-layout-header
          [user]="user"
          [notifications]="notifications"
          [trip]="trip"
          [sidebarShown]="snav.openedChange | async"
          (toggleSidebar)="snav.toggle()"
          (toggleHeaderMenu)="toggleHeaderMenu($event)"
          (addToTrip)="addToTripDialog()"
          [userButtons]="userButtons"
          (logout)="onLogout()"
          [isMobile]="mobileQuery.matches"
          [showSearch]='showSearch'
          [headerMenuCollapsed]="headerMenuCollapsed"
          [visibleFullList]="visibleFullList"
        >
          <ng-content select="[ctHeader]"></ng-content>
        </ct-layout-header>
      </div>
      <div class="container">
        <ng-content select="[ctContent]"></ng-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <ct-layout-footer></ct-layout-footer>
</div>
