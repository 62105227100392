import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ct-sidebar-section-item',
  templateUrl: './sidebar-section-item.component.html',
  styleUrls: ['./sidebar-section-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSectionItemComponent {
  @Input() title: string | undefined;
  @Input() date: Date | string | undefined;
  @Input() username: string | undefined;
}
