import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

import { MerchantProfile } from '../interfaces';

const endpoint = environment.merchantProfileApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class MerchantProfileApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  create(body: any): Observable<any> {
    return this.post('', body);
  }

  getMyProfile(): Observable<MerchantProfile> {
    return this.get('my');
  }
}
