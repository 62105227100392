import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';

import { VersionCheckComponent } from './components';

@NgModule({
  declarations: [VersionCheckComponent],
  imports: [CommonModule, ServiceWorkerModule, MatSnackBarModule],
  exports: [VersionCheckComponent]
})
export class VersionCheckModule {}
