import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApiService } from '@ct/auth';
import { PasswordsComponent } from '@ct/components/password';
import { Features, FormStateDispatcher, TitleConfig, TitleFeature } from '@ct/core';

@Component({
  selector: 'ct-my-account-profile-change-password',
  templateUrl: './my-account-profile-change-password.component.html',
  styleUrls: ['./my-account-profile-change-password.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountProfileChangePasswordComponent {
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_CHANGE_PASSWORD'
  };

  public form = new UntypedFormGroup({
    passwords: new UntypedFormControl()
  });

  @ViewChild(PasswordsComponent) passwordsComponent: PasswordsComponent<any>;

  constructor(
    private formState: FormStateDispatcher,
    private router: Router,
    private route: ActivatedRoute,
    private authApiService: AuthApiService
  ) {}

  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  onConfirm() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    const { password, confirmPassword } = this.form.controls.passwords.value;

    this.authApiService.changePassword(password, confirmPassword).subscribe(
      () => this.router.navigate(['..'], { relativeTo: this.route }),
      (error) =>
        error?.status === 400
          ? this.passwordsComponent?.confirmPasswordControl?.setErrors({ passwordIsNotValid: true })
          : null
    );
  }
}
