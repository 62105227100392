import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IconRegistryService } from './icon-registry.service';

@Injectable()
export class IconService {
  constructor(private readonly iconRegistryService: IconRegistryService) {}

  getAvailableIconList(): string[] {
    return this.iconRegistryService.getAvailableIconList();
  }

  getIcon(name: string, url?: string): Observable<SVGElement> {
    if (url) {
      this.iconRegistryService.addSvgIcon(name, url);
    }

    return this.iconRegistryService.getSvgIcon(name);
  }
}
