import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule, ImageModule, StaticPagesModule, VideoModule } from '@ct/components';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule, MediaSharedModule, TripSharedModule, YoutubeModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';

import { MyAccountSharedModule } from '../my-account-shared';
import { MyAccountOverviewComponent } from './components/my-account-overview';
import { MyAccountOverviewMarketplaceComponent } from './components/my-account-overview-marketplace';
import { MyAccountOverviewPostsComponent } from './components/my-account-overview-posts';
import { MyAccountOverviewTripsComponent } from './components/my-account-overview-trips';

@NgModule({
  declarations: [
    MyAccountOverviewComponent,
    MyAccountOverviewTripsComponent,
    MyAccountOverviewPostsComponent,
    MyAccountOverviewMarketplaceComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    ImageModule,
    VideoModule,
    MyAccountSharedModule,
    BlogSharedModule,
    TripSharedModule,
    MediaSharedModule,
    StaticPagesModule,
    YoutubeModule,
    LayoutModule
  ]
})
export class MyAccountOverviewModule {}
