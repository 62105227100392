import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UploadedImage, UserProfile } from '@ct/core';
import { CardItem } from '@ct/shared';

import { MarketplaceItem } from '../../interfaces';

@Component({
  selector: 'ct-marketplace-item',
  templateUrl: './marketplace-item.component.html',
  styleUrls: ['./marketplace-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketplaceItemComponent {
  @Input() showLink = false;
  @Input() showAuthor = true;
  @Input() imgHeight: string;
  @Input() imgWidth: string;
  @Input() objectFit = 'cover';
  @Input() item: MarketplaceItem | undefined;
  @Input() link: string;
  @Input() imgCols = 3;
  @Input() contentCols = 9;

  get itemWithPhoto(): CardItem {
    return {
      ...this.item,
      author: this.item?.merchant as UserProfile,
      featuredPhoto: (this.hasPhotos && (this.item?.photos as UploadedImage[])[0]) || undefined
    };
  }

  get hasPhotos(): boolean {
    return Boolean(this.item?.photos?.length);
  }

  get thumbnail(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].xsPreview : undefined;
  }

  get featured(): string | undefined {
    return this.hasPhotos ? this.item?.photos?.[0].path : undefined;
  }

  get isHorizontal() {
    return this.imgCols === 12;
  }
}
