import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthorModule } from '@ct/components/author';
import { ButtonModule } from '@ct/components/button';
import { ImageModule } from '@ct/components/image';
import { LinkModule } from '@ct/components/link';
import { TagModule } from '@ct/components/tag';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { CardItemComponent } from './card-item.component';

@NgModule({
  declarations: [CardItemComponent],
  imports: [CommonModule, ImageModule, LinkModule, TranslateModule, AuthorModule, PipesModule, ButtonModule, TagModule],
  exports: [CardItemComponent]
})
export class CardItemModule {}
