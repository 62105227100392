import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { JournalEntryApiService, TripJournalEntry } from '@ct/shared';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../constants';

@Injectable({ providedIn: 'root' })
export class MyAccountJournalEntriesDataResolverService implements Resolve<TripJournalEntry[]> {
  constructor(private journalEntryApiService: JournalEntryApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    const campsiteId = activatedRouteSnapshot.queryParams?.campsiteId as string;
    return this.journalEntryApiService.getAll({ range: { limit: MY_ACCOUNT_POSTS_FEATURE_LIMIT, offset }, campsiteId });
  }
}
