<div class="filter-pane">
  <a
    *ngFor="let filter of filters"
    class="filter pr-3"
    [class.filter-active]="activeFilter === filter.name"
    (click)="filterChanged.emit(filter)"
  >
    {{ filter.labelKey | translate }}
  </a>
</div>
