import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, BlogPost, BlogPostStatus, FlagStory, HeaderType, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { stripTags } from '@ct/shared/helpers';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BlogPostFactory } from '../factories';
import { SortOrder } from './../../../enums';
import { PostsStore } from './state/posts.store';

const endpoint = environment.blogApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class BlogPostApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient, public blogPostFactory: BlogPostFactory, public store: PostsStore) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    status,
    sortOrder = SortOrder.Desc,
    sortBy,
    authorId,
    tagId,
    type,
    url = 'posts'
  }: {
    range?: RequestRange;
    status?: BlogPostStatus;
    sortOrder?: SortOrder;
    sortBy?: keyof BlogPost;
    authorId?: string;
    tagId?: string;
    type?: string;
    url?: string;
  }): Observable<BlogPost[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (status !== undefined && status !== null && status !== BlogPostStatus.All) {
      params = params.append('status', status);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (tagId !== undefined && tagId !== null) {
      params = params.append('tagId', tagId);
    }
    return this.get(url, params, { headers, withCredentials: true }).pipe(
      tap((posts) => {
        if (type === 'list') {
          // save to store only for list requests.
          this.store.update({ posts });
        }
      })
    );
  }

  getById(id: string): Observable<BlogPost | undefined> {
    return this.get(`posts/${id}`);
  }

  remove(id: string): Observable<undefined> {
    return this.delete(`posts/${id}`);
  }

  create(post: BlogPost): Observable<BlogPost> {
    const request = this.blogPostFactory.toRequest(post);
    return this.post('posts', request);
  }

  update(id: string, post: Partial<BlogPost>): Observable<BlogPost> {
    const request = this.blogPostFactory.toRequest(post);
    return this.patch(`posts/${id}`, request);
  }

  flagAsInappropriate(blogPostId: string, flagStory: FlagStory) {
    const request = { reason: stripTags(flagStory.reason) };
    return this.post(`posts/${blogPostId}/flag`, request);
  }

  getAllThreads(blogPostId: string) {
    return this.get(`posts/${blogPostId}/threads`);
  }

  getLatestTags() {
    return this.get(`posts/latest/tags`);
  }

  search(search: string, range: RequestRange, type: string = 'list') {
    return this.get(`posts/search`, { query: search, ...range }).pipe(
      tap((posts) => {
        if (type === 'list') {
          this.store.update({ posts });
        }
      })
    );
  }
}
